import { createFeatureSelector } from '@ngrx/store';
import { Order, OrderAdmin, OrderAdminInvitation, QuickFormOrder, QuickFormOrderAction } from '../models';
import TextAssetsState from '../models/text-assets-state';
import { QuickformOrderFeaturesState } from './quickform-order-features/quickform-order-features.reducer';
import { PrecautionInfoState } from '../../company-portal/store/common';
import { FuneralAddressState } from '../features/funeral-address/store/funeral-addresses-state.model';
import { FuneralPrintState } from '../features/funeral-print/store/funeral-documents-state.model';
import { FuneralMusicState } from '../features/funeral-music/store/funeral-music-state.model';
import { OrderSummary } from '@order/models/quickform-order-summary';

export enum QuickFormOrderLoadingState {
  NotLoaded,
  Loading,
  Loaded,
  Error
}

export interface QuickFormState {
  order: QuickFormOrder;
  orderActions: QuickFormOrderAction[];
  selectedOrderAction: QuickFormOrderAction;
  orderActionsLoading: QuickFormOrderLoadingState;
  orderLoadStatus: QuickFormOrderLoadingState;
  orderSummary: OrderSummary;
}

export interface OrderPortalState {
  order: Order;
  orderAdmins: OrderAdmin[];
  quickFormState: QuickFormState;
  funeralPrintState: FuneralPrintState;
  orderAdminInvitations: OrderAdminInvitation[];
  orderFeatures: string[];
  quickformOrderFeatures: QuickformOrderFeaturesState;
  textAssetsState: TextAssetsState;
  precautionInfoState: PrecautionInfoState;
  funeralAddressState: FuneralAddressState;
  funeralMusicState: FuneralMusicState;
}

export const orderPortalStateFeatureKey = 'orderPortalState';
export const orderPortalStateFeatureSelector = createFeatureSelector(orderPortalStateFeatureKey);
