<form
  (ngSubmit)="submitForm()"
  [formGroup]="registrationForm"
  class="mpac-reg-frm"
  autocomplete="off"
  *ngIf="registrationForm">
  <img class="mpac-reg-frm__logo" alt="Logo" src="{{ logoPath }}" title="Logo" />

  <mpac-spinner-full
    [isBusy]="isRegistrationInProgress"
    spinnerText="{{ 'forms.labels.shared.dataTransferInProgress' | translate }}"
    [overlayMode]="true">
    <div [innerHTML]="introTextHTML" class="mpac-reg-frm__txt"></div>

    <div class="mpac-reg-frm-user">
      <label for="userName" class="mpac-reg-frm-user__lbl">{{
        'forms.labels.userRegistration.e-mail' | translate
      }}</label>

      <!-- If userName is passed: -show the user name as text
                          If not: -show an input field           -->
      <span *ngIf="registrationData.userName" class="mpac-reg-frm-user__email">{{ registrationData.userName }}</span>
      <div *ngIf="!registrationData.userName" class="input-group mb-3 custom-prepend userName">
        <input
          attr.aria-label="{{ 'forms.labels.userRegistration.userName' | translate }}"
          class="form-control"
          formControlName="userName"
          id="userName"
          placeholder="{{ 'forms.placeholders.userRegistration.userName' | translate }}"
          type="text"
          autocomplete="off"
          libTrim />
        <p
          [@slideUpDown]="{
            value: slideUpDownValueFor(!!getFormControls.userName.errors?.maxlength),
            params: { maxHeight: 20 }
          }"
          class="validation-feedback mb-0 invalid text-danger mt-1"
          *ngIf="getFormControls.userName.touched && getFormControls.userName.errors?.maxlength">
          {{
            'forms.errorMessages.shared.maxLength'
              | translate
                : {
                    actualLength: getFormControls.userName.errors.maxlength.actualLength,
                    maxLength: getFormControls.userName.errors.maxlength.requiredLength
                  }
          }}
        </p>
      </div>
    </div>

    <!-- PASSWORD  INPUT FIELD -->
    <div class="mpac-reg-frm-pass">
      <label for="password" class="mpac-reg-frm__lbl">{{
        'forms.labels.userRegistration.choosePassword' | translate
      }}</label>
      <div class="mpac-reg-frm-pass-input">
        <div class="mpac-reg-frm-pass-input-field">
          <div class="input-group custom-prepend password">
            <input
              (blur)="onPassBlur()"
              attr.aria-label="{{ 'forms.labels.userRegistration.password' | translate }}"
              class="form-control"
              formControlName="password"
              id="password"
              placeholder="{{ 'forms.placeholders.userRegistration.password' | translate }}"
              [type]="showPassword ? 'text' : 'password'"
              autocomplete="off"
              #password />
            <div *ngIf="showCheckIcon">
              <span class="mpac-reg-frm-pass-input-field__icon"></span>
            </div>
          </div>
          <mpac-print-form-errors-for [control]="registrationForm.get('password')"></mpac-print-form-errors-for>
        </div>
        <div class="mpac-reg-frm-pass-showIcon-cnt">
          <span
            class="btn mpac-reg-frm-pass-showIcon-cnt__icon"
            [class.btn-danger]="showPassword"
            (click)="showPasswordToggle()">
            <span *ngIf="showPassword" class="mpac-reg-frm-pass-showIcon-cnt__icon--show"> </span>
            <span *ngIf="!showPassword" class="mpac-reg-frm-pass-showIcon-cnt__icon--hide"> </span>
          </span>
        </div>
      </div>

      <!-- REPEATE PASSWORD FIELD -->
      <div class="row mt-3" *ngIf="showPasswordRepetitionField">
        <div class="mpac-reg-frm-pass-input-field">
          <div class="input-group custom-prepend passwordRepetition">
            <input
              (blur)="onPassRepBlur()"
              attr.aria-label="{{ 'forms.labels.userRegistration.passwordRepetition' | translate }}"
              class="form-control"
              formControlName="passwordRepetition"
              id="passwordRepetition"
              placeholder="{{ 'forms.placeholders.userRegistration.passwordRepetition' | translate }}"
              [type]="showPassword ? 'text' : 'password'"
              autocomplete="off" />
            <div *ngIf="showCheckIconRep">
              <span class="mpac-reg-frm-pass-input-field__icon"></span>
            </div>
          </div>
          <mpac-print-form-errors-for
            *ngIf="showPassRepError"
            [control]="registrationForm.get('passwordRepetition')"></mpac-print-form-errors-for>
        </div>
      </div>

      <!-- PASSWORD STRENGTH METER-->
      <div class="mpac-password-strength">
        <div class="mpac-password-strength-cnt">
          <mat-password-strength
            class="mpac-password-strength-meter"
            #passwordComponent
            [password]="password.value"
            (onStrengthChanged)="onStrengthChanged($event)">
          </mat-password-strength>
          <div class="mpac-reg-frm-pass-hint">
            <span
              class="mpac-reg-frm-pass-hint__txt"
              #pop="bs-tooltip"
              tabindex="0"
              [tooltip]="popTemplate"
              placement="bottom"
              triggers="focus:blur">
              <span class="mpac-reg-frm-pass-hint__icon"></span><span>{{ passHelpText }}</span>
            </span>
          </div>
        </div>
      </div>
    </div>
    <ng-template #popTemplate> <div [innerHtml]="toolTipHTML"></div></ng-template>

    <!-- TERMS AND CONDITIONS SECTION -->
    <div class="custom-checkbox mpac-reg-frm__terms" [class.mpac-reg-frm__terms-highlight]="highlight">
      <input
        type="checkbox"
        class="custom-control-input"
        id="customControlValidation1"
        formControlName="termsAccepted" />
      <label class="custom-control-label has-link" for="customControlValidation1">
        <span>{{ 'forms.labels.userRegistration.acceptTerms_PREFIX' | translate }}</span>
        <a [href]="privacyPolicyUrl" class="mpac-reg-frm__link" target="_blank">{{
          'forms.labels.userRegistration.acceptTerms_LINK' | translate
        }}</a>
        <span *ngIf="!isServiceCenter">{{ 'forms.labels.userRegistration.acceptTerms_SUFFIX' | translate }}</span>
        <span *ngIf="isServiceCenter">{{
          'forms.labels.userRegistration.acceptTerms_SERVICE_SUFFIX' | translate
        }}</span>
      </label>
      <p
        [@slideUpDown]="{
          value: slideUpDownValueFor(getFormControls.termsAccepted.touched && getFormControls.termsAccepted.invalid),
          params: { maxHeight: 20 }
        }"
        class="validation-feedback mb-0 mt-2 invalid text-danger"
        *ngIf="getFormControls.termsAccepted.touched && getFormControls.termsAccepted.invalid">
        {{ 'forms.labels.userRegistration.acceptTerms_required' | translate }}
      </p>
    </div>

    <!-- SUBMIT BUTTON-->
    <div class="mpac-reg-frm__submit">
      <button class="btn btn-primary" type="submit">
        <span>{{ 'forms.labels.shared.register' | translate }}</span>
        <span class="mpac-reg-frm__submit-icon"></span>
      </button>
    </div>
    <div>
      <ng-content select=".login-link"></ng-content>
    </div>
  </mpac-spinner-full>
</form>
