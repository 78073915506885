export enum CmsContentTypes {
  None,
  Country = 'country',
  CountryList = 'countryList',
  OrderStartpage = 'orderStartpage',
  ProductInfoPage = 'productInfoPage',
  ServiceCenterFeatureRichText = 'serviceCenterFeatureRichText',
  ServiceCenterFeatureText = 'serviceCenterFeatureText',
  ServiceCenterFeatureMedia = 'serviceCenterFeatureMedia',
  EmailTemplate = 'email-template',
  HelpText = 'helpText'
}
