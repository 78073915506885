import { DateTime } from 'luxon';
/**
 * Utility for general date handling not provided by out extensive set of libs.
 */
export class DateUtil {
  /**
   * Invalid DateTime string from/for Html5 Date/Time fields.
   */
  public static invalidDateTime = 'Invalid DateTime';

  /**
   * Returns today as iso.
   *
   * @return Date in yyyy-MM-dd format.
   */
  public static dateTodayAsIso(): string {
    return DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd');
  }

  /**
   * Yesterday from now.
   *
   * @return Date in yyyy-MM-dd format.
   */
  public static yesterdayAsIso(): string {
    return DateTime.fromJSDate(new Date()).minus({ days: 1 }).toFormat('yyyy-MM-dd');
  }

  /**
   * Strips a local date object of its's time zone.
   *
   * @returns Datestring with time zone and with indication of it not being present (Z).
   */
  public static localDateRemoveTimeZone(date: Date): string {
    return DateTime.fromJSDate(date, { zone: 'local' }).toString().slice(0, 23);
  }

  /**
   * Treats the incoming date string as iso and converts it to local time.
   */
  public static getLocaltTimefromIso(date: string): DateTime {
    return DateTime.fromISO(date, { zone: 'utc', setZone: true }).toLocal();
  }

  /**
   * Compares DateTimes of the format dd/MM/yyyy to each other.
   *
   * @return 0=same, 1 = a > b, 2 = a < b => inverted for descending.
   */
  public static compareDate(a: DateTime, b: DateTime, ascending: boolean): number {
    if (ascending) {
      if (a > b) {
        return 1;
      } else if (a < b) {
        return -1;
      }
    } else {
      if (a > b) {
        return -1;
      } else if (a < b) {
        return 1;
      }
    }
    return 0;
  }
}
