import { Injectable } from '@angular/core';
import { DependantDemoService } from './dependant-demo.service';

@Injectable({
  providedIn: 'root'
})
export class GlobalWriteProtectionService {
  constructor(private dependantDemoService: DependantDemoService) {}

  public isActive(): boolean {
    return this.dependantDemoService.dependantDemoUserActive();
  }
}
