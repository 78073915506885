import { createAction, props, union } from '@ngrx/store';

export const attachBreakpointListenersAction = createAction('[Display] Attach Breakpoint Listeners');

export const reportBreakpointStateAction = createAction(
  '[Display] Report Breakpoint State',
  props<{ breakpoint: string; matches: boolean }>()
);

export const reportNewBreakpointsStateAction = createAction(
  '[Display] Report new breakpoints state',
  props<{ breakpoints: { [key: string]: boolean } }>()
);

export const collapseNavigationAction = createAction('[Display] Collapse Navigation');

export const toggleNavigationVisibilityAction = createAction('[Display] Toggle Navigation Visibility');

export const toggleHelpVisibilityAction = createAction('[Display] Toggle Help Visibility');

const actions = union({
  /* eslint-disable @typescript-eslint/naming-convention */
  ReportBreakpointStateAction: reportBreakpointStateAction,
  ReportNewBreakpointsStateAction: reportNewBreakpointsStateAction,
  CollapseNavigationAction: collapseNavigationAction,
  ToggleNavigationVisibilityAction: toggleNavigationVisibilityAction,
  ToggleHelpVisibilityAction: toggleHelpVisibilityAction
  /* eslint-enable @typescript-eslint/naming-convention */
});

export type DisplayAction = typeof actions;
