import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import { companyReducer } from './company/company.reducer';
import { breadcrumbsReducer } from './breadcrumbs/breadcrumbs.reducer';
import { CoreState } from './common';
import { displayReducer } from './display/display.reducer';
import { helpReducer } from './help/help.reducer';
import { userReducer } from './user/user.reducer';

export const coreStateReducers: ActionReducerMap<CoreState> = {
  companyState: companyReducer,
  breadcrumbs: breadcrumbsReducer,
  helpText: helpReducer,
  user: userReducer,
  display: displayReducer
};

export const coreStateMetaReducers: MetaReducer<CoreState>[] = [];

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface State {}

export const reducers: ActionReducerMap<State> = {};

export const metaReducers: MetaReducer<State>[] = [];
