<section class="mpac-page__section">
  <header class="mpac-page__section-header">
    <ng-content select=".headline"></ng-content>
    <ng-content select=".subheader"></ng-content>
  </header>
  <div class="mpac-page__section-cnt">
    <ng-content select=".mpac-page__section-cnt-hdl"></ng-content>
    <ng-content></ng-content>
  </div>
</section>
