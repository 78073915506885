import { DesctructableComponent } from 'src/app/shared/components/destructable/destructable.component';
import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { SettingsService } from '@core/config/settings.service';
import { setTopicAction } from '@core/store/help/help.actions';
import { selectUserLevel } from '@core/store/user/user.reducer';
import { ofType } from '@ngrx/effects';
import { ActionsSubject, select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { filter, first, takeUntil } from 'rxjs/operators';
import { Company } from 'src/app/shared/models/company';
import { PrecautionInfoConfiguration } from 'src/app/shared/models/precaution-info-configuration.interface';
import { UserLevel } from 'src/app/shared/models/user-level.enum';
import {
  requestAdditionalInformation,
  requestAdditionalInformationFailure
} from '../../store/precaution-info/precaution-info.actions';
import {
  selectPrecautionInfoAdditionalInfoRequested,
  selectPrecautionInfoForOrder
} from '../../store/precaution-info/precaution-info.selectors';
import { PrecautionInfoService } from 'src/app/shared/services/precaution-info.service';
import { ContentfulEntriesService } from '@rapid/cms-lib';
import { selectCompany } from '@core/store/company/company.selectors';
import { LoadCompanyByOrderPkAction } from '@core/store/company/company.actions';

@Component({
  selector: 'mpac-precaution-info-landing-page',
  templateUrl: './precaution-info-landing-page.component.html',
  styleUrls: ['./precaution-info-landing-page.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PrecautionInfoLandingPageComponent extends DesctructableComponent implements OnInit {
  @Input() precautionInfoConfiguration: PrecautionInfoConfiguration;

  @Input() previewMode: boolean;

  public informationRequested = false;

  /**
   * The information about the current company.
   */
  public company: Company;

  private readonly helpTopicId: string;

  constructor(
    private store$: Store<any>,
    private translate: TranslateService,
    private router: Router,
    private actionsSubj: ActionsSubject,
    private precautionInfoService: PrecautionInfoService,
    private settingsService: SettingsService,
    private contentfulEntriesService: ContentfulEntriesService
  ) {
    super();
    this.helpTopicId = this.contentfulEntriesService.instant('helpText.admin.precautionInfo');
  }

  public get requestInfoButtonText(): string {
    let buttonText: string;
    if (this.informationRequested) {
      buttonText = this.translate.instant('precautionInfo.informationRequested');
    } else {
      buttonText =
        this.precautionInfoConfiguration.requestInfoButtonText || this.translate.instant('precautionInfo.requestInfo');
    }

    return buttonText;
  }

  public get coverImageData(): string {
    let imageData: string;
    if (this.precautionInfoConfiguration.coverImage?.data) {
      imageData = this.precautionInfoConfiguration.coverImage.data;
    } else {
      imageData = this.precautionInfoService.defaultCoverImageAssetPath;
    }
    return imageData;
  }

  /**
   * Returns the url to the company logo or an empty
   * string if the company is not loaded
   */
  public get companyLogoUrl(): string {
    const config = this.settingsService.settings;
    return this.company?.rapidId
      ? `${config.imageAssetRootStatic}${config.imageAssetStaticIndividual.replace(
          '{companyId}',
          this.company.rapidId
        )}${config.imageAssetStaticLogo}`
      : '';
  }

  private get orderId(): number {
    const nameSegmentId = this.router.url.split('/').reverse()[1];
    const orderPk: number = +nameSegmentId.substr(nameSegmentId.lastIndexOf('-') + 1);
    return orderPk;
  }

  ngOnInit(): void {
    if (!this.previewMode) {
      this.store$
        .pipe(
          select(selectPrecautionInfoForOrder),
          filter((pi) => !!pi.id)
        )
        .subscribe((config) => {
          this.precautionInfoConfiguration = config;
          this.loadCurrentCompany();
        });

      this.store$.pipe(select(selectPrecautionInfoAdditionalInfoRequested)).subscribe((requested: boolean) => {
        this.informationRequested = requested;
      });

      this.subscribeToFailures();
    }

    this.setHelpText();

    if (this.precautionInfoConfiguration?.showCompanyLogo) {
      this.loadCurrentCompany();
    }
  }

  public downloadInfoDocument(): void {
    if (!this.previewMode) {
      const linkSource = this.precautionInfoConfiguration.infoDocument.data;
      const downloadLink = document.createElement('a');
      const fileName = this.precautionInfoConfiguration.infoDocument.name;

      downloadLink.target = '_blank';
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    }
  }

  public requestInfo(): void {
    if (!this.previewMode && !this.informationRequested) {
      const orderId = this.orderId;
      const recipient = this.precautionInfoConfiguration.requestInfoEmail;
      this.store$.dispatch(requestAdditionalInformation({ orderId, recipient }));
    }
  }

  private setHelpText(): void {
    this.store$
      .pipe(
        select(selectUserLevel),
        filter((l: UserLevel) => l !== UserLevel.None),
        first()
      )
      .subscribe((userLevel: UserLevel) => {
        if (userLevel === UserLevel.PageAdmin) {
          this.store$.dispatch(setTopicAction({ topic: this.helpTopicId }));
        }
      });
  }

  private loadCurrentCompany(): void {
    this.store$.pipe(takeUntil(this.shutdown$), select(selectCompany)).subscribe((company: Company) => {
      if (!company) {
        this.store$.dispatch(new LoadCompanyByOrderPkAction(this.orderId));
      } else {
        this.company = company;
      }
    });
  }

  private subscribeToFailures(): void {
    this.actionsSubj
      .pipe(takeUntil(this.shutdown$), ofType(requestAdditionalInformationFailure))
      .subscribe(() => (this.informationRequested = false));
  }
}
