import { Component, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'mpac-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.scss', './upload-file.component--invalid.scss']
})
export class UploadFileComponent {
  @Input()
  public isValid: boolean;

  @Input()
  public file: File;

  @Input()
  public isAudioFile?: false;

  @Input()
  public validationMessages: string[];

  @Output()
  public removalTriggered = new EventEmitter<File>();

  private _validationMessageDisplayed = false;

  constructor() {}

  public get name(): string {
    return this.file ? this.file.name.substring(0, this.file.name.lastIndexOf('.')) : '';
  }

  public get extension(): string {
    return this.file ? this.file.name.substring(this.file.name.lastIndexOf('.'), this.file.name.length) : '';
  }

  public get fileSize(): string {
    const size = this.file.size / (1024 * 1024);
    return size.toFixed(2);
  }

  public get skipFileSize(): boolean {
    return this.file.size === 0;
  }

  public get validationMessageDisplayed(): boolean {
    return this._validationMessageDisplayed;
  }

  public toggleValidationList(): void {
    if (this.isValid || (!this.isValid && (!this.validationMessages || !this.validationMessages.length))) {
      this._validationMessageDisplayed = false;
    } else {
      this._validationMessageDisplayed = !this._validationMessageDisplayed;
    }
  }
}
