import { Component, Input, OnInit, Output, EventEmitter, ViewEncapsulation, HostBinding } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AbstractControl, UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { FormsService } from '@rapid/forms';

@Component({
  selector: 'mpac-error-overlay',
  templateUrl: './error-overlay.component.html',
  styleUrls: ['./error-overlay.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ErrorOverlayComponent implements OnInit {
  public static cssClass = 'mpac-error-ovl';

  private static errorDelimiter = '.';

  @HostBinding('class') hostClassBinding;

  @Input() public form: UntypedFormGroup;

  @Input() public touchedOnly = true;

  @Output() public toggleOverlay: EventEmitter<void> = new EventEmitter<void>();

  public errors: Array<string>;

  constructor(public translateService: TranslateService, protected formsService: FormsService) {}

  public ngOnInit(): void {
    this.setHostClassBinding();
    this.getFormValidationErrors();
  }

  public focus(event: Event, field: string): void {
    const focusField = document.getElementById(field);
    if (focusField) {
      this.toggleOverlay.emit();
      focusField.focus();
    }
    // prevent click through & any weird browser link behavior
    event.stopImmediatePropagation();
    event.preventDefault();
  }

  public gfcn(field: string): UntypedFormGroup | AbstractControl | UntypedFormArray {
    return this.formsService.getFormControlNested(this.form, field);
  }

  public getFormValidationErrors(): Array<string> {
    if (!this.form) {
      return [];
    }
    return this.formsService.getFormValidationErrors(this.form, this.touchedOnly);
  }

  public lastErrorSegment(input: string): string {
    if (input.indexOf(ErrorOverlayComponent.errorDelimiter) > -1) {
      const split = input.split(ErrorOverlayComponent.errorDelimiter);
      return split[split.length - 1];
    }
    return input ? input : '';
  }

  protected setHostClassBinding(): void {
    this.hostClassBinding = `${ErrorOverlayComponent.cssClass}`;
  }
}
