import { Injectable } from '@angular/core';
import { ContentfulEntriesService } from '@rapid/cms-lib';

@Injectable({ providedIn: 'root' })
export class ContentfulEntriesHttpService {
  constructor(private contentfulEntriesService: ContentfulEntriesService) {}

  public initializeApp(): void {
    this.contentfulEntriesService.loadAssets();
  }
}
