import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { catchError, delay, map, switchMap, tap } from 'rxjs/operators';
import { UserLevel } from '../../../shared/models/user-level.enum';
import { User } from '../../models/user';
import { AuthService } from '../../services/auth.service';
import { UserService } from '../../services/user.service';
import {
  loadLoggedInUserInfoAction,
  loginStoredUserAction,
  loginStoredUserFailureAction,
  loginStoredUserSuccessAction,
  loginUserAction,
  loginUserSuccessAction,
  logoutUserAction,
  reloadUserInfoAction,
  updateUserInfoAction
} from './user.actions';

@Injectable()
export class UserEffects {
  loginUser$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loginUserAction),
        switchMap((action) =>
          this.userService.validateUser$(action.userName, action.password).pipe(
            map((user: User) => {
              if (user) {
                return loginUserSuccessAction({ user });
              }

              return logoutUserAction();
            })
          )
        )
      ),
    { dispatch: true }
  );

  loadLoggedInUser$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loadLoggedInUserInfoAction),
        switchMap(() =>
          this.userService.loadUserInfo().pipe(
            map((user: User) => {
              if (!user) {
                return logoutUserAction();
              }
              return loginUserSuccessAction({ user });
            }),
            catchError(() => of(loginStoredUserFailureAction()))
          )
        )
      ),
    { dispatch: true }
  );

  reloadUserInfo$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(reloadUserInfoAction),
        switchMap(() =>
          this.userService.loadUserInfo().pipe(
            map((user: User) => {
              if (!user) {
                return logoutUserAction();
              }
              return updateUserInfoAction({ user });
            }),
            catchError(() => of(loginStoredUserFailureAction()))
          )
        )
      ),
    { dispatch: true }
  );

  loginUserSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loginUserSuccessAction),
        tap((action) => {
          this.userService.storeUserToStorages(action.user);
          const redirectUrl = UserEffects.chooseRedirectUrl(
            action.user.level,
            this.userService.readReturnUrlFromStorage()
          );
          this.router.navigate([redirectUrl]);
        })
      ),
    { dispatch: false }
  );

  loginUserFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loginStoredUserFailureAction),
        tap(() => {
          this.toastr.error('Fehler beim Laden Ihrer Nutzerdaten. Bitte versuchen sie es erneut.', 'Anmeldung', {
            disableTimeOut: true,
            enableHtml: true
          });
        }),
        delay(5000),
        tap(() => {
          this.authService.logout();
        })
      ),
    { dispatch: false }
  );

  autoLoginUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loginStoredUserAction),
      switchMap(() => {
        const user: User = this.userService.readUserFromLocalStorage();
        if (user && user.name) {
          return of(loginStoredUserSuccessAction({ user }));
        }
        return of(logoutUserAction());
      })
    )
  );

  logoutUser$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(logoutUserAction),
        tap(() => {
          this.userService.removeUserFromStorages();
          this.userService.removeReturnUrlFromStorage();
        })
      ),
    { dispatch: false }
  );

  updateUserInfo$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(updateUserInfoAction),
        tap((action) => {
          this.userService.storeUserToStorages(action.user);
        })
      ),
    { dispatch: false }
  );

  constructor(
    private router: Router,
    private actions$: Actions,
    private authService: AuthService,
    private userService: UserService,
    private toastr: ToastrService
  ) {}

  /**
   * Evaluates the correct url to redirect
   */
  private static chooseRedirectUrl(userLevel: UserLevel, returnUrl: string) {
    returnUrl = returnUrl || '';
    let redirectUrl = '';
    switch (userLevel) {
      case UserLevel.PageAdmin:
        redirectUrl = returnUrl.startsWith('/gedenkseite') ? returnUrl : '';
        break;
      case UserLevel.Mortician:
        redirectUrl = !returnUrl.startsWith('/bestatter') ? returnUrl : '';
        break;
      case UserLevel.Rapid:
        redirectUrl = returnUrl;
        break;
      default:
        redirectUrl = '';
    }
    return redirectUrl || '/';
  }
}
