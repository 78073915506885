import { Injectable } from '@angular/core';
import { User } from '@core/models/user';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import cloneDeep from 'lodash-es/cloneDeep';
import { combineLatest } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';
import { BreadcrumbEntry } from '../../models/breadcrumb-entry';
import { BreadcrumbsService } from '../../services/breadcrumbs.service';
import { CoreState } from '../common';
import { selectUser } from '../user/user.reducer';
import { selectBreadcrumbs } from './breadcrum.selectors';
import {
  BreadcrumActionsEnum,
  SetBreadcrumbsAction,
  SetBreadcrumbSuccessAction,
  SetBreadcrumbFailureAction
} from './breadcrumbs.actions';

@Injectable()
export class BreadcrumbsEffects {
  setOrderBreadcrumbs$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SetBreadcrumbsAction>(BreadcrumActionsEnum.SetBreadcrumbsAction),
      withLatestFrom(
        combineLatest([this.store$.pipe(select(selectUser)), this.store$.pipe(select(selectBreadcrumbs))])
      ),
      map(([action, [user, breadcrumbs]]: [SetBreadcrumbsAction, [User, Array<BreadcrumbEntry>]]) => {
        const entries = cloneDeep(breadcrumbs);

        if (!action.company.displayText || !action.company.routerLink)
          return new SetBreadcrumbFailureAction(action.company, action.order);
        if (!entries.length) {
          entries.push(action.company);
        } else {
          entries[0] = action.company;
        }

        if (action.order) {
          if (!action.order.displayText || !action.order.routerLink)
            return new SetBreadcrumbFailureAction(action.company, action.order);

          if (entries.length === 1) {
            entries.push(action.order);
          } else {
            entries[1] = action.order;
          }
        }
        this.breadcrumbsService.checkEntriesWithUser(entries, user);
        return new SetBreadcrumbSuccessAction(entries);
      })
    )
  );

  constructor(
    private actions$: Actions,
    private store$: Store<CoreState>,
    private breadcrumbsService: BreadcrumbsService
  ) {}
}
