import { EventEmitter, HostBinding, Input, Output, ViewEncapsulation } from '@angular/core';
import { Component } from '@angular/core';

@Component({
  selector: 'mpac-preset-reset',
  templateUrl: './preset-reset.component.html',
  styleUrls: ['./preset-reset.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PresetResetComponent {
  public static cssBaseClass = 'mpac-preset-reset';

  @Input()
  public controlName: string;

  @Input()
  public resetToValue: string;

  @Output()
  public clickEmitter = new EventEmitter<string>();

  @HostBinding('class') hostElementClass = PresetResetComponent.cssBaseClass;

  constructor() {}

  /**
   * Event handler for the button click. Emits the clickEmitter.
   */
  public resetButtonClick(): void {
    this.clickEmitter.emit(this.controlName);
  }
}
