import { AuthService } from 'src/app/core/services/auth.service';
import { CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LogoutGuard implements CanActivate {
  constructor(private authService: AuthService) {}

  canActivate(): boolean | Observable<boolean> {
    this.authService.logout();
    return true;
  }
}
