import { MusicOrderStatus } from '@company/models/music-order-status';
import { FuneralPrintStatusEntry } from './funeral-print-status-entry';
import { UserInfoDto } from './UserInfo.dto';

/**
 * Possible order states
 */
export enum OrderState {
  Premium = 4,
  Base = -1,
  NoMemorialPage = 0
}

/**
 * data object needed by the order list entry component
 */
export interface OrderEntry {
  pk: number;
  companyPk: number;
  firstNames: string;
  lastName: string;
  fullName: string;
  fullOriginName: string;
  dateOfDeath: string;
  friendlyUrl: string;
  routerLink: string;
  created: string;
  state: OrderState;
  dateOfLock: string;
  visibleFrom: string;
  entriesCount: number;
  reportedEntriesCount: number;
  uploadedImagesByMorticiansCount: number;
  documents: Array<FuneralPrintStatusEntry>;
  userAdminInfo: UserInfoDto;
  musicStatus: MusicOrderStatus;
}
