import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'mpac-menu-icon',
  templateUrl: './menu-icon.component.html',
  styleUrls: ['./menu-icon.component.variables.scss', './menu-icon.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MenuIconComponent {
  @Input()
  public iconClass: string;

  constructor() {}
}
