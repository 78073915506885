<ng-template #tooltipTemplate>
  <div *ngIf="tooltipContentConfig">
    <h4 class="h4 alert-heading">{{ tooltipContentConfig.headerTranslationKey | translate }}</h4>
    <p class="well-sm">
      {{ tooltipContentConfig.bodyTranslationKey | translate }}
    </p>
    <button type="button" class="tooltip-btn" (click)="setCookieAndHideTooltip()">
      {{ tooltipContentConfig.dismissButtonTranslationKey | translate }}
    </button>
  </div>
</ng-template>
<a
  (click)="toggleHelp()"
  class="cursor-pointer mpac-help-toggle d-lg-block"
  [class.text-dark]="!mobile"
  [ngClass]="{ 'text-light btn btn-link': mobile }">
  <span
    mpacIcon="fal fa-question-circle fa-2x"
    triggers=""
    [tooltip]="tooltipTemplate"
    #tooltipRef="bs-tooltip"
    container="body"
    (onShown)="toggleIsTooltipVisible(true)"
    (onHidden)="toggleIsTooltipVisible(false)">
  </span>
</a>
<div [class.separator]="!mobile"></div>
