import { Component, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'mpac-dialog-container',
  templateUrl: './dialog-container.component.html'
})
export class DialogContainerComponent {
  @Input() modalRef: BsModalRef;

  @Input() dialogTitleKey: string;

  public hideDialog(): void {
    this.modalRef.hide();
  }
}
