import { Component, EventEmitter, Output, ViewChild, ElementRef, Input, OnInit } from '@angular/core';

@Component({
  selector: 'mpac-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
  @Input() currentSearchValue: string;

  /**
   * event emitter to emit the current search string
   */
  @Output() public termChanged: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild('searchBox')
  public searchBox: ElementRef;

  public ngOnInit(): void {
    if (!this.currentSearchValue) {
      this.currentSearchValue = '';
    }
  }

  /**
   * emits the current search string to the outside  on input change
   */
  public searchTermChanged(e: KeyboardEvent): void {
    const target = e.currentTarget as HTMLInputElement;
    this.termChanged.emit(target.value.trim());
  }

  /**
   * clears the value of the input and emits an empty string to the outside
   */
  public clearInput(): void {
    if (this.searchBox) {
      this.currentSearchValue = '';
      this.termChanged.emit('');
    }
  }
}
