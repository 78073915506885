import { AfterViewInit, Directive, ElementRef, Renderer2 } from '@angular/core';
import { GlobalWriteProtectionService } from '../services/global-write-protection.service';

@Directive({
  selector: '[mpacGlobalWriteProtection]',
  exportAs: 'mpacGlobalWriteProtection'
})
export class GlobalWriteProtectionDirective implements AfterViewInit {
  public readonly globalWriteProtectionActive: boolean;

  private readonly disabled = 'disabled';

  constructor(
    private eleRef: ElementRef,
    private renderer: Renderer2,
    private globalWriteProtectionService: GlobalWriteProtectionService
  ) {
    this.globalWriteProtectionActive = this.globalWriteProtectionService.isActive();
  }

  public ngAfterViewInit(): void {
    this.applyWriteProtection();
  }

  /**
   * Applies the write protection to IO-elemets if the requirements are met.
   */
  private applyWriteProtection(): void {
    if (!this.globalWriteProtectionActive) {
      return;
    }

    const element = this.eleRef.nativeElement;
    if (element.tagName.toLowerCase() === 'button') {
      this.renderer.setAttribute(element, this.disabled, 'true');
    }
  }
}
