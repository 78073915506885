<svg class="d-block" height="0" width="0">
  <defs>
    <clipPath id="edge-clip-path">
      <polygon points="-5 -5,40 -5,95 50,95 -5,1000 -5, 1000 1000, -5 1000"></polygon>
    </clipPath>
  </defs>
</svg>

<mpac-spinner-full
  [displayContentWhileBusy]="false"
  [isBusy]="isContentLoading"
  [overlayMode]="true"
  spinnerText="Inhalt wird geladen...">
  <div *ngIf="!hasContentLoadErrorOccurred; else failedToLoad" class="product-info-page row">
    <img
      [alt]="pageContent?.backgroundImageDescription ? pageContent?.backgroundImageDescription : 'Hintergrundbild'"
      [src]="pageContent?.backgroundImageUrl"
      [title]="pageContent?.backgroundImageTitle"
      class="bg-image" />
    <div class="container-fluid d-flex flex-column">
      <div class="jumbotron flex-lg-fill">
        <h1 [innerHTML]="markdownHeadline" class="display-3"></h1>
      </div>
      <div class="row row--bottom">
        <div
          [innerHTML]="pageContent?.tileLeft | markdown"
          class="col-md-7 col-xl-5 tile-padding bg-white text-red"></div>
        <div [innerHTML]="pageContent?.tileCenter | markdown" class="col-md-5 col-xl-4 bg-light tile-padding"></div>
        <div class="col-xl-3 col-md-12 row bg-light-gray mx-0">
          <div class="col-md-8 col-xl mx-auto d-flex flex-column tile-padding text-center">
            <div [innerHTML]="pageContent?.tileRightText | markdown"></div>
            <mpac-spinner-button
              btnClasses="btn btn-secondary mx-auto"
              [disabled]="isEmailTemplateLoading"
              [isBusy]="isEmailTemplateLoading"
              (click)="navigateToContactFormWithRouterState()">
              {{ pageContent?.tileRightButton }}
            </mpac-spinner-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</mpac-spinner-full>

<ng-template #failedToLoad>
  <div class="row min-vh-100 justify-content-center align-items-center">
    <div class="col-md-6 text-center">
      <i aria-hidden="true" class="fal fa-times-circle fa-3x mb-2"></i>
      <h1 class="h3">Der Inhalt konnte nicht geladen werden</h1>
      <p>Bitte überprüfen Sie ihre Internetverbindung und versuchen Sie es erneut.</p>
    </div>
  </div>
</ng-template>
