import { DBConfig } from 'ngx-indexed-db';

/**
 * head of time compiles requires an exported function for factories
 * Here are describe possible later database migrations, indexed by version numbers
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, prefer-arrow/prefer-arrow-functions
export function migrationFactory() {
  return {
    // Example migration
    // 1: (db, transaction) => {
    //   const store = transaction.objectStore('user');
    //   store.createIndex('userId', 'userId', { unique: false });
    // },
  };
}

/**
 * Config object for the IndexedDB
 */
export const dbConfig: DBConfig = {
  name: 'AppDb',
  version: 1,
  objectStoresMeta: [
    {
      store: 'user',
      storeConfig: { keyPath: 'id', autoIncrement: false },
      storeSchema: [{ name: 'accessToken', keypath: 'accessToken', options: { unique: true } }]
    }
  ],
  // provide the migration factory to the DBConfig
  migrationFactory
};
