import { IFileUploadStatus } from './../../models/file-upload-status.interface';
import { Component, Input } from '@angular/core';
import { ProgressbarType } from 'ngx-bootstrap/progressbar';

@Component({
  selector: 'mpac-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss']
})
export class MpacProgressBarComponent {
  @Input()
  public status: IFileUploadStatus;

  public get progressForDisplay(): string {
    if (this.status.success) {
      return this.status.progress.toString();
    }
    return this.status.progress.toFixed(1);
  }

  public get progressBarType(): ProgressbarType {
    if (this.status.failure) {
      return 'danger';
    } else if (this.status.success) {
      return 'success';
    }
    return 'info';
  }
}
