import { AbstractControl, ValidatorFn } from '@angular/forms';

export const futureTimeFor =
  (dateTimeFieldName: string): ValidatorFn =>
  (timeControl: AbstractControl): { [key: string]: any } | null => {
    const formGroup = timeControl.parent;
    if (formGroup) {
      const dateControl = formGroup.get(dateTimeFieldName);
      if (!!timeControl.value && !!dateControl.value) {
        const currentDateTimeValue = new Date();
        const dateControlDateTimeValue = new Date(dateControl.value as string | number | Date);
        const hours = timeControl.value.split(':')[0];
        const minutes = timeControl.value.split(':')[1];
        dateControlDateTimeValue.setHours(hours as number);
        dateControlDateTimeValue.setMinutes(minutes as number);
        if (dateControlDateTimeValue < currentDateTimeValue) {
          return { futureTime: true };
        }
      }
    }
  };
