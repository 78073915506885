import { createSelector } from '@ngrx/store';
import { CoreState, coreStateFeatureSelector } from '../common';
import { HelpAction, setTopicSuccessAction } from './help.actions';

export const helpReducer = (state: string, action: HelpAction): string => {
  switch (action.type) {
    case setTopicSuccessAction.type: {
      return action.helpText;
    }
  }

  return state;
};

export const selectHelpText = createSelector(coreStateFeatureSelector, (state: CoreState) =>
  state ? state.helpText : undefined
);
