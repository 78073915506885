import { createAction, props, union } from '@ngrx/store';
import {
  QuickFormOrderAction,
  QuickFormOrderActionSendAnswer,
  QuickFormOrderActionProblemReport
} from '../../models/quick-form-order-action';
import { QuickFormOrder } from '../../models/quick-form-order';
import { ServiceError } from '../../services/errors/service.error';
import { OrderSummary } from '@order/models/quickform-order-summary';

export enum ApplicationErrorType {
  NotFound,
  Unknown
}

export const loadQuickFormOrderAction = createAction(
  '[QuickFormOrder] Load Quick Form Order',
  props<{ orderId: string }>()
);
export const loadQuickFormOrderSuccessAction = createAction(
  '[QuickFormOrder] Load Quick Form Order Success',
  props<{ order: QuickFormOrder }>()
);
export const loadQuickFormOrderFailureAction = createAction(
  '[QuickFormOrder] Load Quick Form Order Failure',
  props<{ error: ServiceError }>()
);

export const loadOrderActionsAction = createAction(
  '[QuickFormOrderActions] Load Quick Form Order Actions',
  props<{ order: QuickFormOrder }>()
);
export const loadOrderActionsSuccessAction = createAction(
  '[QuickFormOrderActions] Load Quick Form Order Actions Success',
  props<{ order: QuickFormOrder; orderActions: QuickFormOrderAction[] }>()
);
export const loadOrderActionsFailureAction = createAction(
  '[QuickFormOrderActions] Load Quick Form Order Actions Failure',
  props<{ error: ServiceError }>()
);

export const loadOrderActionDetailsAction = createAction(
  '[QuickFormOrderActionDetail] Load Quick Form Order Action Details',
  props<{ orderActionId: string }>()
);
export const loadOrderActionDetailsSuccessAction = createAction(
  '[QuickFormOrderActionDetail] Load Quick Form Order Actions Details Success',
  props<{ orderAction: QuickFormOrderAction; orderActionId: string }>()
);
export const loadOrderActionDetailsFailureAction = createAction(
  '[QuickFormOrderActionDetail] Load Quick Form Order Actions Details Failure',
  props<{ orderActionId: string; error: ServiceError }>()
);
export const unselectOrderActionDetailsAction = createAction(
  '[QuickFormOrderActionDetail] Unselect Quick Form Order Action Details',
  props<{ orderActionId: string }>()
);

export const answerOrderActionAction = createAction(
  '[QuickFormOrderActionAnswer] Answer Quick Form Order Action',
  props<{ answer: QuickFormOrderActionSendAnswer }>()
);
export const answerOrderActionSuccessAction = createAction(
  '[QuickFormOrderActionAnswer] Answer Quick Form Order Actions Success',
  props<{ orderActionId: string }>()
);
export const answerOrderActionFailedAction = createAction(
  '[QuickFormOrderActionAnswer] Answer Quick Form Order Actions Failed',
  props<{ orderActionId: string; error: ServiceError }>()
);

export const discardOrderActionAction = createAction(
  '[QuickFormOrderActionDiscard] Discard Quick Form Order Action',
  props<{ orderActionId: string }>()
);
export const discardOrderActionSuccessAction = createAction(
  '[QuickFormOrderActionDiscard] Discard Quick Form Order Actions Success',
  props<{ orderActionId: string }>()
);
export const discardOrderActionFailedAction = createAction(
  '[QuickFormOrderActionDiscard] Discard Quick Form Order Actions Failed',
  props<{ orderActionId: string; error: ServiceError }>()
);

export const reportProblemOrderActionAction = createAction(
  '[QuickFormOrderActionReportProblem] Report Quick Form Order Action Problem',
  props<{ problem: QuickFormOrderActionProblemReport }>()
);
export const reportProblemOrderActionSuccessAction = createAction(
  '[QuickFormOrderActionReportProblem] Report Quick Form Order Action Problem Success',
  props<{ orderActionId: string }>()
);
export const reportProblemOrderActionFailedAction = createAction(
  '[QuickFormOrderActionReportProblem] Report Quick Form Order Action Problem Failed',
  props<{ orderActionId: string; error: ServiceError }>()
);

export const loadQuickFormOrderSummaryAction = createAction(
  '[QuickFormOrderSummary] Load Quick Form Order Summary',
  props<{ thirdPartyId: string }>()
);

export const loadQuickFormOrderSummarySuccessAction = createAction(
  '[QuickFormOrderSummary] Load Quick Form Order Summary Success',
  props<{ orderSummary: OrderSummary }>()
);

export const loadQuickFormOrderSummaryFailureAction = createAction(
  '[QuickFormOrderSummary] Load Quick Form Order Summary Failure',
  props<{ error: ServiceError }>()
);

export const orderActionChangedAction = createAction(
  '[QuickFormOrderActionReportProblem] Changed',
  props<{ orderActionId: string }>()
);

export const noopAction = createAction('[QuickFormNoop] QuickForm NoopAction');

const actions = union({
  /* eslint-disable @typescript-eslint/naming-convention */
  LoadQuickFormOrderAction: loadQuickFormOrderAction,
  LoadQuickFormOrderSuccessAction: loadQuickFormOrderSuccessAction,
  LoadQuickFormOrderFailureAction: loadQuickFormOrderFailureAction,
  LoadOrderActionsAction: loadOrderActionsAction,
  LoadOrderActionsSuccessAction: loadOrderActionsSuccessAction,
  LoadOrderActionsFailureAction: loadOrderActionsFailureAction,
  LoadOrderActionDetailsAction: loadOrderActionDetailsAction,
  LoadOrderActionDetailsSuccessAction: loadOrderActionDetailsSuccessAction,
  LoadOrderActionDetailsFailureAction: loadOrderActionDetailsFailureAction,
  UnselectOrderActionDetailsAction: unselectOrderActionDetailsAction,
  AnswerOrderActionAction: answerOrderActionAction,
  AnswerOrderActionSuccessAction: answerOrderActionSuccessAction,
  AnswerOrderActionFailedAction: answerOrderActionFailedAction,
  DiscardOrderActionAction: discardOrderActionAction,
  DiscardOrderActionSuccessAction: discardOrderActionSuccessAction,
  DiscardOrderActionFailedAction: discardOrderActionFailedAction,
  OrderActionChangedAction: orderActionChangedAction,
  LoadQuickFormOrderSummaryAction: loadQuickFormOrderSummaryAction,
  LoadQuickFormOrderSummarySuccessAction: loadQuickFormOrderSummarySuccessAction,
  LoadQuickFormOrderSummaryFailureAction: loadQuickFormOrderSummaryFailureAction,
  NoopAction: noopAction
  /* eslint-enable @typescript-eslint/naming-convention */
});

export type QuickFormOrderActionsAction = typeof actions;
