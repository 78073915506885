import { takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { AfterViewInit, Directive, OnDestroy } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { DesctructableDirective } from '../components/destructable/destructable.directive';

@Directive({
  selector: '[mpacDialogOverflowDisabled]',
  exportAs: 'mpacDialogOverflowDisabled'
})
export class DialogDisableOverFlowDirective extends DesctructableDirective implements AfterViewInit, OnDestroy {
  private dialogsOpen: boolean;

  constructor(private dialogRef: MatDialog, private modalService: BsModalService) {
    super();
    this.dialogsOpen = !!dialogRef?.openDialogs?.length || this.modalService.getModalsCount() > 0 ? true : false;
  }

  public ngAfterViewInit(): void {
    this.subscribeToDialogState();
  }

  private subscribeToDialogState(): void {
    // Bootstrap
    this.modalService.onShow.pipe(takeUntil(this.shutdown$)).subscribe(() => {
      this.detectDialogChanges();
    });

    this.modalService.onHide.pipe(takeUntil(this.shutdown$)).subscribe(() => {
      this.detectDialogChanges();
    });

    // Material
    this.dialogRef.afterAllClosed.pipe(takeUntil(this.shutdown$)).subscribe(() => {
      this.detectDialogChanges();
    });
    this.dialogRef.afterOpened.pipe(takeUntil(this.shutdown$)).subscribe(() => {
      this.detectDialogChanges();
    });
  }

  private detectDialogChanges(): void {
    const newDialogsOpen = !!this.dialogRef.openDialogs.length || this.modalService.getModalsCount() > 0;
    if (this.dialogsOpen !== newDialogsOpen) {
      this.dialogsOpen = newDialogsOpen;
    }
  }
}
