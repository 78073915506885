import { Component, HostBinding, OnInit, ViewEncapsulation } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { CoreState } from '../../store/common';
import { selectHelpText } from '../../store/help/help.reducer';

@Component({
  selector: 'mpac-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HelpComponent implements OnInit {
  public static baseClass = 'mpac-help';

  @HostBinding('class') hostClassBinding;

  public content$: Observable<string>;

  constructor(private store: Store<CoreState>) {}

  public ngOnInit(): void {
    this.setHostClassBinding();
    this.content$ = this.store.pipe(select(selectHelpText));
  }

  protected setHostClassBinding(): void {
    this.hostClassBinding = HelpComponent.baseClass;
  }
}
