import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { User } from '@core/models/user';
import { AuthService } from '@core/services/auth.service';
import { NavigationService } from '@core/services/navigation.service';
import { selectUser } from '@core/store/user/user.reducer';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RedirectToUserStartpageGuard implements CanActivate {
  constructor(
    private router: Router,
    private store$: Store<any>,
    private authService: AuthService,
    private navigationService: NavigationService
  ) {}

  /* eslint-disable @typescript-eslint/no-unused-vars */
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.store$.pipe(
      select(selectUser),
      map((user: User) => {
        // Keep the users current route for active sessions
        const hasValidRedirectUrl = this.authService.hasValidRedirectUrl();
        const route = this.navigationService.getRootRouteForUser(user);

        if (hasValidRedirectUrl) {
          this.router.navigate([this.authService.getRedirectUrl()]);
        } else if (route !== '/' && this.router.url !== route) {
          this.router.navigate([route]);
        } else if (this.router.url !== '/login') {
          this.router.navigate(['/login']);
        }
        return false;
      })
    );
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */
}
