<div
  class="mpac-file-upload"
  [class.mpac-file-upload--multiple]="multiple"
  [class.mpac-file-upload--disabled]="disabled"
  [formGroup]="formGroup"
>
  <div class="mpac-file-upload__wrap">
    <!-- Drop Area-->
    <div class="mpac-file-upload__drop-area">
      <file-upload
        class="mpac-file-upload__drop-area-cnt"
        [multiple]="multiple"
        [control]="fileUploadControl"
        [formControlName]="controlName"
      >
        <ng-template #placeholder>
          <div class="mpac-file-upload__drop-area-plh">
            <span class="mpac-file-upload__drop-area-plh-ico"></span>
            <span class="mpac-file-upload__drop-area-plh-txt">
              <ng-container>{{ placeholderTextTranslationKey | translate }}</ng-container>
            </span>
          </div>
        </ng-template>
      </file-upload>
    </div>
    <!-- Errors for the control itself -->
    <div class="mpac-file-upload__validation">
      <mpac-print-form-errors-for [control]="formGroup.get(controlName)"></mpac-print-form-errors-for>
    </div>
    <!-- File List and progress bar(s) -->
    <div class="mpac-file-upload__files" *ngIf="value && value.length">
      <ul class="mpac-file-upload__files-lst">
        <!-- File -->
        <li *ngFor="let file of value; index as i" class="mpac-file-upload__files-lst-itm">
          <mpac-upload-file
            [file]="file"
            [isValid]="!fileValidationMessages.has(i)"
            [validationMessages]="fileValidationMessages.get(i)"
            (removalTriggered)="removeFile($event)"
            [isAudioFile]="isAudioFile"
          >
          </mpac-upload-file>
          <mpac-progress-bar
            class="mpac-file-upload__files-lst-itm-prg"
            *ngIf="uploadProgress.has(i)"
            [status]="uploadProgress.get(i)"
          ></mpac-progress-bar>
        </li>
      </ul>
    </div>
  </div>
</div>
