<div class="login-container">
  <img class="img-fluid wt-logo" src="{{ logoPath }}" title="Logo {{ logoTitle }}" />
  <form (ngSubmit)="login()" [formGroup]="loginForm" class="form--login">
    <mpac-spinner-full
      [isBusy]="isLoading === true"
      spinnerText="{{ spinnerTextKey | translate }}"
      [overlayMode]="true">
      <h2 class="text-center" innerHtml="{{ 'loginPage.introText' | translate }}"></h2>

      <div class="mt-4 text-center" *ngIf="hasAnyPermission !== false">
        <button class="btn btn-outline-primary" type="submit">
          <img class="mr-2" [src]="arrowIcon" />
          {{ 'loginPage.continueToSignIn' | translate }}
        </button>
      </div>
      <div class="mt-4 text-center" *ngIf="hasAnyPermission === false">
        <p class="text-black"><strong innerHtml="{{ 'loginPage.noPermSignedOn' | translate }}"></strong></p>
        <button class="btn btn-outline-primary" type="button" (click)="logout()">
          <img class="mr-2" [src]="arrowIcon" />
          {{ 'actions.signout' | translate }}
        </button>
      </div>
      <div class="mt-4 text-center" *ngIf="hasAnyPermission !== false">
        <p class="text-center">{{ 'loginPage.noPermSignedOff' | translate }}</p>
      </div>
    </mpac-spinner-full>
  </form>
</div>
