import { takeUntil } from 'rxjs/operators';
import { DesctructableComponent } from 'src/app/shared/components/destructable/destructable.component';
import { ISetHostClasses } from './../../interfaces/iset-host-classes.interface';
import { Component, EventEmitter, HostBinding, Output } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormArray, AbstractControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { AlertType } from '../../enums/form-save-alert-types.enum';
import { FormsService } from '@rapid/forms';

@Component({
  template: ''
})
export abstract class FormBaseComponent<T> extends DesctructableComponent implements ISetHostClasses {
  @HostBinding('class') hostClassBinding;

  /**
   * Event that fires current form values on form submit
   */
  @Output()
  public submittedEmitter: EventEmitter<T> = new EventEmitter<T>();

  /**
   * Reference to the form element.
   */
  public form: UntypedFormGroup;

  /**
   * Translations for the forms key.
   */
  protected translations;

  constructor(
    protected formBuilder: UntypedFormBuilder,
    protected translateService: TranslateService,
    protected formsService: FormsService
  ) {
    super();
    this.translateService
      .get('forms')
      .pipe(takeUntil(this.shutdown$))
      .subscribe((translations) => {
        this.translations = translations;
      });
  }

  /**
   * Returns the form control fir this component.
   */
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public get getFormControl() {
    return this.formsService.getFormControl(this.form);
  }

  /**
   * Whether or not the submit should be disabled.
   */
  public get submitDisabled(): boolean {
    return !!this.form?.invalid;
  }

  /**
   * Whether or not the save action bar should be displayed with a given value.
   */
  public get shouldDisplaySaveActionBar(): boolean {
    return this.form && !!this.form && this.form.dirty;
  }

  /**
   * Gets the appropriate alert message type for a given form state.
   *
   */
  public get messageTypeForFormState(): AlertType {
    let alertType: AlertType;
    if (this.form?.dirty) {
      if (this.form?.invalid) {
        alertType = AlertType.DANGER;
      } else {
        alertType = AlertType.WARNING;
      }
    } else {
      alertType = AlertType.SUCCESS;
    }
    return alertType;
  }

  /**
   * Submits the form if it's valid.
   */
  public submit(): void {
    this.form.markAllAsTouched();
    this.form.markAsDirty();

    if (this.form && this.form.valid) {
      this.submittedEmitter.emit(this.convertFormDataToType());
    } else {
      if (this.form) {
        this.form.markAllAsTouched();
      }
    }
  }

  /**
   * Abbreviation for getFormControlNested.
   *
   * @example gfcn('personalData.title')
   */
  public gfcn(relatedControlPath: string): AbstractControl | UntypedFormGroup | UntypedFormArray {
    return this.getFormControlNested(relatedControlPath);
  }

  /**
   * Gets the form control with the given path from the form.
   *
   * @example getFormControlNested('personalData.title')
   */
  public getFormControlNested(relatedControlPath: string): AbstractControl | UntypedFormGroup | UntypedFormArray {
    return this.formsService.getFormControlNested(this.form, relatedControlPath);
  }

  public abstract setHostClassBinding(): void;

  /**
   * Converts the form data to the source Type<T> | null.
   */
  public abstract convertFormDataToType(): T | null;

  /**
   * Base call for initializing the form.
   */
  protected abstract initForm(): void;
}
