import { Component } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { OrderAdminInvitation } from '../../../features/order-portal/models';

@Component({
  selector: 'mpac-withdraw-admin-invite-dialog',
  templateUrl: './withdraw-admin-invite-dialog.component.html'
})
export class WithdrawAdminInviteDialogComponent {
  public invitation: OrderAdminInvitation;

  constructor(private bsModalRef: BsModalRef, private modalService: BsModalService) {}

  public hide(withdraw: boolean = false): void {
    this.modalService.setDismissReason(withdraw.toString());
    this.bsModalRef.hide();
  }
}
