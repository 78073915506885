import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { QuickFormResponse } from './dtos/quick-form-response';
import { OrderMasterdata, AccountInformationDto } from './dtos/order-masterdata.interface';
import { SettingsService } from 'src/app/core/config/settings.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class QuickformOrderMasterdataService {
  /**
   * Endpoint definition.
   */
  private static serviceEndpoint = '/v1';

  constructor(private httpClient: HttpClient, private settingsService: SettingsService) {}

  /**
   * The part of the api url, which is the same across all calls issued from this service.
   */
  private get baseUrl(): string {
    return this.settingsService?.settings?.quickformApiRoot
      ? `${this.settingsService.settings.quickformApiRoot}${QuickformOrderMasterdataService.serviceEndpoint}`
      : '';
  }

  public masterdataUrl = (orderId: string): string => `${this.baseUrl}/orders/${orderId}/master-data`;

  public getMasterdata(orderId: string): Observable<QuickFormResponse<OrderMasterdata>> {
    return this.httpClient.get<QuickFormResponse<OrderMasterdata>>(this.masterdataUrl(orderId));
  }

  public updateBankAccountInfo(orderId: string, accountInfo: AccountInformationDto): any {
    return this.httpClient.put<QuickFormResponse<any>>(
      `${this.baseUrl}/orders/${orderId}/master-data/bank-account`,
      accountInfo
    );
  }
}
