import { NgxBootstrapModule } from './ngx-bootstrap.module';
import { MaterialModule } from './material.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { appearance, CUSTOM_FORMATS, LuxonDateAdapterWrapper } from './const.conf';
import { MAT_LUXON_DATE_ADAPTER_OPTIONS } from '@angular/material-luxon-adapter';

@NgModule({
  declarations: [],
  imports: [FormsModule, ReactiveFormsModule, MaterialModule, NgxBootstrapModule, TranslateModule, CommonModule],
  exports: [FormsModule, ReactiveFormsModule, MaterialModule, NgxBootstrapModule, TranslateModule],
  providers: [
    { provide: MAT_LUXON_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: appearance },
    { provide: MAT_DATE_LOCALE, useValue: 'de-DE' },
    { provide: DateAdapter, useClass: LuxonDateAdapterWrapper, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: CUSTOM_FORMATS },
    DatePipe
  ]
})
export class BaseModule {}
