import { Component, Input, OnInit } from '@angular/core';
import { SettingsService } from '@core/config/settings.service';

export type TrainingCampLinkPlacement = 'header' | 'mobile-menu';

@Component({
  selector: 'mpac-training-camp-link',
  templateUrl: './training-camp-link.component.html'
})
export class TrainingCampLinkComponent implements OnInit {
  @Input() linkPlacement: TrainingCampLinkPlacement;

  public trainingCampUrl: string;

  constructor(private settingsService: SettingsService) {}

  public get cssClassNames(): string {
    switch (this.linkPlacement) {
      case 'header':
        return 'd-lg-block d-none mr-3 text-dark';
      case 'mobile-menu':
        return 'mpac-mobile-menu__item text-light btn btn-link';
      default:
        return '';
    }
  }

  public ngOnInit(): void {
    this.trainingCampUrl = this.settingsService.settings.trainingCampUrl;
  }
}
