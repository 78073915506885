import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';

@Directive({
  selector: '[mpacAnimateWhileLoading]'
})
export class AnimateWhileLoadingDirective {
  constructor(private elRef: ElementRef, private renderer: Renderer2) {}

  @HostListener('load', ['$event'])
  @HostListener('error', ['$event'])
  public onImageLoad(event: Event): void {
    if (!this.elRef.nativeElement.className.includes('loading-done')) {
      this.renderer.addClass(event.target, 'loading-done');
    }
  }
}
