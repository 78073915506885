import { Directive, ElementRef, Input, OnChanges, OnInit, Renderer2, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[mpacIcon]'
})
export class IconDirective implements OnInit, OnChanges {
  @Input('mpacIcon') iconClassName: string;

  constructor(private elementRef: ElementRef, private renderer: Renderer2) {}

  public ngOnInit(): void {
    if (this.iconClassName) {
      this.renderer.insertBefore(
        this.elementRef.nativeElement,
        this.createIconElement(this.iconClassName),
        this.elementRef.nativeElement.firstChild
      );
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (!changes.iconClassName.firstChange) {
      const changedIconClassName: string = changes.iconClassName.currentValue;
      this.elementRef.nativeElement.firstChild.remove();
      this.renderer.insertBefore(
        this.elementRef.nativeElement,
        this.createIconElement(changedIconClassName),
        this.elementRef.nativeElement.firstChild
      );
    }
  }

  private createIconElement(className: string): HTMLElement {
    const iconEl = document.createElement('i');
    iconEl.setAttribute('class', [className, 'icon-spacing-right'].join(' '));

    return iconEl;
  }
}
