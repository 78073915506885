<div [formGroup]="textEditorFormGroup">
  <quill-editor
    formControlName="{{ textEditorFieldName }}"
    id="editor-{{ textEditorFieldName }}"
    [modules]="quillModulesWrapper.quillModules"
    [customModules]="[customModule]"
    placeholder="{{ placeholder }}"
    (onEditorCreated)="created($event)"
    (onContentChanged)="contentChanged($event)"
    [format]="outputFormat"
    [bounds]="'self'">
  </quill-editor>
</div>
