import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { CustomModule } from 'ngx-quill';
import Quill from 'quill';
import { QuillModulesWrapper } from './quill-modules-wrapper';
import MagicUrl from 'quill-magic-url';
import { FontAwesomeTypes } from '../../enums/font-awesome-types.enum';

@Component({
  selector: 'mpac-text-editor',
  templateUrl: './text-editor.component.html',
  styleUrls: ['./text-editor.component.scss']
})
export class TextEditorComponent implements OnInit {
  @Input()
  public useRichTextEditor = false;

  @Input()
  public textEditorFormGroup: UntypedFormGroup;

  @Input()
  public textEditorFieldName: string;

  @Input()
  public placeholder: string;

  @Input()
  public loadTextLabel: string;

  @Input()
  public loadTextIconName: string;

  @Input()
  public loadTextIconType: FontAwesomeTypes;

  @Input()
  public enabledFeatures: string[] = [];

  @Output()
  public loadTextAction = new EventEmitter();

  public outputFormat: string;

  public quillModulesWrapper: QuillModulesWrapper;

  public customModule: CustomModule = {
    path: 'modules/magicUrl',
    implementation: MagicUrl
  };

  private readonly loadText: string = 'load-text';

  private get editorControl(): AbstractControl {
    return this.textEditorFormGroup.get(this.textEditorFieldName);
  }

  public ngOnInit(): void {
    try {
      this.quillModulesWrapper = new QuillModulesWrapper(
        {
          key: this.loadText,
          label: this.loadTextLabel,
          action: this.loadTextAction,
          iconName: this.loadTextIconName,
          iconType: this.loadTextIconType
        },
        this.useRichTextEditor,
        this.enabledFeatures
      );
    } catch (e) {}
    if (!this.useRichTextEditor) {
      this.outputFormat = 'text';
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public created(event: any): void {
    this.quillModulesWrapper.onEditorCreated(event as Quill);
    this.setEditorContent(this.editorControl.value as string);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public contentChanged(event: any): void {
    if (event.source === 'api') {
      this.setEditorContent(this.editorControl.value as string);
    }
    this.complementLink();
  }

  private setEditorContent(text: string): void {
    if (!this.useRichTextEditor) {
      return;
    }

    const trimmedText = text?.trim();
    if (trimmedText && !this.stringContainsAnyTags(trimmedText)) {
      const htmlText = trimmedText.replace(/\r\n/g, '<br>');
      if (htmlText !== trimmedText) {
        this.editorControl.setValue(htmlText);
      }
    }
  }

  /**
   * regex includes among other things tags with/without closing tags, tags with style attributes
   */
  private stringContainsAnyTags(text: string): boolean {
    return /<\/?[a-z][\s\S]*>/i.test(text);
  }

  private complementLink(): void {
    document.querySelectorAll('.ql-editor a').forEach((element) => {
      const href = element.getAttribute('href');
      const prefixHttp = 'http://';
      const prefixHttps = 'https://';

      if (
        href &&
        this.validURL(href) &&
        href.substr(0, prefixHttp.length) !== prefixHttp &&
        href.substr(0, prefixHttps.length) !== prefixHttps
      ) {
        element.setAttribute('href', prefixHttps + href);
      }
    });
  }

  // TODO: Nope, that's not all. Better url validator pattern.
  private validURL(str): boolean {
    const pattern = new RegExp(
      '^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$',
      'i'
    ); // fragment locator
    return !!pattern.test(str as string);
  }
}
