<div class="custom-control custom-switch">
  <input type="hidden" [formControl]="ngControl.control" />
  <input
    type="checkbox"
    class="custom-control-input"
    (change)="onInputChange($event)"
    [attr.id]="ngControl.name"
    [ngClass]="{ 'ng-invalid': ngControl.invalid, 'ng-touched': ngControl.touched }"
    [checked]="innerValue"
    [disabled]="ngControl.disabled" />
  <label class="custom-control-label" [attr.for]="ngControl.name" *ngIf="hasLabel">
    {{ getLabelText() }}
  </label>
  <mpac-tooltip-container [text]="getTooltipText()" *ngIf="hasTooltip"></mpac-tooltip-container>
</div>
<mpac-print-form-errors-for [control]="ngControl.control"> </mpac-print-form-errors-for>
