import { AbstractControl, ValidatorFn } from '@angular/forms';

export const greaterThanValidator =
  (valueFieldKey: string, valueFieldName: string): ValidatorFn =>
  (control: AbstractControl): { [key: string]: any } | null => {
    if (control.value) {
      if (control.parent) {
        const relatedField = control.parent.get(valueFieldKey);
        if (!!relatedField.value && control.value < relatedField.value) {
          return { greaterThan: valueFieldName };
        }
      }
    }
  };
