import { DynTableSorter } from './../../models/dyn-table/dynamic-table-sorter.interface';
import { filter } from 'rxjs/operators';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
  EventEmitter,
  Output
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Sort } from '@angular/material/sort';
import { DynamicTableConfig } from '../../models/dyn-table/dynamic-table-config.model';
import { DynamicTableColumnDef } from '../../models/dyn-table/dynamic-table-column-definition.model';
import { MatPaginator } from '@angular/material/paginator';
import { DomUtil } from '../../helpers/dom.util';
import { IDynamicTableFunction } from '../../models/dyn-table/dynamic-table-function.model';
import { DynamicTableFilterModel } from '../../models/dyn-table/dynamic-table-filter.model';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'mpac-dynamic-table',
  templateUrl: './dynamic-table.component.html',
  styleUrls: [
    './dynamic-table.component.variables.scss',
    './dynamic-table.component--ksc.variables.scss',
    './dynamic-table.component.scss',
    './dynamic-table.component--ksc.scss'
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class DynamicTableComponent implements AfterViewInit, OnChanges {
  [x: string]: any;

  public static sortHeaderHoverClass = 'mpac-dyn-table__tbl-cell--hover';

  @Input()
  public useNativeStyles = true;

  @Input()
  public data: Array<any>;

  @Input()
  public tableConfig: DynamicTableConfig;

  @Input()
  public columnDefJSON: string;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  @Input()
  public filterModel: DynamicTableFilterModel;

  @Input()
  public initialTextFilter: string;

  @Output()
  public initEmitter = new EventEmitter<void>();

  public dataSource: MatTableDataSource<any>;

  public totalRows = 0;

  public filteredRows$: BehaviorSubject<number>;

  private originalData: Array<any>;

  private filteredRows = 0;

  constructor() {
    this.dataSource = new MatTableDataSource();
    this.filteredRows$ = new BehaviorSubject<number>(0);
  }

  /**
   * Compares strings, number and booleans either ascending or descending.
   */
  public static compare(a: number | string, b: number | string, isAsc: boolean): number {
    if (typeof a === 'string' && typeof b === 'string') {
      const res = a.localeCompare(b);
      return isAsc ? res : res * -1;
    }
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.data && changes.data.currentValue && Array.isArray(this.dataSource.data)) {
      // set sorting and pagination prior to data assignment for performance reasons
      this.setSortingAndPagination();

      // check whether or not to update the data
      let updateData = false;
      let checkForFilter = false;
      if (this.originalData && this.originalData !== this.data) {
        updateData = true;
      } else if (!this.originalData) {
        checkForFilter = true;
      }
      this.originalData = this.data;

      // Note: This has been implemented as mitigation for slow rendering when re-rendering the table without reloading
      window.setTimeout(() => {
        const isFiltered = this.isFiltered();
        if (updateData || (checkForFilter && !isFiltered)) {
          this.dataSource.data = this.originalData.slice(0);
        }
        if (this.initialTextFilter) {
          this.applyTextDataFilter(this.initialTextFilter);
        }
        if (isFiltered) {
          this.applyDataFilters();
        }
      }, 0);
      this.updateRowNumberTotal();
      this.updateRowNumberFiltered();
    }
  }

  public ngAfterViewInit(): void {
    this.setSortingAndPagination();
  }

  /**
   * Applies the given filter value to the dataSource.
   *
   * @param filterValue The string value to filter for.
   */
  public applyTextDataFilter(filterValue: string): void {
    const filterValueIsEmpty = !filter || !filterValue.length;
    if (this.dataSource) {
      this.dataSource.filter = !filterValueIsEmpty ? filterValue.trim().toLowerCase() : '';
      this.updateRowNumberFiltered();
    }
  }

  /**
   * Applies the data filters configured for the table.
   */
  public applyDataFilters(): void {
    if (!this.filterModel) {
      return;
    }
    const data = this.filterModel.filter(this.originalData);
    this.dataSource.data = data;
    this.updateRowNumberFiltered();
  }

  /**
   * Clears the data filters of the table and resets the data.
   */
  public clearDataFilters(): void {
    if (!this.filterModel) {
      return;
    }
    this.filterModel.clear();
    this.dataSource.data = this.data;
    this.updateRowNumberFiltered();
  }

  /**
   * Checks whether or not a row router action is defined.
   */
  public hasRowAction(): boolean {
    return this.tableConfig && this.tableConfig.rowAction && this.tableConfig.rowAction.routeBase ? true : false;
  }

  public isRowSortable(row: DynamicTableColumnDef): boolean {
    return (
      (typeof row.sortOptions === 'boolean' && row.sortOptions === true) || (row.sortOptions as DynTableSorter).sortable
    );
  }

  /**
   * Gets the row action string matched with the required params for the given row.
   */
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public getRowAction(row: any): string {
    if (this.hasRowAction()) {
      let paramsString = '';
      if (this.tableConfig.rowAction.routeParameters && this.tableConfig.rowAction.routeParameters.length) {
        for (const param of this.tableConfig.rowAction.routeParameters) {
          const routeParamValue: string = row[param].trim();
          paramsString += '/';
          paramsString += `${encodeURIComponent(routeParamValue)}`;
        }
      }
      return this.tableConfig.rowAction.routeBase + paramsString;
    }
    return null;
  }

  /**
   * Returns the query parameters for a url as object or null.
   */
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public getRowQueryParams(row: any): any | null {
    let queryParams = null;
    if (this.tableConfig.rowAction.queryParameters && this.tableConfig.rowAction.queryParameters.length) {
      for (const param of this.tableConfig.rowAction.queryParameters) {
        if (!queryParams) {
          queryParams = {};
        }
        queryParams[param] = row[param];
      }
      return queryParams;
    }
    return null;
  }

  /**
   * Checks whether or not the column config has a valid cell action.
   */
  public hasColCellAction(columnDef: DynamicTableColumnDef): boolean {
    return columnDef && columnDef.cellAction && columnDef.cellAction.routeBase ? true : false;
  }

  /**
   * Checks whether the given column has cell col actions defined.
   */
  public hasColCellFunctions(columnDef: DynamicTableColumnDef): boolean {
    return columnDef && columnDef.cellFunctions && columnDef.cellFunctions.length ? true : false;
  }

  /**
   * Checks whether the given column has image defined.
   */
  public hasColImage(columnDef: DynamicTableColumnDef): boolean {
    return columnDef && columnDef.image ? true : false;
  }

  /**
   * Gets the router link for the cell action defined for a coumn.
   *
   * @return The base route combined with the route params for the cell action.
   */
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public getCellAction(columDef: DynamicTableColumnDef, row: any): string {
    if (this.hasColCellAction(columDef)) {
      let paramsString = '';
      if (columDef.cellAction.routeParameters && columDef.cellAction.routeParameters.length) {
        for (const param of columDef.cellAction.routeParameters) {
          const routeParamValue: string = row[param];
          paramsString += '/';
          paramsString += `${encodeURIComponent(routeParamValue)}`;
        }
      }
      return columDef.cellAction.routeBase + paramsString;
    }
    return null;
  }

  /**
   * Handler for the matSortChange event.
   */
  public sortData(sort: Sort): void {
    const data = this.dataSource.data.slice();
    if (!sort.active || sort.direction === '') {
      this.dataSource.data = data;
      return;
    }

    const config = this.tableConfig.columDefinitions.find((col) => col.accessor === sort.active).sortOptions;
    if (typeof config === 'boolean' || config.useDefaultComparer) {
      this.dataSource.data = data.sort((a, b) => {
        const isAsc = sort.direction === 'asc';
        const aValue: string | number = a[sort.active];
        const bValue: string | number = b[sort.active];
        return DynamicTableComponent.compare(aValue, bValue, isAsc);
      });
    } else {
      const comparer = config.comparer;
      if (config.compareAccessor) {
        const sortAccessor = config.compareAccessor;
        this.dataSource.data = data.sort((a, b) => {
          const isAsc = sort.direction === 'asc';
          return comparer(a[sortAccessor], b[sortAccessor], isAsc);
        });
      } else {
        this.dataSource.data = data.sort((a, b) => {
          const isAsc = sort.direction === 'asc';
          return comparer(a[sort.active], b[sort.active], isAsc);
        });
      }
    }
  }

  /**
   * Handler for mouse events on the sort header elements.
   *
   * @param $event Dom Event mouseover, mouseout
   */
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public toggleSortHoverClass($event): void {
    const eventTarget = DomUtil.originalEventTarget($event);
    if (!eventTarget) {
      return;
    }
    const originalTarget: Element = $event.originalTarget;
    const currentTarget: Element = $event.currentTarget;
    const isDescendant = DomUtil.isDescendant(originalTarget, currentTarget);

    if (
      eventTarget.classList.contains(DynamicTableComponent.sortHeaderHoverClass) &&
      $event.type === 'mouseleave' &&
      !isDescendant
    ) {
      eventTarget.classList.remove(DynamicTableComponent.sortHeaderHoverClass);
    } else {
      this.pruneSortHeadClass(eventTarget);
      eventTarget.classList.add(DynamicTableComponent.sortHeaderHoverClass);
    }
  }

  /**
   * Whether or not the text value of this column should be displayed.
   */
  public shouldShowTextValue(columDef: DynamicTableColumnDef): boolean {
    return columDef.displayTextValue;
  }

  /**
   * Calls the functions provided by any dynamic table function and inserts the params given by the data.
   */
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public apply(data: any, fct: IDynamicTableFunction): string {
    const call = fct.call;
    if (call && typeof call === 'function') {
      // Apply args
      const args = [];
      for (const paramKey of fct.callParams) {
        if (!paramKey || !data[paramKey]) {
          continue;
        }
        args.push(data[paramKey]);
      }

      // call
      return call.apply(fct.thisRef, args);
    }
  }

  public isFiltered(): boolean {
    if (this.filteredRows === 0) {
      return (this.totalRows !== this.filteredRows &&
        this.dataSource &&
        this.dataSource.filter &&
        this.dataSource.filter.length) ||
        (this.filterModel && this.filterModel.hasActiveFilters())
        ? true
        : false;
    }
    return this.totalRows !== this.filteredRows ||
      (this.dataSource && this.dataSource.filter && this.dataSource.filter.length) ||
      (this.filterModel && this.filterModel.hasActiveFilters())
      ? true
      : false;
  }

  public hasData(): boolean {
    return this.dataSource && this.dataSource.data && this.dataSource.data.length && this.dataSource.filteredData.length
      ? true
      : false;
  }

  /**
   * Removes the sort header hover classes from all headers.
   */
  private pruneSortHeadClass(sortHeader: Element): void {
    if (sortHeader) {
      const headersWClass = sortHeader.parentNode.querySelectorAll(`.${DynamicTableComponent.sortHeaderHoverClass}`);

      for (let i = 0, iLen = headersWClass.length; i < iLen; ++i) {
        const element = headersWClass[i];
        if (element) {
          continue;
        }
        element.classList.remove(DynamicTableComponent.sortHeaderHoverClass);
      }
    }
  }

  private updateRowNumberTotal(): void {
    this.totalRows = this.originalData.length;
  }

  private updateRowNumberFiltered(): void {
    this.filteredRows = this.dataSource.filteredData.length;
    this.filteredRows$.next(this.filteredRows);
  }

  private setSortingAndPagination(): void {
    if (this.dataSource) {
      if (this.dataSource.sort !== this.sort) {
        this.dataSource.sort = this.sort;
      }
      if (this.dataSource.paginator !== this.paginator) {
        this.dataSource.paginator = this.paginator;
      }
      this.initEmitter.emit();
    }
  }
}
