<div class="container">
  <div class="row">
    <div class="tos col">
      <div class="d-block text-center text-danger p-5" *ngIf="loading">
        <span class="spinner-border"></span>
      </div>

      <ng-container *ngIf="!loading">
        <ng-container *ngIf="termsOfUseText">
          <div [data]="termsOfUseText" markdown>{{ termsOfUseText }}</div>
        </ng-container>

        <ng-container *ngIf="!termsOfUseText">
          <h3>{{ 'termsOfUse.loadingError.message' | translate }}</h3>
          <p>
            {{ 'termsOfUse.loadingError.locationInfo' | translate }}
            <a target="_blank" href="{{ privacyPolicyUrl }}">{{ 'general.here' | translate }}</a>
          </p>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>
