<div class="modal-header">
  <h2 class="modal-title pull-left">{{ (invitationWasSent ? 'order.administration.resend.resend' : 'order.administration.send.send') | translate }}</h2>
  <button (click)="hide()" attr.aria-label="{{ 'actions.close' | translate }}" class="close pull-right" type="button">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <p>
    {{ (invitationWasSent ? 'order.administration.resend.body.p0' : 'order.administration.send.body.p0') | translate }}
    <ng-container *ngIf="invitation && invitation.emailAddress">
      <strong>{{ invitation.emailAddress }}</strong>
    </ng-container>
    {{ (invitationWasSent ? 'order.administration.resend.body.p1' : 'order.administration.send.body.p1') | translate }}
  </p>
</div>
<div class="modal-footer">
  <div class="container modal-footer__cnt">
    <div class="row modal-footer__row">
      <div class="col modal-footer__col col-12 col-md-auto ml-auto">
        <button (click)="hide()" class="btn btn-primary">{{ 'actions.cancel' | translate }}</button>
      </div>
      <div class="col modal-footer__col col-12 col-md-auto">
        <button (click)="hide(true)" class="btn btn-primary" mpacGlobalWriteProtection>
          <span>
            <i [class]="symbolClass"></i>{{ (invitationWasSent ? 'order.administration.renewInvitation' : 'order.administration.sendInvitation') | translate }}
          </span>
        </button>
      </div>
    </div>
  </div>
</div>
