import { SettingsService } from './../config/settings.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { catchError, first, map } from 'rxjs/operators';
import { ProcessUserResult } from '../models/process-user-result';
import { CheckedUser, UserState } from '../models/user-state';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class RegistrationGuard implements CanActivate {
  constructor(
    private router: Router,
    private httpClient: HttpClient,
    private settingsService: SettingsService,
    private userService: UserService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const hash = state.url.substr(state.url.lastIndexOf('/') + 1);
    return this.userService.checkInvitedUser(hash).pipe(
      map((checkedUser: CheckedUser) => {
        switch (checkedUser.userState) {
          case UserState.Unknown:
          case UserState.Unconfirmed:
            return true;

          case UserState.Confirmed:
            // user is known and has confirmed email address => elevate
            this.processKnownUser(hash)
              .pipe(first())
              .subscribe((result: ProcessUserResult) => {
                this.router.navigate(['rechte-erweiterung'], { state: result });
              });

            return false;
        }
      }),
      catchError(() => {
        this.router.navigate(['login']);
        return of(false);
      })
    );
  }

  private processKnownUser(hash: string): Observable<ProcessUserResult> {
    return this.httpClient.post<ProcessUserResult>(
      `${this.settingsService.settings.apiRoot}/registration/process-known-user`,
      `"${hash}"`,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      { headers: new HttpHeaders({ 'Content-type': 'application/json' }) }
    );
  }
}
