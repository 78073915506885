import { QuillModules } from 'ngx-quill';
import QuillType from 'quill';
import { FontAwesomeTypes } from '../../enums/font-awesome-types.enum';
import { RichTextEditorButton } from './rich-text-editor-button.model';

export class QuillModulesWrapper {
  private readonly richTextEditorButtonField: RichTextEditorButton;

  private readonly quillModulesField: QuillModules;

  private enabledFeatures: (string | { list: string })[];

  private quillEditor: QuillType;

  private readonly titles: { [key: string]: string } = {
    bold: 'Fett',
    underline: 'Unterstreichen',
    italic: 'Kursiv',
    link: 'Link erstellen',
    list: 'Stichpunkte',
    clean: 'Formatierung zurücksetzen'
  };

  constructor(
    richTextEditorButton: RichTextEditorButton,
    useRichTextEditor: boolean,
    enabledFeatures: (string | { list: string })[] = []
  ) {
    this.richTextEditorButtonField = richTextEditorButton;
    this.enabledFeatures = enabledFeatures.length ? enabledFeatures : this.defaultFeatures;
    this.quillModulesField = this.initQuillModules(useRichTextEditor);
  }

  public get quillModules(): QuillModules {
    return this.quillModulesField;
  }

  private get defaultFeatures(): string[] {
    return ['bold', 'italic', 'underline', 'link'];
  }

  public onEditorCreated(editor: QuillType): void {
    this.quillEditor = editor;
    this.setButtonLabel();
    this.setTitle();
  }

  private setButtonLabel(): void {
    if (!this.quillEditor) {
      return;
    }

    // set float left if we got an additional button here, otherwise float right
    const ownerDocument = this.quillEditor.root.ownerDocument;
    if (!this.richTextEditorButtonField.label) {
      const formats = ownerDocument.getElementsByClassName('ql-formats') as HTMLCollectionOf<HTMLElement>;
      if (formats[formats.length - 1]) {
        formats[formats.length - 1].style.float = 'left';
      }
      return;
    }

    // set the label & icon
    const el: Element = ownerDocument.getElementsByClassName(`ql-${this.richTextEditorButtonField.key}`)[0];
    if (!el) {
      return;
    }
    el.innerHTML = this.getButtonHtml();
  }

  private getButtonHtml(): string {
    let innerHtml = `<span class="ql-${this.richTextEditorButtonField.key}__txt">${this.richTextEditorButtonField.label}</span>`;
    if (this.richTextEditorButtonField.iconName) {
      let iconTypeClass = null;

      switch (this.richTextEditorButtonField.iconType) {
        case FontAwesomeTypes.Light:
          iconTypeClass = 'fal';
          break;
        case FontAwesomeTypes.Solid:
          iconTypeClass = 'fas';
          break;
        case FontAwesomeTypes.Regular:
        default:
          iconTypeClass = 'fa';
      }
      innerHtml += `<i class="${iconTypeClass as string} ${this.richTextEditorButtonField.iconName} ql-${
        this.richTextEditorButtonField.key
      }__ico"></i>`;
    }
    return innerHtml;
  }

  private setTitle(): void {
    if (!this.quillEditor) {
      return;
    }
    const ownerDocument = this.quillEditor.root.ownerDocument;
    // tslint:disable-next-line: forin
    for (const key in this.titles) {
      // eslint-disable-next-line no-prototype-builtins
      if (this.titles.hasOwnProperty(key)) {
        const button = ownerDocument.getElementsByClassName(`ql-${key}`)[0] as HTMLElement;
        if (button) {
          button.title = this.titles[key];
        }
      }
    }
  }

  private initQuillModules(useRichTextEditor: boolean): QuillModules {
    const modules = {
      magicUrl: true,
      toolbar: {
        container: [this.enabledFeatures, ['clean'], [this.richTextEditorButtonField.key]],
        handlers: {
          [this.richTextEditorButtonField.key]: () => {
            this.richTextEditorButtonField.action.emit();
            this.quillEditor.root.blur();
          }
        }
      }
    };
    this.customizeToolbar(modules, useRichTextEditor);
    return modules;
  }

  private customizeToolbar(quillModules: QuillModules, useRichTextEditor: boolean): void {
    if (!useRichTextEditor) {
      (quillModules.toolbar as any).container = (quillModules.toolbar as any).container.slice(3, 4);
    }
    if (!this.richTextEditorButtonField.label) {
      (quillModules.toolbar as any).container.pop();
    }
    if ((quillModules.toolbar as any).container.length === 0) {
      quillModules.toolbar = false;
    }
  }
}
