import { createAction, props, union } from '@ngrx/store';
import { User } from '../../models/user';

export const loadLoggedInUserInfoAction = createAction('[User] Load LoggedIn User Info');

export const loginUserAction = createAction('[User] Login User', props<{ userName: string; password: string }>());

export const loginUserSuccessAction = createAction('[User] Login User Success', props<{ user: User }>());

export const loginStoredUserAction = createAction('[User] Login Stored User');

export const loginStoredUserSuccessAction = createAction('[User] Login Stored User Success', props<{ user: User }>());

export const loginStoredUserFailureAction = createAction('[User] Login Stored User Failure');

export const logoutUserAction = createAction('[User] Logout User');

export const reloadUserInfoAction = createAction('[User] Reload User Info');

export const updateUserInfoAction = createAction('[User] Update User Info in Local Storage', props<{ user: User }>());

const actions = union({
  /* eslint-disable @typescript-eslint/naming-convention */
  LoadLoggedInUserInfoAction: loadLoggedInUserInfoAction,
  LoginUserAction: loginUserAction,
  LoginUserSuccessAction: loginUserSuccessAction,
  LoginStoredUserAction: loginStoredUserAction,
  LoginStoredUserSuccessAction: loginStoredUserSuccessAction,
  LoginStoredUserFailureAction: loginStoredUserFailureAction,
  LogoutUserAction: logoutUserAction,
  UpdateUserInfoAction: updateUserInfoAction,
  ReloadUserInfoAction: reloadUserInfoAction
  /* eslint-enable @typescript-eslint/naming-convention */
});

export type UserAction = typeof actions;
