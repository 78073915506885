import { HostBinding, Input, OnInit, OnChanges, ViewEncapsulation } from '@angular/core';
import { Component } from '@angular/core';

@Component({
  selector: 'mpac-start-page-widget',
  templateUrl: './start-page-widget.component.html',
  styleUrls: ['./start-page-widget.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class StartPageWidgetComponent implements OnInit, OnChanges {
  public static baseClass = 'mpac-stp-widget';

  public static wideClass = ' mpac-stp-widget--wide';

  public static disabledClass = ' mpac-stp-widget--disabled';

  public static progressiveClass = ' mpac-stp-widget--prog';

  @HostBinding('class') hostClassBinding;

  /**
   * Whether or not the classic rendering styles should be used.
   */
  @Input()
  public useLegacyRendering: boolean;

  /**
   * Applicable for legacyRendering only (link to the image).
   */
  @Input()
  public icon: string;

  /**
   * Headline text
   */
  @Input()
  public headline: string;

  /**
   * Content text
   */
  @Input()
  public contentText: string;

  /**
   * Router link to the action.
   */
  @Input()
  public actionLink: string;

  /**
   * Reouter link text
   */
  @Input()
  public actionText: string;

  /**
   * Whether or not the widget is wide.
   */
  @Input()
  public wide: boolean;

  /**
   * Whether or not the widget is active.
   */
  @Input()
  public disabled: boolean;

  constructor() {}

  public ngOnInit(): void {
    this.setHostClassBinding();
  }

  public ngOnChanges(): void {
    this.setHostClassBinding();
  }

  protected setHostClassBinding(): void {
    this.hostClassBinding = `${StartPageWidgetComponent.baseClass}${
      this.wide ? StartPageWidgetComponent.wideClass : ''
    }${this.disabled ? StartPageWidgetComponent.disabledClass : ''}${
      this.wide ? StartPageWidgetComponent.progressiveClass : ''
    }`;
  }
}
