import { AbstractControl, ValidatorFn } from '@angular/forms';

/**
 * Checks if the HTML5 date input element returned a valid value.
 * If something is wrong with the current input it for some reason
 * returns the string 'Invalid DateTime'. This returns an object
 * identifying the error with 'invalidDate' in that case.
 *
 * Usage:
 * if (control.errors.invalidDate) {
 * //show message
 * }
 *
 * @param control The control to check on
 * @return an object containing the field 'invalidDate' which is set to true
 */
export const dateInputValidator: ValidatorFn = (control: AbstractControl): { [key: string]: any } | null =>
  control.value !== 'Invalid DateTime'
    ? null // pass
    : { invalidDate: true }; // error
