/**
 * Enumeration of possible PartnerAction Types for a Product
 */
export enum PartnerActionType {
  Undefined = 'Undefined',
  DIRECTCANCELATION = 'DIRECT_CANCELATION',
  DISCLOSURE = 'DISCLOSURE',
  DIRECTTRANSFER = 'DIRECT_TRANSFER',
  DIRECTCANCELATIONWITHEXPECTEDBALANCE = 'DIRECT_CANCELATION_WITH_EXPECTED_BALANCE'
}
