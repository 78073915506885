<mpac-mat-dialog-header headline="E-Mail Einstellungen" [translateHeadline]="false">
</mpac-mat-dialog-header>

<mpac-mat-dialog-content>
    <ng-template #contentElementRef>
      <mpac-email-settings-form
        [isMortician]="isMortician"
        [isBusy]="busy"
        (toggleClicked)="updateSetting($event)"
        #emailSettingsForm>
      </mpac-email-settings-form>
    </ng-template>
</mpac-mat-dialog-content>
