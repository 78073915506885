import { createAction, props, union } from '@ngrx/store';
import { Order } from '../../models/order';
import { OrderCustomization } from '../../models/order-customization';
import { OrderSettingsFormData } from '../../../../shared/models/order-settings';

export const loadOrderAction = createAction('[Order] Load Order', props<{ orderPk: number }>());

export const loadOrderSuccessAction = createAction('[Order] Load Order Success', props<{ order: Order }>());

export const loadOrderFailureAction = createAction(
  '[Order] Load Order failed!',
  props<{ errorMsg: string; errorCode: number }>()
);

export const saveModifiedOrderAction = createAction('[Order] Save modified order', props<{ order: Order }>());

export const saveOrderCustomizationAction = createAction(
  '[Order] Save customized order',
  props<{ orderPk: number; orderCustomization: OrderCustomization }>()
);

export const saveOrderSettingsAction = createAction(
  '[Order] Save order settings',
  props<{ orderPk: number; orderSettings: OrderSettingsFormData }>()
);

export const saveOrderSettingsSuccessAction = createAction(
  '[Order] Save order settings success',
  props<{ order: Order }>()
);

export const saveOrderSuccessAction = createAction('[Order] Save order success', props<{ orderPk: number }>());

export const saveOrderFailureAction = createAction(
  '[Order] Order upload failed',
  props<{ errorMsg: string; errorCode: number }>()
);

export const uploadNewProfileImgForOrderAction = createAction(
  '[Order] Upload new profile image',
  props<{ fileToUpload: string }>()
);

const orderActions = union({
  /* eslint-disable @typescript-eslint/naming-convention */
  LoadOrderAction: loadOrderAction,
  LoadOrderSuccessAction: loadOrderSuccessAction,
  LoadOrderFailureAction: loadOrderFailureAction,
  SaveModifiedOrderAction: saveModifiedOrderAction,
  SaveOrderCustomizationAction: saveOrderCustomizationAction,
  SaveOrderSettingsAction: saveOrderSettingsAction,
  SaveOrderSuccessAction: saveOrderSuccessAction,
  SaveOrderSettingsSuccessAction: saveOrderSettingsSuccessAction,
  SaveOrderFailureAction: saveOrderFailureAction,
  UploadNewProfileImgForOrder: uploadNewProfileImgForOrderAction
  /* eslint-enable @typescript-eslint/naming-convention */
});

export type OrderAction = typeof orderActions;
