import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';

export class MatPaginatorTranslated extends MatPaginatorIntl {
  constructor(private translateService: TranslateService) {
    super();

    this.itemsPerPageLabel = this.translateService.instant('mat.paginator.itemsPerPage');
    this.nextPageLabel = this.translateService.instant('mat.paginator.nextPage');
    this.previousPageLabel = this.translateService.instant('mat.paginator.previousPage');
    this.firstPageLabel = this.translateService.instant('mat.paginator.firstPage');
    this.lastPageLabel = this.translateService.instant('mat.paginator.lastPage');
    this.getRangeLabel = this.getRangeLabelFct;
  }

  private getRangeLabelFct(page: number, pageSize: number, length: number): string {
    if (length === 0 || pageSize === 0) {
      return this.translateService.instant('mat.paginator.range.first', { length });
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
    return this.translateService.instant('mat.paginator.range.second', {
      startIndex: startIndex + 1,
      endIndex,
      length
    });
  }
}
