import { Component, HostBinding, Input, OnInit, ViewEncapsulation, OnChanges, SimpleChanges } from '@angular/core';
import { DomUtil } from '../../helpers/dom.util';

@Component({
  selector: 'mpac-page-sec-cnt-header',
  templateUrl: './page-sec-cnt-header.html',
  styleUrls: ['./page-sec-cnt-header.variables.scss', './page-sec-cnt-header.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PageSectionContentHeaderComponent implements OnInit, OnChanges {
  public static baseClass = 'mpac-page-sec-cnt-hdr';

  public static baseClassScroll = 'mpac-page-sec-cnt-hdr--scroll';

  @HostBinding('class') hostClassBinding;

  @Input()
  public headlineText: string;

  @Input()
  public headlineId = '';

  @Input()
  public showScrollButton = false;

  @Input()
  public btnText: string;

  @Input()
  public scrollToSelector: string;

  @Input()
  public scrollArrowDirectionDown: boolean;

  @Input()
  public scrollEvenIfInView = false;

  /**
   * indicates if the page-content has padding
   */
  @Input() public padding = true;

  public get hasScrollButton(): boolean {
    return this.showScrollButton && !!this.scrollToSelector;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (
      (changes.showScrollButton && !changes.showScrollButton.firstChange) ||
      (changes.scrollEvenIfInView && !changes.scrollEvenIfInView.firstChange)
    ) {
      this.setHostClassBinding();
    }
  }

  public ngOnInit(): void {
    this.setHostClassBinding();
  }

  public scrollToElement(id: string, checkViewport: boolean = true): void {
    DomUtil.scrollToElement(id, checkViewport);
  }

  protected setHostClassBinding(): void {
    const arr = [PageSectionContentHeaderComponent.baseClass];
    if (this.hasScrollButton) {
      arr.push(PageSectionContentHeaderComponent.baseClassScroll);
    }
    this.hostClassBinding = arr.join(' ');
  }
}
