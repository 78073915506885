<!-- Header -->
<mpac-header></mpac-header>

<!-- Content -->
<div class="mpac-loggedin__cnt">
  <div class="mpac-loggedin__cnt-main">
    <router-outlet></router-outlet>
  </div>
  <mpac-help class="mpac-loggedin__cnt-aside"></mpac-help>
</div>

<!-- Footer-->
<mpac-mobile-menu *ngIf="isMobile"></mpac-mobile-menu>
