export enum NotificationTypes {
  None,
  Info,
  MissingInfo,
  Warning,
  Success,
  Error,
  Indeterminate,
  Change,
  Date
}
