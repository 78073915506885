import { Expose, Transform } from 'class-transformer';

export class AuthSettings {
  /**
   * API client key to connect with the identitiy authority.
   */
  @Expose({ name: 'IDENTITY_API_CLIENT_ID' })
  public identityApiClientId: string;

  /**
   * Login service url (identitiy service).
   */
  @Expose({ name: 'IDENTITIY_AUTHORITY' })
  public identityAuthority: string;

  /**
   * Wehter or not HTTPS should be required for authentication.
   */
  @Expose({ name: 'REQUIRE_HTTPS' })
  @Transform(({ value }) => {
    switch (value) {
      case 'true':
        return true;
      case 'false':
        return false;
      default:
        return value;
    }
  })
  public requireHttps: boolean;

  /**
   * Whether or not to skip the certificate issuer check.
   */
  @Expose({ name: 'SKIP_ISSUER_CHECK' })
  @Transform(({ value }) => {
    switch (value) {
      case 'true':
        return true;
      case 'false':
        return false;
      default:
        return value;
    }
  })
  public skipIssuerCheck: boolean;
}
