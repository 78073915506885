import { AccessLevelDto } from '../dtos/access-level-dt.model';
import { SalutationType } from '../../../../../shared/enums/salutation.enum';
import { LocationDto } from '../dtos/location-dto.model';
import { AddressDto } from '../dtos/address-dto.model';
import { AddressListSectionDto } from '../dtos/address-list-section-dto.model';
import { AddressSnapshotDto } from '../dtos/address-snapshot-dto.model';
import { AddressListDto } from '../dtos/address-list-dto.model';
import { AddressInfoDto } from '../dtos/address-info-dto.model';
import { DateUtil } from 'src/app/shared/helpers/date-util';
import { AddressLatestSnapShotDto } from '../dtos/address-latest-snapshot-dto.model';
import { AddressListSectionSnapShotDto } from '../dtos/address-list-section-snapshot-dto.model';
import { AddressAccessLevel } from '../enums/address-access-level.enum';
import { AddressSectionType } from '../enums/address-types.enum';
import { LocationType } from '../enums/location-type.enum';
import { Location } from '../models/location.model';
import { ServiceType } from '../enums/service-types.enum';
import { AddressSnapshotSectionLatest } from '../models/address-latest-snapshot.model';
import { AddressListSection } from '../models/address-list-section.model';
import { AddressList } from '../models/address-list.model';
import { AddressSnapshotLatest, AddressSnapShot } from '../models/address-snapshot.model';
import { Address } from '../models/address.model';
import { AddressInfo } from '../models/address-info.model';
import { DateTime } from 'luxon';

export class FuneralAddressDtoConverter {
  public static convertAddressListDto(response: AddressListDto): AddressList {
    return {
      addresses: response.addresses.map((addr) => FuneralAddressDtoConverter.convertAddressDto(addr)),
      id: response.id,
      lastSnapshotSection: FuneralAddressDtoConverter.convertLastSnapshotSectionDto(response.lastSnapshotSection),
      sections: response.sections.map((sec) => FuneralAddressDtoConverter.convertAddressListSectionDto(sec)),
      updatedAt: FuneralAddressDtoConverter.getDateFormatted(response.updatedAt),
      version: response.version,
      webloopOrderPk: response.webloopOrderPk
    } as AddressList;
  }

  public static convertLastSnapshotSectionDto(response: AddressLatestSnapShotDto): AddressSnapshotSectionLatest | null {
    if (!response) {
      return null;
    }

    return {
      id: response.id,
      sectionType: this.getAddressSectionType(response.sectionType),
      snapshot: this.convertLastSnapShotSectionSnapshotDto(response.snapshot),
      updatedAt: FuneralAddressDtoConverter.getDateFormatted(response.updatedAt),
      snapshotManuallyRequested: response.snapshotManuallyRequested
    } as AddressSnapshotSectionLatest;
  }

  static convertLastSnapShotSectionSnapshotDto(response: AddressSnapshotDto): AddressSnapshotLatest {
    return {
      comment: response.comment,
      outdated: response.outdated,
      timedOut: response.timedOut,
      addresses: response.addresses.map((adr) => FuneralAddressDtoConverter.convertAddressDto(adr))
    } as AddressSnapshotLatest;
  }

  static convertListSectionSnapshotDto(response: AddressListSectionSnapShotDto): AddressSnapShot | null {
    if (!response) {
      return null;
    }

    return {
      comment: response.comment,
      outdated: response.outdated,
      timedOut: response.timedOut
    } as AddressSnapShot;
  }

  public static convertAddressListSectionDto(response: AddressListSectionDto): AddressListSection {
    return {
      id: response.id,
      snapshot: FuneralAddressDtoConverter.convertListSectionSnapshotDto(response.snapshot),
      type: FuneralAddressDtoConverter.getAddressSectionType(response.type),
      updatedAt: FuneralAddressDtoConverter.getDateFormatted(response.updatedAt),
      version: response.version,
      isSnapshotRequested: response.isSnapshotRequested,
      snapshotManuallyRequested: response.isSnapshotManuallyRequested,
      snapshotReminderSendAt: response.snapshotReminderSendAt
        ? FuneralAddressDtoConverter.getDateFormatted(response.snapshotReminderSendAt)
        : null,
      snapshotAnsweredAt: response.snapshotAnsweredAt
        ? FuneralAddressDtoConverter.getDateFormatted(response.snapshotAnsweredAt)
        : null,
      snapshotRequestedAt: response.snapshotRequestedAt
        ? FuneralAddressDtoConverter.getDateFormatted(response.snapshotRequestedAt)
        : null
    } as AddressListSection;
  }

  public static convertAddressDto(response: AddressDto): Address {
    return {
      id: response.id,
      info: FuneralAddressDtoConverter.convertAddressInfoDto(response.info),
      commentForMortician: response.commentForMortician,
      location: FuneralAddressDtoConverter.convertAddressLocationDto(response.location),
      privateComment: response.privateComment,
      serviceTypes: response.serviceTypes.map((st) => FuneralAddressDtoConverter.getServicesType(st)),
      updatedAt: FuneralAddressDtoConverter.getDateFormatted(response.updatedAt)
    } as Address;
  }

  public static convertAddress(data: Address): AddressDto {
    return {
      id: data.id,
      info: FuneralAddressDtoConverter.convertAddressInfo(data.info),
      commentForMortician: data.commentForMortician,
      location: FuneralAddressDtoConverter.convertAddressLocation(data.location),
      privateComment: data.privateComment,
      serviceTypes: data.serviceTypes.map((st) => FuneralAddressDtoConverter.getServicesTypeString(st)),
      updatedAt: data.updatedAt ? data.updatedAt.toISO() : null
    } as AddressDto;
  }

  public static convertAddressLocationDto(response: LocationDto): Location {
    return {
      additionalAddress: response.additionalAddress,
      city: response.city,
      country: response.country,
      houseNumber: response.houseNumber,
      postalCode: response.postalCode,
      street: response.street,
      type: response.type ? FuneralAddressDtoConverter.getLocationType(response.type) : LocationType.Unknown
    } as Location;
  }

  public static convertAddressLocation(data: Location): LocationDto {
    return {
      additionalAddress: data.additionalAddress,
      city: data.city,
      country: data.type === LocationType.Private ? null : data.country,
      houseNumber: data.houseNumber,
      postalCode: data.postalCode,
      street: data.street,
      type: FuneralAddressDtoConverter.getLocationTypeString(data.type)
    } as LocationDto;
  }

  public static convertAddressInfoDto(response: AddressInfoDto): AddressInfo {
    return {
      salutation: FuneralAddressDtoConverter.getSalutationType(response.salutation),
      title: response.title,
      firstName: response.firstName,
      lastName: response.lastName,
      familyName: response.familyName,
      companyName: response.companyName
    } as AddressInfo;
  }

  public static convertAddressInfo(data: AddressInfo): AddressInfoDto {
    return {
      salutation: FuneralAddressDtoConverter.getSalutationTypeString(data.salutation),
      title: data.title,
      firstName: data.firstName,
      lastName: data.lastName,
      familyName: data.familyName,
      companyName: data.companyName
    };
  }

  public static convertAccessLevelDto(data: AccessLevelDto): AddressAccessLevel {
    switch (data.accessLevelType) {
      case AddressAccessLevel[AddressAccessLevel.ReadOnly]:
        return AddressAccessLevel.ReadOnly;
      case AddressAccessLevel[AddressAccessLevel.ReadWrite]:
        return AddressAccessLevel.ReadWrite;
      default:
        return AddressAccessLevel.NoAccess;
    }
  }

  public static getAddressSectionType(input: string): AddressSectionType {
    switch (AddressSectionType[input]) {
      case AddressSectionType.Ceremony:
        return AddressSectionType.Ceremony;
      case AddressSectionType.Thanksgiving:
        return AddressSectionType.Thanksgiving;
      default:
        return AddressSectionType.Unknown;
    }
  }

  public static getLocationType(input: string): LocationType {
    switch (LocationType[input]) {
      case LocationType.Mail:
        return LocationType.Mail;
      case LocationType.Private:
        return LocationType.Private;
      default:
        return LocationType.Unknown;
    }
  }

  public static getLocationTypeString(input: LocationType): string {
    if (typeof input === 'string') {
      return LocationType[LocationType[input]];
    }
    return LocationType[input];
  }

  public static getServicesType(input: string): ServiceType {
    switch (ServiceType[input]) {
      case ServiceType.CeremonyWithCafe:
        return ServiceType.CeremonyWithCafe;
      case ServiceType.CeremonyWithoutCafe:
        return ServiceType.CeremonyWithoutCafe;
      case ServiceType.ThanksCart:
        return ServiceType.ThanksCart;
      default:
        return ServiceType.Unknown;
    }
  }

  public static getServicesTypeString(input: ServiceType): string {
    if (typeof input === 'string') {
      return ServiceType[ServiceType[input]];
    }
    return ServiceType[input];
  }

  public static getSalutationType(input: string): SalutationType {
    switch (SalutationType[input]) {
      case SalutationType.Company:
        return SalutationType.Company;
      case SalutationType.Family:
        return SalutationType.Family;
      case SalutationType.Misses:
        return SalutationType.Misses;
      case SalutationType.Mister:
        return SalutationType.Mister;
      default:
        return SalutationType.Other;
    }
  }

  public static getSalutationTypeString(input: SalutationType): string {
    if (typeof input === 'string') {
      return SalutationType[SalutationType[input]];
    }
    return SalutationType[input];
  }

  private static getDateFormatted(dateInput: string): DateTime {
    return DateUtil.getLocaltTimefromIso(dateInput);
  }
}
