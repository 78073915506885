<div class="mpac-upload-file" [class.mpac-upload-file--invalid]="!isValid">
  <div clas="mpac-upload-file__wrap">
    <div class="mpac-upload-file__info-box">
      <!-- Validation -->
      <div *ngIf="!isValid" class="mpac-upload-file__validation">
        <div class="mpac-upload-file__validation-cnt">
          <div class="mpac-upload-file__info-validaiton-row">
            <div
              class="mpac-upload-file__validation-wrap"
              (click)="toggleValidationList()"
              title="{{
                this.validationMessages && this.validationMessages.length ? this.validationMessages.join(', ') : ''
              }}"
            >
              <div class="mpac-upload-file__icon mpac-upload-file__validation-icon"></div>
            </div>
          </div>
        </div>
      </div>
      <!-- Info -->
      <div class="mpac-upload-file__info">
        <div class="mpac-upload-file__info-cnt">
          <div class="mpac-upload-file__info-cnt-row">
            <!-- Icon or preview -->
            <div *ngIf="!isAudioFile" class="mpac-upload-file__icon mpac-upload-file__info-icon"></div>
            <div *ngIf="isAudioFile" class="mpac-upload-file__icon mpac-upload-file__info-audio-icon"></div>
            <!-- Name -->
            <div class="mpac-upload-file__info-name">
              <span class="mpac-upload-file__info-name-txt">{{ name }}</span>
              <span class="mpac-upload-file__info-name-ext">{{ extension }}</span>
            </div>
            <div *ngIf="!skipFileSize" class="mpac-upload-file__info-size">
              <span class="mpac-upload-file__info-size-txt">{{ fileSize }}</span>
              <span class="mpac-upload-file__info-size-unit">{{ 'general.units.megabyte' | translate }}</span>
            </div>
          </div>
        </div>
      </div>
      <!-- Functions -->
      <div class="mpac-upload-file__fcts">
        <div class="mpac-upload-file__fcts-cnt">
          <div class="mpac-upload-file__fcts-cnt-row">
            <button
              class="mpac-upload-file__fcts-fct mpac-upload-file__fcts-fct--del btn btn-outline-danger"
              (click)="removalTriggered.emit(file)"
              title="{{ 'actions.removeImage' | translate }}"
            >
              <span class="mpac-upload-file__icon mpac-upload-file__fcts-fct-icon"></span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Validation messages -->
    <div *ngIf="!isValid && validationMessageDisplayed" class="mpac-upload-file__validation-box">
      <ul class="mpac-upload-file__validation-box-lst">
        <li *ngFor="let msg of validationMessages" class="mpac-upload-file__validation-box-lst-itm">{{ msg }}</li>
      </ul>
    </div>
  </div>
</div>
