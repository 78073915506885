import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { Company } from '../../../shared/models/company';
import { CompanyService } from '../../services/company.service';
import {
  LoadCompanyByOrderPkAction,
  CompanyActionsEnum,
  LoadCompanyByRapidIdAction,
  SaveCompanyAction,
  SaveCompanyCandleAutomaticSettingsAction,
  LoadCompanySuccessAction,
  SaveCompanySuccessAction,
  SaveCompanyFailureAction,
  SaveCompanyCandleAutomaticSettingsSuccessAction
} from './company.actions';
@Injectable()
export class CompanyEffects {
  /**
   * Consumes a @{loadCompanyByOrderPkAction} and maps it into a @{loadCompanySuccessAction}. The data passed to the resulting
   * action is read from the @{dataService}.
   */
  public loadCompanyByOrderPk$ = createEffect(() =>
    this.actions$.pipe(
      ofType<LoadCompanyByOrderPkAction>(CompanyActionsEnum.LoadCompanyByOrderPkAction),
      switchMap((action) =>
        this.dataService
          .loadCompanyByOrderPk(action.orderPk)
          .pipe(map((company: Company) => new LoadCompanySuccessAction(company)))
      )
    )
  );

  /**
   * Consumes a @{loadCompanyByRapidIdAction} and maps it into a @{loadCompanySuccessAction}. The data passed to the resulting
   * action is read from the @{dataService}.
   */
  public loadCompanyByRapidId$ = createEffect(() =>
    this.actions$.pipe(
      ofType<LoadCompanyByRapidIdAction>(CompanyActionsEnum.LoadCompanyByRapidIdAction),
      switchMap((action) =>
        this.dataService
          .loadCompanyByRapidId(action.rapidId)
          .pipe(map((company: Company) => new LoadCompanySuccessAction(company)))
      )
    )
  );

  /**
   * Consumes a @{saveCompanyAction} and maps it into a @{saveCompanySuccessAction}. The data passed to the resulting
   * action is saved and (possibly adjusted) via the @{dataService}.
   */
  public saveCompany$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SaveCompanyAction>(CompanyActionsEnum.SaveCompanyAction),
      switchMap((action) =>
        this.dataService.updateCompany(action.company).pipe(
          map((company: Company) => new SaveCompanySuccessAction(company)),
          catchError(() => of(new SaveCompanyFailureAction()))
        )
      )
    )
  );

  /**
   * Consumes a @{saveCompanyCandleAutomaticSettingsAction} and maps it into a @{saveCompanyCandleAutomaticSettingsSuccessAction}.
   * The data passed to the resulting action is saved and (possibly adjusted) via the @{dataService}.
   */
  public saveCompanyCandleAutomaticSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SaveCompanyCandleAutomaticSettingsAction>(CompanyActionsEnum.SaveCompanyCandleAutomaticSettingsAction),
      switchMap((action) =>
        this.dataService.saveCompanyCandleAutomaticSettings(action.companyId, action.settings).pipe(
          tap(() => this.toastr.success('Ihre Einstellungen wurden gespeichert.', 'Kerzen verwalten')),
          map((company: Company) => new SaveCompanyCandleAutomaticSettingsSuccessAction(company))
        )
      )
    )
  );

  constructor(private actions$: Actions, private dataService: CompanyService, private toastr: ToastrService) {}
}
