import { trigger, transition, style, animate } from '@angular/animations';

export const slideInOutBottom = [
  trigger('slideInOutBottom', [
    transition(':enter', [
      style({ bottom: -67, opacity: 0.5 }),
      animate('400ms ease-in', style({ bottom: 0, opacity: 1 }))
    ]),
    transition(':leave', [style({ opacity: 1 }), animate('400ms ease-in', style({ bottom: -67, opacity: 0.5 }))])
  ])
];
