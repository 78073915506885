import { Appointment } from '@order/models/appointment';
import { OrderState } from '@company/models/order-entry';
import { OrderCustomization } from './order-customization';

export interface Order extends OrderCustomization {
  pk: number;
  companyPk: number;
  state: OrderState;
  orderId: string;
  deceasedTitle: string;
  deceasedFirstNames: string;
  deceasedLastName: string;
  deceasedNameOfBirth: string;
  deceasedDateOfBirth: string;
  deceasedPlaceOfBirth: string;
  deceasedDateOfDeath: string;
  deceasedPlaceOfDeath: string;
  isSearchable: boolean;
  friendlyUrl: string;
  communityPassword: string;
  dateOfLock: string;
  visibleFrom: string;
  isDateOfLockFixed: boolean;
  reportedDateOfLock: string;
  isSocialMediaAvailable: boolean;
  eurofloristMemberId: string;
  showInUcloud: boolean;
  dateOfLockOverrideId: string;
  dateOfLockOverrideUntil: string;
  created: string;
  candlesCount: number;
  giftsCount: number;
  condolencesCount: number;
  publicImagesCount: number;
  appointments?: Appointment[];
}
