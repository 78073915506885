import { OrderAdminInvitation } from '../../models/order-admin-invitation';
import { Component } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'mpac-send-admin-invite-dialog',
  templateUrl: './send-admin-invite-dialog.component.html'
})
export class SendAdminInviteDialogComponent {
  public invitation: OrderAdminInvitation;

  constructor(private bsModalRef: BsModalRef, private modalService: BsModalService) {}

  public get invitationWasSent(): boolean {
    return !!this.invitation?.dateOfInvitation;
  }

  public get symbolClass(): string {
    return this.invitationWasSent ? 'fal fa-repeat mr-2' : 'fal fa-envelope mr-2';
  }

  public hide(shouldSend: boolean = false): void {
    this.modalService.setDismissReason(shouldSend.toString());
    this.bsModalRef.hide();
  }
}
