import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { DateTime } from 'luxon';

@Component({
  selector: 'mpac-print-form-errors-for',
  templateUrl: './print-form-errors-for.component.html',
  styleUrls: ['./print-form-errors-for.component.scss']
})
export class PrintFormErrorsForComponent {
  /**
   * The control to display validation errors for.
   */
  @Input()
  public control: AbstractControl;

  constructor() {}

  public getDateOnly(date: DateTime): string {
    return date.toFormat('dd.MM.yyyy');
  }
}
