import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SettingsService } from '@core/config/settings.service';
import { Observable, Observer } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpHelper } from 'src/app/features/order-portal/services/helper/http.helper';
import { PrecautionInfoResponse } from '../models/precaution-info/precaution-info-response.interface';
import { PrecautionInfoConfiguration } from './../models/precaution-info-configuration.interface';

@Injectable({
  providedIn: 'root'
})
export class PrecautionInfoService {
  constructor(private http: HttpClient, private settingsService: SettingsService) {}

  public get defaultCoverImageAssetPath(): string {
    return '/assets/images/precaution-info/default-cover-precaution.jpg';
  }

  private get defaultApiVersion(): string {
    return 'v1.0';
  }

  public getPrecautionInfo(companyId: string): Observable<PrecautionInfoConfiguration> {
    const url = `${this.baseUrl()}/companies/${companyId}/precaution-info`;

    return this.http
      .get(url)
      .pipe(map(HttpHelper.ensureValidResponse), catchError(HttpHelper.throwApiError))
      .pipe(map((response: PrecautionInfoResponse<PrecautionInfoConfiguration>) => response.data));
  }

  public createOrUpdate(
    config: PrecautionInfoConfiguration,
    companyId: string
  ): Observable<PrecautionInfoConfiguration> {
    const url = `${this.baseUrl()}/companies/${companyId}/precaution-info`;

    return this.http
      .put<PrecautionInfoResponse<PrecautionInfoConfiguration>>(url, config)
      .pipe(map(HttpHelper.ensureValidResponse), catchError(HttpHelper.throwApiError))
      .pipe(map((response: PrecautionInfoResponse<PrecautionInfoConfiguration>) => response.data));
  }

  public toggleEnabled(companyId: string, enabled: boolean): Observable<PrecautionInfoConfiguration> {
    const url = `${this.baseUrl()}/companies/${companyId}/precaution-info/enable`;

    return this.http
      .put<PrecautionInfoResponse<PrecautionInfoConfiguration>>(url, { enabled })
      .pipe(map(HttpHelper.ensureValidResponse), catchError(HttpHelper.throwApiError))
      .pipe(map((response: PrecautionInfoResponse<PrecautionInfoConfiguration>) => response.data));
  }

  public requestAdditionalPrecautionInfo(orderPk: number, requestInfoEmail: string): Observable<{ success: boolean }> {
    const urlBase = this.settingsService.settings.apiRoot;
    const url = `${urlBase}/mail/send-precaution-info-request`;

    return this.http
      .post<{ success: boolean }>(url, { orderPk, requestInfoEmail })
      .pipe(map(HttpHelper.ensureValidResponse), catchError(HttpHelper.throwApiError))
      .pipe(map(() => ({ success: true })));
  }

  public getBase64ImageFromURL(url: string): Observable<string> {
    return new Observable((observer: Observer<string>) => {
      const img = new Image();
      img.crossOrigin = 'Anonymous';
      img.src = url;
      if (!img.complete) {
        img.onload = () => {
          observer.next(this.getBase64Image(img));
          observer.complete();
        };
        img.onerror = (err) => {
          observer.error(err);
        };
      } else {
        observer.next(this.getBase64Image(img));
        observer.complete();
      }
    });
  }

  private getBase64Image(img: HTMLImageElement): string {
    const canvas = document.createElement('canvas');
    canvas.width = img.width;
    canvas.height = img.height;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(img, 0, 0);
    const dataURL = canvas.toDataURL();
    return dataURL;
  }

  private baseUrl(version: string = this.defaultApiVersion): string {
    return `${this.settingsService.settings.precautionInfoApiUrl || ''}/${version}`;
  }
}
