import { AbstractControl, ValidatorFn } from '@angular/forms';

export const maxTimeValidator =
  (maxVal: number): ValidatorFn =>
  (control: AbstractControl): { [key: string]: any } | null => {
    const currentVal: string = control.value;
    if (currentVal) {
      const hours = parseInt(currentVal.split(':')[0], 10);
      const minutes = parseInt(currentVal.split(':')[1], 10);
      return hours + minutes > maxVal ? { maxTimeValue: { currentVal } } : null;
    }
    return null;
  };
