import { CompanyState, initialCompanyState } from './company.state.model';
import { CompanyActions, CompanyActionsEnum } from './company.actions';

export const companyReducer = (state = initialCompanyState, action: CompanyActions): CompanyState => {
  switch (action.type) {
    case CompanyActionsEnum.LoadCompanySuccessAction:
    case CompanyActionsEnum.SaveCompanySuccessAction:
    case CompanyActionsEnum.SaveCompanyCandleAutomaticSettingsSuccessAction:
      return {
        ...state,
        selectedCompany: action.company
      };

    case CompanyActionsEnum.ResetCompanyAction:
      return initialCompanyState;

    default:
      return state;
  }
};
