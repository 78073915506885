import { DesctructableComponent } from 'src/app/shared/components/destructable/destructable.component';
import { UserUtil } from './../../../shared/helpers/user-util';
import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { filter, take, takeUntil } from 'rxjs/operators';
import { UserLevel } from '../../../shared/models/user-level.enum';
import { BreadcrumbEntry } from '../../models/breadcrumb-entry';
import { ManagedOrder } from '../../models/user';
import { CoreState } from '../../store/common';
import { selectUsersManagedOrders } from '../../store/user/user.reducer';
import { selectBreadcrumbs } from '@core/store/breadcrumbs/breadcrum.selectors';
import { PopBreadcrumbAction } from '@core/store/breadcrumbs/breadcrumbs.actions';

@Component({
  selector: 'mpac-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent extends DesctructableComponent {
  @Input()
  public userLevel: UserLevel = UserLevel.None;

  public breadcrumbs: BreadcrumbEntry[] = [];

  public managedOrders: ManagedOrder[];

  public showOrderSwitch = false;

  public dropdownOpen = false;

  constructor(private router: Router, private store: Store<CoreState>) {
    super();
    this.subscribeToNavigationsEntries();
  }

  /**
   * Returns if the user is page admin
   */
  public get isPageAdmin(): boolean {
    return UserUtil.isPageAdmin(this.userLevel);
  }

  public get shouldRenderPopup(): boolean {
    return this.isPageAdmin && this.managedOrders.length > 1;
  }

  public navigateByBreadcrumb(entry: BreadcrumbEntry): void {
    this.store.dispatch(new PopBreadcrumbAction());
    this.router.navigate([entry.routerLink]);
  }

  /**
   * Navigates to the selected order url
   */
  public switchOrder(orderId: string): void {
    const segments = this.router.url.split('/');
    segments[2] = orderId;
    segments[3] = 'start';

    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.navigate(segments.slice(0, 4));
  }

  public dropDownOpenStatusChanged(isOpen: boolean): void {
    this.dropdownOpen = isOpen;
  }

  private subscribeToNavigationsEntries(): void {
    this.store
      .select(selectBreadcrumbs)
      .pipe(
        takeUntil(this.shutdown$),
        filter((breadcrumbs) => !!breadcrumbs)
      )
      .subscribe((breadcrumbs: Array<BreadcrumbEntry>) => {
        this.breadcrumbs = breadcrumbs;
      });

    this.store.pipe(select(selectUsersManagedOrders), take(1)).subscribe((managedOrders) => {
      this.managedOrders = managedOrders ? managedOrders : [];
      this.showOrderSwitch = this.managedOrders.length > 1;
    });
  }
}
