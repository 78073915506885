import { takeUntil } from 'rxjs/operators';
import { DesctructableComponent } from 'src/app/shared/components/destructable/destructable.component';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { User } from '@core/models/user';
import { selectUser } from '@core/store/user/user.reducer';
import { select, Store } from '@ngrx/store';
import { UserLevel } from '../../../shared/models/user-level.enum';
import { AuthService } from '../../services/auth.service';
import { SettingBasedOnHostnameService } from '../../services/setting-based-on-hostname.service';

@Component({
  selector: 'mpac-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent extends DesctructableComponent implements OnInit {
  private static arrowIcon = '/assets/images/svg/button-arrow.svg';

  /**
   * Angular FormGroup
   */
  public loginForm: UntypedFormGroup;

  /**
   * logo path base on domain name
   */
  public logoPath: string;

  /**
   * logo title base on domain name
   */
  public logoTitle: string;

  /**
   * Loading status
   */
  public isLoading = false;

  /**
   * Permission status
   * indicates if the user has any user level
   */
  public hasAnyPermission = null;

  public spinnerTextKey: string;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private authService: AuthService,
    private store$: Store<any>,
    private titleService: Title,
    private settingBasedOnHostnameService: SettingBasedOnHostnameService
  ) {
    super();
  }

  public get arrowIcon(): string {
    return LoginPageComponent.arrowIcon;
  }

  public ngOnInit(): void {
    this.setSpinnerTextKey();
    this.addAuthSubs();
    this.loginForm = this.formBuilder.group({
      userName: ['', [Validators.required]],
      password: ['', [Validators.required]]
    });
    this.logoPath = this.settingBasedOnHostnameService.getLogoPathByHostname(true);
    this.logoTitle = this.titleService.getTitle();

    // TODO: Determine a way to improve it
    setTimeout(() => (this.isLoading = false), 2500);
  }

  public login(): void {
    this.authService.login();
  }

  public logout(): void {
    this.authService.logout();
  }

  private setSpinnerTextKey(isAuthenticated: boolean = false): void {
    this.spinnerTextKey = isAuthenticated ? 'loginPage.forwardNotice' : 'loginPage.statusCheckNotice';
  }

  private addAuthSubs(): void {
    // TODO: Ok; wtf?
    /**
     * Subscription are done by code, because subscribing in template is to late
     * sometimes subscribing after rendering misses authentication event
     */
    this.authService.isAuthenticated$.pipe(takeUntil(this.shutdown$)).subscribe((isAuthenticated) => {
      this.setSpinnerTextKey(isAuthenticated);
    });

    this.authService.isLoading$.pipe(takeUntil(this.shutdown$)).subscribe((il) => (this.isLoading = il));

    this.store$.pipe(takeUntil(this.shutdown$), select(selectUser)).subscribe((user: User) => {
      if (!user || !user.name) {
        this.hasAnyPermission = null;
        return;
      }
      switch (user.level) {
        case UserLevel.Rapid:
        case UserLevel.Mortician:
          this.hasAnyPermission = true;
          break;
        case UserLevel.PageAdmin:
          this.hasAnyPermission = user.managedOrders.length > 0;
          break;
        default:
          this.hasAnyPermission = false;
          break;
      }
    });
  }
}
