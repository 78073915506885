export interface OrderAdmin {
  userPk: number;
  orderPk: number;
  name: string;
  account: string;
  emailAddress: string;
  isDependant: boolean;
  isMortician: boolean;
  registrationTimestamp: Date | null | undefined;
}
