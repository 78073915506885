import { OnDestroy, Directive } from '@angular/core';
import { Subject } from 'rxjs';

@Directive({})
export abstract class DesctructableDirective implements OnDestroy {
  protected shutdown$: Subject<void> = new Subject<void>();

  public ngOnDestroy(): void {
    this.shutdown$.next(null);
    this.shutdown$.unsubscribe();
  }
}
