import { coreStateReducers, coreStateMetaReducers } from '@core/store';
import { ErrorHttpInterceptorService } from './interceptors/error-http-interceptor.service';
import { GdprCookieBannerConfigurationProvider, GdprCookieBannerModule } from 'gdpr-cookie-banner';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, LOCALE_ID, NgModule, Optional, SkipSelf } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { Store, StoreModule } from '@ngrx/store';
import { OAuthModule, OAuthStorage } from 'angular-oauth2-oidc';
import { NgxIndexedDBModule } from 'ngx-indexed-db';
import { MarkdownModule } from 'ngx-markdown';
import { SharedModule } from '@shared/shared.module';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { HeaderComponent } from './components/header/header.component';
import { HelpComponent } from './components/help/help.component';
import { MobileMenuComponent } from './components/mobile-menu/mobile-menu.component';
import { ProductInfoPageComponent } from './components/product-info-page/product-info-page.component';
import { dbConfig } from './indexdb/config';
import { LoggedInLayoutComponent } from './layouts/logged-in-layout/logged-in-layout.component';
import { LoggedOutLayoutComponent } from './layouts/logged-out-layout/logged-out-layout.component';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { UserElevationPageComponent } from './pages/user-elevation-page/user-elevation-page.component';
import { UserRegistrationPageComponent } from './pages/user-registration-page/user-registration-page.component';
import { AuthenticationGuard } from './services/authentication.guard';
import { JwtHttpInterceptorService } from './services/jwt-http-interceptor.service';
import { CoreState, coreStateFeatureKey } from './store/common';
import { attachBreakpointListenersAction } from './store/display/display.actions';
import { RegistrationFormComponent } from './components/registration-form/registration-form.component';
import { ModuleWithProviders } from '@angular/core';
import { BaseModule } from '@base/base.module';
import { authConfigFactory } from './config/auth.factory';
import { AuthFactoryHttpService } from './config/auth.factory-service';
import { TranslationModule } from './translations/translations.module';
import { GdprCookieBannerConfigService } from './config/gdpr-cookie-banner-config.service';
import { cookieBannerConfigFactory } from './config/gdpr-cookie-banner-config.factory';
import { CookieBannerConfigFactoryHttpService } from './config/gdpr-cookie-banner.factory-service';
import { CookieModule } from 'ngx-cookie';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { ConfirmDialogService } from './services/confirm-dialog.service';
import { HelpToggleComponent } from './components/help-toggle/help-toggle.component';
import { FakeBackendInterceptor } from './services/fake-backend.interceptor';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';
import { MatPaginatorTranslated } from './helper/mat-paginator.tanslated';
import { EffectsModule } from '@ngrx/effects';
import { BreadcrumbsEffects } from './store/breadcrumbs/breadcrumbs.effects';
import { CompanyEffects } from './store/company/company.effects';
import { DisplayEffects } from './store/display/display.effects';
import { HelpEffects } from './store/help/help.effects';
import { UserEffects } from './store/user/user.effects';
import { TrainingCampLinkComponent } from './components/training-camp-link/training-camp-link.component';
import { NewAppVersionAvailableComponent } from './components/new-app-version-available/new-app-version-available.component';

export const storageFactory = (): OAuthStorage => localStorage;

@NgModule({
  declarations: [
    LoggedInLayoutComponent,
    LoggedOutLayoutComponent,
    HelpComponent,
    HeaderComponent,
    LoginPageComponent,
    BreadcrumbComponent,
    MobileMenuComponent,
    ProductInfoPageComponent,
    UserElevationPageComponent,
    UserRegistrationPageComponent,
    RegistrationFormComponent,
    ConfirmDialogComponent,
    HelpToggleComponent,
    TrainingCampLinkComponent,
    NewAppVersionAvailableComponent
  ],
  imports: [
    TranslationModule,
    CommonModule,
    BaseModule,
    RouterModule,
    FormsModule,
    MarkdownModule.forChild(),
    OAuthModule.forRoot(),
    NgxIndexedDBModule.forRoot(dbConfig),
    StoreModule.forFeature(coreStateFeatureKey, coreStateReducers, {
      metaReducers: coreStateMetaReducers
    }),
    EffectsModule.forFeature([BreadcrumbsEffects, DisplayEffects, HelpEffects, UserEffects, CompanyEffects]),
    ReactiveFormsModule,
    SharedModule,
    CookieModule.forRoot(),
    GdprCookieBannerModule.forRoot({
      config: {
        provide: GdprCookieBannerConfigurationProvider,
        useClass: GdprCookieBannerConfigService
      },
      loadBannerConfig: {
        provide: GdprCookieBannerConfigurationProvider,
        useClass: GdprCookieBannerConfigService
      }
    })
  ],
  exports: [LoggedInLayoutComponent, LoggedOutLayoutComponent, ProductInfoPageComponent, ConfirmDialogComponent]
})
export class CoreModule {
  constructor(private store: Store<CoreState>, @Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule only');
    }
    this.store.dispatch(attachBreakpointListenersAction());
  }

  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        {
          provide: MatPaginatorIntl,
          useClass: MatPaginatorTranslated,
          deps: [TranslateService]
        },
        { provide: LOCALE_ID, useValue: 'de-DE' },
        { provide: APP_INITIALIZER, useFactory: authConfigFactory, deps: [AuthFactoryHttpService], multi: true },
        {
          provide: APP_INITIALIZER,
          useFactory: cookieBannerConfigFactory,
          deps: [CookieBannerConfigFactoryHttpService],
          multi: true
        },
        { provide: OAuthStorage, useFactory: storageFactory },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorHttpInterceptorService, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: FakeBackendInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: JwtHttpInterceptorService, multi: true },
        AuthenticationGuard,
        ConfirmDialogService
      ]
    };
  }
}
