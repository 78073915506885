import { Component, HostBinding, Input, ViewEncapsulation, OnInit, ContentChild, TemplateRef } from '@angular/core';

@Component({
  selector: 'mpac-mat-dialog-footer',
  templateUrl: './mat-dialog-footer.component.html',
  encapsulation: ViewEncapsulation.None
})
export class MatDialogFooterComponent implements OnInit {
  public static baseClass = 'mpac-mat-dlg__ftr';

  @HostBinding('class') hostClassBinding: string;

  @ContentChild(TemplateRef) footerElsListRef;

  @Input()
  public customClass = '';

  constructor() {}

  public ngOnInit(): void {
    this.setHostClassBinding();
  }

  protected setHostClassBinding(): void {
    this.hostClassBinding = `${MatDialogFooterComponent.baseClass}${
      this.customClass ? ' ' + this.customClass.trim() : ''
    }`;
  }
}
