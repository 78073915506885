<div
  *ngIf="show"
  class="mpac-not-box"
  [class.mpac-not-box--error]="isError"
  [class.mpac-not-box--warning]="isWarning"
  [class.mpac-not-box--info]="isInfo"
  [class.mpac-not-box--success]="isSuccess"
  [class.mpac-not-box--indeterminate]="isIndeterminate"
  [class.mpac-not-box--change]="isChange"
  [class.mpac-not-box--missing-info]="isMissingInfo"
  [class.mpac-not-box--date]="isDate">
  <span class="mpac-not-box__icon">
    <span class="mpac-not-box__icon-rnd"></span>
  </span>
  <div class="mpac-not-box__txt">
    <span class="mpac-not-box__txt-box">{{ text }}</span>
  </div>
</div>
