import { Component } from '@angular/core';
import { Subject } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'mpac-confirm-leave',
  templateUrl: './confirm-leave.component.html'
})
export class ConfirmLeaveComponent {
  subject: Subject<boolean>;

  constructor(public bsModalRef: BsModalRef) {}

  public action(value: boolean): void {
    this.bsModalRef.hide();
    this.subject.next(value);
    this.subject.complete();
  }
}
