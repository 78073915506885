<div class="modal-header">
  <h4 class="modal-title pull-left">{{ 'appVersionDialog.header' | translate }}</h4>
</div>
<div class="modal-body">
  <p class="mt-3">{{ 'appVersionDialog.body' | translate }}</p>
</div>
<div class="modal-footer">
  <button class="btn btn-primary" (click)="confirm()">
    {{ 'appVersionDialog.confirmButton' | translate }}
  </button>
</div>
