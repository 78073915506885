<form class="form-inline">
  <div class="input-group">
    <input
      #searchBox
      (keyup)="searchTermChanged($event)"
      class="form-control col"
      name="searchTerm"
      id="searchTerm"
      placeholder="Suchen"
      type="text"
      [value]="currentSearchValue"
      libTrim />
    <div class="input-group-append">
      <ng-container *ngIf="!!searchBox.value">
        <button
          (click)="clearInput()"
          [disabled]="!searchBox.value"
          [title]="!!searchBox.value ? 'Suche zurücksetzen' : 'Suchen'"
          class="btn btn-primary"
          type="button">
          <i class="fal fa-times"></i>
        </button>
      </ng-container>
      <ng-container *ngIf="!searchBox.value">
        <button
          (click)="clearInput()"
          [disabled]="!searchBox.value"
          [title]="!!searchBox.value ? 'Suche zurücksetzen' : 'Suchen'"
          class="btn btn-primary"
          type="button">
          <i class="fal fa-search"></i>
        </button>
      </ng-container>
    </div>
  </div>
</form>
