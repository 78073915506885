import { Component, HostBinding, OnInit, ViewEncapsulation } from '@angular/core';
import { Breakpoints } from '@core/helper/breakpoints';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CoreState } from '../../store/common';
import { selectIsBreakpointAbove, selectIsHelpVisible } from '../../store/display/display.reducer';

@Component({
  selector: 'mpac-root-layout',
  templateUrl: './logged-in-layout.component.html',
  styleUrls: ['./logged-in-layout.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LoggedInLayoutComponent implements OnInit {
  public static baseClass = 'mpac-loggedin';

  public static baseClassHelp = 'mpac-loggedin--view-help';

  @HostBinding('class') hostClassBinding;

  public isHelpVisible$: Observable<boolean>;

  public isHelpVisible: boolean;

  public isMobile: boolean;

  private shutdown$ = new Subject<void>();

  constructor(private store: Store<CoreState>) {
    this.subscribeToBreakpointChanges();
  }

  public ngOnInit(): void {
    this.setHostClassBinding();
    this.isHelpVisible$ = this.store.pipe(select(selectIsHelpVisible));

    this.isHelpVisible$.subscribe((visible) => {
      this.isHelpVisible = visible;
      this.setHostClassBinding();
    });
  }

  protected setHostClassBinding(): void {
    const bindings = [LoggedInLayoutComponent.baseClass];
    if (this.isHelpVisible) {
      bindings.push(LoggedInLayoutComponent.baseClassHelp);
    }
    this.hostClassBinding = bindings.join(' ');
  }

  protected subscribeToBreakpointChanges(): void {
    this.store
      .pipe(takeUntil(this.shutdown$), select(selectIsBreakpointAbove, Breakpoints.MD))
      .subscribe((notMobile) => {
        this.isMobile = !notMobile;
      });
  }
}
