import { Component, Input, Output, EventEmitter } from '@angular/core';
import { GlobalWriteProtectionService } from '../../services/global-write-protection.service';

@Component({
  selector: 'mpac-spinner-button',
  templateUrl: './spinner-button.component.html',
  styleUrls: ['./spinner-button.component.scss']
})
export class SpinnerButtonComponent {
  /**
   * Indicates busy state
   */
  @Input()
  public isBusy: boolean;

  /**
   * classes the button has to have exclude the btn class
   * default is the button primary style
   */
  @Input()
  public btnClasses = 'btn-primary';

  /**
   * classes the spinner element has to have
   * default is a small spinner of type border
   */
  @Input()
  public spinnerClasses = 'spinner-border spinner-border-sm';

  /**
   * Type (HTML-Property) of the button
   */
  @Input()
  public btnType = 'submit';

  /**
   * controls whether the button is disabled or not
   */
  @Input()
  public disabled = false;

  @Input()
  public ignoreGlobalWriteProtection = false;

  @Input()
  public btnId: string;

  @Output() public buttonClicked: EventEmitter<any> = new EventEmitter<any>();

  constructor(private globalWriteProtectionService: GlobalWriteProtectionService) {}

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public onClick(evt: any): void {
    if (this.disabled || this.isBusy) {
      return;
    }
    this.buttonClicked.emit(evt);
  }

  public isButtonDisabled(): boolean {
    return (
      this.isBusy ||
      this.disabled ||
      (this.globalWriteProtectionService.isActive() && !this.ignoreGlobalWriteProtection)
    );
  }
}
