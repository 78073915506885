import { initialCompanyState } from './company.state.model';
import { CoreState } from 'src/app/core/store/common';
import { coreStateFeatureSelector } from './../common';
import { createSelector, DefaultProjectorFn, MemoizedSelector } from '@ngrx/store';
import { ActivatableFeature } from 'src/app/shared/models/activatable-feature';
import { PortalState, Company } from 'src/app/shared/models/company';
import { CompanyFeatures } from '@core/models/company-features.enum';

/**
 * Returns the trimmed first index of the company host or undefined.
 */
const convertHost = (host: string): string | undefined =>
  host
    .replace(/^;+|;+$/g, '')
    .split(';')[0]
    .trim() ?? undefined;

export const selectCompanyState = createSelector(coreStateFeatureSelector, (state: CoreState) =>
  state ? state.companyState : initialCompanyState
);

export const selectCompany = createSelector(coreStateFeatureSelector, (state: CoreState): Company => {
  if (state && state.companyState && state.companyState?.selectedCompany) {
    const company = Object.assign({}, state.companyState.selectedCompany);
    company.host = convertHost(company.host);
    return company;
  }
  return initialCompanyState.selectedCompany;
});

export const selectCompanyFullHost = createSelector(coreStateFeatureSelector, (state: CoreState): Company => {
  if (state && state.companyState && state.companyState?.selectedCompany) {
    const company = Object.assign({}, state.companyState.selectedCompany);
    return company;
  }
  return initialCompanyState.selectedCompany;
});

export const selectCompanyFeatures = createSelector(
  coreStateFeatureSelector,
  (state: CoreState): ActivatableFeature[] =>
    state && state.companyState && state.companyState?.selectedCompany && state.companyState?.selectedCompany?.features
      ? state.companyState?.selectedCompany?.features
      : []
);

export const selectCompanyPortalState = createSelector(
  coreStateFeatureSelector,
  (state: CoreState): PortalState => state?.companyState?.selectedCompany?.portalState || PortalState.None
);

export const selectCompanyFeature = (
  feature: CompanyFeatures
): MemoizedSelector<any, ActivatableFeature, DefaultProjectorFn<ActivatableFeature>> =>
  createSelector(
    selectCompanyFeatures,
    (features: ActivatableFeature[] | null): ActivatableFeature =>
      features && features.length ? features.find((f: ActivatableFeature) => f.internalName === feature) : null
  );
