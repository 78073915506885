<button
  [attr.id]="btnId"
  [disabled]="isButtonDisabled()"
  [type]="btnType"
  (click)="onClick($event)"
  class="btn {{ btnClasses }}">
  <span *ngIf="isBusy" class="spinner-overlay d-flex justify-content-center align-items-center">
    <i class="{{ spinnerClasses }}" role="status"></i>
  </span>
  <span [class.invisible]="isBusy">
    <ng-content></ng-content>
  </span>
</button>
