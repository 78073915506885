import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ComponentCatalogGuard } from '@core/guards/component-catalog.guard';
import { LoggedInLayoutComponent } from '@core/layouts/logged-in-layout/logged-in-layout.component';
import { LoggedOutLayoutComponent } from '@core/layouts/logged-out-layout/logged-out-layout.component';
import { LoginPageComponent } from '@core/pages/login-page/login-page.component';
import { UserElevationPageComponent } from '@core/pages/user-elevation-page/user-elevation-page.component';
import { UserRegistrationPageComponent } from '@core/pages/user-registration-page/user-registration-page.component';
import { AuthenticationGuard } from '@core/services/authentication.guard';
import { RedirectToUserStartpageGuard } from '@core/services/redirect-to-user-startpage.guard';
import { RegistrationGuard } from '@core/services/registration.guard';
import { NotImplementedComponent } from '@shared/components/not-implemented/not-implemented.component';
import { LogoutGuard } from '@shared/services/logout-guard';

const routes: Routes = [
  { path: '', pathMatch: 'full', canActivate: [RedirectToUserStartpageGuard], component: NotImplementedComponent },
  {
    path: '',
    component: LoggedOutLayoutComponent,
    children: [
      { path: 'registrieren/:hash', canActivate: [RegistrationGuard], component: UserRegistrationPageComponent },
      { path: 'rechte-erweiterung', component: UserElevationPageComponent },
      { path: 'login', component: LoginPageComponent },
      { path: 'logout', children: [], canActivate: [LogoutGuard] }
    ]
  },
  {
    path: '',
    component: LoggedInLayoutComponent,
    children: [
      {
        path: 'portale',
        canActivateChild: [AuthenticationGuard],
        loadChildren: () =>
          import('./features/rapid-portal/rapid-portal.module').then((module) => module.RapidPortalModule)
      },
      {
        path: 'bestatter',
        canActivateChild: [AuthenticationGuard],
        loadChildren: () =>
          import('./features/rapid-portal/rapid-portal.module').then((module) => module.RapidPortalModule)
      },
      {
        path: 'portal',
        canActivateChild: [AuthenticationGuard],
        loadChildren: () =>
          import('./features/company-portal/company-portal.module').then((module) => module.CompanyPortalModule)
      },
      {
        path: 'gedenkseiten',
        canActivateChild: [AuthenticationGuard],
        loadChildren: () =>
          import('./features/order-portal/order-portal.module').then((module) => module.OrderPortalModule)
      },
      {
        path: 'component-catalog',
        canLoad: [ComponentCatalogGuard],
        loadChildren: () => import('./component-catalog/component-catalog.module').then((m) => m.ComponentCatalogModule)
      },
      { path: 'kontakt', component: NotImplementedComponent, data: { headline: 'Kontakt/Feedback' } }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      enableTracing: false,
      initialNavigation: 'disabled'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
