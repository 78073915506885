import { AbstractControl, ValidatorFn } from '@angular/forms';

export const validateEurofloristId: ValidatorFn = (control: AbstractControl): { [key: string]: any } | null => {
  const regexp = new RegExp('^([A-Z]{2}[0-9]{2})-([0-9]{6})-([0-9]{2})$');
  const currentValue: string = control.value;

  return regexp.test(currentValue)
    ? null
    : {
        validateEurofloristId: {
          valid: false
        }
      };
};
