import { Action } from '@ngrx/store';
import { CandleAutomaticSettings } from '../../../features/company-portal/models/candle-automatic-settings';
import { Company } from '../../../shared/models/company';

export enum CompanyActionsEnum {
  /* eslint-disable @typescript-eslint/no-shadow */
  ResetCompanyAction = '[Company] Reset Company',
  LoadCompanyByOrderPkAction = '[Company] Load Company By Order Pk',
  LoadCompanyByRapidIdAction = '[Company] Load Company By Rapid Id',
  LoadCompanySuccessAction = '[Company] Load Company Success',
  SaveCompanyAction = '[Company] Save Company',
  SaveCompanySuccessAction = '[Company] Save Company Success',
  SaveCompanyFailureAction = '[Company] Save Company Failure',
  SaveCompanyCandleAutomaticSettingsAction = '[Company] Save Company Candle Automation Settings',
  SaveCompanyCandleAutomaticSettingsSuccessAction = '[Company] Save Company Candle Automation Settings Success',
  SaveCompanyCandleAutomaticSettingsFailureAction = '[Company] Save Company Candle Automation Settings Failure'
  /* eslint-enable @typescript-eslint/no-shadow */
}

export class ResetCompanyAction implements Action {
  readonly type = CompanyActionsEnum.ResetCompanyAction;

  constructor() {}
}

export class LoadCompanyByOrderPkAction implements Action {
  readonly type = CompanyActionsEnum.LoadCompanyByOrderPkAction;

  constructor(public orderPk: number) {}
}

export class LoadCompanyByRapidIdAction implements Action {
  readonly type = CompanyActionsEnum.LoadCompanyByRapidIdAction;

  constructor(public rapidId: string) {}
}

export class LoadCompanySuccessAction implements Action {
  readonly type = CompanyActionsEnum.LoadCompanySuccessAction;

  constructor(public company: Company) {}
}

export class SaveCompanyAction implements Action {
  readonly type = CompanyActionsEnum.SaveCompanyAction;

  constructor(public company: Company) {}
}

export class SaveCompanySuccessAction implements Action {
  readonly type = CompanyActionsEnum.SaveCompanySuccessAction;

  constructor(public company: Company) {}
}

export class SaveCompanyFailureAction implements Action {
  readonly type = CompanyActionsEnum.SaveCompanyFailureAction;

  constructor() {}
}

export class SaveCompanyCandleAutomaticSettingsAction implements Action {
  readonly type = CompanyActionsEnum.SaveCompanyCandleAutomaticSettingsAction;

  constructor(public companyId: string, public settings: CandleAutomaticSettings) {}
}

export class SaveCompanyCandleAutomaticSettingsSuccessAction implements Action {
  readonly type = CompanyActionsEnum.SaveCompanyCandleAutomaticSettingsSuccessAction;

  constructor(public company: Company) {}
}

export class SaveCompanyCandleAutomaticSettingsFailureAction implements Action {
  readonly type = CompanyActionsEnum.SaveCompanyCandleAutomaticSettingsFailureAction;

  constructor(public errorMessage: string) {}
}

export type CompanyActions =
  | ResetCompanyAction
  | LoadCompanyByOrderPkAction
  | LoadCompanyByRapidIdAction
  | LoadCompanySuccessAction
  | SaveCompanyAction
  | SaveCompanySuccessAction
  | SaveCompanyFailureAction
  | SaveCompanyCandleAutomaticSettingsAction
  | SaveCompanyCandleAutomaticSettingsSuccessAction
  | SaveCompanyCandleAutomaticSettingsFailureAction;
