<ng2-pdfjs-viewer
  [viewerId]="viewerId"
  [pdfSrc]="pdfData"
  [locale]="locale"
  [print]="config.allowPrint"
  [download]="config.allowDownload"
  [find]="config.allowSearchTools"
  [fullScreen]="config.allowFullScreen"
  [openFile]="config.allowFileOpen"
  [viewBookmark]="config.allowViewBookmark"
  [showSpinner]="config.showSpinner"
  (onDocumentLoad)="config.onDocumentLoad()"
  pagemode="none"></ng2-pdfjs-viewer>
