import { LuxonDateAdapter } from '@angular/material-luxon-adapter';
import { MatFormFieldDefaultOptions } from '@angular/material/form-field';

export const CUSTOM_FORMATS = {
  parse: {
    dateInput: ['dd', 'dd.LL', 'dd.LL.yyyy']
  },
  display: {
    dateInput: 'dd.LL.yyyy',
    monthYearLabel: 'yyyy',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'yyyy'
  }
};

export const appearance: MatFormFieldDefaultOptions = {
  appearance: 'fill'
};

export const onlyDigitsRegex = /^-?(0|[1-9]\d*)?$/;

export class LuxonDateAdapterWrapper extends LuxonDateAdapter {
  public getFirstDayOfWeek(): number {
    return 1;
  }

  public getDayOfWeekNames(style: 'long' | 'short' | 'narrow'): string[] {
    if (style === 'narrow') {
      return super.getDayOfWeekNames('short');
    }
    return super.getDayOfWeekNames(style);
  }
}
