import { ErrorHandler, Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class GlobalErrorHandler implements ErrorHandler {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  handleError(error: any): void {
    if (error?.message?.includes('ChunkLoadError')) {
      // app would crash anyway so we might as well reload to recover
      window.location.reload();
    }
  }
}
