import { Injectable } from '@angular/core';
import { UserLevel } from '../../shared/models/user-level.enum';
import { BreadcrumbEntry } from '../models/breadcrumb-entry';
import { User } from '../models/user';

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbsService {
  public checkEntriesWithUser(entries: BreadcrumbEntry[], user: User): BreadcrumbEntry[] {
    switch (user?.level) {
      case UserLevel.Rapid:
        return entries;

      case UserLevel.Mortician:
        if (user.morticianIds.length === 1) {
          entries.splice(0, 1);
        }
        return entries;

      case UserLevel.PageAdmin:
        entries.splice(0, 1);
        return entries;

      default:
        return [];
    }
  }
}
