export interface OrderCustomization {
  deceasedTitleOverride: string;
  deceasedFirstNamesOverride: string;
  deceasedLastNameOverride: string;
  deceasedNameOfBirthOverride: string;
  deceasedDateOfBirthOverride: string;
  deceasedPlaceOfBirthOverride: string;
  deceasedDateOfDeathOverride: string;
  deceasedPlaceOfDeathOverride: string;
  showDeceasedTitle: boolean;
  showDeceasedNameOfBirth: boolean;
  showDeceasedDateOfBirth: boolean;
  showDeceasedPlaceOfBirth: boolean;
  showDeceasedPlaceOfDeath: boolean;
  text: string;
}
