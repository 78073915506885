import { AuthConfigService } from './../config/auth.config.service';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { OAuthErrorEvent, OAuthEvent, OAuthService } from 'angular-oauth2-oidc';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { loadLoggedInUserInfoAction, logoutUserAction, reloadUserInfoAction } from '../store/user/user.actions';
import { UserService } from './user.service';

@Injectable({ providedIn: 'root' })
export class AuthService {
  private isAuthenticatedSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private isLoadingSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  private readonly signInOutCalled = 'sign-in-out-called';

  private readonly reloadTriggeredByServiceWorker = 'reload-triggered-by-service-worker';

  private readonly logInSucceedEvent = new Event('logInSucceed');

  constructor(
    private oauthService: OAuthService,
    private store$: Store<any>,
    private router: Router,
    private userService: UserService,
    private authConfigService: AuthConfigService
  ) {
    this.registerChangesInAllTabs();
  }

  public get isAuthenticated$(): Observable<boolean> {
    return this.isAuthenticatedSubject$.asObservable();
  }

  public get isLoading$(): Observable<boolean> {
    return this.isLoadingSubject$.asObservable();
  }

  public get accessToken(): string {
    return this.oauthService.getAccessToken();
  }

  public get hasValidAccessToken(): boolean {
    return this.oauthService.hasValidAccessToken();
  }

  public navigateToLogin(): void {
    this.router.navigateByUrl('/login');
  }

  public configureCodeFlow(): void {
    this.oauthService.configure(this.authConfigService.authConfig);
    this.oauthService.setupAutomaticSilentRefresh();
    this.oauthService
      .loadDiscoveryDocumentAndTryLogin()
      .then(() => {
        this.router.initialNavigation();
        const dispatchLogInSucceedEvent = () => window.dispatchEvent(this.logInSucceedEvent); // used by sw.js
        if (localStorage.getItem(this.signInOutCalled)) {
          this.isLoadingSubject$.next(this.oauthService.hasValidAccessToken());
          this.isAuthenticatedSubject$.next(this.oauthService.hasValidAccessToken());

          if (this.oauthService.hasValidAccessToken()) {
            this.store$.dispatch(loadLoggedInUserInfoAction());
            dispatchLogInSucceedEvent();
          }
          this.removeSignInOutCalled();
        } else if (!sessionStorage.getItem(this.reloadTriggeredByServiceWorker)) {
          this.runBeforeIs4Callback();

          const pathName = window.location.pathname;
          const allowedPathName = !pathName || (pathName && pathName.includes('login')) ? '/' : pathName;
          this.userService.storeReturnUrlInStorage(allowedPathName);
          this.oauthService.initLoginFlow('', { prompt: 'none' });
        } else if (sessionStorage.getItem(this.reloadTriggeredByServiceWorker)) {
          dispatchLogInSucceedEvent();
          this.isAuthenticatedSubject$.next(this.oauthService.hasValidAccessToken());
        }
      })
      .catch(() => {
        this.isLoadingSubject$.next(false);
      });

    const errorResponsesRequiringUserInteraction = [
      'interaction_required',
      'login_required',
      'account_selection_required',
      'consent_required'
    ];

    this.oauthService.events
      .pipe(filter((e: OAuthEvent) => ['code_error', 'token_refresh_error', 'silent_refresh_error'].includes(e.type)))
      .subscribe((errorEvent: OAuthErrorEvent) => {
        if (
          errorEvent &&
          errorEvent.params &&
          errorResponsesRequiringUserInteraction.includes((errorEvent.params as any).error as string)
        ) {
          const errorValue: string = (errorEvent.params as any).error;
          if (errorValue === 'login_required' && this.accessToken) {
            this.logout();
          } else {
            this.removeSignInOutCalled();
            if (this.hasValidRedirectUrl()) {
              this.router.navigate([this.getRedirectUrl()]);
            } else {
              this.navigateToLogin();
            }
          }
        }
        else if (
          errorEvent &&
          (
            errorEvent.type === 'token_refresh_error' ||
            errorEvent.type === 'silent_refresh_error'
          )
        ) {
          console.warn('token refresh has failed');
          this.navigateToLogin();
        }

        this.isLoadingSubject$.next(false);
      });

    this.oauthService.events
      .pipe(filter((e) => ['session_terminated', 'session_error'].includes(e.type)))
      .subscribe(() => this.navigateToLogin());

    this.oauthService.events.pipe(filter((e) => e.type === 'token_received')).subscribe(() => {
      this.userService.updateAccessToken(this.oauthService.getAccessToken());
      this.isAuthenticatedSubject$.next(this.oauthService.hasValidAccessToken());
      this.oauthService.loadUserProfile();
    });
  }

  public login(): void {
    const username = this.userService.readUsernameFromStorage();
    this.userService.removeUsernameFromStorage();
    this.runBeforeIs4Callback();
    this.userService.storeReturnUrlInStorage('');
    // eslint-disable-next-line @typescript-eslint/naming-convention
    this.oauthService.initLoginFlow('', { login_hint: username });
  }

  public logout(): void {
    this.runBeforeIs4Callback();
    this.store$.dispatch(logoutUserAction());
    this.oauthService.logOut();
  }

  public impersonate(userId: string): void {
    const claims = this.oauthService.getIdentityClaims() as any;
    // tslint:disable-next-line: no-string-literal
    if (claims.sub === userId) {
      throw new Error('source-equal-target-user-error');
    }
    this.runBeforeIs4Callback();
    this.store$.dispatch(logoutUserAction());
    this.oauthService.initLoginFlow('', { impersonate: userId });
  }

  public depersonate(): void {
    this.runBeforeIs4Callback();
    this.store$.dispatch(logoutUserAction());
    this.oauthService.initLoginFlow('', { depersonate: '' });
  }

  public isRapidUser(): boolean {
    const claims = this.oauthService.getIdentityClaims() as any;
    // tslint:disable-next-line: no-string-literal
    return !!claims && !!claims.role && claims.role.includes('rapid');
  }

  public hasValidRedirectUrl(): boolean {
    const redirectUrl = this.getRedirectUrl();
    return redirectUrl && redirectUrl !== '/';
  }

  public getRedirectUrl(): string {
    return this.userService.readReturnUrlFromStorage();
  }

  public removeReturnUrlFromStorage(): void {
    this.userService.removeReturnUrlFromStorage();
  }

  public isMortician(): boolean {
    const claims = this.oauthService.getIdentityClaims() as any;
    // tslint:disable-next-line: no-string-literal
    return !!claims.mortician_id;
  }

  public userImpersonated(): boolean {
    const claims = this.oauthService.getIdentityClaims() as any;
    // tslint:disable-next-line: no-string-literal
    return !!claims && !!claims['o-sub'];
  }

  public getUserId(): string {
    const claims = this.oauthService.getIdentityClaims() as any;
    // tslint:disable-next-line: no-string-literal
    return !!claims && claims.sub ? claims.sub : null;
  }

  public refreshUserData(): void {
    if (this.hasValidAccessToken) {
      void this.oauthService.refreshToken().then(() => this.store$.dispatch(reloadUserInfoAction()));
    }
  }

  public refreshAccessToken(): void {
    void this.oauthService.refreshToken().then(() => this.store$.dispatch(reloadUserInfoAction()));
  }

  public getLoggedinUserId(): string | null {
    const claims = this.oauthService.getIdentityClaims() as any;
    // tslint:disable: no-string-literal
    if (claims.sub) {
      return claims.sub;
    }
    return null;
  }

  private registerChangesInAllTabs(): void {
    window.addEventListener('storage', (event) => {
      if (event.key !== 'access_token' && event.key !== null) {
        return;
      }

      this.isAuthenticatedSubject$.next(this.oauthService.hasValidAccessToken());
      if (!this.oauthService.hasValidAccessToken()) {
        this.navigateToLogin();
        this.isLoadingSubject$.next(false);
      }
    });
  }

  private runBeforeIs4Callback(): void {
    localStorage.setItem(this.signInOutCalled, 'true');
    this.isLoadingSubject$.next(true);
  }

  private removeSignInOutCalled(): void {
    localStorage.removeItem(this.signInOutCalled);
  }
}
