<div class="spinner-wrapper {{ wrapperClasses }}">
  <div [class.invisible]="isBusy && !displayContentWhileBusy">
    <ng-content></ng-content>
  </div>
  <div
    *ngIf="isBusy"
    [ngClass]="{ 'position-absolute': overlayMode }"
    class="spinner-overlay d-flex justify-content-center align-items-center flex-column min-vh-{{
      placeholderMinHeight
    }}"
    [ngStyle]="{ background: overlayBg }">
    <i class="{{ spinnerClasses }}" role="status"></i>
    <div class="mt-3" *ngIf="spinnerText">{{ spinnerText }}</div>
  </div>
</div>
