<ng-container *ngTemplateOutlet="content; context: { animate: animate }"></ng-container>

<ng-template #content let-animate="animate">
  <ng-container *ngIf="animate">
    <div
      *ngIf="displayMessage"
      @slideInOutBottom
      class="mpac-form-save__alert alert alert-{{ messageType }}"
      role="alert"
      [hidden]="dir.globalWriteProtectionActive"
      mpacGlobalWriteProtection
      #dir="mpacGlobalWriteProtection">
      <div class="mpac-form-save__alert-cnt">
        <div class="mpac-form-save__alert-cnt-msg" innerHtml="{{ messageTranslationKey | translate }}"></div>
        <div class="mpac-form-save__alert-cnt-btn">
          <mpac-spinner-button
            btnClasses="btn btn-primary"
            [disabled]="!!submitDisabled"
            [isBusy]="false"
            (click)="buttonClicked()">
            <i aria-hidden="true" class="fal fa-save mr-2"></i>
            {{ 'actions.save' | translate }}
          </mpac-spinner-button>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="!animate">
    <div
      *ngIf="displayMessage"
      class="mpac-form-save__alert alert alert-{{ messageType }}"
      role="alert"
      [hidden]="dir.globalWriteProtectionActive"
      mpacGlobalWriteProtection
      #dir="mpacGlobalWriteProtection">
      <div class="mpac-form-save__alert-cnt">
        <div class="mpac-form-save__alert-cnt-msg" innerHtml="{{ messageTranslationKey | translate }}"></div>
        <div class="mpac-form-save__alert-cnt-btn">
          <mpac-spinner-button
            btnClasses="btn btn-primary"
            [disabled]="!!submitDisabled"
            [isBusy]="false"
            (click)="buttonClicked()">
            <i aria-hidden="true" class="fal fa-save mr-2"></i>
            {{ 'actions.save' | translate }}
          </mpac-spinner-button>
        </div>
      </div>
    </div>
  </ng-container>
</ng-template>
