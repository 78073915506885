import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Breakpoints } from '../../helper/breakpoints';
import { CoreState } from '../common';
import {
  attachBreakpointListenersAction,
  reportBreakpointStateAction,
  reportNewBreakpointsStateAction
} from './display.actions';

@Injectable()
export class DisplayEffects {
  initBreakpointListener$ = createEffect(() =>
    this.actions$.pipe(
      ofType(attachBreakpointListenersAction),
      switchMap(() => {
        // listen for breakpoint match changes
        const initialBreakpoints: { [key: string]: boolean } = {};
        Breakpoints.asList.forEach((breakpoint: string) => {
          const mediaQueryList = window.matchMedia(breakpoint);

          if (mediaQueryList.addEventListener !== undefined && typeof mediaQueryList.addEventListener === 'function') {
            mediaQueryList.addEventListener('change', (ev) => {
              this.store$.dispatch(reportBreakpointStateAction({ breakpoint, matches: ev.matches }));
            });
          } else {
            // tslint:disable-next-line: deprecation
            mediaQueryList.addListener((ev) => {
              this.store$.dispatch(reportBreakpointStateAction({ breakpoint, matches: ev.matches }));
            });
          }
          initialBreakpoints[breakpoint] = mediaQueryList.matches;
        });

        // Force redraw on orientation change
        window.addEventListener(
          'orientationchange',
          () => {
            try {
              document.body.style.translate = 'inherit';
              window.setTimeout(() => {
                document.body.style.translate = '';
              }, 100);
            } catch (e) {
              document.body.style.translate = '';
            }
          },
          false
        );

        return of(reportNewBreakpointsStateAction({ breakpoints: initialBreakpoints }));
      })
    )
  );

  constructor(private actions$: Actions, private store$: Store<CoreState>) {}
}
