export enum FuneralPrintStatus {
  Created = 'Created',
  Updating = 'Updating',
  ApprovalRequested = 'ApprovalRequested',
  ApprovalDenied = 'ApprovalDenied',
  Discarded = 'Discarded',
  Approved = 'Approved',
  Done = 'Done',
  Unknown = 'Unknown'
}
