import { Component, ContentChild, HostBinding, OnInit, TemplateRef, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'mpac-page-actions-bar',
  templateUrl: './page-actions-bar.component.html',
  styleUrls: ['./page-actions-bar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PageActionsBarComponent implements OnInit {
  public static baseClass = 'mpac-page-action-bar';

  @HostBinding('class') hostClassBinding;

  @ContentChild(TemplateRef) actionListsRef;

  public ngOnInit(): void {
    this.setHostClassBinding();
  }

  protected setHostClassBinding(): void {
    this.hostClassBinding = PageActionsBarComponent.baseClass;
  }
}
