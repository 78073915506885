<div
  class="mpac-progress-bar"
  [class.mpac-progress-bar--done]="status.success && !status.failure"
  [class.mpac-progress-bar--error]="status.failure">
  <div clas="mpac-progress-bar__wrap">
    <progressbar clkass="mpac-progress-bar__bar" [max]="100" [value]="status.progress" [type]="progressBarType">
      <ng-container *ngIf="!status.success">
        {{ progressForDisplay + '%' }}
      </ng-container>
    </progressbar>
  </div>
</div>
