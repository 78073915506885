import { Component, Input, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControlDirective, NgControl } from '@angular/forms';
import {
  confirmButtonDefaultTranslationKey,
  ConfirmDialogComponent,
  ConfirmDialogConfig,
  dismissButtonDefaultTranslationKey,
  messageDefaultTranslationKey,
  titleDefaultTranslationKey
} from '@core/components/confirm-dialog/confirm-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { DesctructableComponent } from '@shared/components/destructable/destructable.component';
import { DialogService } from '@shared/services/dialog-service';
import { takeUntil } from 'rxjs/operators';
import { ToggleBaseConfiguration, ToggleStateBaseConfiguration } from '../../../models/toggle-base.model';

export interface ThreeWayToggleConfiguration extends ToggleBaseConfiguration {
  left: ThreeWayToggleStateConfiguration;
  middle: ThreeWayToggleStateConfiguration;
  right: ThreeWayToggleStateConfiguration;
}

export interface ThreeWayToggleStateConfiguration extends ToggleStateBaseConfiguration {
  isDisabled?: boolean;
  iconNames: string;
  tooltipTranslationKey?: string;
}

@Component({
  selector: 'mpac-three-way-toggle',
  templateUrl: './three-way-toggle.component.html',
  styleUrls: ['./three-way-toggle.component.scss']
})
export class ThreeWayToggleComponent extends DesctructableComponent implements ControlValueAccessor {
  @ViewChild(FormControlDirective, { static: true }) formControlDirective: FormControlDirective;

  @Input() configuration: ThreeWayToggleConfiguration;

  public innerValue: any;

  public currentValue: any;

  constructor(
    public ngControl: NgControl,
    private translateService: TranslateService,
    private dialogService: DialogService
  ) {
    super();
    this.ngControl.valueAccessor = this;
  }

  public get hasLabel(): boolean {
    return !!this.configuration?.labelTranslationKey || !!this.configuration?.labelText;
  }

  public writeValue(value: any): void {
    this.innerValue = this.currentValue = value;
    this.formControlDirective.valueAccessor.writeValue(value);
  }

  public registerOnChange(fn: any): void {
    this.formControlDirective.valueAccessor.registerOnChange(fn);
  }

  public registerOnTouched(fn: any): void {
    this.formControlDirective.valueAccessor.registerOnTouched(fn);
  }

  public setDisabledState?(isDisabled: boolean): void {
    this.formControlDirective.valueAccessor.setDisabledState(isDisabled);
  }

  public onValueChange(value: any): void {
    const previousValue = this.innerValue;
    if (previousValue !== value) {
      let confirmationProcessing: boolean;
      const valuesWithConfirmChange = this.getValuesWithChangeConfirmation();
      if (
        !confirmationProcessing &&
        valuesWithConfirmChange.length &&
        valuesWithConfirmChange.includes(previousValue)
      ) {
        confirmationProcessing = true;
        this.dialogService
          .openAndReturnObservable(ConfirmDialogComponent, {
            width: '400px',
            data: {
              ...this.getDialogConfiguration()
            }
          })
          .pipe(takeUntil(this.shutdown$))
          .subscribe((confirmed: boolean) => {
            if (confirmed) {
              this.changeInnerValueAndPropagate(value);
            } else {
              this.currentValue = previousValue;
            }
            confirmationProcessing = false;
          });
      } else {
        this.changeInnerValueAndPropagate(value);
      }
    }
  }

  public getLabelText(): string {
    let labelText = '';
    if (this.configuration?.labelTranslationKey) {
      labelText = this.translateService.instant(this.configuration.labelTranslationKey);
    } else if (this.configuration?.labelText) {
      labelText = this.configuration.labelText;
    }

    return labelText;
  }

  private changeInnerValueAndPropagate(value: any): void {
    this.innerValue = value;
    this.ngControl.control.markAsDirty();
    this.ngControl.control.setValue(value);
  }

  private getValuesWithChangeConfirmation(): any[] {
    const valuesWithChangeConfirmation = [];

    if (this.configuration?.left.confirmChange) {
      valuesWithChangeConfirmation.push(this.configuration?.left.value);
    }
    if (this.configuration?.middle.confirmChange) {
      valuesWithChangeConfirmation.push(this.configuration?.middle.value);
    }
    if (this.configuration?.right.confirmChange) {
      valuesWithChangeConfirmation.push(this.configuration?.right.value);
    }

    return valuesWithChangeConfirmation;
  }

  private getDialogConfiguration(): ConfirmDialogConfig {
    const config: ConfirmDialogConfig = {};
    const outerConfig = this.configuration?.confirmDialogConfig;
    config.title = outerConfig?.title || this.getTranslationFor(titleDefaultTranslationKey);
    config.message = outerConfig?.message || this.getTranslationFor(messageDefaultTranslationKey);
    config.confirmButtonText =
      outerConfig?.confirmButtonText || this.getTranslationFor(confirmButtonDefaultTranslationKey);
    config.dismissButtonText =
      outerConfig?.dismissButtonText || this.getTranslationFor(dismissButtonDefaultTranslationKey);
    config.confirmButtonIcon = outerConfig?.dismissButtonIcon;
    config.dismissButtonIcon = outerConfig?.dismissButtonIcon;

    return config;
  }

  private getTranslationFor(translationKey: string): string {
    return this.translateService.instant(translationKey);
  }
}
