<mpac-page [padding]="false" *ngIf="!!precautionInfoConfiguration">
  <div class="cover" [ngStyle]="{ 'background-image': 'url(' + coverImageData + ')' }">
    <div *ngIf="precautionInfoConfiguration.showCompanyLogo" class="brand-logo">
      <span class="vertical-align-helper"></span>
      <img [src]="companyLogoUrl" />
    </div>
  </div>

  <div class="px-3 py-2 p-md-5">
    <h1>{{ precautionInfoConfiguration.title }}</h1>

    <div class="quill-text" [innerHtml]="precautionInfoConfiguration.description"></div>

    <div class="text-center mt-5 mb-4">
      <button
        (click)="requestInfo()"
        type="button"
        class="btn"
        [ngClass]="{ 'btn-success': informationRequested, 'btn-danger': !informationRequested }">
        <span class="info-request-btn {{ informationRequested ? 'info-sent' : 'info-not-sent' }}">
          {{ requestInfoButtonText }}
        </span>
      </button>
    </div>

    <ng-container *ngIf="!!precautionInfoConfiguration.infoDocument?.data">
      <p class="text-center mb-0">
        <button class="btn btn-link" (click)="downloadInfoDocument()" [class.not-active]="previewMode">
          {{ 'forms.labels.precautionInfo.infoDocumentFile' | translate }}
          <i class="fal fa-external-link"></i>
        </button>
      </p>
    </ng-container>
  </div>
</mpac-page>
