/**
 * Enumeration of possible Salutations.
 */
export enum Salutation {
  UNDEFINED,
  EMPTY,
  MISTER,
  MISSES,
  COMPANY
}

export enum SalutationType {
  Undefined = 0,
  Mister = 1,
  Misses = 2,
  Other = 3,
  Company = 4,
  Family = 5
}
