import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  QuickFormOrderAction,
  QuickFormOrderActionAnwserType,
  QuickFormPrefilledDocumentType
} from '../models/quick-form-order-action';
import { Observable, from } from 'rxjs';
import { map, catchError, flatMap } from 'rxjs/operators';
import { QuickFormOrder, QuickFormCreateOrderDocument } from '../models/quick-form-order';
import { QuickFormResponse } from './dtos/quick-form-response';
import { QuickFormOrderActionDto } from './dtos/quick-form-order-action.dto';
import { Base64Helper } from '../helpers/base64-helper';
import { DtoConverter } from './converter/dto-converter';
import { QuickFormOrderDto } from './dtos/quick-form-order.dto';
import { HttpHelper } from './helper/http.helper';
import { SettingsService } from 'src/app/core/config/settings.service';
import { OrderSummary } from '@order/models/quickform-order-summary';

@Injectable({
  providedIn: 'root'
})
export class QuickFormDependantService {
  /**
   * Endpoint definition.
   */
  private static serviceEndpoint = '/v1';

  constructor(private httpClient: HttpClient, private settingsService: SettingsService) {}

  /**
   * The part of the api url, which is the same across all calls issued from this service.
   */
  private get baseUrl(): string {
    return this.settingsService?.settings?.quickformApiRoot
      ? `${this.settingsService.settings.quickformApiRoot}${QuickFormDependantService.serviceEndpoint}`
      : '';
  }

  public orderByIdUrl = (orderId: string): string => `${this.baseUrl}/orders/${orderId}`;

  public partnerActionsUrl = (orderId: string): string => `${this.baseUrl}/orders/${orderId}/partner-actions`;

  public partnerActionByIdUrl = (orderActionId: string): string => `${this.baseUrl}/partner-actions/${orderActionId}`;

  public partnerActionDocumentByIdUrl = (documentId: string): string =>
    `${this.baseUrl}/partner-action-documents/${documentId}`;

  public orderDocumentsUrl = (documentId: string): string => `${this.baseUrl}/order-documents/${documentId}`;

  public loadOrder(orderId: string): Observable<QuickFormOrder> {
    const url = this.orderByIdUrl(orderId);
    return this.httpClient
      .get<QuickFormResponse<QuickFormOrderDto>>(url)
      .pipe(map(HttpHelper.ensureValidQuickFormResponse), catchError(HttpHelper.throwApiError))
      .pipe(map((response: QuickFormResponse<QuickFormOrderDto>) => DtoConverter.toQuickFormOrder(response.data)));
  }

  public loadOrderActions(orderId: string): Observable<Array<QuickFormOrderAction>> {
    const url = this.partnerActionsUrl(orderId);
    return this.httpClient
      .get<QuickFormResponse<Array<QuickFormOrderActionDto>>>(url)
      .pipe(
        map(HttpHelper.ensureValidQuickFormResponse),
        map(
          (response: QuickFormResponse<Array<QuickFormOrderActionDto>>) =>
            response.data.map((d) => DtoConverter.resumeToQuickFormPartnerAction(d)),
          catchError(HttpHelper.throwApiError)
        )
      );
  }

  public loadOrderAction(orderActionId: string): Observable<QuickFormOrderAction> {
    const url = this.partnerActionByIdUrl(orderActionId);
    return this.httpClient
      .get<QuickFormResponse<QuickFormOrderActionDto>>(url)
      .pipe(map(HttpHelper.ensureValidQuickFormResponse), catchError(HttpHelper.throwApiError))
      .pipe(
        map((response: QuickFormResponse<QuickFormOrderActionDto>) =>
          DtoConverter.toQuickFormPartnerAction(response.data)
        )
      );
  }

  public loadOrderActionDocument(documentId: string): Observable<{ blob: Blob }> {
    const url = `${this.baseUrl}/partner-action-documents/${documentId}`;
    return this.loadBlob(url);
  }

  public loadOrderDocument(documentId: string): Observable<{ blob: Blob }> {
    const url = this.orderDocumentsUrl(documentId);
    return this.loadBlob(url);
  }

  public loadPrefilledCertificateOfAuthority(orderId: string): Observable<{ blob: Blob }> {
    const url = `${this.baseUrl}/orders/${orderId}/documents/prefilled-certificate-of-authority`;
    return this.loadBlob(url);
  }

  public loadPrefilledDeclaration(
    orderActionId: string,
    prefilledDocumentType: QuickFormPrefilledDocumentType
  ): Observable<{ blob: Blob; fileName: string }> {
    switch (prefilledDocumentType) {
      case QuickFormPrefilledDocumentType.WidowPensionDeclaration:
        return this.loadWidowPensionDeclaration(orderActionId);
      case QuickFormPrefilledDocumentType.LossDeclaration:
        return this.loadLossDeclaration(orderActionId);
      case QuickFormPrefilledDocumentType.OriginalsNeededCoverLetter:
        return this.loadOriginalsNeededCoverLetter(orderActionId);
      case QuickFormPrefilledDocumentType.CessionDeclaration:
        return this.loadCessionDeclaration(orderActionId);
      default:
        throw new Error(`the prefilled document type ${prefilledDocumentType} is unknown`);
    }
  }

  public loadWidowPensionDeclaration(orderActionId: string): Observable<{ blob: Blob; fileName: string }> {
    return this.loadBlob(
      `${this.baseUrl}/partner-actions/${orderActionId}/prefilled-documents/widow-pension-declaration`
    );
  }

  public loadLossDeclaration(orderActionId: string): Observable<{ blob: Blob; fileName: string }> {
    return this.loadBlob(`${this.baseUrl}/partner-actions/${orderActionId}/prefilled-documents/loss-declaration`);
  }

  public loadOriginalsNeededCoverLetter(orderActionId: string): Observable<{ blob: Blob; fileName: string }> {
    return this.loadBlob(
      `${this.baseUrl}/partner-actions/${orderActionId}/prefilled-documents/original-needed-cover-letter`
    );
  }

  public loadCessionDeclaration(orderActionId: string): Observable<{ blob: Blob; fileName: string }> {
    return this.loadBlob(`${this.baseUrl}/partner-actions/${orderActionId}/prefilled-documents/cession-declaration`);
  }

  public discardOrderAction(orderActionId: string): Observable<string> {
    const url = `${this.baseUrl}/partner-actions/${orderActionId}/status/discarded`;
    return this.httpClient
      .post<QuickFormResponse<any>>(url, {})
      .pipe(map(HttpHelper.ensureValidQuickFormResponse), catchError(HttpHelper.throwApiError))
      .pipe(map(() => orderActionId));
  }

  public answerOrderAction(
    orderActionId: string,
    answerType: QuickFormOrderActionAnwserType,
    file: File
  ): Observable<string> {
    const suffix = `${answerType === QuickFormOrderActionAnwserType.Accepted ? 'accepted' : 'rejected'}`;
    const url = `${this.baseUrl}/partner-actions/${orderActionId}/answer/${suffix}`;

    const formData: FormData = new FormData();
    if (file !== null) {
      formData.append('uploadFile', file, file.name);
    }

    const headers = new HttpHeaders();
    /** In Angular 5, including the header Content-Type can invalidate your request */
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    const options = { headers };

    return this.httpClient
      .post<QuickFormResponse<any>>(url, formData, options)
      .pipe(map(HttpHelper.ensureValidQuickFormResponse), catchError(HttpHelper.throwApiError))
      .pipe(map(() => orderActionId));
  }

  public reportOrderActionProblem(orderActionId: string, message: string): Observable<string> {
    const url = `${this.baseUrl}/partner-actions/${orderActionId}/problems`;
    const body = {
      message
    };
    return this.httpClient
      .post<QuickFormResponse<any>>(url, body)
      .pipe(map(HttpHelper.ensureValidQuickFormResponse), catchError(HttpHelper.throwApiError))
      .pipe(map(() => orderActionId));
  }

  public activateDigitalLegacy(orderId: string, hitAction: string, excludeAdultSearch: boolean): Observable<boolean> {
    const url =
      `${this.baseUrl}/orders/${orderId}/digital-legacy/activation/${hitAction}` +
      (excludeAdultSearch ? '/exclude-adult-search' : '');

    return this.httpClient
      .post<QuickFormResponse<any>>(url, {})
      .pipe(map(HttpHelper.ensureValidQuickFormResponse), catchError(HttpHelper.throwApiError))
      .pipe(map(() => true));
  }

  public addDeceasedEmailAddresses(orderId: string, emailAddresses: string[]): Observable<boolean> {
    const url = `${this.baseUrl}/orders/${orderId}/deceased-email-addresses`;

    return this.httpClient
      .post<QuickFormResponse<any>>(url, emailAddresses)
      .pipe(map(HttpHelper.ensureValidQuickFormResponse), catchError(HttpHelper.throwApiError))
      .pipe(map(() => true));
  }

  public uploadDocument(orderId: string, document: QuickFormCreateOrderDocument): Observable<boolean> {
    const url = `${this.baseUrl}/orders/${orderId}/documents`;

    return from(Base64Helper.getDataUrl(document.file)).pipe(
      flatMap((dataUrl) => {
        const body = {
          name: document.file.name,
          type: DtoConverter.toDocumentTypeString(document.type),
          data: Base64Helper.getBase64(dataUrl as string)
        };
        return this.httpClient
          .post<QuickFormResponse<any>>(url, body)
          .pipe(map(HttpHelper.ensureValidQuickFormResponse), catchError(HttpHelper.throwApiError))
          .pipe(map(() => true));
      })
    );
  }

  public loadSummaryReport(orderId: string): Observable<any> {
    const url = `${this.baseUrl}/orders/${orderId}/documents/summary-report`;
    return this.loadBlob(url);
  }

  public getOrderSummary(thirdPartyId: string): Observable<OrderSummary> {
    const url = `${this.baseUrl}/orders/detail/${thirdPartyId}`;
    return this.httpClient
      .get<QuickFormResponse<OrderSummary>>(url)
      .pipe(
        map(HttpHelper.ensureValidQuickFormResponse),
        catchError(HttpHelper.throwApiError))
      .pipe(map((response: QuickFormResponse<OrderSummary>) => response.data));
  }

  public confirmOrderSummary(thirdPartyId: string): Observable<any> {
    const url = `${this.baseUrl}/orders/confirm/${thirdPartyId}`;
    return this.httpClient
      .post(url, {})
      .pipe(
        map(HttpHelper.ensureValidQuickFormResponse),
        catchError(HttpHelper.throwApiError))
      .pipe(map(() => true));
  }

  private loadBlob(url: string): Observable<{ blob: Blob; fileName: string }> {
    const httpOptions = {
      observe: 'response' as 'body',
      responseType: 'blob' as 'json'
    };
    return this.httpClient
      .get(url, httpOptions)
      .pipe(map(HttpHelper.extractBlob), catchError(HttpHelper.throwApiError));
  }
}
