<div class="mpac-portal-layout__cnt">
  <mpac-side-menu
    [menuSections]="menuSections || []"
    [userLevel]="userLevel$ | async"
    class="mpac-portal-layout__cnt-mnu">
  </mpac-side-menu>
  <main
    class="mpac-portal-layout__cnt-main"
    (scrolled)="handleScroll()"
    [infiniteScrollThrottle]="10"
    [scrollWindow]="false"
    infiniteScroll
    mpacDialogOverflowDisabled>
    <router-outlet></router-outlet>
  </main>
</div>
