import { Component, Input, ViewEncapsulation, ViewChild, AfterViewInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { CoreState } from '@core/store/common';
import { toggleHelpVisibilityAction } from '@core/store/display/display.actions';
import {
  HelpToggleDismissableTooltipService,
  TooltipContentConfig
} from '@core/services/help-toggle-dismissable-tooltip.service';
import { DesctructableComponent } from '@shared/components/destructable/destructable.component';
import { takeUntil } from 'rxjs/operators';
import { BehaviorSubject, combineLatest } from 'rxjs';

@Component({
  selector: 'mpac-help-toggle',
  templateUrl: './help-toggle.component.html',
  styleUrls: ['./help-toggle.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HelpToggleComponent extends DesctructableComponent implements AfterViewInit {
  @Input() mobile: boolean;

  @ViewChild('tooltipRef') public tooltipRef: any;

  public tooltipContentConfig: TooltipContentConfig;

  private isTooltipCurrentlyVisible$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  /**
   * Following workflow to add dismissable tooltip to a component/page:
   * 1. inject HelpToggleDismissableTooltipService in constructor in the respective component
   * 2. set a unique ID, for example private static someUniqueId = 'foo-bar';
   * 3. implement AfterViewInit and call injectedService.setContentConfigFor(ClassName.someUniqueId);
   * 4. implement OnDestroy and call injectedService.helpToggleDismissableTooltipService.clearContentConfig()
   * 5. add translations in de.json under dismissableTooltips.{{ComponentClassName}} for header, body and dismissButton
   */
  constructor(
    private store$: Store<CoreState>,
    private helpToggleDismissableTooltipService: HelpToggleDismissableTooltipService
  ) {
    super();
  }

  public ngAfterViewInit(): void {
    combineLatest([
      this.isTooltipCurrentlyVisible$,
      this.helpToggleDismissableTooltipService.canShowTooltip$,
      this.helpToggleDismissableTooltipService.dismissableTooltipContentConfig$
    ])
      .pipe(takeUntil(this.shutdown$))
      .subscribe(([isTooltipCurrentlyVisible, canShowTooltip, contentConfig]) => {
        if (isTooltipCurrentlyVisible) {
          if (canShowTooltip) {
            this.tooltipContentConfig = contentConfig;
          } else {
            this.tooltipRef.hide();
          }
        } else {
          this.tooltipContentConfig = contentConfig;
          if (canShowTooltip) {
            this.tooltipRef.show();
          }
        }
      });
  }

  public toggleIsTooltipVisible(isVisible: boolean): void {
    this.isTooltipCurrentlyVisible$.next(isVisible);
  }

  public toggleHelp(): void {
    this.store$.dispatch(toggleHelpVisibilityAction());
    this.helpToggleDismissableTooltipService.clearContentConfig();
  }

  public setCookieAndHideTooltip(): void {
    this.helpToggleDismissableTooltipService.setCookieAndDismissTooltip(this.tooltipContentConfig.cookieId);
  }
}
