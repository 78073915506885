import { Component, OnDestroy, OnInit } from '@angular/core';
import { NewAppVersionAvailableComponent } from '@core/components/new-app-version-available/new-app-version-available.component';
import { AppVersionService } from '@core/services/app-version.service';
import { TranslateService } from '@ngx-translate/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { distinctUntilChanged, filter, skip } from 'rxjs/operators';
import { AuthService } from './core/services/auth.service';
import { SettingBasedOnHostnameService } from './core/services/setting-based-on-hostname.service';
import { DependantDemoService } from './shared/services/dependant-demo.service';
import { I18nService } from './shared/services/i18n.service';
import { TrackerIntegrationService } from './shared/services/tracker-integration.service';

@Component({
  selector: 'mpac-root',
  template: '<router-outlet></router-outlet>'
})
export class AppComponent implements OnInit, OnDestroy {
  private subscription: Subscription;

  private newVersionDialogOpen = false;

  constructor(
    private authService: AuthService,
    private settingBasedOnHostnameService: SettingBasedOnHostnameService,
    private i18nService: I18nService,
    public translate: TranslateService,
    private trackingService: TrackerIntegrationService,
    private dependantDemoService: DependantDemoService,
    private appVersionService: AppVersionService,
    private modalService: BsModalService
  ) {
    this.translate.setDefaultLang(this.i18nService.language);
    this.translate.use(this.i18nService.language);
    authService.configureCodeFlow();
    settingBasedOnHostnameService.setTitleByHostname();
    settingBasedOnHostnameService.setFaviconByHostname();
    this.initVersionCheck();
  }

  public ngOnInit(): void {
    this.trackingService.withPrivacySetup();

    this.subscription = this.authService.isAuthenticated$
      .pipe(skip(1), distinctUntilChanged())
      .subscribe((isAuthenticated) => {
        if (isAuthenticated && this.dependantDemoService.dependantDemoUserActive()) {
          this.settingBasedOnHostnameService.setTitleToDependantDemo();
        }
      });
  }

  public ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  private initVersionCheck(): void {
    setInterval(() => {
      if (!this.newVersionDialogOpen) {
        this.appVersionService
          .checkForNewServerVersion()
          .pipe(filter((result) => result.isNewVersionAvailable))
          .subscribe(() => {
            this.modalService.show(NewAppVersionAvailableComponent, {
              class: 'modal-sm',
              ignoreBackdropClick: true,
              keyboard: false
            });

            this.newVersionDialogOpen = true;
          });
      }
    }, 120000);
  }
}
