import { trigger, transition, style, animate } from '@angular/animations';

export const slideUpDown = [
  trigger('slideUpDown', [
    transition(':enter', [
      style({ height: 0, overflow: 'hidden' }),
      animate('200ms ease-in', style({ height: '{{ maxHeight }}px' }))
    ]),
    transition(':leave', [style({ overflow: 'hidden' }), animate('200ms ease-in', style({ height: 0 }))])
  ])
];
