import { SettingsService } from './../config/settings.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CandleAutomaticSettings } from 'src/app/features/company-portal/models/candle-automatic-settings';
import { CompanyAdmin } from '../../features/company-portal/models/company-admin';
import { CompanyAdminInvitation } from '../../features/company-portal/models/company-admin-invitation';
import { Company } from '../../shared/models/company';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {
  /**
   * Endpoint definition.
   */
  private static serviceEndpoint = '/companies';

  constructor(private httpClient: HttpClient, private settingsService: SettingsService) {}

  /**
   * The part of the api url, which is the same across all calls issued from this service.
   */
  private get baseUrl(): string {
    return this.settingsService?.settings?.apiRoot
      ? `${this.settingsService.settings.apiRoot}${CompanyService.serviceEndpoint}`
      : '';
  }

  /**
   * Loads data about a mortician (=company) via an api get request.
   * The mortician is identified by the pk of an related order.
   *
   * @param orderPk: Identifies the related order.
   * @returns the mortician's data as a stream.
   */
  public loadCompanyByOrderPk(orderPk: number): Observable<Company> {
    const url = `${this.baseUrl}/by-order-pk/${orderPk}`;
    return this.httpClient.get<Company>(url);
  }

  /**
   * Loads data about a mortician (=company) via an api get request.
   * The mortician is identified by its rapidId.
   *
   * @param rapidId: Identifies the mortician.
   * @returns the mortician's data as a stream.
   */
  public loadCompanyByRapidId(rapidId: string): Observable<Company> {
    const url = this.baseUrl + '/by-rapid-id/' + rapidId;
    return this.httpClient.get<Company>(url);
  }

  /**
   * Sends data about a mortician (=company)via an api put request.
   *
   * @param company: The data.
   * @returns the possibly updated data about the mortician.
   */
  public updateCompany(company: Company): Observable<Company> {
    const url = this.baseUrl + '/' + company.rapidId;
    return this.httpClient.put<Company>(url, company);
  }

  /**
   * Loads a list of a morticians (=company) registered admins via an api get request.
   *
   * @param companyId: Identifies the mortician.
   * @returns the list of admins.
   */
  public loadAdmins(companyId: string): Observable<CompanyAdmin[]> {
    const url = this.baseUrl + `/${companyId}/admins`;
    return this.httpClient.get<CompanyAdmin[]>(url);
  }

  /**
   * Updates a company data via an api put request and set the MemoryBookUserPk
   *
   * @param companyId: Identifies the mortician.
   * @param adminPk: Identifies the admin which should set as memory book user
   * @returns the updated company
   */
  public setMemoryBookUser(companyId: string, adminPk: number): Observable<Company> {
    const url = this.baseUrl + `/${companyId}/admins/${adminPk}/set-mb-user`;
    return this.httpClient.put<Company>(url, {});
  }

  /**
   * Removes the morticians (=company) admin status from a user via an api delete request.
   *
   * @param companyId: Identifies the mortician.
   * @param adminUserPk: Identifies the user.
   * @returns The list of admins as it exists after the deletion.
   */
  public removeAdmin(companyId: string, adminUserPk: number): Observable<CompanyAdmin[]> {
    const url = this.baseUrl + `/${companyId}/admins/${adminUserPk}`;
    return this.httpClient.delete<CompanyAdmin[]>(url);
  }

  /**
   * Loads a list of a morticians (=company) admin invitations list.
   *
   * @param companyId: Identifies the mortician.
   * @returns The list.
   */
  public loadAdminInvitations(companyId: string): Observable<CompanyAdminInvitation[]> {
    const url = this.baseUrl + `/${companyId}/invitations`;
    return this.httpClient.get<CompanyAdminInvitation[]>(url);
  }

  /**
   * Creates a new mortician's (=company) admin invitation.
   *
   * @param companyId: Identifies the mortician.
   * @param emailAddress: The email address, the invitation will be sent to.
   * @returns the updated list of the mortician's admin invitations.
   */
  public addAdminInvitation(companyId: string, emailAddress: string): Observable<CompanyAdminInvitation[]> {
    const url = this.baseUrl + `/${companyId}/invitations`;

    return this.httpClient.post<CompanyAdminInvitation[]>(
      url,
      `"${emailAddress}"`,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      { headers: new HttpHeaders({ 'Content-type': 'application/json' }) }
    );
  }

  /**
   * Removes an invitation as a mortician's (=company) admin.
   *
   * @param companyId: Identifies the mortician.
   * @param hash: Identifies the invitation.
   * @returns the updated list of invitations as the mortician's admin.
   */
  public removeAdminInvitation(companyId: string, hash: string): Observable<CompanyAdminInvitation[]> {
    const url = this.baseUrl + `/${companyId}/invitations/${hash}`;
    return this.httpClient.delete<CompanyAdminInvitation[]>(url);
  }

  /**
   * Renews a specific invitation as a mortician's admin.
   *
   * @param companyId: Identifies the specific mortician.
   * @param hash: Identifies the invitation.
   * @returns the updated list of invitations as the mortician's admin.
   */
  public renewAdminInvitation(companyId: string, hash: string): Observable<CompanyAdminInvitation[]> {
    const url = this.baseUrl + `/${companyId}/invitations/${hash}`;
    return this.httpClient.get<CompanyAdminInvitation[]>(url);
  }

  /**
   * saves the candle automatic settings to server
   * and retrieves a whole company back
   */
  public saveCompanyCandleAutomaticSettings(companyId: string, settings: CandleAutomaticSettings): Observable<Company> {
    const url = this.baseUrl + `/${companyId}/candle-automation`;
    return this.httpClient.put<Company>(url, settings);
  }
}
