import { FormsLibModule } from '@rapid/forms';
import { FilterFormComponent } from './components/filter/filter-form/filter-form.component';
import { MatDialogFooterFctComponent } from './components/mat-dialog/mat-dialog-footer-fct/mat-dialog-footer-fct.component';
import { MatDialogFooterComponent } from './components/mat-dialog/mat-dialog-footer/mat-dialog-footer.component';
import { MatDialogContentComponent } from './components/mat-dialog/mat-dialog-content/mat-dialog-content.component';
import { FilterDialogComponent } from './components/filter/filter-dialog/filter-dialog.component';
import { MatDialogHeaderComponent } from './components/mat-dialog/mat-dialog-header/mat-dialog-header.component';
import { ToolbarComponent } from './components/toolbar/toolbar.component';
import { ToolbarElementComponent } from './components/toolbar-element/toolbar-element.component';
import { WithdrawAdminInviteDialogComponent } from './components/withdraw-admin-invite-dialog/withdraw-admin-invite-dialog.component';
import { SendAdminInviteDialogComponent } from './components/send-admin-invite-dialog/send-admin-invite-dialog.component';
import { StartPageWidgetComponent } from './components/start-page-widget/start-page-widget.component';
import { MpacProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MarkdownModule } from 'ngx-markdown';
import { NG_VALIDATORS } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { QuillModule } from 'ngx-quill';
import { ColorInputComponent } from './components/color-input/color-input.component';
import { UploadFileComponent } from './components/upload-file/upload-file.component';
import { NotImplementedComponent } from './components/not-implemented/not-implemented.component';
import { PageComponent } from './components/page/page.component';
import { PrintFormErrorsForComponent } from './components/print-form-errors-for/print-form-errors-for.component';
import { SearchComponent } from './components/search/search.component';
import { SideMenuComponent } from './components/side-menu/side-menu.component';
import { SpinnerButtonComponent } from './components/spinner-button/spinner-button.component';
import { SpinnerFullComponent } from './components/spinner-full/spinner-full.component';
import { TextEditorComponent } from './components/text-editor/text-editor.component';
import { DisableControlDirective } from './directives/disable-control.directive';
import { PortalLayoutComponent } from './layouts/portal-layout/portal-layout.component';
import { dateInputValidator } from './validators/date-input.validator';
import { validateEurofloristId } from './validators/euroflorist-id.validator';
import { BaseModule } from '../base/base.module';
import { MpacFileUploadComponent } from './components/file-upload/file-upload.component';
import { FileUploadModule } from '@iplab/ngx-file-upload';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import { TermsOfUseComponent } from './components/terms-of-use/terms-of-use.component';
import { ContactFormComponent } from './components/contact-form/contact-form.component';
import { ConfirmLeaveComponent } from './components/confirm-leave/confirm-leave.component';
import { PresetResetComponent } from './components/preset-reset/preset-reset.component';
import { VisibilityBtnComponent } from './components/visibility-btn/visibility-btn.component';
import { PrecautionInfoLandingPageComponent } from '../features/order-portal/pages/precaution-info-landing-page/precaution-info-landing-page.component';
import { FormSaveComponent } from './components/form-save/form-save.component';
import { DynamicTableComponent } from './components/dynamic-table/dynamic-table.component';
import { DynamicTableCellFunctionsComponent } from './components/dynamic-table-cell-functions/dynamic-table-cell-functions.component';
import { PageActionsBarComponent } from './components/page-actions-bar/page-actions-bar.component';
import { PageActionButtonComponent } from './components/page-action-button/page-action-button.component';
import { MenuIconComponent } from './components/menu-icon/menu-icon.component';
import { GlobalWriteProtectionDirective } from './directives/global-write-protection.directive';
import { NotificationBoxComponent } from './components/notification-box/notification-box.component';
import { ErrorOverlayComponent } from './components/error-overlay/error-overlay.component';
import { DialogDisableOverFlowDirective } from './directives/is-dialog-open.directive';
import { PageSectionContentHeaderComponent } from './components/page-sec-cnt-header/page-sec-cnt-header';
import { AngularIbanModule } from 'angular-iban';
import { ClickStopPropagationDirective } from './directives/click-stop-propagation.directive';
import { IconDirective } from './directives/icon.directive';
import { TooltipContainerComponent } from './components/tooltip-container/tooltip-container.component';
import { HideTooltipsOnScrollDirective } from './directives/hide-tooltips-onscroll.directive';
import { ThreeWayToggleComponent } from './components/forms/three-way-toggle/three-way-toggle.component';
import { CustomSwitchComponent } from './components/forms/custom-checkbox/custom-switch.component';
import { TagsInputComponent } from './components/forms/tags-input/tags-input.component';
import { ImageGalleryComponent } from './components/image-gallery/image-gallery.component';
import { NgxGalleryModule } from '@kolkov/ngx-gallery';
import { DialogContainerComponent } from './components/dialog-container/dialog-container.component';
import { DialogContentDirective } from './directives/dialog-content.directive';
import { PdfViewerComponent } from './components/pdf-viewer/pdf-viewer.component';
import { PdfViewerDialogComponent } from './components/pdf-viewer-dialog/pdf-viewer-dialog.component';
import { AnimateWhileLoadingDirective } from './directives/animate-while-loading.directive';
import { EmailSettingsDialogComponent } from './components/email-settings-dialog/email-settings-dialog.component';
import { EmailSettingsFormComponent } from './components/email-settings-form/email-settings-form.component';

@NgModule({
  declarations: [
    ColorInputComponent,
    ConfirmLeaveComponent,
    ContactFormComponent,
    DialogDisableOverFlowDirective,
    DisableControlDirective,
    AnimateWhileLoadingDirective,
    DynamicTableCellFunctionsComponent,
    DynamicTableComponent,
    ErrorOverlayComponent,
    FilterDialogComponent,
    FilterFormComponent,
    FormSaveComponent,
    GlobalWriteProtectionDirective,
    MatDialogContentComponent,
    MatDialogFooterComponent,
    MatDialogFooterFctComponent,
    MatDialogHeaderComponent,
    MenuIconComponent,
    MpacFileUploadComponent,
    MpacProgressBarComponent,
    NotificationBoxComponent,
    NotImplementedComponent,
    PageActionButtonComponent,
    PageActionsBarComponent,
    PageComponent,
    PageSectionContentHeaderComponent,
    PortalLayoutComponent,
    PrecautionInfoLandingPageComponent,
    PresetResetComponent,
    PrintFormErrorsForComponent,
    SendAdminInviteDialogComponent,
    SearchComponent,
    SideMenuComponent,
    SpinnerButtonComponent,
    SpinnerFullComponent,
    StartPageWidgetComponent,
    TermsOfUseComponent,
    TextEditorComponent,
    ToolbarComponent,
    ToolbarElementComponent,
    UploadFileComponent,
    VisibilityBtnComponent,
    WithdrawAdminInviteDialogComponent,
    ClickStopPropagationDirective,
    IconDirective,
    HideTooltipsOnScrollDirective,
    DialogContentDirective,
    ThreeWayToggleComponent,
    TooltipContainerComponent,
    CustomSwitchComponent,
    ImageGalleryComponent,
    DialogContainerComponent,
    TagsInputComponent,
    PdfViewerComponent,
    PdfViewerDialogComponent,
    EmailSettingsDialogComponent,
    EmailSettingsFormComponent
  ],
  exports: [
    ColorInputComponent,
    ConfirmLeaveComponent,
    ContactFormComponent,
    DialogDisableOverFlowDirective,
    DisableControlDirective,
    IconDirective,
    HideTooltipsOnScrollDirective,
    DialogContentDirective,
    AnimateWhileLoadingDirective,
    DynamicTableCellFunctionsComponent,
    DynamicTableComponent,
    ErrorOverlayComponent,
    FilterDialogComponent,
    FilterFormComponent,
    FormSaveComponent,
    GlobalWriteProtectionDirective,
    MatDialogContentComponent,
    MatDialogFooterComponent,
    MatDialogFooterFctComponent,
    MatDialogHeaderComponent,
    MenuIconComponent,
    MpacFileUploadComponent,
    MpacProgressBarComponent,
    NotificationBoxComponent,
    NotImplementedComponent,
    PageActionButtonComponent,
    PageActionsBarComponent,
    PageComponent,
    PageSectionContentHeaderComponent,
    PortalLayoutComponent,
    PrecautionInfoLandingPageComponent,
    PresetResetComponent,
    PrintFormErrorsForComponent,
    SearchComponent,
    SideMenuComponent,
    SpinnerButtonComponent,
    SpinnerFullComponent,
    StartPageWidgetComponent,
    TermsOfUseComponent,
    TextEditorComponent,
    ToolbarComponent,
    ToolbarElementComponent,
    UploadFileComponent,
    VisibilityBtnComponent,
    ClickStopPropagationDirective,
    FormsLibModule,
    ThreeWayToggleComponent,
    CustomSwitchComponent,
    TooltipContainerComponent,
    ImageGalleryComponent,
    DialogContainerComponent,
    TagsInputComponent,
    PdfViewerComponent,
    PdfViewerDialogComponent
  ],
  providers: [
    { provide: NG_VALIDATORS, useValue: validateEurofloristId, multi: true },
    { provide: NG_VALIDATORS, useValue: dateInputValidator, multi: true }
  ],
  imports: [
    FormsLibModule,
    BaseModule,
    CommonModule,
    FileUploadModule,
    InfiniteScrollModule,
    NgxGalleryModule,
    PdfJsViewerModule,
    MarkdownModule.forChild(),
    QuillModule.forRoot(),
    AngularIbanModule,
    RouterModule
  ]
})
export class SharedModule {}
