import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from '@angular/router';

import { select, Store } from '@ngrx/store';

import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { UserLevel } from '../../shared/models/user-level.enum';
import { ManagedOrder, User } from '../models/user';
import { loginStoredUserAction } from '../store/user/user.actions';
import { selectUser } from '../store/user/user.reducer';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationGuard implements CanActivateChild {
  /**
   * Indicates, whether the browser storage has been checked for a (formerly logged in) user.
   */
  private hasStoredUserBeenChecked = false;

  /**
   * The url from which the user was redirected to the login route. Backing field for @{returnUrl}.
   */
  private returnUrlField: string;

  constructor(private router: Router, private store$: Store<any>) {}

  /**
   * Getter for @{returnUrlField}
   */
  public get returnUrl(): string {
    return this.returnUrlField;
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (!this.hasStoredUserBeenChecked) {
      this.store$.dispatch(loginStoredUserAction());
      this.hasStoredUserBeenChecked = true;
    }

    return this.checkLogin(state);
  }

  private checkLogin(state: RouterStateSnapshot): Observable<boolean> {
    return this.store$.pipe(
      select(selectUser),
      filter((u: User) => !!u),
      map((user: User) => {
        this.returnUrlField = state.url;
        if (user.level === UserLevel.Rapid) {
          this.returnUrlField = undefined;
          return true;
        }

        if (user.level === UserLevel.Mortician) {
          if (this.verifyMorticianUrl(state.url, user.morticianIds)) {
            this.returnUrlField = undefined;
            return true;
          }

          if (state.url.startsWith('/gedenkseiten')) {
            this.returnUrlField = undefined;

            // ToDo: validate mortician with OWN order
            return true;
          }
        }

        if (user.level === UserLevel.PageAdmin) {
          this.returnUrlField = undefined;

          // if any of the user's managedOrders is contained in the url, the admin may proceed
          if (
            user.managedOrders.find((managedOrder: ManagedOrder) =>
              state.url.startsWith('/gedenkseiten/' + managedOrder.id)
            )
          ) {
            return true;
          }
        }

        this.router.navigate(['/login']);
        return false;
      })
    );
  }

  /**
   * Verifies, whether a url is the mortician-portal route's url to one of a list of morticians.
   *
   * @param url: The url.
   * @param morticianIds: The list of morticians.
   */
  private verifyMorticianUrl(url: string, morticianIds: string[]): boolean {
    if (!morticianIds) {
      return false;
    }

    if (morticianIds.length > 1 && url.startsWith('/portale/')) {
      return true;
    }

    return !!morticianIds.find((id) => url.startsWith('/portal/' + id));
  }
}
