<ng-container *ngIf="breadcrumbs && breadcrumbs.length">
  <!-- Mortician or Rapid admin view from within an order-->
  <ng-container *ngIf="breadcrumbs.length > 1">
    <ng-container *ngFor="let entry of breadcrumbs; let i = index">
      <ng-container *ngIf="i < breadcrumbs.length - 1">
        <a (click)="navigateByBreadcrumb(entry)" class="cursor-pointer mr-2">
          {{ entry.displayText }}
        </a>
        &gt;
      </ng-container>

      <ng-container *ngIf="i === breadcrumbs.length - 1">
        <ng-container
          *ngTemplateOutlet="lastEntry; context: { text: entry.displayText, multiOrdersActive: shouldRenderPopup }">
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>

  <!-- Company Portal Base View / Order menu / Customer view-->
  <ng-container *ngIf="breadcrumbs.length < 2">
    <ng-container
      *ngTemplateOutlet="
        lastEntry;
        context: { text: breadcrumbs[0].displayText, multiOrdersActive: shouldRenderPopup }
      ">
    </ng-container>
  </ng-container>
</ng-container>

<ng-template #lastEntry let-text="text" let-multiOrdersActive="multiOrdersActive">
  <!-- Multi Orders: Customer only-->
  <ng-container *ngIf="multiOrdersActive" dropdown (isOpenChange)="dropDownOpenStatusChanged($event)">
    <button
      id="app-breadcrumb-order-dropdown-button"
      class="btn btn-link"
      dropdownToggle
      aria-controls="app-breadcrumb-order-dropdown">
      <span class="btn-txt">{{ text }}</span>
      <span class="btn-icon" [class.btn-icon--open]="dropdownOpen"></span>
    </button>
    <div
      id="app-breadcrumb-order-dropdown"
      [class.d-none]="!showOrderSwitch"
      *dropdownMenu
      class="dropdown-menu shadow-lg show"
      role="menu"
      aria-labelledby="app-breadcrumb-order-dropdown-button">
      <ul class="list-unstyled p-0 m-0">
        <li (click)="switchOrder(order.id)" class="p-1 pl-3 pr-3 cursor-pointer" *ngFor="let order of managedOrders">
          {{ order.name }}
        </li>
      </ul>
    </div>
  </ng-container>

  <!-- Single Order-->
  <ng-container *ngIf="!multiOrdersActive">
    <span>{{ text }}</span>
  </ng-container>
</ng-template>
