export class ConfirmDialogModel {
  constructor(
    public title: string,
    public message: string,
    public confirmButtonText: string,
    public confirmButtonIcn?: string,
    public dismissButtonIcn?: string,
    public dismissButtonText?: string,
    public confirmButtonId?: string
  ) {}
}
