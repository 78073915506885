import {
  Component,
  ContentChild,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  TemplateRef,
  ViewEncapsulation
} from '@angular/core';

@Component({
  selector: 'mpac-toolbar-element',
  templateUrl: './toolbar-element.component.html',
  styleUrls: ['./toolbar-element.component.variables.scss', './toolbar-element.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ToolbarElementComponent implements OnInit, OnChanges {
  private static cssBaseClass = 'mpac-toolbar-el';

  private static cssBaseClassAlignLeft = 'mpac-toolbar-el--left';

  @Input()
  public alignLeft: boolean;

  @ContentChild(TemplateRef) toolbarElRef;

  @HostBinding('class') hostElementClass = ToolbarElementComponent.cssBaseClass;

  constructor() {}

  public ngOnInit(): void {
    this.setHostClassBinding();
  }

  public ngOnChanges(): void {
    this.setHostClassBinding();
  }

  protected setHostClassBinding(): void {
    this.hostElementClass = `${ToolbarElementComponent.cssBaseClass}${
      this.alignLeft ? ' ' + ToolbarElementComponent.cssBaseClassAlignLeft : ''
    }`;
  }
}
