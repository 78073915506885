<form class="mpac-filter-form" *ngIf="form && !!filters" [formGroup]="form">
  <fieldset class="mpac-filter-form__wrap">
    <!-- Filter fields -->
    <ng-container *ngFor="let filterItem of filters; let i = index">
      <div class="mpac-filter-form__row">
        <div class="mpac-filter-form__field">
          <label class="mpac-filter-form__field-label" [attr.for]="getItemKey(filterItem, i)">{{
            filterItem.displayName
          }}</label>

          <!-- List -->
          <ng-container *ngIf="isList(filterItem)">
            <ng-container *ngTemplateOutlet="list; context: { filterItem: filterItem, pIndex: i }"></ng-container>
          </ng-container>

          <!-- Checkbox -->
          <ng-container *ngIf="isCheckbox(filterItem)">
            <ng-container *ngTemplateOutlet="checkbox; context: { filterItem: filterItem, pIndex: i }"></ng-container>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </fieldset>
</form>

<!-- List -->
<ng-template #list let-filterItem="filterItem" let-pIndex="pIndex">
  <!-- Multi -->
  <ng-container *ngIf="filterItem.multi" [formGroup]="form">
    <select
      class="form-control custom-select"
      [formControlName]="getItemKey(filterItem, pIndex)"
      [id]="getItemKey(filterItem, pIndex)"
      multiple>
      <option *ngFor="let value of filterItem.displayValues" [value]="value.value">
        {{ value.displayName }}
      </option>
    </select>
  </ng-container>

  <!-- Single -->
  <ng-container *ngIf="!filterItem.multi" [formGroup]="form">
    <select
      class="form-control custom-select"
      [formControlName]="getItemKey(filterItem, pIndex)"
      [id]="getItemKey(filterItem, pIndex)">
      <option *ngFor="let value of filterItem.displayValues" [value]="value.value">
        {{ value.displayName }}
      </option>
    </select>
  </ng-container>
</ng-template>

<!-- Checkbox -->
<ng-template #checkbox let-filterItem="filterItem" let-pIndex="pIndex">
  <!-- Multi -->
  <ng-container *ngIf="filterItem.multi" [formGroup]="form">
    <div
      *ngFor="let value of filterItem.displayValues; let i = index"
      class="custom-control custom-checkbox"
      [formArrayName]="getItemKey(filterItem, pIndex)">
      <input
        [id]="getItemKey(filterItem, pIndex) + '_' + value.value"
        [formControl]="getFormControlNested(getItemKey(filterItem, pIndex) + '.' + i)"
        type="checkbox"
        class="custom-control-input"
        [formControlName]="i" />
      <label class="custom-control-label" [for]="getItemKey(filterItem, pIndex) + '_' + value.value">{{
        value.displayName
      }}</label>
    </div>
  </ng-container>

  <!-- Single -->
  <div *ngIf="!filterItem.multi" class="form-check-inline" [formGroup]="form">
    <ng-container *ngFor="let value of filterItem.displayValues; let i = index">
      <div class="custom-control custom-checkbox" [formGroup]="form" [formArrayName]="getItemKey(filterItem, pIndex)">
        <input
          class="custom-control-input"
          type="radio"
          [formControl]="getFormControlNested(getItemKey(filterItem, pIndex) + '.' + i)"
          [id]="getItemKey(filterItem, pIndex) + '_' + value.value"
          [formControlName]="i" />
        <label class="custom-control-label" [for]="getItemKey(filterItem, pIndex) + '_' + value.value">
          {{ value.displayName }}</label
        >
      </div>
    </ng-container>
  </div>
</ng-template>
