import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControlDirective, NgControl } from '@angular/forms';
import { DesctructableComponent } from '@rapid/forms';
import { takeUntil } from 'rxjs/operators';

/**
 * A text input for CSS colors with an attached color picker input
 */
@Component({
  selector: 'mpac-color-input',
  templateUrl: './color-input.component.html',
  styleUrls: ['./color-input.component.scss']
})
export class ColorInputComponent extends DesctructableComponent implements ControlValueAccessor, OnInit {
  @ViewChild(FormControlDirective, { static: true }) formControlDirective: FormControlDirective;

  @Input() labelTextKey: string;

  /**
   * Name prefix for naming the actual HTML input fields
   *
   * @param namePrefix The name prefix
   */
  @Input() namePrefix = '';

  constructor(public ngControl: NgControl) {
    super();
    this.ngControl.valueAccessor = this;
  }

  public get htmlIdAttributeValue(): string {
    return [this.namePrefix, '__color-picker'].join();
  }

  public ngOnInit(): void {
    this.initCustomValidation();
  }

  public colorStringValueChanged(event: any): void {
    this.ngControl.control.patchValue(event.target.value);
  }

  public markControlAsDirty(): void {
    this.ngControl.control.markAsDirty();
  }

  public writeValue(value: any): void {
    this.formControlDirective.valueAccessor.writeValue(value);
  }

  public registerOnChange(fn: any): void {
    this.formControlDirective.valueAccessor.registerOnChange(fn);
  }

  public registerOnTouched(fn: any): void {
    this.formControlDirective.valueAccessor.registerOnTouched(fn);
  }

  public setDisabledState?(isDisabled: boolean): void {
    this.formControlDirective.valueAccessor.setDisabledState(isDisabled);
  }

  private initCustomValidation(): void {
    this.ngControl.control.valueChanges.pipe(takeUntil(this.shutdown$)).subscribe((value: string) => {
      if (!!value && this.isColorInvalid(value)) {
        this.ngControl.control.setErrors({ invalidDatepickerValue: true });
      }
    });
  }

  private isColorInvalid(colorValue: string): boolean {
    const s = new Option().style;
    s.color = (colorValue || '').toLowerCase();
    return s.color === '';
  }
}
