<ng-container *ngFor="let error of getFormValidationErrors()">
  <div class="mpac-error-ovl__fld">
    <a class="mpac-error-ovl__fld-lbl" href="" (click)="focus($event, error)">
      {{ 'forms.labels.shared.' + lastErrorSegment(error) | translate }}:
    </a>

    <div class="mpac-error-ovl__fld-cnt">
      <mpac-print-form-errors-for class="mpac-error-ovl__fld-cnt-errs" [control]="gfcn(error)">
      </mpac-print-form-errors-for>
    </div>
    <hr class="mpac-error-ovl__fld-hr" />
  </div>
</ng-container>
