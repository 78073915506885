import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { slideUpDown } from '@core/animations/slide-up-down.animation';
import { TranslateService } from '@ngx-translate/core';
import { Subject, Subscription } from 'rxjs';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { defaultPasswordValidations, PasswordValidation } from '../../models/password-validation';
import { takeUntil } from 'rxjs/operators';
import { UserRegistration } from '../../models/user-registration';
import { SettingBasedOnHostnameService } from '../../services/setting-based-on-hostname.service';
import { CmsService, ContentfulEntriesService, FeatureRichText } from '@rapid/cms-lib';
import { CmsContentTypes } from 'src/app/shared/features/contentful/enums/cms-content-types.enum';
import { ValidatorsService } from '@rapid/forms';

const emptyRegistrationData: UserRegistration = {
  userName: '',
  password: '',
  passwordRepetition: '',
  termsAccepted: false
};

@Component({
  selector: 'mpac-registration-form',
  templateUrl: './registration-form.component.html',
  styleUrls: ['./registration-form.component.variables.scss', './registration-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [slideUpDown]
})
export class RegistrationFormComponent implements OnInit, OnDestroy, AfterViewInit {
  private static passMinlength = 8;

  /**
   * The data to prefill the fom with.
   */
  @Input()
  public registrationData: UserRegistration = emptyRegistrationData;

  /**
   * Indicates, whether the registration is in progress (on the server).
   */
  @Input()
  public isRegistrationInProgress = false;

  @Input()
  public isUserNameDisabled = false;

  @Input()
  public privacyPolicyUrl: string;

  /**
   * Signals the registration data to process to the caller.
   */
  @Output()
  public dataSubmitted: EventEmitter<UserRegistration> = new EventEmitter<UserRegistration>();

  /**
   * A structure to hold the data for the user to be registered.
   */
  public registrationForm: UntypedFormGroup;

  /**
   * logo path base on domain name
   */
  public logoPath: string;

  public formGroup: UntypedFormGroup;

  public introTextHTML: string;

  public toolTipHTML: string;

  public passHelpText: string;

  public showPassword = false;

  public showPasswordRules = false;

  public showPasswordRepetitionHint = false;

  public showPasswordRepetitionField = false;

  public showCheckIcon: boolean;

  public showCheckIconRep: boolean;

  public passwordValidations: PasswordValidation[] = defaultPasswordValidations;

  public subscriptions: Subscription[] = [];

  public highlight = false;

  public showPassRepError = false;

  public passStrength: number;

  public isServiceCenter: boolean;

  private shutdown$ = new Subject<void>();

  private readonly introTxtCustomerId: string;

  private readonly introTxtServiceId: string;

  private readonly passHelpTextId: string;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private settingBasedOnHostnameService: SettingBasedOnHostnameService,
    private translate: TranslateService,
    protected cmsService: CmsService,
    private contentfulEntriesService: ContentfulEntriesService
  ) {
    this.introTxtCustomerId = this.contentfulEntriesService.instant('pages.registrationPage.client.introText');
    this.introTxtServiceId = this.contentfulEntriesService.instant('pages.registrationPage.mortician.introText');
    this.passHelpTextId = this.contentfulEntriesService.instant('pages.registrationPage.passHelpText');
  }

  public get invalidPassword(): boolean {
    const ctrl = this.getFormControls.password;
    return ctrl.invalid && ctrl.touched && ctrl.errors.pattern;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public get getFormControls() {
    if (!this.registrationForm) {
      return;
    }
    return this.registrationForm.controls;
  }

  public get allValidationsPass(): boolean {
    if (!this.registrationForm) {
      return;
    }
    return this.passwordValidations.filter((v) => !v.passesFor(this.getFormControls.password.value as string)).length === 0;
  }

  public get passwordRepetitionFeedback(): string {
    const key = `forms.labels.userRegistration.passwordValidation.${
      this.passwordsMatch ? 'PasswordsMatch' : 'PasswordsDontMatch'
    }`;
    return this.translate.instant(key);
  }

  public get passwordsMatch(): boolean {
    return this.getFormControls.passwordRepetition.value === this.getFormControls.password.value;
  }

  public ngOnInit(): void {
    this.isServiceCenter = this.settingBasedOnHostnameService.hostnameServiceCenter() ? true : false;
    this.loadContent();
    this.updateHelpText();
    this.registrationForm = this.createForm();
    this.registrationForm.setValue({ ...emptyRegistrationData, ...this.registrationData });
    if (this.isUserNameDisabled) {
      this.registrationForm.get('userName').disable();
    }
    this.logoPath = this.settingBasedOnHostnameService.getLogoPathByHostname();
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  public ngAfterViewInit(): void {
    this.subscribeToPasswordFieldChanges();
    this.subscribeToPasswordRepetitionFieldChanges();
  }

  /**
   * Called from the passwordMeter <mat-password-strength> on password field changes
   * updates passStrength to the value of password strength, calls updateHelpText()
   * in order to update the hint text accordingly;
   */
  public onStrengthChanged(strength: number): void {
    this.passStrength = strength;
    this.updateHelpText();
  }

  /**
   * Update the hint text under; defaults to "Mindestens 8 Zeichen"
   */
  public updateHelpText(): void {
    let key = 'forms.labels.userRegistration.passwordHelpText.default';
    if (
      !!this.registrationForm &&
      this.getFormControls.password.value.length < RegistrationFormComponent.passMinlength
    ) {
      this.passHelpText = this.translate.instant('forms.labels.userRegistration.passwordHelpText.minimum');
      return;
    }
    if (this.passStrength === 20 || this.passStrength === 40) {
      key = 'forms.labels.userRegistration.passwordHelpText.weak';
    }
    if (this.passStrength === 60) {
      key = 'forms.labels.userRegistration.passwordHelpText.medium';
    }
    if (this.passStrength === 80) {
      key = 'forms.labels.userRegistration.passwordHelpText.good';
    }
    if (this.passStrength === 100) {
      key = 'forms.labels.userRegistration.passwordHelpText.strong';
    }
    this.passHelpText = this.translate.instant(key);
  }

  public slideUpDownValueFor(showError: boolean): string {
    return showError ? ':enter' : ':leave';
  }

  public showPasswordToggle(): void {
    this.showPassword = !this.showPassword;
  }

  /**
   * show/hide password repetition field; the field is only shown if the password is valid (more than 8 chars)
   */
  public toggleShowPasswordRepetition(): void {
    if (this.getFormControls.password.valid) {
      this.showPasswordRepetitionField = true;
    }
    this.showPasswordRepetitionField = false;
  }

  /**
   * Checks the input for validity and submits the entered data.
   */
  public submitForm(): void {
    if (!this.registrationForm.valid) {
      this.registrationForm.markAllAsTouched();
      return;
    }
    const data: UserRegistration = this.registrationForm.getRawValue() as UserRegistration;
    this.dataSubmitted.emit(data);
  }

  public onPassRepBlur(): void {
    this.showPassRepError = true;
  }

  public onPassBlur(): void {
    if (
      this.getFormControls.password.value.length < RegistrationFormComponent.passMinlength &&
      this.getFormControls.password.value.length > 0
    ) {
      this.getFormControls.password.setErrors({ minimumStatic: { value: RegistrationFormComponent.passMinlength } });
    }
  }

  /**
   * Retrieves the intro text + password helpText (the tooltip) from the cmsSerive
   */
  protected loadContent(): void {
    const introTxtId = this.isServiceCenter ? this.introTxtServiceId : this.introTxtCustomerId;
    this.cmsService
      .getFieldById([introTxtId, this.passHelpTextId])
      .pipe(
        takeUntil(this.shutdown$),
        filter((res) => !!res)
      )
      .subscribe((cmsContent: Map<string, Map<string, FeatureRichText>>) => {
        this.introTextHTML = cmsContent
          ?.get(CmsContentTypes.ServiceCenterFeatureRichText)
          ?.get(introTxtId)?.displayText;
        this.toolTipHTML = cmsContent
          ?.get(CmsContentTypes.ServiceCenterFeatureRichText)
          ?.get(this.passHelpTextId)?.displayText;
      });
  }

  /**
   * Creates the form for the user input.
   */
  private createForm(): UntypedFormGroup {
    return this.formBuilder.group(
      {
        userName: ['', [Validators.required, Validators.maxLength(56)]],
        password: ['', [Validators.required]],
        passwordRepetition: ['', { validators: [Validators.required] }],
        termsAccepted: [false, [Validators.requiredTrue]]
      },
      { validators: ValidatorsService.matchFields('password', 'passwordRepetition') }
    );
  }

  private updateUIonPassMatch(): void {
    if (this.passwordsMatch) {
      this.showCheckIconRep = true;
      this.highlight = true;
    } else {
      this.showCheckIconRep = false;
      this.highlight = false;
    }
  }

  private subscribeToPasswordRepetitionFieldChanges(): void {
    if (!this.getFormControls.passwordRepetition) {
      return;
    }

    this.getFormControls.passwordRepetition.valueChanges.pipe(distinctUntilChanged()).subscribe(() => {
      this.updateUIonPassMatch();
    });
  }

  private subscribeToPasswordFieldChanges(): void {
    if (!this.getFormControls.password) {
      return;
    }

    this.getFormControls.password.valueChanges.pipe(distinctUntilChanged()).subscribe(() => {
      if (this.getFormControls.password.value.length > RegistrationFormComponent.passMinlength - 1) {
        this.showPasswordRepetitionField = true;
        this.showCheckIcon = true;
        this.updateUIonPassMatch();
      } else {
        this.showPasswordRepetitionField = false;
        this.showCheckIcon = false;
        this.passHelpText = this.translate.instant('forms.labels.userRegistration.passwordHelpText.minimum');
      }
    });
  }
}
