import { AuthConfig } from 'angular-oauth2-oidc';
import { AppAuthConfig } from './auth-config';

import { Injectable } from '@angular/core';
import { SettingsService } from '../config/settings.service';

@Injectable({ providedIn: 'root' })
export class AuthConfigService {
  private _authConfig: AppAuthConfig;

  constructor(private settingsService: SettingsService) {}

  get authConfig(): AuthConfig {
    if (!this._authConfig) {
      this._authConfig = new AppAuthConfig(this.settingsService);
    }
    return this._authConfig.authConfig;
  }
}
