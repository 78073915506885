<ng-container *ngIf="cellFunctions && cellFunctions.length">
  <!-- Some cell functions aren't multi -->
  <ng-container *ngFor="let fct of cellFunctions">
    <span class="mpac-cell-fcts__fct" [title]="getTitleText(fct)" *ngIf="shouldRender(fct)">
      <!-- A single function definition that has multiple data values -->
      <ng-container *ngIf="fct.multi">
        <ng-container *ngFor="let data of getDataArray(fct); let i = index">
          <ng-container *ngTemplateOutlet="renderCellFunction; context: { a_fct: fct, a_dataIndex: i }"> </ng-container>
        </ng-container>
      </ng-container>

      <!-- A single function only -->
      <ng-container *ngIf="!fct.multi">
        <ng-container *ngTemplateOutlet="renderCellFunction; context: { a_fct: fct, a_dataIndex: undefined }">
        </ng-container>
      </ng-container>
    </span>
  </ng-container>
</ng-container>

<!-- Cell function template -->
<ng-template #renderCellFunction let-fct="a_fct" let-dataIndex="a_dataIndex">
  <!-- Render icon with functionality -->
  <ng-container *ngIf="isCallSet(fct)">
    <ng-container *ngIf="fct.checkbox">
      <div class="custom-control custom-checkbox" (click)="checkboxClicked($event, fct, dataIndex)">
        <input *ngIf="getValue(fct, dataIndex) === true" type="checkbox" class="custom-control-input" checked />
        <input *ngIf="getValue(fct, dataIndex) !== true" type="checkbox" class="custom-control-input" />
        <label class="custom-control-label"></label>
      </div>
    </ng-container>
    <ng-container *ngIf="!fct.checkbox">
      <button class="btn btn-link mpac-cell-fcts__fct-btn" (click)="apply($event, fct, dataIndex)">
        <i
          [class]="'mpac-cell-fcts__fct-btn-ico ' + getIconName(fct, dataIndex)"
          [class.fal]="fct.iconType === iconTypeEnum.Light"
          [class.fas]="fct.iconType === iconTypeEnum.Solid"
          [class.far]="fct.iconType === iconTypeEnum.Pro"
          [class.fa]="fct.iconType === iconTypeEnum.Regular"></i>
      </button>
    </ng-container>
  </ng-container>
  <!-- Render icon only -->
  <ng-container *ngIf="!isCallSet(fct)">
    <ng-container *ngIf="fct.checkbox">
      <div class="custom-control custom-checkbox">
        <input
          disabled
          *ngIf="getValue(fct, dataIndex) === true"
          type="checkbox"
          class="custom-control-input"
          checked />
        <input disabled *ngIf="getValue(fct, dataIndex) !== true" type="checkbox" class="custom-control-input" />
        <label class="custom-control-label"></label>
      </div>
    </ng-container>
    <ng-container *ngIf="!fct.checkbox">
      <i
        [class]="'mpac-cell-fcts__fct-ico ' + getIconName(fct, dataIndex)"
        [class.fal]="fct.iconType === iconTypeEnum.Light"
        [class.fas]="fct.iconType === iconTypeEnum.Solid"
        [class.fa]="fct.iconType === iconTypeEnum.Regular"
        [class.far]="fct.iconType === iconTypeEnum.Pro"></i>
    </ng-container>
  </ng-container>
</ng-template>
