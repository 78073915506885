export enum UserState {
  /**
   * The user is unknown (and his email address is unconfirmed).
   */
  Unknown = 0,

  /**
   * The user is known, but his email address has not been confirmed yet.
   */
  Unconfirmed = 1,

  /**
   * The user is known and his email address is confirmed.
   */
  Confirmed = 2
}

export interface CheckedUser {
  userName: string;
  emailAddress: string;
  userState: UserState;
}
