/**
 * Utility class for DOM Related stuff.
 */
export class DomUtil {
  /**
   * Returns whether or not the child is a descendant of the parent or the same.
   */
  public static isDescendantOrSelf(parent: Element, child: Element | Node): boolean {
    let node = child;
    while (node != null) {
      if (DomUtil.isSelf(parent, node)) {
        return true;
      }
      node = node.parentNode;
    }
    return false;
  }

  /**
   * Returns whether or not the child is a descendant of the parent.
   */
  public static isDescendant(parent: Element, child: Element | Node): boolean {
    if (DomUtil.isSelf(parent, child)) {
      return false;
    }
    return DomUtil.isDescendantOrSelf(parent, child);
  }

  /**
   * Returns whether or not the given elements are the same.
   */
  public static isSelf(parent: Element, child: Element | Node): boolean {
    return parent === child;
  }

  /**
   * Returns the original target for the given DOMEvent.
   *
   * @param event DOMEvent
   * @return Target node or null.
   */
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public static originalEventTarget(event): Element {
    return event ? event.currentTarget || event.target : null;
  }

  /**
   * Checks whether or not the given element is within viewport.
   *
   * @param element Element to check for.
   * @param [rect=null] Elements clientRect or null.
   * @return Whether or not the element is in viewport.
   */
  public static isInViewport(element: Element, rect: DOMRect = null): boolean {
    const bounding = rect ? rect : element.getBoundingClientRect();
    return (
      bounding.top >= 0 &&
      bounding.left >= 0 &&
      bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }

  /**
   * Scrolls to the given element.
   *
   * @param querySelector Element eselector.
   * @param [checkViewport=true] Only scrolls to an element if its not in viewport.
   * @param [offsetBy=20] Additional (to the height of the element) offset for scrolling.
   */
  public static scrollToElement(querySelector: string, checkViewPort: boolean = true, offsetBy = 20): void {
    const element = document.querySelector(querySelector);
    const rect = element.getBoundingClientRect();

    if (element) {
      if (checkViewPort && DomUtil.isInViewport(element)) {
        return;
      }
      const scrollingParent = DomUtil.getNextScrollableParent(element);

      let offset = rect.height;
      if (rect.y >= scrollingParent.scrollTop) {
        // down
        offset = offset + offsetBy;
      } else {
        offset = (offset + offsetBy) * -1;
      }
      scrollingParent.scrollTo(scrollingParent.scrollLeft, rect.y - offset);
    }
  }

  /**
   * Gets the next visible and scrollable parent element.
   *
   * @param node Element to get the parent for.
   * @return Either the scrollable element or null.
   */
  public static getNextScrollableParent(node: Element): Element | null {
    if (!node) {
      return;
    }
    const overflowYVal = window.getComputedStyle(node).overflowY;
    const canOverflow = !(overflowYVal.includes('hidden') || overflowYVal.includes('visible'));

    if (canOverflow && node.scrollHeight >= node.clientHeight) {
      return node;
    }

    return DomUtil.getNextScrollableParent(node.parentElement) || document.body;
  }

  /**
   * Creates a file download link.
   *
   * @param fileName Name of the file including extension
   * @param blob The Source blob.
   * @param [autoTrigger=true] Whether or not to automatically trigger the download.
   * @return The created element.
   */
  public static createFileDownloadLink(fileName: string, blob: Blob, autoTrigger: boolean = true): Element {
    const downloadLink = document.createElement('a');
    downloadLink.target = '_blank';
    downloadLink.href = window.URL.createObjectURL(blob);
    downloadLink.download = fileName;
    if (autoTrigger) {
      downloadLink.click();
    }
    return downloadLink;
  }
}
