<div class="mpac-visibility-btn__wrap" [formGroup]="formGroup">
  <!-- Input element to make Reactive forms happy -->
  <ng-container *ngIf="disabled">
    <input type="hidden" [value]="this.value" [formControlName]="controlName" disabled="true" />
  </ng-container>
  <ng-container *ngIf="!disabled">
    <input type="hidden" [value]="this.value" [formControlName]="controlName" />
  </ng-container>

  <!-- Button -->
  <button
    class="btn mpac-visibility-btn__btn"
    [disabled]="disabled"
    (click)="changeValue(!value)"
    [title]="
      value
        ? translateService.instant('order.visibility.public')
        : translateService.instant('order.visibility.adminOnly')
    "
    btnCheckbox
    role="button"
    type="button"
    tabindex="0"></button>
</div>
