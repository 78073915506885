import { Component, HostBinding, Input, ViewEncapsulation, OnInit, TemplateRef } from '@angular/core';

@Component({
  selector: 'mpac-mat-dialog-header',
  templateUrl: './mat-dialog-header.component.html',
  encapsulation: ViewEncapsulation.None
})
export class MatDialogHeaderComponent implements OnInit {
  public static baseClass = 'mpac-mat-dlg__hdr modal-header';

  @HostBinding('class') hostClassBinding: string;

  @Input()
  public customClass = '';

  @Input()
  public headline: string;

  @Input()
  public translateHeadline = false;

  @Input()
  headerTemplate: TemplateRef<any>;

  constructor() {}

  public ngOnInit(): void {
    this.setHostClassBinding();
  }

  protected setHostClassBinding(): void {
    this.hostClassBinding = `${MatDialogHeaderComponent.baseClass}${
      this.customClass ? ' ' + this.customClass.trim() : ''
    }`;
  }
}
