import { createSelector } from '@ngrx/store';

import { UserLevel } from '../../../shared/models/user-level.enum';

import { User } from '../../models/user';

import { CoreState, coreStateFeatureSelector } from '../common';

import {
  loginStoredUserSuccessAction,
  loginUserSuccessAction,
  logoutUserAction,
  updateUserInfoAction,
  UserAction
} from './user.actions';

export const initialUserState: User = {
  name: undefined,
  emailAddress: undefined,
  level: UserLevel.None,
  morticianIds: [],
  managedOrders: [],
  accessToken: undefined
};

export const userReducer = (state: User = initialUserState, action: UserAction): User => {
  switch (action.type) {
    case loginUserSuccessAction.type: {
      return { ...action.user };
    }

    case loginStoredUserSuccessAction.type: {
      return { ...action.user };
    }

    case updateUserInfoAction.type: {
      return { ...action.user };
    }

    case logoutUserAction.type: {
      return initialUserState;
    }
  }

  return state;
};

export const selectUser = createSelector(coreStateFeatureSelector, (state: CoreState) =>
  state ? state.user : undefined
);

export const selectUserLevel = createSelector(
  coreStateFeatureSelector,
  (state: CoreState) => state?.user?.level || initialUserState.level
);

export const selectUserName = createSelector(
  coreStateFeatureSelector,
  (state: CoreState) => state?.user?.name || initialUserState.name
);

export const selectUsersManagedOrders = createSelector(
  coreStateFeatureSelector,
  (state: CoreState) => state?.user?.managedOrders || initialUserState.managedOrders
);
