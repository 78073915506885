import { Component, HostBinding, Input, ViewEncapsulation, OnInit, ContentChild, TemplateRef } from '@angular/core';

@Component({
  selector: 'mpac-mat-dialog-content',
  templateUrl: './mat-dialog-content.component.html',
  encapsulation: ViewEncapsulation.None
})
export class MatDialogContentComponent implements OnInit {
  public static baseClass = 'mpac-mat-dlg__cnt';

  @HostBinding('class') hostClassBinding: string;

  @ContentChild(TemplateRef) contentElementRef;

  @Input()
  public customClass = '';

  constructor() {}

  public ngOnInit(): void {
    this.setHostClassBinding();
  }

  protected setHostClassBinding(): void {
    this.hostClassBinding = `${MatDialogContentComponent.baseClass}${
      this.customClass ? ' ' + this.customClass.trim() : ''
    }`;
  }
}
