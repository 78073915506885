import { Component, HostBinding, Input, ViewEncapsulation, OnInit, ContentChild, TemplateRef } from '@angular/core';

@Component({
  selector: 'mpac-mat-dialog-footer-fct',
  templateUrl: './mat-dialog-footer-fct.component.html',
  encapsulation: ViewEncapsulation.None
})
export class MatDialogFooterFctComponent implements OnInit {
  public static baseClass = 'mpac-mat-dlg__ftr-fct';

  public static baseClassLeft = 'mpac-mat-dlg__ftr-fct--left';

  @HostBinding('class') hostClassBinding: string;

  @ContentChild(TemplateRef) footerElsListRef;

  @Input()
  public alignLeft = false;

  @Input()
  public customClass = '';

  constructor() {}

  public ngOnInit(): void {
    this.setHostClassBinding();
  }

  protected setHostClassBinding(): void {
    this.hostClassBinding = `${MatDialogFooterFctComponent.baseClass}${
      this.customClass ? ' ' + this.customClass.trim() : ''
    }${this.alignLeft ? ' ' + MatDialogFooterFctComponent.baseClassLeft : ''}`;
  }
}
