import { AbstractControl, ValidatorFn } from '@angular/forms';

export const dateLessThanValidator =
  (valueFieldKey: string, valueFieldName: string): ValidatorFn =>
  (control: AbstractControl): { [key: string]: any } | null => {
    if (control.value) {
      if (control.parent) {
        const relatedField = control.parent.get(valueFieldKey);
        if (relatedField.value) {
          const relatedFieldDate = new Date(relatedField.value as string | number | Date);
          const controlDate = new Date(control.value as string | number | Date);
          if (controlDate > relatedFieldDate) {
            return { lessThan: valueFieldName };
          }
        }
      }
    }
  };
