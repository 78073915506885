import { Component, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { EmailSettingsFormComponent } from '../email-settings-form/email-settings-form.component';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserSettingsService } from '@shared/services/user-settings.service';
import { catchError, of, take, takeUntil } from 'rxjs';
import { DesctructableComponent } from '@rapid/forms';
import { ToastrService } from 'ngx-toastr';
import { UpdateEmailSetting } from '@shared/models/update-email-setting';
import { TranslateService } from '@ngx-translate/core';
import { EmailSettings } from '@shared/models/email-settings';

@Component({
  selector: 'mpac-email-settings-dialog',
  templateUrl: './email-settings-dialog.component.html',
  styleUrls: ['./email-settings-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EmailSettingsDialogComponent extends DesctructableComponent implements OnInit {
  public static baseClass = 'mpac-em-stg-dlg';

  @ViewChild('emailSettingsForm') emailSettingsForm: EmailSettingsFormComponent;

  public busy = false;

  public isMortician: boolean;

  public formViewModel: EmailSettings;

  constructor(
    private toastr: ToastrService,
    private translateService: TranslateService,
    private userSettingsService: UserSettingsService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    super();
    this.isMortician = data?.isMortician;
  }

  ngOnInit(): void {
    this.loadEmailSettings();
  }

  public updateSetting(data: UpdateEmailSetting): void {
    this.userSettingsService
      .updateEmailSettings(data.emailKey, data.isEnabled)
      .pipe(
        take(1),
        catchError((e) => {
          console.log(e);
          this.toastr.error(this.translateService.instant('forms.emailSettings.toasterMessages.settingsSavedError') as string);
          this.loadEmailSettings();
          return of<boolean>(true);
        })
      ).subscribe((isError: boolean | undefined) => {
        if (!isError) {
          this.toastr.success(this.translateService.instant('forms.emailSettings.toasterMessages.settingsSavedSuccess') as string);
        }
      });
  }

  private loadEmailSettings(): void {
    this.busy = true;
    this.userSettingsService.getUserEmailSettings()
    .pipe(
      takeUntil(this.shutdown$),
      catchError(() => {
        this.toastr.error(this.translateService.instant('forms.emailSettings.toasterMessages.settingsLoadedError') as string);
        return of<EmailSettings>(null as EmailSettings);
      })
    )
    .subscribe((formViewModel) => {
      this.formViewModel = formViewModel;
      this.emailSettingsForm.setDataAndInitForm(formViewModel);
      this.busy = false;
    });
  }
}
