import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap } from 'rxjs/operators';
import { setTopicAction, setTopicSuccessAction } from './help.actions';
import { CmsService, HelpText } from '@rapid/cms-lib';

@Injectable()
export class HelpEffects {
  loadHelpText$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setTopicAction),
      switchMap((action) =>
        this.cmsService.getFieldById(action.topic).pipe(
          map((helpText: HelpText) => {
            if (!helpText || !helpText.content) {
              return setTopicSuccessAction({ helpText: this.translateService.instant('errors.help.notAvailable') });
            }

            return setTopicSuccessAction({ helpText: helpText.content });
          })
        )
      )
    )
  );

  constructor(private actions$: Actions, private cmsService: CmsService, private translateService: TranslateService) {}
}
