import { Component } from '@angular/core';

@Component({
  selector: 'mpac-new-app-version-available',
  templateUrl: './new-app-version-available.component.html'
})
export class NewAppVersionAvailableComponent {
  public confirm(): void {
    window.location.reload();
  }
}
