import { Observable, Observer } from 'rxjs';

export class Base64Helper {
  // dataUrl [<mediatype>][;base64],<data>
  public static getBase64(dataUrl: string): string {
    if (dataUrl == null) {
      return null;
    }
    const data = dataUrl.split(',');
    if (data.length === 1) {
      throw new Error('cannot extract base 64 value from this dataUrl.');
    }
    return data[1];
  }

  public static getDataUrl(file: File): Promise<string | ArrayBuffer> {
    const reader = new FileReader();
    return new Promise((resolve) => {
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.readAsDataURL(file);
    });
  }

  public static blobToBase64(blob: Blob): Observable<string> {
    return new Observable((observer: Observer<string>) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        observer.next(reader.result.toString());
        observer.complete();
      };

      reader.readAsDataURL(blob);
    });
  }
}
