<div class="mpac-side-menu__wrap">
  <mpac-spinner-full
    class="mpac-side-menu__wrap-spinner"
    [isBusy]="!menuDisplayData || !menuDisplayData.length"
    [overlayMode]="false">
    <!-- TODO: Refactor this garbage -->
    <div
      *ngFor="let section of menuDisplayData"
      [class.collapsed]="(isNavbarVisible$ | async) === false"
      class="menu-section flex-column row">
      <h4 *ngIf="section.headline && section.headline?.text" class="menu-section__headline">
        <a
          *ngIf="section.headline.externalLink && section.headline.routingActivated"
          [href]="sanitizedUrl(section.headline.externalLink)"
          class="nav-link cursor-pointer"
          target="_blank">
          {{ section.headline.text }}
          <i
            class="fal fa-external-link mpac-side-menu__itm-ico mpac-side-menu__itm-ico--link"
            title="Externer Link"></i>
        </a>
        <ng-container
          *ngIf="
            (!section.headline.routerLink && !section.headline.externalLink) || !section.headline.routingActivated
          ">
          {{ section.headline.text }}
        </ng-container>
      </h4>
      <ul class="nav flex-column">
        <li *ngFor="let menuItem of section.items" class="nav-item" (click)="collapseNavigation()">
          <a
            *ngIf="menuItem.routerLink"
            [routerLinkActiveOptions]="{ exact: true }"
            [routerLink]="menuItem.routerLink"
            class="nav-link cursor-pointer"
            routerLinkActive="active">
            <mpac-menu-icon
              *ngIf="!!menuItem.iconClass"
              class="mpac-side-menu__itm-ico"
              [iconClass]="menuItem.iconClass">
            </mpac-menu-icon>
            {{ menuItem.text }}
          </a>
          <a
            *ngIf="menuItem.externalLink"
            [href]="sanitizedUrl(menuItem.externalLink)"
            class="nav-link cursor-pointer"
            target="_blank">
            <mpac-menu-icon
              *ngIf="!!menuItem.iconClass"
              class="mpac-side-menu__itm-ico"
              [iconClass]="menuItem.iconClass">
            </mpac-menu-icon>
            {{ menuItem.text }}
            <i
              class="mpac-side-menu__itm-ico mpac-side-menu__itm-ico--link fa fa-external-link"
              title="Externer Link"></i>
          </a>
        </li>
      </ul>
    </div>
  </mpac-spinner-full>
</div>
