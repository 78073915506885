import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CheckedUser, UserState } from '@core/models/user-state';
import { TranslateService } from '@ngx-translate/core';
import { StatefulResult } from '@shared/utils/stateful-result';
import { ToastrService } from 'ngx-toastr';
import { Observable, of, startWith } from 'rxjs';
import { catchError, first, map, take, tap } from 'rxjs/operators';
import { SettingsService } from '../../config/settings.service';
import { UserRegistration } from '../../models/user-registration';
import { UserService } from '../../services/user.service';
import { PrivacyPolicyLink } from '@core/models/privacy-policy-link';

@Component({
  selector: 'mpac-user-registration-page',
  templateUrl: './user-registration-page.component.html',
  styleUrls: ['./user-registration-page.component.scss']
})
export class UserRegistrationPageComponent implements OnInit {

  public isRegistrationInProgress = false;

  public registrationData: UserRegistration = {} as UserRegistration;

  public privacyPolicyUrl$: Observable<string>;

  private hash: string;

  private userState: UserState;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private userService: UserService,
    private settingsService: SettingsService,
    private translator: TranslateService) { }

  public ngOnInit(): void {
    this.hash = this.route.snapshot.params.hash;
    this.userService
      .checkInvitedUser(this.hash)
      .pipe(
        take(1),
        catchError(() => {
          this.isRegistrationInProgress = true;
          setTimeout(() => {
            this.router.navigate(['/login']).then();
          }, 3000);
          return null;
        })
      )
      .subscribe((checkedUser: CheckedUser) => {
        this.registrationData.userName = checkedUser.emailAddress;
        this.userState = checkedUser.userState;
      });

    this.privacyPolicyUrl$ = this.userService.getPrivacyPolicyLink(this.hash).pipe(
        first(),
        catchError(() => of({ privacyPolicyLink: this.settingsService.settings.privacyPolicyUrl } as PrivacyPolicyLink)),
        map(ppl => ppl?.privacyPolicyLink || this.settingsService.settings.privacyPolicyUrl),
        startWith(this.settingsService.settings.privacyPolicyUrl)
    );
  }

  public submitData(data: UserRegistration): void {
    this.isRegistrationInProgress = true;
    let registration$: Observable<StatefulResult<void>> = this.userService.registerUser(this.hash, data);
    if (this.userState === UserState.Unconfirmed) {
      registration$ =this.userService.confirmUser(this.hash, data);
    }

    registration$.pipe(
        first(),
        tap(() => {
          this.isRegistrationInProgress = false;
        }),
        catchError((err) => of(new StatefulResult<void>(null, 1, err as string)))
    )
        .subscribe((result) => {
          if (result.state === 0) {
            this.handleSuccessfulRegistration(data.userName);
          } else {
            this.handleRegistrationError(result.message);
          }
        });
  }

  private handleSuccessfulRegistration(userName: string): void {
    this.toastr.success(
      this.translator.instant('success.userRegistration.success') as string,
      this.translator.instant('userRegistration.toastTitle') as string
    );
    this.userService.storeUsernameInStorage(userName);
    setTimeout(() => {
      this.router.navigate(['/login']).then();
    }, 3000);
  }

  private handleRegistrationError(message: string): void {
    this.toastr.error(
      (this.translator.instant('errors.userRegistration.registrationFailed') as string) + message,
      this.translator.instant('userRegistration.toastTitle') as string
    );
  }
}
