import { SettingsService } from './settings.service';
import { Injectable } from '@angular/core';
import { GdprCookieBannerLibConfig, GdprCookieBannerConfigurationProvider } from 'gdpr-cookie-banner';
import { TranslateService } from '@ngx-translate/core';
import { IGdprCookieBannerConfig } from 'gdpr-cookie-banner';

@Injectable({ providedIn: 'root' })
export class GdprCookieBannerConfigService implements GdprCookieBannerConfigurationProvider {
  constructor(private settingsService: SettingsService, private translateService: TranslateService) {}

  get config(): GdprCookieBannerLibConfig {
    return this.settingsService.cookieBannerConfig;
  }

  /**
   * Loads the cookie banner configuration in an async fashion.
   */
  public loadBannerConfig(): Promise<IGdprCookieBannerConfig> {
    return new Promise((resolve) => {
      resolve({
        cookieCategories: [
          {
            displayName: this.translateService.instant('cookieBanner.categories.required.name'),
            displayDescription: this.translateService.instant('cookieBanner.categories.required.desc'),
            enabled: false,
            name: 'required',
            order: 0,
            selected: true,
            displayLink: null,
            vendors: []
          },
          {
            displayName: this.translateService.instant('cookieBanner.categories.tracking.name'),
            displayDescription: this.translateService.instant('cookieBanner.categories.tracking.desc'),
            enabled: true,
            name: 'tracking',
            order: 1,
            selected: false,
            displayLink: null,
            vendors: [
              {
                displayName: this.translateService.instant('cookieBanner.vendors.hotjar.displayName'),
                displayDescription: this.translateService.instant('cookieBanner.vendors.hotjar.displayDescription'),
                name: 'hotjar',
                enabled: true,
                selected: false,
                order: 0,
                detailInfoData: [
                  {
                    name: this.translateService.instant('cookieBanner.cookieTable.data._hjid.name'),
                    vendor: this.translateService.instant('cookieBanner.cookieTable.data._hjid.vendor'),
                    purpose: this.translateService.instant('cookieBanner.cookieTable.data._hjid.purpose'),
                    host: this.translateService.instant('cookieBanner.cookieTable.data._hjid.host'),
                    techName: this.translateService.instant('cookieBanner.cookieTable.data._hjid.techName'),
                    time: this.translateService.instant('cookieBanner.cookieTable.data._hjid.time')
                  },
                  {
                    name: this.translateService.instant('cookieBanner.cookieTable.data._hjRecordingLastActivity.name'),
                    vendor: this.translateService.instant(
                      'cookieBanner.cookieTable.data._hjRecordingLastActivity.vendor'
                    ),
                    purpose: this.translateService.instant(
                      'cookieBanner.cookieTable.data._hjRecordingLastActivity.purpose'
                    ),
                    host: this.translateService.instant('cookieBanner.cookieTable.data._hjRecordingLastActivity.host'),
                    techName: this.translateService.instant(
                      'cookieBanner.cookieTable.data._hjRecordingLastActivity.techName'
                    ),
                    time: this.translateService.instant('cookieBanner.cookieTable.data._hjRecordingLastActivity.time')
                  },
                  {
                    name: this.translateService.instant('cookieBanner.cookieTable.data._hjTLDTest.name'),
                    vendor: this.translateService.instant('cookieBanner.cookieTable.data._hjTLDTest.vendor'),
                    purpose: this.translateService.instant('cookieBanner.cookieTable.data._hjTLDTest.purpose'),
                    host: this.translateService.instant('cookieBanner.cookieTable.data._hjTLDTest.host'),
                    techName: this.translateService.instant('cookieBanner.cookieTable.data._hjTLDTest.techName'),
                    time: this.translateService.instant('cookieBanner.cookieTable.data._hjTLDTest.time')
                  },
                  {
                    name: this.translateService.instant('cookieBanner.cookieTable.data._hjUserAttributesHash.name'),
                    vendor: this.translateService.instant('cookieBanner.cookieTable.data._hjUserAttributesHash.vendor'),
                    purpose: this.translateService.instant(
                      'cookieBanner.cookieTable.data._hjUserAttributesHash.purpose'
                    ),
                    host: this.translateService.instant('cookieBanner.cookieTable.data._hjUserAttributesHash.host'),
                    techName: this.translateService.instant(
                      'cookieBanner.cookieTable.data._hjUserAttributesHash.techName'
                    ),
                    time: this.translateService.instant('cookieBanner.cookieTable.data._hjUserAttributesHash.time')
                  },
                  {
                    name: this.translateService.instant('cookieBanner.cookieTable.data._hjCachedUserAttributes.name'),
                    vendor: this.translateService.instant(
                      'cookieBanner.cookieTable.data._hjCachedUserAttributes.vendor'
                    ),
                    purpose: this.translateService.instant(
                      'cookieBanner.cookieTable.data._hjCachedUserAttributes.purpose'
                    ),
                    host: this.translateService.instant('cookieBanner.cookieTable.data._hjCachedUserAttributes.host'),
                    techName: this.translateService.instant(
                      'cookieBanner.cookieTable.data._hjCachedUserAttributes.techName'
                    ),
                    time: this.translateService.instant('cookieBanner.cookieTable.data._hjCachedUserAttributes.time')
                  },
                  {
                    name: this.translateService.instant('cookieBanner.cookieTable.data._hjLocalStorageTest.name'),
                    vendor: this.translateService.instant('cookieBanner.cookieTable.data._hjLocalStorageTest.vendor'),
                    purpose: this.translateService.instant('cookieBanner.cookieTable.data._hjLocalStorageTest.purpose'),
                    host: this.translateService.instant('cookieBanner.cookieTable.data._hjLocalStorageTest.host'),
                    techName: this.translateService.instant(
                      'cookieBanner.cookieTable.data._hjLocalStorageTest.techName'
                    ),
                    time: this.translateService.instant('cookieBanner.cookieTable.data._hjLocalStorageTest.time')
                  },
                  {
                    name: this.translateService.instant(
                      'cookieBanner.cookieTable.data._hjIncludedInPageviewSample.name'
                    ),
                    vendor: this.translateService.instant(
                      'cookieBanner.cookieTable.data._hjIncludedInPageviewSample.vendor'
                    ),
                    purpose: this.translateService.instant(
                      'cookieBanner.cookieTable.data._hjIncludedInPageviewSample.purpose'
                    ),
                    host: this.translateService.instant(
                      'cookieBanner.cookieTable.data._hjIncludedInPageviewSample.host'
                    ),
                    techName: this.translateService.instant(
                      'cookieBanner.cookieTable.data._hjIncludedInPageviewSample.techName'
                    ),
                    time: this.translateService.instant(
                      'cookieBanner.cookieTable.data._hjIncludedInPageviewSample.time'
                    )
                  },
                  {
                    name: this.translateService.instant(
                      'cookieBanner.cookieTable.data._hjAbsoluteSessionInProgress.name'
                    ),
                    vendor: this.translateService.instant(
                      'cookieBanner.cookieTable.data._hjAbsoluteSessionInProgress.vendor'
                    ),
                    purpose: this.translateService.instant(
                      'cookieBanner.cookieTable.data._hjAbsoluteSessionInProgress.purpose'
                    ),
                    host: this.translateService.instant(
                      'cookieBanner.cookieTable.data._hjAbsoluteSessionInProgress.host'
                    ),
                    techName: this.translateService.instant(
                      'cookieBanner.cookieTable.data._hjAbsoluteSessionInProgress.techName'
                    ),
                    time: this.translateService.instant(
                      'cookieBanner.cookieTable.data._hjAbsoluteSessionInProgress.time'
                    )
                  },
                  {
                    name: this.translateService.instant(
                      'cookieBanner.cookieTable.data._hjIncludedInSessionSample.name'
                    ),
                    vendor: this.translateService.instant(
                      'cookieBanner.cookieTable.data._hjIncludedInSessionSample.vendor'
                    ),
                    purpose: this.translateService.instant(
                      'cookieBanner.cookieTable.data._hjIncludedInSessionSample.purpose'
                    ),
                    host: this.translateService.instant(
                      'cookieBanner.cookieTable.data._hjIncludedInSessionSample.host'
                    ),
                    techName: this.translateService.instant(
                      'cookieBanner.cookieTable.data._hjIncludedInSessionSample.techName'
                    ),
                    time: this.translateService.instant('cookieBanner.cookieTable.data._hjIncludedInSessionSample.time')
                  },
                  {
                    name: this.translateService.instant('cookieBanner.cookieTable.data._hjFirstSeen.name'),
                    vendor: this.translateService.instant('cookieBanner.cookieTable.data._hjFirstSeen.vendor'),
                    purpose: this.translateService.instant('cookieBanner.cookieTable.data._hjFirstSeen.purpose'),
                    host: this.translateService.instant('cookieBanner.cookieTable.data._hjFirstSeen.host'),
                    techName: this.translateService.instant('cookieBanner.cookieTable.data._hjFirstSeen.techName'),
                    time: this.translateService.instant('cookieBanner.cookieTable.data._hjFirstSeen.time')
                  },
                  {
                    name: this.translateService.instant('cookieBanner.cookieTable.data._hjViewportId.name'),
                    vendor: this.translateService.instant('cookieBanner.cookieTable.data._hjViewportId.vendor'),
                    purpose: this.translateService.instant('cookieBanner.cookieTable.data._hjViewportId.purpose'),
                    host: this.translateService.instant('cookieBanner.cookieTable.data._hjViewportId.host'),
                    techName: this.translateService.instant('cookieBanner.cookieTable.data._hjViewportId.techName'),
                    time: this.translateService.instant('cookieBanner.cookieTable.data._hjViewportId.time')
                  },
                  {
                    name: this.translateService.instant('cookieBanner.cookieTable.data._hjRecordingEnabled.name'),
                    vendor: this.translateService.instant('cookieBanner.cookieTable.data._hjRecordingEnabled.vendor'),
                    purpose: this.translateService.instant('cookieBanner.cookieTable.data._hjRecordingEnabled.purpose'),
                    host: this.translateService.instant('cookieBanner.cookieTable.data._hjRecordingEnabled.host'),
                    techName: this.translateService.instant(
                      'cookieBanner.cookieTable.data._hjRecordingEnabled.techName'
                    ),
                    time: this.translateService.instant('cookieBanner.cookieTable.data._hjRecordingEnabled.time')
                  }
                ]
              }
            ]
          }
        ],
        settingsText: this.translateService.instant('cookieBanner.settingsText'),
        confirmText: this.translateService.instant('actions.confirmSelection'),
        dataProtectionLink: this.settingsService.settings.privacyPolicyUrl,
        dataProtectionLinkText: this.translateService.instant('cookieBanner.privacyPolicy'),
        settingsInitiallyOpen: false,
        headline: this.translateService.instant('cookieBanner.headline'),
        impressumLink: this.settingsService.settings.impressumUrl,
        impressumLinkText: this.translateService.instant('cookieBanner.impressum'),
        introText: this.translateService.instant('cookieBanner.intro'),
        selectAllText: this.translateService.instant('actions.selectAll'),
        rejectAllText: this.translateService.instant('cookieBanner.rejectAll'),
        displayRejectAll: false,
        cookieTable: {
          backText: this.translateService.instant('actions.back'),
          headlineText: this.translateService.instant('cookieBanner.cookieTable.headlineText'),
          moreInfoText: this.translateService.instant('general.moreInfo'),
          cols: new Map<string, string>([
            ['name', this.translateService.instant('cookieBanner.cookieTable.cols.name')],
            ['vendor', this.translateService.instant('cookieBanner.cookieTable.cols.vendor')],
            ['purpose', this.translateService.instant('cookieBanner.cookieTable.cols.purpose')],
            ['host', this.translateService.instant('cookieBanner.cookieTable.cols.host')],
            ['techName', this.translateService.instant('cookieBanner.cookieTable.cols.techName')],
            ['time', this.translateService.instant('cookieBanner.cookieTable.cols.time')]
          ])
        }
      });
    });
  }

  /**
   * Initializes the cookie banner base configuration.
   */
  public initializeConfig(): void {
    this.settingsService.cookieBannerConfig = {
      cookiesVersion: this.settingsService.settings.cookieBannerConfigRevision,
      customClass: 'mpac-cookie-banner',
      cookieOptions: null,
      cookieId: null
    };
  }
}
