import { SettingsHttpService } from 'src/app/core/config/settings.http.service';
import { HttpClient } from '@angular/common/http';
import { TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { Observable } from 'rxjs';

export class TranslateDelayedLoader implements TranslateLoader {
  constructor(private httpClient: HttpClient) {}

  public getTranslation(lang: string): Observable<any> {
    const buildSettings = SettingsHttpService.getBuildSettingsForJson();
    if (buildSettings.buildNumber && buildSettings.buildNumber > -1) {
      return new TranslateHttpLoader(
        this.httpClient,
        './assets/i18n/',
        `.json?${buildSettings.buildNumber.toString()}`
      ).getTranslation(lang);
    } else {
      return new TranslateHttpLoader(this.httpClient, './assets/i18n/', `.json?${new Date().getTime()}`).getTranslation(
        lang
      );
    }
  }
}
