import { FuneralPrintItem } from './../models/funeral-print.model';
import { GetFuneralDocumentStatusesByOrderQueryResponseDto } from '../dtos/responses/get-funeral-document-statuses-by-order-query-response.dto';
import { FuneralDocumentCommentDto } from '../dtos/shared/funeral-document-comment.dto';
import { FuneralDocumentFileDto } from '../dtos/shared/funeral-document-file.dto';
import { FuneralDocumentStatusDto } from '../dtos/shared/funeral-document-status.dto';
import { FuneralDocumentStatusesOrderDto } from '../dtos/shared/funeral-document-statuses-order.dto';
import { FuneralDocumentDto } from '../dtos/shared/funeral-document.dto';
import { FuneralPrintFileThumbnailSize } from '../enums/funeral-print-file-thumbnail-size.enum';
import { FuneralPrintStatus } from '../enums/funeral-print-status.enum';
import { FuneralPrintComment } from '../models/funeral-print-comment.model';
import { FuneralPrintFile } from '../models/funeral-print-file.model';

export class FuneralDtoConverter {
  public static toFuneralPrint(dto: FuneralDocumentDto): FuneralPrintItem {
    const funeralPrint = {
      id: dto.id,
      templateId: dto.templateId,
      comments: dto.comments != null ? dto.comments.map(FuneralDtoConverter.toFuneralPrintComment) : [],
      documents: dto.files.map((d) => FuneralDtoConverter.toFuneralPrintFile(d)),
      uploadedAt: dto.createdAt,
      lastUpdatedAt: dto.updatedAt,
      lastUpdatedBy: dto.updatedBy,
      createdAt: dto.createdAt,
      status: FuneralDtoConverter.toFuneralPrintStatus(dto.status),
      lastReminderTime: dto.lastReminderTime
    } as FuneralPrintItem;

    dto.values.forEach((fieldValue) => {
      funeralPrint[fieldValue.key] = fieldValue.value;
    });

    return funeralPrint;
  }

  public static toFuneralPrintStatus(value: string): FuneralPrintStatus {
    if (!value) {
      return FuneralPrintStatus.Unknown;
    }
    switch (value) {
      case 'Created':
        return FuneralPrintStatus.Created;
      case 'ApprovalRequested':
        return FuneralPrintStatus.ApprovalRequested;
      case 'ApprovalDenied':
        return FuneralPrintStatus.ApprovalDenied;
      case 'Discarded':
        return FuneralPrintStatus.Discarded;
      case 'Approved':
        return FuneralPrintStatus.Approved;
      case 'Done':
        return FuneralPrintStatus.Done;
      default:
        return FuneralPrintStatus.Unknown;
    }
  }

  public static toDtoFuneralPrintStatus(value: FuneralPrintStatus): string {
    switch (value) {
      case FuneralPrintStatus.Created:
        return 'Created';
      case FuneralPrintStatus.ApprovalRequested:
        return 'ApprovalRequested';
      case FuneralPrintStatus.ApprovalDenied:
        return 'ApprovalDenied';
      case FuneralPrintStatus.Discarded:
        return 'Discarded';
      case FuneralPrintStatus.Approved:
        return 'Approved';
      case FuneralPrintStatus.Done:
        return 'Done';
      default:
        return null;
    }
  }

  public static toFuneralPrintComment(dto: FuneralDocumentCommentDto): FuneralPrintComment {
    if (!dto) {
      return null;
    }
    return {
      comment: dto.text,
      createdAt: dto.createdAt,
      createdBy: dto.creator.userName
    } as FuneralPrintComment;
  }

  public static toFuneralPrintFile(
    dto: FuneralDocumentFileDto,
    size = FuneralPrintFileThumbnailSize.Small
  ): FuneralPrintFile {
    if (!dto) {
      return null;
    }

    const funeralPrintFile: FuneralPrintFile = {
      id: dto.id,
      name: dto.name,
      thumbnailData: size === FuneralPrintFileThumbnailSize.Small ? dto.data : null,
      previewData: size === FuneralPrintFileThumbnailSize.Medium ? dto.data : null,
      size: 0,
      status: FuneralDtoConverter.toFuneralPrintStatus(dto.status),
      imageLoading: false,
      isImage: FuneralDtoConverter.isImageFile(dto.name)
    };

    return funeralPrintFile;
  }

  public static toFile(source: FuneralPrintFile): File {
    const base64 = '';
    const imageName = source.name;
    const fakeDefaultMime = 'image/png';
    const imageBlob = FuneralDtoConverter.dataURItoBlob(base64, fakeDefaultMime);
    return new File([imageBlob], imageName, { type: fakeDefaultMime });
  }

  public static dataURItoBlob(dataURI: string, type: string): Blob {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    return new Blob([int8Array], { type });
  }

  public static toFuneralDocumentStatusesOrder(
    dto: GetFuneralDocumentStatusesByOrderQueryResponseDto
  ): Array<FuneralDocumentStatusesOrderDto> {
    const result = dto.orders.map((d) => {
      const orderPk = Number.parseInt(d.id, 10);
      const docs = d.documents.map(
        (x) => ({ id: x.id, status: FuneralDtoConverter.toFuneralPrintStatus(x.status) } as FuneralDocumentStatusDto)
      );
      return { orderPk, documents: docs } as FuneralDocumentStatusesOrderDto;
    });
    return result;
  }

  private static isImageFile(fileName: string): boolean {
    const notImageExtensions = ['pdf'];
    const fileExtension = fileName?.split('.').reverse()[0];
    return !notImageExtensions.includes(fileExtension);
  }
}
