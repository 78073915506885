import { Injectable } from '@angular/core';
import { User } from '@core/models/user';
import { UserLevel } from '../../shared/models/user-level.enum';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  constructor() {}

  /**
   * Gets the root route for a user. Returns '/' if the user is falsy.
   */
  public getRootRouteForUser(user: User): string {
    if (user) {
      switch (user.level) {
        case UserLevel.Rapid:
          return '/bestatter';

        case UserLevel.Mortician:
          if (user.morticianIds.length > 1) {
            return '/portale';
          }

          return '/portal/' + user.morticianIds[0];

        case UserLevel.PageAdmin:
          return '/gedenkseiten/' + user.managedOrders[0].id + '/start';
      }
    }

    return '/';
  }
}
