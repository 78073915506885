import { GdprCookieBannerConfigService } from './gdpr-cookie-banner-config.service';
import { SettingsHttpService } from './../config/settings.http.service';
import { Injectable } from '@angular/core';
import { distinctUntilChanged, filter } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class CookieBannerConfigFactoryHttpService {
  constructor(
    private cookieBannerConfigService: GdprCookieBannerConfigService,
    private settingsHttpService: SettingsHttpService
  ) {}

  initializeApp(): Promise<any> {
    return new Promise((resolve) => {
      this.settingsHttpService.appSettingsInitialized$
        .pipe(
          distinctUntilChanged(),
          filter((isInitialized) => isInitialized)
        )
        .subscribe(() => {
          this.cookieBannerConfigService.initializeConfig();
          resolve(this.cookieBannerConfigService.config);
        });
    });
  }
}
