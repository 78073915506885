export enum QuickFormOrderStatus {
  Undefined,
  Draft,
  Confirmed,
  InProgress,
  Paused,
  Finished,
  Anonymized,
  Archived
}

export enum QuickFormOrderDocumentType {
  Undefined,
  DeathCertificate,
  CertificateOfAuthority,
  CertificateOfInheritance,
  AdditionalDocument,
  InsuranceContract,
  CertificateOfAuthorityQuickFormOnly,
  IdCardCopy,
  CertificateOfInheritanceAustrian,
  OfficialConfirmationOfErbantrittserklarung,
  OfficialAuthorization,
  DocumentaryEvidence,
  MandateAuthorization,
  CertificateOfAuthorityPrecaution,
  Unknown,
  MergedOrderDocuments
}

export interface QuickFormOrder {
  id: string;
  status: QuickFormOrderStatus;
  isDigitalLegacyActivated: boolean;
  activationDate: Date;
  liveSpan: number;
  orderDate: Date;
  deceasedEmailAdresses: Array<string>;
  documents: Array<QuickFormOrderResumeDocument>;
}

export interface QuickFormCreateOrderDocument {
  type: QuickFormOrderDocumentType;
  file: File;
}

export interface QuickFormOrderResumeDocument {
  id: string;
  type: QuickFormOrderDocumentType;
  createdAt: Date;
  verified: boolean;
  fileName: string;
  displayName: string;
}
export interface QuickFormOrderDocument extends QuickFormOrderResumeDocument {
  blob: any;
}
