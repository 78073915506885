import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { interceptorSkipHeader } from '@core/consts/interceptor-skip-header.const';
import { interceptorSkipErrorHandlingHeader } from '@core/consts/interceptor-skip-error-handling-header.const';
import { AuthService } from '@core/services/auth.service';
@Injectable({
  providedIn: 'root'
})
export class ErrorHttpInterceptorService implements HttpInterceptor {
  constructor(private toastrService: ToastrService, private translateService: TranslateService, private authService: AuthService) {}

  private static handleErrors(request: HttpRequest<any>): boolean {
    return !request.headers.has(interceptorSkipErrorHandlingHeader as string);
  }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.headers.has(interceptorSkipHeader)) {
      return next.handle(request);
    }
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        switch (error.status) {
          case 200:
          case 202:
            if (ErrorHttpInterceptorService.handleErrors(request) && error.statusText === 'MailFailure') {
              this.toastrService.warning(
                this.translateService.instant('notifications.mailNotificationFailure') as string,
                this.translateService.instant('notifications.mailNotificationFailureTitle') as string
              );
            }
            break;
          case 400:
            if (
              error.error &&
              error.error.error === 'invalid_grant'
            ) {
              // IdentityServer returns an 'invalid_grant' error when trying to refresh an access token fails
              this.toastrService.warning(
                this.translateService.instant('errors.http.tokenRefreshError') as string,
                this.translateService.instant('general.hint') as string,
                { disableTimeOut: true }
              );
            }
            else if (
              ErrorHttpInterceptorService.handleErrors(request)
            ) {
              this.toastrService.error(
                `${this.translateService.instant('errors.http.badRequest') as string} ${
                  this.translateService.instant('errors.http.contactSupport') as string
                }`,
                this.translateService.instant('general.error') as string
              );
            }
            break;

          case 401:
            if (ErrorHttpInterceptorService.handleErrors(request)) {
              if (!this.authService.hasValidAccessToken) {
                this.toastrService.error(
                  this.translateService.instant('errors.http.invalidAccessToken') as string,
                  this.translateService.instant('general.error') as string
                );
                this.authService.refreshAccessToken();
              } else {
                this.toastrService.error(
                  this.translateService.instant('errors.http.unauthorized') as string,
                  this.translateService.instant('general.error') as string
                );
              }
            }
            break;

          case 403:
            if (ErrorHttpInterceptorService.handleErrors(request)) {
              this.toastrService.error(
                this.translateService.instant('errors.http.unauthorized') as string,
                this.translateService.instant('general.error') as string
              );
            }
            break;

          case 404:
            // status: Not Found => is the default stuff.
            // TODO: throw custom error if the service error is a 404 without message saying xyz not found
            // because then the service is down and we can display and error message accordingly
            console.log(error);
            break;

          case 409:
            if (ErrorHttpInterceptorService.handleErrors(request)) {
              this.toastrService.error(
                `${this.translateService.instant('errors.http.conflict') as string} ${
                  this.translateService.instant('errors.http.contactSupport') as string
                }`,
                this.translateService.instant('general.error') as string
              );
            }
            break;

          case 503:
            if (ErrorHttpInterceptorService.handleErrors(request)) {
              if (error.error?.message?.indexOf('Radiopark') > 0) {
                this.toastrService.warning(
                  this.translateService.instant('notifications.funeralMusic.serviceUnavailableMessage') as string,
                  this.translateService.instant('notifications.funeralMusic.funeralMusicTitle') as string
                );
              } else {
                this.toastrService.error(
                  `${this.translateService.instant('errors.http.undefinedError') as string} ${
                    this.translateService.instant('errors.http.contactSupport') as string
                  }`,
                  this.translateService.instant('general.error') as string
                );
              }
            }
            break;

          case 500:
          case 501:
          case 502:
          case 504:
            if (ErrorHttpInterceptorService.handleErrors(request)) {
              this.toastrService.error(
                `${this.translateService.instant('errors.http.undefinedError') as string} ${
                  this.translateService.instant('errors.http.contactSupport') as string
                }`,
                this.translateService.instant('general.error') as string
              );
            }
        }
        return throwError(error);
      })
    );
  }
}
