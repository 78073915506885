import { Component, Input, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControlDirective, NgControl } from '@angular/forms';
import {
  confirmButtonDefaultTranslationKey,
  ConfirmDialogComponent,
  ConfirmDialogConfig,
  dismissButtonDefaultTranslationKey,
  messageDefaultTranslationKey,
  titleDefaultTranslationKey
} from '@core/components/confirm-dialog/confirm-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { DesctructableComponent } from '@shared/components/destructable/destructable.component';
import { DialogService } from '@shared/services/dialog-service';
import { takeUntil } from 'rxjs/operators';
import { ToggleBaseConfiguration } from '../../../models/toggle-base.model';

export interface CustomSwitchConfiguration extends ToggleBaseConfiguration {
  tooltipTranslationKey?: string;
  tooltipText?: string;
}

@Component({
  selector: 'mpac-custom-switch',
  templateUrl: './custom-switch.component.html'
})
export class CustomSwitchComponent extends DesctructableComponent implements ControlValueAccessor {
  @ViewChild(FormControlDirective, { static: true }) formControlDirective: FormControlDirective;

  @Input() configuration: CustomSwitchConfiguration;

  public innerValue: boolean;

  constructor(
    public ngControl: NgControl,
    private translateService: TranslateService,
    private dialogService: DialogService
  ) {
    super();
    this.ngControl.valueAccessor = this;
  }

  public get hasTooltip(): boolean {
    return !!this.configuration?.tooltipTranslationKey || !!this.configuration?.tooltipText;
  }

  public get hasLabel(): boolean {
    return !!this.configuration?.labelTranslationKey || !!this.configuration?.labelText;
  }

  public getLabelText(): string {
    let labelText = '';

    if (this.configuration?.labelTranslationKey) {
      labelText = this.translateService.instant(this.configuration.labelTranslationKey);
    } else if (this.configuration?.labelText) {
      labelText = this.configuration.labelText;
    }

    return labelText;
  }

  public getTooltipText(): string {
    let tooltipText: string;

    if (this.configuration?.tooltipTranslationKey) {
      tooltipText = this.translateService.instant(this.configuration.tooltipTranslationKey);
    } else if (this.configuration?.labelText) {
      tooltipText = this.configuration.tooltipText;
    }

    return tooltipText;
  }

  public onInputChange(event: any): void {
    const previousValue = this.innerValue;
    const isChecked: boolean = event.target.checked;
    if (previousValue !== event.target.checked) {
      let confirmationProcessing: boolean;
      const previousValueRequiresChangeConfirmation =
        previousValue === true ? !!this.configuration?.right.confirmChange : !!this.configuration?.left.confirmChange;
      if (previousValueRequiresChangeConfirmation && !confirmationProcessing) {
        confirmationProcessing = true;
        this.dialogService
          .openAndReturnObservable(ConfirmDialogComponent, {
            width: '400px',
            data: {
              ...this.getDialogConfiguration()
            }
          })
          .pipe(takeUntil(this.shutdown$))
          .subscribe((confirmed: boolean) => {
            if (confirmed) {
              this.changeInnerValueAndPropagate(isChecked);
            } else {
              event.target.checked = previousValue;
            }
            confirmationProcessing = false;
          });
      } else {
        this.changeInnerValueAndPropagate(isChecked);
      }
    }
  }

  public writeValue(value: any): void {
    this.innerValue = value === this.configuration?.right.value;
    this.formControlDirective.valueAccessor.writeValue(value);
  }

  public registerOnChange(fn: any): void {
    this.formControlDirective.valueAccessor.registerOnChange(fn);
  }

  public registerOnTouched(fn: any): void {
    this.formControlDirective.valueAccessor.registerOnTouched(fn);
  }

  public setDisabledState?(isDisabled: boolean): void {
    this.formControlDirective.valueAccessor.setDisabledState(isDisabled);
  }

  private changeInnerValueAndPropagate(isChecked: boolean): void {
    this.innerValue = isChecked;
    const propagateValue = isChecked ? this.configuration?.right.value : this.configuration?.left.value;
    this.ngControl.control.markAsDirty();
    this.ngControl.control.setValue(propagateValue);
  }

  private getDialogConfiguration(): ConfirmDialogConfig {
    const config: ConfirmDialogConfig = {};
    const outerConfig = this.configuration?.confirmDialogConfig;
    config.title = outerConfig?.title || this.getTranslationFor(titleDefaultTranslationKey);
    config.message = outerConfig?.message || this.getTranslationFor(messageDefaultTranslationKey);
    config.confirmButtonText =
      outerConfig?.confirmButtonText || this.getTranslationFor(confirmButtonDefaultTranslationKey);
    config.dismissButtonText =
      outerConfig?.dismissButtonText || this.getTranslationFor(dismissButtonDefaultTranslationKey);
    config.confirmButtonIcon = outerConfig?.dismissButtonIcon;
    config.dismissButtonIcon = outerConfig?.dismissButtonIcon;

    return config;
  }

  private getTranslationFor(translationKey: string): string {
    return this.translateService.instant(translationKey);
  }
}
