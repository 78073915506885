import { AuthSettings } from './auth-settings.model';
import { BuildSettings } from './build-settings.model';
import { AppSettings } from './settings.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SettingsService } from './settings.service';
import { map } from 'rxjs/operators';
import { deserialize, plainToClass } from 'class-transformer';
import 'reflect-metadata';
import { DemoMorticianSettings } from './settings-demo-mortician.model';
import buildSettingsBase from './../../../assets/config/build/build-settings.json';
import appSettingsBase from './../../../assets/config/base/settings.docker.json';
import appSettingsBaseFromAngular from './../../../assets/config/base/settings.json';
import { BehaviorSubject, forkJoin, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SettingsHttpService {
  public static appSettingsPathDocker = 'assets/config/base/settings.docker.json';

  public static appSettingsPathAngular = 'assets/config/base/settings.json';

  public static authSettingsPath = 'assets/config/auth/auth-settings.json';

  public static buildSettingsPath = 'assets/config/build/build-settings.json';

  public static demoMorticianSettingsPath = 'assets/config/demo/demo-mortician-settings.json';

  public injector: any;

  public appSettingsInitialized$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(private http: HttpClient, private settingsService: SettingsService) {}

  /**
   * Reads the buildSettings from file. For docker environments only!
   * Required since some of the metadata references the settings during compilation (no async option).
   */
  public static getBuildSettingsForJson(): BuildSettings {
    return SettingsHttpService.readBuildSettingsFromFile();
  }

  /**
   * Reads the appSettigns from file(s).
   * Required since some of the metadata references the settings during compilation (no async option).
   */
  public static getAppSettingsForJson(): AppSettings {
    const settings = SettingsHttpService.readAppSettingsFromFile();

    if (SettingsHttpService.useLocalConfigSet(settings)) {
      return SettingsHttpService.readAppSettingsFromFile(false);
    }
    return settings;
  }

  /**
   * Reads the build settings from file.
   */
  private static readBuildSettingsFromFile(): BuildSettings {
    return plainToClass(BuildSettings, buildSettingsBase, { enableImplicitConversion: true });
  }

  /**
   * Reads the settings from file.
   *
   * @param [useEnvironmentDefault=true] Whether or not to use the docker-env provided
   */
  private static readAppSettingsFromFile(useEnvironmentDefault: boolean = true): AppSettings {
    if (useEnvironmentDefault) {
      return plainToClass(AppSettings, appSettingsBase, { enableImplicitConversion: true });
    }
    return plainToClass(AppSettings, appSettingsBaseFromAngular, { enableImplicitConversion: true });
  }

  /**
   * Checks with some required settings whether or not the given <c>AppSettings</c> object is present and has values.
   *
   * @return Whether or not the given AppSettings instance is present and has values.
   */
  private static settingsNullOrEmpty(settings: AppSettings): boolean {
    // We use apiRoot & contentfulSpaceId because these settings must always be present.
    return !settings || !settings.apiRoot || !settings.contentfulSpaceId;
  }

  /**
   * Checks whether or not the conditions are set to use the environment config.
   */
  private static useLocalConfigSet(settings: AppSettings): boolean {
    return SettingsHttpService.settingsNullOrEmpty(settings) || !settings.useEnvironmentVariableConfig;
  }

  /**
   * Application startup factory method initiliazing the app-settings.
   */
  public initializeApp(): Observable<any> {
    let useLocalConfig = false;
    // load build settings => only present for docker contexts!
    const settingsCallList: Observable<any>[] = [];

    /* eslint-disable @typescript-eslint/naming-convention */
    const httpOptions = {
      headers: new HttpHeaders({
        'Cache-Control': 'no-cache, no-store, must-revalidate',
        Pragma: 'no-cache',
        Expires: '0',
        ResponseType: 'text'
      })
    };
    /* eslint-enable @typescript-eslint/naming-convention */
    settingsCallList.push(
      this.http.get(SettingsHttpService.buildSettingsPath, { ...httpOptions, responseType: 'text' }).pipe(
        map((response) => {
          try {
            this.settingsService.buildSettings = deserialize(BuildSettings, response, {
              enableImplicitConversion: true
            });
          } catch (e) {
            this.settingsService.buildSettings = null;
          }
        })
      )
    );

    // Load the auth settings
    settingsCallList.push(
      this.http.get(SettingsHttpService.authSettingsPath, { ...httpOptions, responseType: 'text' }).pipe(
        map((response) => {
          try {
            this.settingsService.authSettings = deserialize(AuthSettings, response, {
              enableImplicitConversion: true
            });
          } catch (e) {
            this.settingsService.authSettings = null;
          }
        })
      )
    );

    // the settings need to be awaited here first, since they are being loaded for the first time.
    // A simple if/else won't do, because we could only check an empty settings instance.
    settingsCallList.push(
      this.http.get(SettingsHttpService.appSettingsPathDocker, { ...httpOptions, responseType: 'text' }).pipe(
        map((response) => {
          try {
            this.settingsService.settings = deserialize(AppSettings, response, { enableImplicitConversion: true });
            useLocalConfig = SettingsHttpService.useLocalConfigSet(this.settingsService.settings);
          } catch (e) {
            this.settingsService.settings = null;
          }
        })
      )
    );

    settingsCallList.push(
      this.http.get(SettingsHttpService.demoMorticianSettingsPath, { ...httpOptions, responseType: 'text' }).pipe(
        map((response) => {
          try {
            this.settingsService.demoMorticianSettings = deserialize(DemoMorticianSettings, response, {
              enableImplicitConversion: true
            });
          } catch (e) {
            this.settingsService.demoMorticianSettings = null;
          }
        })
      )
    );

    return forkJoin(settingsCallList).pipe(
      map(() => {
        if (useLocalConfig) {
          this.http
            .get(SettingsHttpService.appSettingsPathAngular, { ...httpOptions, responseType: 'text' })
            .subscribe((result) => {
              this.settingsService.settings = deserialize(AppSettings, result, { enableImplicitConversion: true });
              this.appSettingsInitialized$.next(true);
            });
        } else {
          this.appSettingsInitialized$.next(true);
        }
      })
    );
  }
}
