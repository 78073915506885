/**
 * Screen size breakpoints for this application.
 * This is the equivalent of the $grid-breakpoints in _variables.scss.
 */
export class Breakpoints {
  /* eslint-disable @typescript-eslint/naming-convention */
  public static readonly XS = '(max-width: 575px)';

  public static readonly SM = '(min-width: 576px) and (max-width: 767px)';

  public static readonly MD = '(min-width: 768px) and (max-width: 991px)';

  public static readonly LG = '(min-width: 992px) and (max-width: 1299px)';

  public static readonly XL = '(min-width: 1300px) and (max-width: 1919px)';

  public static readonly FHD = '(min-width: 1920px) and (max-width: 2870px)';

  public static readonly UHD = '(min-width: 2880px)';
  /* eslint-enable @typescript-eslint/naming-convention */

  /**
   * Returns an array of all breakpoints.
   */
  public static get asList(): Array<string> {
    return [
      Breakpoints.XS,
      Breakpoints.SM,
      Breakpoints.MD,
      Breakpoints.LG,
      Breakpoints.XL,
      Breakpoints.FHD,
      Breakpoints.UHD
    ];
  }

  /**
   * Determines whether one of the breakpoints above the specified breakpoint match
   * the given state.
   *
   * @param breakpoint The breakpoint above which a breakpoint in the state should match.
   * @param  state The current state indicating which breakpoints currently match.
   * @returns Whether any breakpoint above the specified one matches.
   */
  public static above(breakpoint: string, state: { [key: string]: boolean }): boolean {
    switch (breakpoint) {
      case Breakpoints.XS:
        return (
          state[Breakpoints.SM] ||
          state[Breakpoints.MD] ||
          state[Breakpoints.LG] ||
          state[Breakpoints.XL] ||
          state[Breakpoints.FHD] ||
          state[Breakpoints.UHD]
        );
      case Breakpoints.SM:
        return (
          state[Breakpoints.MD] ||
          state[Breakpoints.LG] ||
          state[Breakpoints.XL] ||
          state[Breakpoints.FHD] ||
          state[Breakpoints.UHD]
        );
      case Breakpoints.MD:
        return state[Breakpoints.LG] || state[Breakpoints.XL] || state[Breakpoints.FHD] || state[Breakpoints.UHD];
      case Breakpoints.LG:
        return state[Breakpoints.XL] || state[Breakpoints.FHD] || state[Breakpoints.UHD];
      case Breakpoints.XL:
        return state[Breakpoints.FHD] || state[Breakpoints.UHD];
      case Breakpoints.FHD:
        return state[Breakpoints.UHD];
      default:
        return false;
    }
  }

  /**
   * Checks if the current device size is considered a desktop.
   *
   * @param state the current breakpoints state from the store.
   * @returns true if device is considered a desktop, false if not
   */
  public static isDesktop(state: { [key: string]: boolean }): boolean {
    return Breakpoints.above(Breakpoints.LG, state);
  }
}
