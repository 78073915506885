<div class="modal-header modal-block-primary">
  <h2 class="modal-title pull-left">{{ 'dialog.titles.unsavedChanges' | translate }}</h2>
  <button
    type="button"
    class="close pull-right"
    attr.aria-label="{{ 'actions.close' | translate }}"
    (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body clearfix">
  <div class="modal-text">
    <p>{{ 'dialog.bodies.unsavedChangesBody' | translate }}</p>
  </div>
</div>
<div class="modal-footer">
  <button class="btn btn-primary" (click)="action(false)">{{ 'actions.continueEditing' | translate }}</button>
  <button class="btn btn-danger right" (click)="action(true)">{{ 'actions.leave' | translate }}</button>
</div>
