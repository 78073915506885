import { ApplicationErrorType } from '../../store/quick-form-order-actions/quick-form-order.actions';

export class ServiceError extends Error {
  public readonly applicationCode: ApplicationErrorType;

  public readonly traceId: string;

  public readonly statusCode: number;

  constructor(m: string, traceId: string, applicationCode: ApplicationErrorType, statusCode: number) {
    super(m);
    this.traceId = traceId;
    this.applicationCode = applicationCode;
    this.statusCode = statusCode;
    // Set the prototype explicitly.
    Object.setPrototypeOf(this, ServiceError.prototype);
  }
}
