<div *ngIf="control && control.errors && (control.dirty || control.touched)" class="alert alert-danger">
  <ul class="invalid-feedback">
    <li *ngIf="control.errors.required">
      <small>{{ 'forms.errorMessages.shared.required' | translate }}</small>
    </li>
    <li *ngIf="control.errors.maxlength">
      <small>{{
        'forms.errorMessages.shared.maxLength'
          | translate
            : {
                actualLength: control.errors.maxlength.actualLength,
                maxLength: control.errors.maxlength.requiredLength
              }
      }}</small>
    </li>
    <li *ngIf="control.errors.minlength">
      <small>{{
        'forms.errorMessages.shared.minLength'
          | translate
            : {
                actualLength: control.errors.minlength.actualLength,
                minLength: control.errors.minlength.requiredLength
              }
      }}</small>
    </li>
    <li *ngIf="control.errors.min">
      <small>{{ 'forms.errorMessages.shared.min' | translate: { min: control.errors.min.min } }}</small>
    </li>
    <li *ngIf="control.errors.max">
      <small>{{ 'forms.errorMessages.shared.max' | translate: { min: control.errors.max.max } }}</small>
    </li>
    <li *ngIf="control.errors.matDatepickerMin">
      <small>{{
        'forms.errorMessages.shared.min' | translate: { min: getDateOnly(control.errors.matDatepickerMin.min) }
      }}</small>
    </li>
    <li *ngIf="control.errors.matDatepickerMax">
      <small>{{
        'forms.errorMessages.shared.max' | translate: { max: getDateOnly(control.errors.matDatepickerMax.max) }
      }}</small>
    </li>
    <li *ngIf="control.errors.matDatepickerParse">
      <small>{{ 'forms.errorMessages.shared.invalidDate' | translate }}</small>
    </li>
    <li *ngIf="control.errors.email || control.errors.invalidEmail">
      <small>{{ 'forms.errorMessages.shared.email' | translate }}</small>
    </li>
    <li *ngIf="control.errors.filesLimit">
      <small>
        {{
          'forms.errorMessages.shared.filesLimit' | translate: { maxFilesPerOrder: control.errors.filesLimit.max }
        }}</small
      >
    </li>
    <li *ngIf="control.errors.filesLimitOrder">
      <small>{{
        'forms.errorMessages.shared.filesPerOrderLimit'
          | translate: { maxFilesPerOrder: control.errors.filesLimitOrder.value }
      }}</small>
    </li>
    <li *ngIf="control.errors.fileSize">
      <small>{{
        'forms.errorMessages.shared.fileSizeSingle'
          | translate: { fileSize: control.errors.fileSize[0].maxSize / (1024 * 1024) }
      }}</small>
    </li>
    <li *ngIf="control.errors.fileSizeSingle">
      <small>{{
        'forms.errorMessages.shared.fileSizeSingle'
          | translate: { fileSize: control.errors.fileSize[0].maxSize / (1024 * 1024) }
      }}</small>
    </li>
    <li *ngIf="control.errors.fileTypes">
      <small>{{
        'forms.errorMessages.shared.fileTypes' | translate: { type: control.errors.fileTypes[0].actual }
      }}</small>
    </li>
    <li *ngIf="control.errors.invalidDate">
      <small>{{ 'forms.errorMessages.shared.invalidDate' | translate }}</small>
    </li>
    <li *ngIf="control.errors.notPastDate">
      <small>{{ 'forms.errorMessages.shared.pastDate' | translate }}</small>
    </li>
    <li *ngIf="control.errors.greaterThan">
      <small>{{
        'forms.errorMessages.shared.greaterThan' | translate: { relatedFieldName: control.errors.greaterThan }
      }}</small>
    </li>
    <li *ngIf="control.errors.lessThan">
      <small>{{
        'forms.errorMessages.shared.lessThan' | translate: { relatedFieldName: control.errors.lessThan }
      }}</small>
    </li>
    <li *ngIf="control.errors.futureDate">
      <small>{{ 'forms.errorMessages.shared.futureDate' | translate }}</small>
    </li>
    <li *ngIf="control.errors.futureTime">
      <small>{{ 'forms.errorMessages.shared.futureTime' | translate }}</small>
    </li>
    <li *ngIf="control.errors.futureDateMax === 1">
      <small>{{ 'forms.errorMessages.shared.futureDateMaxOne' | translate }}</small>
    </li>
    <li *ngIf="control.errors.futureDateMax > 1">
      <small>{{
        'forms.errorMessages.shared.futureDateMaxMany' | translate: { value: control.errors.futureDateMax }
      }}</small>
    </li>
    <li *ngIf="control.errors.maxTimeValue">
      <small>{{ 'forms.errorMessages.shared.maxTime' | translate: { value: control.errors.maxTimeValue } }}</small>
    </li>
    <li *ngIf="control.errors.apiValidationError">
      <small>{{ control.errors.apiValidationError }}</small>
    </li>
    <li *ngIf="control.errors.urlNotAvailable">
      <small>{{ 'forms.errorMessages.shared.urlNotAvailable' | translate }}</small>
    </li>
    <li *ngIf="control.errors.repetitionsDontMatch">
      <small>{{ 'forms.labels.userRegistration.passwordValidation.PasswordsDontMatch' | translate }}</small>
    </li>
    <li *ngIf="control.errors.minimumStatic">
      <small
        >{{ 'forms.errorMessages.shared.minStatic' | translate: { value: control.errors.minimumStatic.value } }}
      </small>
    </li>
    <li *ngIf="control.errors.salutationInvalid">
      <small>{{ 'forms.errorMessages.shared.salutationInvalid' | translate }}</small>
    </li>
    <li *ngIf="control.errors.invalidSelection">
      <small>{{ 'forms.errorMessages.shared.invalidSelection' | translate }}</small>
    </li>

    <li *ngIf="control.errors.pattern">
      <small>{{ 'forms.errorMessages.shared.invalidCharacters' | translate }}</small>
    </li>

    <li *ngIf="control.errors.placeholderMissing">
      <small>{{ 'forms.errorMessages.companySettings.placeholderMissing' | translate }}</small>
    </li>

    <li *ngIf="control.errors.invalidDatepickerValue">
      <small>{{ 'forms.errorMessages.shared.invalidColor' | translate: { color: control.value } }}</small>
    </li>

    <li *ngIf="control.errors.imagesXorPdf">
      <small>{{ 'forms.errorMessages.shared.imagesXorPdf' | translate }}</small>
    </li>

    <li *ngIf="control.errors.arrayMaxLength">
      <small>
        {{ 'forms.errorMessages.shared.arrayMaxLength' | translate: { maxLength: control.errors.arrayMaxLength } }}
      </small>
    </li>

    <li *ngIf="control.errors.tagInputMinLength">
      <small>
        {{
          'forms.errorMessages.shared.tagInputMinLength' | translate: { minLength: control.errors.tagInputMinLength }
        }}
      </small>
    </li>

    <li *ngIf="control.errors.iban">
      <small>{{ 'forms.errorMessages.shared.ibanInvalid' | translate }}</small>
    </li>

    <li *ngIf="control.errors.notAUniqueName">
      <small>{{ 'forms.errorMessages.shared.notUniqueName' | translate }}</small>
    </li>

    <li *ngIf="control.errors.bannedWordBehaviourTypeInvalid">
      <small>{{ 'forms.errorMessages.shared.required' | translate }}</small>
    </li>

    <li *ngIf="control.errors.dateOfLockBeforeVisibleFrom">
      <small>{{ 'forms.errorMessages.orderSettings.dateOfLockBeforeVisibleFrom' | translate }}</small>
    </li>

    <li *ngIf="control.errors.visibleFromTooFarIntoTheFuture">
      <small>{{ 'forms.errorMessages.orderSettings.visibleFromTooFarIntoTheFuture' | translate }}</small>
    </li>

    <li *ngIf="control.errors.alreadyRegistered">
      <small>{{ 'forms.errorMessages.invitations.userWithEmailIsAlreadyRegistered' | translate }}</small>
    </li>
  </ul>
</div>
