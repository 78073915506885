import { Injectable } from '@angular/core';
import { CanLoad, Router, UrlTree } from '@angular/router';
import { SettingsService } from '@core/config/settings.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ComponentCatalogGuard implements CanLoad {
  constructor(private settingsService: SettingsService, private router: Router) {}

  canLoad(): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    const isComponentCatalogEnabled = !!this.settingsService.settings.componentCatalogEnabled;
    if (!isComponentCatalogEnabled) {
      return this.router.createUrlTree(['/kontakt']);
    }

    return isComponentCatalogEnabled;
  }
}
