<div class="mpac-stp-widget__wrap">
  <!-- Legacy rendering with action in content area and image instead of icon-->
  <ng-container *ngIf="useLegacyRendering">
    <!-- Header -->
    <div class="mpac-stp-widget__hdr">
      <div class="mpac-stp-widget__hdr-img">
        <img class="mpac-stp-widget__hdr-img-el" [src]="icon" />
      </div>
      <div class="mpac-stp-widget__hdr-hdl">
        <h3 class="mpac-stp-widget__hdr-hdl-txt">{{ headline }}</h3>
      </div>
    </div>

    <!-- Content -->
    <div class="mpac-stp-widget__cnt">
      <div class="mpac-stp-widget__cnt-wrap">
        <div class="mpac-stp-widget__cnt-txt">{{ contentText }}</div>
        <div class="mpac-stp-widget__cnt-action">
          <div class="mpac-stp-widget__cnt-action-wrap">
            <button
              type="button"
              class="btn btn-link mpac-stp-widget__cnt-action-btn"
              [routerLink]="actionLink"
              [disabled]="disabled">
              {{ actionText }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <!-- Progressive rendering with action in headline and icon instead of image -->
  <ng-container *ngIf="!useLegacyRendering">
    <!-- Header -->
    <div class="mpac-stp-widget__hdr">
      <div class="mpac-stp-widget__hdr-ico"></div>
      <div class="mpac-stp-widget__hdr-hdl">
        <h3 class="mpac-stp-widget__hdr-hdl-txt">{{ headline }}</h3>
      </div>
      <div class="mpac-stp-widget__hdr-action-wrap">
        <button type="button" class="btn btn-link mpac-stp-widget__hdr-action-btn" [routerLink]="actionLink">
          {{ actionText }}
        </button>
      </div>
    </div>

    <!-- Content -->
    <div class="mpac-stp-widget__cnt">
      <div class="mpac-stp-widget__cnt-wrap">
        <div class="mpac-stp-widget__cnt-txt">{{ contentText }}</div>
        <div class="mpac-stp-widget__cnt-host"></div>
      </div>
    </div>
  </ng-container>
</div>
