import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SettingsService } from 'src/app/core/config/settings.service';
import { QuickformOrderInfo } from '../models/quickform-order-info';
import { QuickFormResponse } from './dtos/quick-form-response';

@Injectable({
  providedIn: 'root'
})
export class QuickformOrderInfoService {
  /**
   * Endpoint definition.
   */
  private static serviceEndpoint = '/v1/orders';

  constructor(private httpClient: HttpClient, private settingsService: SettingsService) {}

  private get baseUrl(): string {
    return this.settingsService?.settings?.quickformApiRoot
      ? `${this.settingsService.settings.quickformApiRoot}${QuickformOrderInfoService.serviceEndpoint}`
      : '';
  }

  public ordersUrl = (thirdPartyId: string): string => `${this.baseUrl}?thirdPartyId=${thirdPartyId}`;

  /**
   * Loads information about the current users orders from the columba dependant api.
   *
   * @returns The loaded information.
   */
  loadOrderInfo(thirdPartyId: string): Observable<QuickformOrderInfo[]> {
    const url = this.ordersUrl(thirdPartyId);
    return this.httpClient
      .get<QuickFormResponse<QuickformOrderInfo[]>>(url)
      .pipe(map((response: QuickFormResponse<QuickformOrderInfo[]>) => response.data));
  }
}
