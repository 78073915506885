import { Action } from '@ngrx/store';
import { BreadcrumbEntry } from '../../models/breadcrumb-entry';

export enum BreadcrumActionsEnum {
  /* eslint-disable @typescript-eslint/no-shadow */
  ClearBreadcrumbsAction = '[Breadcrumb] Clear Breadcrumbs',
  PopBreadcrumbAction = '[Breadcrumb] Pop Breadcrumb',
  SetBreadcrumbsAction = '[Breadcrumb] Set Breadcrumbs',
  SetBreadcrumbSuccessAction = '[Breadcrumb] Set Breadcrumbs Done',
  SetBreadcrumbFailureAction = '[Breadcrumb] Set Breadcrumbs Failed'
  /* eslint-enable @typescript-eslint/no-shadow */
}

export class ClearBreadcrumbsAction implements Action {
  public readonly type = BreadcrumActionsEnum.ClearBreadcrumbsAction;

  constructor() {}
}

export class PopBreadcrumbAction implements Action {
  public readonly type = BreadcrumActionsEnum.PopBreadcrumbAction;

  constructor() {}
}
export class SetBreadcrumbsAction implements Action {
  public readonly type = BreadcrumActionsEnum.SetBreadcrumbsAction;

  constructor(public company: BreadcrumbEntry, public order: BreadcrumbEntry | null = null) {}
}

export class SetBreadcrumbSuccessAction implements Action {
  public readonly type = BreadcrumActionsEnum.SetBreadcrumbSuccessAction;

  constructor(public breadcrumbs: Array<BreadcrumbEntry>) {}
}

export class SetBreadcrumbFailureAction implements Action {
  public readonly type = BreadcrumActionsEnum.SetBreadcrumbFailureAction;

  constructor(public company: BreadcrumbEntry, public order: BreadcrumbEntry | null = null) {}
}

export type BreadcrumbActions =
  | ClearBreadcrumbsAction
  | PopBreadcrumbAction
  | SetBreadcrumbsAction
  | SetBreadcrumbSuccessAction
  | SetBreadcrumbFailureAction;
