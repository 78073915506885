import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PdfViewerConfig, PdfViewerDialogConfig } from '@shared/models/pdf-viewer.interface';

@Component({
  selector: 'mpac-pdf-viewer-dialog',
  templateUrl: './pdf-viewer-dialog.component.html',
  styleUrls: ['./pdf-viewer-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PdfViewerDialogComponent {
  public static baseClass = 'mpac-dialog-full-screen';

  public pdfPath: string;

  public pdfViewerConfig: PdfViewerConfig;

  constructor(@Inject(MAT_DIALOG_DATA) data: PdfViewerDialogConfig) {
    this.pdfPath = data.src;
    this.pdfViewerConfig = data.config;
  }
}
