import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { StatefulResult } from '../../shared/utils/stateful-result';
import { PrivacyPolicyLink } from '../models/privacy-policy-link';
import { User } from '../models/user';
import { UserRegistration } from '../models/user-registration';
import { CheckedUser } from '../models/user-state';
import { SettingsService } from './../config/settings.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  /**
   * Endpoint definition.
   */
  private static serviceEndpoint = '/auth';

  /**
   * Initializes a new instance of the @{UserService} class. The @{httpClient} to be used for api-access must be injected.
   *
   * @param httpClient: The @{httpClient} to be used for api-access.
   * @param dbService: The @{NgxIndexedDBService} to be used for IndexedDb-access.
   */
  constructor(
    private httpClient: HttpClient,
    private dbService: NgxIndexedDBService,
    private settingsService: SettingsService
  ) {}

  /**
   * The part of the api url, which is the same across all calls issued from this service.
   */
  private get baseUrl(): string {
    return this.settingsService?.settings?.apiRoot
      ? `${this.settingsService.settings.apiRoot}${UserService.serviceEndpoint}`
      : '';
  }

  /**
   * Validates a username and password combination via api. If the combination is valid, the corresponding @{User} is returned
   * as an observable stream.
   *
   * @param userName: The username.
   * @param password: The password.
   */
  public validateUser$(userName: string, password: string): Observable<User> {
    const url = this.baseUrl + '/login';

    return this.httpClient.post<User>(url, { userName, password });
  }

  /**
   * Retrieves information about a logged in user. The successful log in is determined by an existing access token.
   *
   * @returns A stream to the loaded user information.
   */
  public loadUserInfo(): Observable<User> {
    const url = this.baseUrl + '/user-info';

    return this.httpClient.get<User>(url);
  }

  /**
   * Send registration data to the api to create a user and an identity from an invitation and user input.
   *
   * @param hash: Identifies the invitation.
   * @param userRegistration: The user input.
   * @returns Information about the success of the operation.
   */
  public registerUser(hash: string, userRegistration: UserRegistration): Observable<StatefulResult<void>> {
    const url = `${this.settingsService.settings.apiRoot}/registration/register-user/${hash}`;

    return this.httpClient.post<StatefulResult<void>>(url, userRegistration).pipe(
      map(() => new StatefulResult(null, 0, '')),
      catchError((errorResponse: HttpErrorResponse) => {
        const result = new StatefulResult(
          null,
          errorResponse.status,
          (errorResponse.error as string) || errorResponse.message
        );
        if (errorResponse.status === 404) {
          result.message = 'Der Einladungslink ist nicht (mehr) gültig.';
        }

        return of(result);
      })
    );
  }

  public confirmUser(hash: string, userRegistration: UserRegistration): Observable<StatefulResult<void>> {
    const url = `${this.settingsService.settings.apiRoot}/registration/confirm-user/${hash}`;

    return this.httpClient.post<StatefulResult<void>>(url, userRegistration).pipe(
      map(() => new StatefulResult(null, 0, '')),
      catchError((errorResponse: HttpErrorResponse) => {
        const result = new StatefulResult(
          null,
          errorResponse.status,
          (errorResponse.error as string) || errorResponse.message
        );
        if (errorResponse.status === 404) {
          result.message = 'Der Einladungslink ist nicht (mehr) gültig.';
        }

        return of(result);
      })
    );
  }

  public checkInvitedUser(hash: string): Observable<CheckedUser> {
    return this.httpClient.get<CheckedUser>(
      `${this.settingsService.settings.apiRoot}/registration/check-invited-user/${hash}`
    );
  }

  public getPrivacyPolicyLink(hash: string): Observable<PrivacyPolicyLink> {
    return this.httpClient.get<PrivacyPolicyLink>(
      `${this.settingsService.settings.apiRoot}/registration/privacy-link/${hash}`
    );
  }

  /**
   * Is called from the oAuth service when a new token is received
   * updates the access token in the AppDB
   * (images are loaded with the token stored in the AppDB - if the
   * token is not updated, it will expire eventually and the loading
   * of images will fail as unauthorized -> see sw.js)
   */
  public updateAccessToken(newToken: string): void {
    const user: User = this.readUserFromLocalStorage();
    if (!user) {
      return;
    }
    user.accessToken = newToken;
    return this.storeUserToStorages(user);
  }

  /**
   * Stores a user as a serialized json-string in the browsers session store
   * and in the browsers IndexedDb
   *
   * @param user: The user.
   */
  public storeUserToStorages(user: User): void {
    window.localStorage.setItem('user', JSON.stringify(user));

    this.dbService
      .getByKey('user', 1)
      .toPromise()
      .then((result) => {
        if (result) {
          this.dbService
            .update('user', { id: 1, accessToken: user.accessToken })
            .toPromise()
            .then(
              () => {},
              (err) => console.log('UPDATE_ERROR', err)
            );
          return;
        }
        this.dbService
          .add('user', { id: 1, accessToken: user.accessToken })
          .toPromise()
          .then(
            () => {},
            (err) => console.log('CREATE_ERROR', err)
          );
      });
  }

  /**
   * Reads a user from the browsers session store and returns it.
   */
  public readUserFromLocalStorage(): User {
    return JSON.parse(window.localStorage.getItem('user')) as User;
  }

  /**
   * Removes a formerly stored user from the browsers session store
   * an from the browsers IndexedDb
   */
  public removeUserFromStorages(): void {
    window.localStorage.removeItem('user');
    this.dbService.delete('user', 1);
  }

  /**
   * stores the return url in local storage
   */
  public storeReturnUrlInStorage(url: string): void {
    window.localStorage.setItem('returnUrl', url);
  }

  /**
   * returns the return url from local storage
   */
  public readReturnUrlFromStorage(): string {
    return window.localStorage.getItem('returnUrl');
  }

  /**
   * removes the return url from local storage
   */
  public removeReturnUrlFromStorage(): void {
    window.localStorage.removeItem('returnUrl');
  }

  /**
   * stores username in local storage
   */
  public storeUsernameInStorage(username: string): void {
    window.localStorage.setItem('username', username);
  }

  /**
   * returns username from local storage
   */
  public readUsernameFromStorage(): string {
    const username = window.localStorage.getItem('username');
    return username || '';
  }

  /**
   * removes username from local storage
   */
  public removeUsernameFromStorage(): void {
    window.localStorage.removeItem('username');
  }
}
