import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '@core/components/confirm-dialog/confirm-dialog.component';
import { ConfirmDialogModel } from '@core/models/confirm-dialog.model';

@Injectable({
  providedIn: 'root'
})
export class ConfirmDialogService {
  public static dialogMaxWidth = '650px';

  constructor(private dialog: MatDialog, private translate: TranslateService) {}

  get currentCallee(): string {
    const stackTrace = new Error().stack; // Only tested in latest FF and Chrome
    let callerName = stackTrace.replace(/^Error\s+/, ''); // Sanitize Chrome
    callerName = callerName.split('\n')[3].trim(); // 4th item SHOULD be the caller
    callerName = callerName.replace(/^\s+at Object./, ''); // Sanitize Chrome
    callerName = callerName.replace(/ \(.+\)$/, ''); // Sanitize Chrome
    if (callerName.slice(0, 3) === 'at ') {
      callerName = callerName.slice(3);
    } // Sanitize Chrome
    callerName = callerName.replace(/@.+/, ''); // Sanitize Firefox
    callerName = callerName.split('.').reverse()[0]; // Strip away class name in Chrome

    return callerName;
  }

  open(callerClassName: string, context: string = ''): MatDialogRef<ConfirmDialogComponent, any> {
    return this.dialog.open(ConfirmDialogComponent, {
      maxWidth: ConfirmDialogService.dialogMaxWidth,
      panelClass: [ConfirmDialogComponent.baseClass],
      data: this.dialogData(callerClassName, context)
    });
  }

  openAndReturnObservable(callerClassName: string, context: string = ''): Observable<any> {
    return this.dialog
      .open(ConfirmDialogComponent, {
        maxWidth: '650px',
        data: this.dialogData(callerClassName, context),
        panelClass: [ConfirmDialogComponent.baseClass],
        autoFocus: true
      })
      .afterClosed();
  }

  private dialogData(callerClassName: string, context: string): ConfirmDialogModel {
    const translationKeyParts = ['confirmDialog', callerClassName, this.currentCallee, context];
    const translationKey = translationKeyParts.filter((i) => !!i).join('.');
    const translations = this.translate.instant(translationKey);
    if (typeof translations === 'string') {
      console.log(`add translations to de.json => ${translationKey}`);
    }
    return new ConfirmDialogModel(
      translations.title as string,
      translations.message as string,
      translations.confirm as string,
      translations.dismiss as string
    );
  }
}
