<div *ngIf="!isProduction" class="p-2 bg-danger text-center text-white mb-3">
  <h3 class="text-white">{{ 'contact.productionNote.warning' | translate }}</h3>
  <p class="mb-1">{{ 'contact.productionNote.testOnly' | translate }}</p>
</div>
<form (ngSubmit)="formSubmit()" [formGroup]="formGroup">
  <div class="form-row">
    <div class="col-lg-6 form-group">
      <label for="subject">{{ 'contact.form.subjectRequired' | translate }}</label>
      <input
        class="form-control"
        formControlName="subject"
        id="subject"
        placeholder="Bitte geben Sie hier Ihr Thema ein"
        type="text" />
      <mpac-print-form-errors-for [control]="formGroup.get('subject')"></mpac-print-form-errors-for>
    </div>
    <div class="col-lg-6 form-group">
      <label for="email">{{ 'contact.form.emailRequired' | translate }}</label>
      <input
        class="form-control"
        formControlName="senderAddress"
        id="email"
        placeholder="Ihre E-Mail Adresse"
        type="email" />
      <mpac-print-form-errors-for [control]="formGroup.get('senderAddress')"></mpac-print-form-errors-for>
    </div>
  </div>
  <div class="form-group">
    <label for="content">{{ 'contact.form.messageRequired' | translate }}</label>
    <textarea
      class="form-control"
      formControlName="message"
      id="content"
      placeholder="Bitte geben Sie hier Ihre Nachricht ein"
      rows="4"></textarea>
    <mpac-print-form-errors-for [control]="formGroup.get('message')"></mpac-print-form-errors-for>
  </div>
  <!-- !globalWriteProtectionIsActive" -->
  <div class="form-group" *ngIf="true">
    <div class="custom-control custom-checkbox">
      <input
        class="custom-control-input"
        formControlName="dataProtectionCheckbox"
        id="dataProtectionCheckbox"
        type="checkbox" />
      <label class="custom-control-label has-link" for="dataProtectionCheckbox">
        *Ich habe die Informationen in der <a href="{{ privacyPolicyUrl }}" target="_blank">Datenschutzerklärung</a> zu
        Datenverarbeitung bei Kontaktanfragen zur Kenntnis genommen.
      </label>
      <mpac-print-form-errors-for [control]="formGroup.get('dataProtectionCheckbox')"></mpac-print-form-errors-for>
    </div>
  </div>
  <div class="form-row mt-4">
    <div class="col-md-auto mr-auto">
      <span>{{ 'general.required' | translate }}</span>
    </div>
    <mpac-spinner-button
      [disabled]="formGroup.invalid"
      [isBusy]="isBusy"
      btnClasses="btn btn-primary btn-block"
      btnType="submit"
      class="col-md-auto ml-auto">
      <i aria-hidden="true" class="fal fa-paper-plane mr-2"></i>Absenden
    </mpac-spinner-button>
  </div>
</form>
