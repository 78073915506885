import { Component, HostBinding, Input, OnChanges, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { collapseNavigationAction } from '@core/store/display/display.actions';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { delay, tap } from 'rxjs/operators';
import { selectIsNavigationVisible } from '../../../core/store/display/display.reducer';
import { MenuItem, MenuSection } from '../../models/menu-item.interface';
import { UserLevel } from '../../models/user-level.enum';

@Component({
  selector: 'mpac-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SideMenuComponent implements OnInit, OnChanges {
  public static baseClass = 'mpac-side-menu';

  @HostBinding('class') hostClassBinding;

  /**
   * User level of current user
   */
  @Input()
  userLevel: UserLevel = UserLevel.None;

  /**
   * Binding to set the 'open' css class on the host element ('<mpac-side-menu>')
   * when the navigation is visible.
   */
  @HostBinding('class.open') isMenuOpen = false;

  /**
   * menu items to display
   */
  @Input()
  public menuSections: MenuSection[] = [];

  /**
   * indicates if the navbar is visible
   */
  public isNavbarVisible$: Observable<boolean>;

  /**
   * menu items and sections to display after access control is applied
   */
  public menuDisplayData: MenuSection[];

  constructor(private store$: Store<any>, private sanitizer: DomSanitizer) {}

  private static applyVisibilityRules(section: MenuSection): MenuSection {
    const items = section.items.filter((item: MenuItem) => !item.disabled);
    return { ...section, items };
  }

  public ngOnInit(): void {
    this.menuSections = this.menuSections || [];
    this.setHostClassBinding();
    this.isNavbarVisible$ = this.store$.pipe(
      select(selectIsNavigationVisible),
      delay(0),
      tap((isVisible) => (this.isMenuOpen = isVisible))
    );
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.userLevel) {
      this.userLevel = changes.userLevel.currentValue || UserLevel.None;
      this.menuDisplayData = this.menuSections
        .map((section) => this.applyAccessRules(section))
        .map((section) => SideMenuComponent.applyVisibilityRules(section))
        .filter((section) => section.items.length);
    }
    if (changes.menuSections) {
      const value = (changes.menuSections.currentValue as MenuSection[]) || [];
      this.menuDisplayData = value
        .map((section) => this.applyAccessRules(section))
        .map((section) => SideMenuComponent.applyVisibilityRules(section))
        .filter((section) => section.items.length);
    }
  }

  /**
   * Creates a @{SafeUrl}, that can be used as href-parameter in a link. The source is created from a raw url
   *
   * @param url: The raw url.
   * @returns The {SafeUrl} to be used as src-parameter in an image-tag.
   */
  public sanitizedUrl(url: string): SafeUrl {
    if (!url) {
      return;
    }
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  public collapseNavigation(): void {
    this.store$.dispatch(collapseNavigationAction());
  }

  protected setHostClassBinding(): void {
    this.hostClassBinding = `${SideMenuComponent.baseClass}`;
  }

  private applyAccessRules(section: MenuSection): MenuSection {
    const items = section.items.filter(
      (item: MenuItem) => item.allowedUserLevels && item.allowedUserLevels.includes(this.userLevel)
    );
    return { ...section, items };
  }
}
