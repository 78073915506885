<mpac-spinner-full class="mpac-form__wrap" [isBusy]="isBusy">
    <form *ngIf="!!form" class="mpac-form__cnt" [formGroup]="form">
        <div class="mpac-form__row form-row">
            {{ 'forms.emailSettings.introText' | translate }}
        </div>
        <div class="mpac-form__row form-row">
            <div class="custom-control custom-switch">
                <input class="custom-control-input" id="orderAdminInvitationSent" formControlName="orderAdminInvitationSent" type="checkbox" (change)="onToggleClicked($event)" />
                <label class="custom-control-label" for="orderAdminInvitationSent">
                    {{ 'forms.emailSettings.labels.orderAdminInvitationSent' | translate }}
                    <i class="fal fa-info-circle" tooltip="{{ 'forms.emailSettings.tooltips.orderAdminInvitationSent' | translate }}" placement="right"></i>
                </label>
            </div>
        </div>
        <div class="mpac-form__row form-row">
            <div class="custom-control custom-switch">
                <input class="custom-control-input" id="orderAdminRegistered" formControlName="orderAdminRegistered" type="checkbox" (change)="onToggleClicked($event)" />
                <label class="custom-control-label" for="orderAdminRegistered">
                    {{ 'forms.emailSettings.labels.orderAdminRegistered' | translate }}
                    <i class="fal fa-info-circle" tooltip="{{ 'forms.emailSettings.tooltips.orderAdminRegistered' | translate }}" placement="right"></i>
                </label>
            </div>
        </div>
        <div class="mpac-form__row form-row">
            <div class="custom-control custom-switch">
                <input class="custom-control-input" id="orderAdminDeRegistered" formControlName="orderAdminDeRegistered" type="checkbox" (change)="onToggleClicked($event)" />
                <label class="custom-control-label" for="orderAdminDeRegistered">
                    {{ 'forms.emailSettings.labels.orderAdminDeRegistered' | translate }}
                    <i class="fal fa-info-circle" tooltip="{{ 'forms.emailSettings.tooltips.orderAdminDeRegistered' | translate }}" placement="right"></i>
                </label>
            </div>
        </div>
        <div *ngIf="isMortician" class="mpac-form__row form-row">
            <div class="custom-control custom-switch">
                <input class="custom-control-input" id="companyUserRegistered" formControlName="companyUserRegistered" type="checkbox" (change)="onToggleClicked($event)" />
                <label class="custom-control-label" for="companyUserRegistered">
                    {{ 'forms.emailSettings.labels.companyUserRegistered' | translate }}
                    <i class="fal fa-info-circle" tooltip="{{ 'forms.emailSettings.tooltips.companyUserRegistered' | translate }}" placement="right"></i>
                </label>
            </div>
        </div>
        <div class="mpac-form__row form-row">
            <div class="custom-control custom-switch">
                <input class="custom-control-input" id="memorialPageVisibilityEndsReminder" formControlName="memorialPageVisibilityEndsReminder" type="checkbox" (change)="onToggleClicked($event)" />
                <label class="custom-control-label" for="memorialPageVisibilityEndsReminder">
                    {{ 'forms.emailSettings.labels.memorialPageVisibilityEndsReminder' | translate }}
                    <i class="fal fa-info-circle" tooltip="{{ 'forms.emailSettings.tooltips.memorialPageVisibilityEndsReminder' | translate }}" placement="right"></i>
                </label>
            </div>
        </div>
    </form>
</mpac-spinner-full>
