<!-- Add images fab -->
<button
  (click)="clicked()"
  *ngIf="visible"
  [title]="title"
  [class.btn-primary]="isPrimary"
  [class.btn-secondary]="!isPrimary"
  [ngClass]="{ 'btn-primary': isPrimary, 'btn-secondary': !isPrimary, handheld: isMobile }"
  class="mpac-page-action-button__btn btn-fab btn"
  [mpacIcon]="iconNames">
  {{ !isMobile ? title : '' }}
</button>
