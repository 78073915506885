import { UserLevel } from '../models/user-level.enum';

export class UserUtil {
  public static isMorticianOrRapid(userLevel: UserLevel): boolean {
    return UserUtil.isRapid(userLevel) || UserUtil.isMortician(userLevel);
  }

  public static isMortician(userLevel: UserLevel): boolean {
    return userLevel && userLevel === UserLevel.Mortician;
  }

  public static isPageAdmin(userLevel: UserLevel): boolean {
    return userLevel && userLevel === UserLevel.PageAdmin;
  }

  public static isRapid(userLevel: UserLevel): boolean {
    return userLevel && userLevel === UserLevel.Rapid;
  }
}
