import { AuthConfigService } from './auth.config.service';
import { SettingsHttpService } from './../config/settings.http.service';
import { Injectable } from '@angular/core';
import { distinctUntilChanged, filter } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AuthFactoryHttpService {
  public injector: any;

  constructor(private authConfigService: AuthConfigService, private settingsHttpService: SettingsHttpService) {}

  initializeApp(): Promise<any> {
    return new Promise((resolve) => {
      this.settingsHttpService.appSettingsInitialized$
        .pipe(
          distinctUntilChanged(),
          filter((isInitialized) => isInitialized)
        )
        .subscribe(() => resolve(this.authConfigService.authConfig));
    });
  }
}
