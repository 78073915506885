import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { SettingsService } from '@core/config/settings.service';
import { EmailSettings } from '@shared/models/email-settings';

@Injectable({
  providedIn: 'root'
})

export class UserSettingsService {
  private static emailKeys = {
    orderAdminInvitationSent: 'OrderAdminInvitationSent',
    orderAdminRegistered: 'OrderAdminRegistered',
    orderAdminDeRegistered: 'OrderAdminDeRegistered',
    companyUserRegistered: 'CompanyUserRegistered',
    memorialPageVisibilityEndsReminder: 'MemorialPageVisibilityEndsReminder'
  };

  constructor(private http: HttpClient, private settingsService: SettingsService) {}

  private get baseUrl(): string {
    return `${this.settingsService.settings.apiRoot || ''}`;
  }

  public getUserEmailSettings(): Observable<EmailSettings> {
    const url = `${this.baseUrl}/users/email-settings`;
    const defaultSettings = {
        isOrderAdminInvitationSentEmailEnabled: true,
        isOrderAdminRegisteredEmailEnabled: true,
        isOrderAdminDeRegisteredEmailEnabled: true,
        isCompanyUserRegisteredEmailEnabled: true,
        isMemorialPageVisibilityEndsReminderEmailEnabled: true
    };

    return this.http
      .get<EmailSettings>(url)
      .pipe(
        map((response: EmailSettings) => response || defaultSettings)
      );
  }

  public updateEmailSettings(emailKey: string, shouldEmailBeEnabled: boolean): Observable<any> {
    const translatedKey = UserSettingsService.emailKeys[emailKey];
    const url = `${this.baseUrl}/users/email-settings`;
    const requestDto = {
        emailKey: translatedKey,
        shouldEmailBeEnabled
    };

    return this.http.put(url, requestDto);
  }
}
