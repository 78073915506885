import { OrderMasterdata } from './../dtos/order-masterdata.interface';
import {
  QuickFormOrderDocumentType,
  AllowanceActionStatusType,
  QuickFormOrderStatus,
  ExpectedOspHitActionType,
  QuickFormOrderAction,
  PartnerCustomerStatusType,
  QuickFormOrderActionLoadingStatus,
  QuickFormOrder,
  QuickFormOrderActionDocument,
  QuickFormOrderActionParameter,
  QuickFormOrderActionComment,
  QuickFormOrderActionCommentType,
  QuickFormLoginType,
  QuickFormFileType,
  QuickFormOrderActionDocumentType,
  QuickFormOrderActionType,
  QuickFormOrderResumeDocument,
  QuickFormOrderActionProduct,
  QuickFormPrefilledDocumentType,
  QuickFormPrefilledDocument,
  QuickFormMoneyRepartition,
  QuickFormMoneyRepartitionReceiverType,
  QuickFormAccountInformation
} from '../../models';
import {
  QuickFormOrderActionResumeDto,
  QuickFormOrderActionDto,
  QuickFormOrderActionDocumentDto,
  QuickFormOrderActionParameterDto,
  QuickFormOrderActionCommentDto,
  QuickFormOrderActionProductDto,
  QuickFormPrefilledDocumentDto,
  QuickFormMoneyRepartitionDto,
  QuickFormAccountInformationDto,
  OrderMasterdataDto
} from '../dtos/quick-form-order-action.dto';
import { QuickFormOrderDto, QuickFormOrderResumeDocumentDto } from '../dtos/quick-form-order.dto';
import { QuickFormOrderActionParameterTypeConverter } from './quick-form-order-action-parameter-type.converter';

export class DtoConverter {
  private static readonly documentTypeMapping = [
    {
      text: 'UNDEFINED',
      enum: QuickFormOrderDocumentType.Undefined
    },
    {
      text: 'DEATH_CERTIFICATE',
      enum: QuickFormOrderDocumentType.DeathCertificate
    },
    {
      text: 'CERTIFICATE_OF_AUTHORITY',
      enum: QuickFormOrderDocumentType.CertificateOfAuthority
    },
    {
      text: 'CERTIFICATE_OF_INHERITANCE',
      enum: QuickFormOrderDocumentType.CertificateOfInheritance
    },
    {
      text: 'ADDITIONAL_DOCUMENT',
      enum: QuickFormOrderDocumentType.AdditionalDocument
    },
    {
      text: 'INSURANCE_CONTRACT',
      enum: QuickFormOrderDocumentType.InsuranceContract
    },
    {
      text: 'CERTIFICATE_OF_AUTHORITY_QUICKFORM_ONLY',
      enum: QuickFormOrderDocumentType.CertificateOfAuthorityQuickFormOnly
    },
    {
      text: 'ID_CARD_COPY',
      enum: QuickFormOrderDocumentType.IdCardCopy
    },
    {
      text: 'CERTIFICATE_OF_INHERITANCE_AUSTRIAN',
      enum: QuickFormOrderDocumentType.CertificateOfInheritanceAustrian
    },
    {
      text: 'OFFICIAL_CONFIRMATION_OF_ERBANTRITTSERKLAERUNG',
      enum: QuickFormOrderDocumentType.OfficialConfirmationOfErbantrittserklarung
    },
    {
      text: 'OFFICIAL_AUTHORIZATION',
      enum: QuickFormOrderDocumentType.OfficialAuthorization
    },
    {
      text: 'DOCUMENTARY_EVIDENCE',
      enum: QuickFormOrderDocumentType.DocumentaryEvidence
    },
    {
      text: 'MANDATE_AUTHORIZATION',
      enum: QuickFormOrderDocumentType.MandateAuthorization
    },
    {
      text: 'CERTIFICATE_OF_AUTHORITY_PRECAUTION',
      enum: QuickFormOrderDocumentType.CertificateOfAuthorityPrecaution
    },
    {
      text: 'UNKNOWN',
      enum: QuickFormOrderDocumentType.Unknown
    },
    {
      text: 'MERGED_ORDER_DOCUMENTS',
      enum: QuickFormOrderDocumentType.MergedOrderDocuments
    }
  ];

  public static resumeToQuickFormPartnerAction(dto: QuickFormOrderActionResumeDto): QuickFormOrderAction {
    return {
      id: dto.id,
      type: DtoConverter.toOrderActionType(dto.type),
      allowanceActionStatus: DtoConverter.toAllowanceActionStatusType(dto.allowanceActionStatus),
      // todo: fix it
      expectedOspHitAction: ExpectedOspHitActionType[dto.expectedOspHitAction] as ExpectedOspHitActionType,
      // todo: fix it
      partnerCustomerStatus: PartnerCustomerStatusType[dto.partnerCustomerStatus] as PartnerCustomerStatusType,
      domain: dto.domain,
      createdAt: new Date(dto.createdAt),
      updatedAt: new Date(dto.updatedAt),
      statusChangedAt: new Date(dto.statusGainedDate),
      product: DtoConverter.toQuickFormPartnerActionProduct(dto.product),
      loadStatus: QuickFormOrderActionLoadingStatus.Idle
    } as QuickFormOrderAction;
  }

  public static toQuickFormOrder(dto: QuickFormOrderDto): QuickFormOrder {
    return {
      id: dto.id,
      status: DtoConverter.toQuickFormOrderStatus(dto.status),
      isDigitalLegacyActivated: dto.ospActivated,
      activationDate: new Date(dto.activationDate),
      liveSpan: dto.liveSpan,
      orderDate: new Date(dto.orderDate),
      deceasedEmailAdresses: dto.deceasedEmailAdresses,
      documents: dto.documents.map((d) => DtoConverter.toQuickFormOrderDocumentResume(d))
    } as QuickFormOrder;
  }

  public static toQuickFormPartnerAction(dto: QuickFormOrderActionDto): QuickFormOrderAction {
    const action = DtoConverter.resumeToQuickFormPartnerAction(dto);
    action.documents = dto.documents.map((documentDto) => DtoConverter.toQuickFormPartnerActionDocument(documentDto));
    action.comments = dto.comments.map((documentDto) => DtoConverter.toQuickFormPartnerActionComment(documentDto));
    action.parameters = dto.parameters.map((parameterDto) =>
      DtoConverter.toQuickFormPartnerActionParameter(parameterDto)
    );
    action.prefilledDocuments = dto.prefilledDocuments.map(DtoConverter.toQuickFormPrefilledDocument);
    action.moneyRepartitions = dto.moneyRepartitions.map(DtoConverter.toMoneyRepartition);
    action.accountTransferRecipient = DtoConverter.toAccountRecipient(dto.thirdPerson);
    return action;
  }

  public static toMoneyRepartitionReceiverType(value: string): QuickFormMoneyRepartitionReceiverType {
    switch (value) {
      case 'Mortician':
        return QuickFormMoneyRepartitionReceiverType.Mortician;
      case 'Dependant':
        return QuickFormMoneyRepartitionReceiverType.Dependant;
      case 'ThirdPerson':
        return QuickFormMoneyRepartitionReceiverType.ThirdPerson;
      case 'PartnerAccount':
        return QuickFormMoneyRepartitionReceiverType.PartnerAccount;
      default:
        return QuickFormMoneyRepartitionReceiverType.Undefined;
    }
  }

  public static toQuickFormPartnerActionDocument(dto: QuickFormOrderActionDocumentDto): QuickFormOrderActionDocument {
    return {
      id: dto.id,
      type: DtoConverter.toQuickFormPartnerActionDocumentType(dto.type),
      fileType: DtoConverter.toQuickFormFileType(dto.fileType),
      fileName: dto.fileName,
      displayName: dto.displayName
    } as QuickFormOrderActionDocument;
  }

  public static toQuickFormPartnerActionParameter(
    dto: QuickFormOrderActionParameterDto
  ): QuickFormOrderActionParameter {
    return {
      type: QuickFormOrderActionParameterTypeConverter.toType(dto.parameterHelp),
      value: dto.parameterValue,
      unknownValue: dto.notSet
    } as QuickFormOrderActionParameter;
  }

  public static toQuickFormPartnerActionComment(dto: QuickFormOrderActionCommentDto): QuickFormOrderActionComment {
    return {
      isImage: dto.isImage,
      creatorType: DtoConverter.toQuickFormLoginType(dto.creatorType),
      value: dto.value,
      commentType: DtoConverter.toCommentType(dto.commentType),
      createdAt: new Date(dto.createdAt),
      updatedAt: new Date(dto.updatedAt)
    } as QuickFormOrderActionComment;
  }

  public static toDocumentTypeString(value: QuickFormOrderDocumentType): string {
    const elt = DtoConverter.documentTypeMapping.find((x) => x.enum === value);
    return !elt
      ? DtoConverter.documentTypeMapping.find((x) => x.enum === QuickFormOrderDocumentType.Unknown).toString()
      : elt.text;
  }

  public static toDocumentType(value: string): QuickFormOrderDocumentType {
    const elt = DtoConverter.documentTypeMapping.find((x) => x.text === value);
    return !elt ? QuickFormOrderDocumentType.Unknown : elt.enum;
  }

  public static toAllowanceActionStatusType(value: string): AllowanceActionStatusType {
    switch (value) {
      case 'ON_HOLD':
        return AllowanceActionStatusType.OnHold;
      case 'IN_PROCESS':
        return AllowanceActionStatusType.InProcess;
      case 'DISCLOSURE_INFORMED':
        return AllowanceActionStatusType.DisclosureInformed;
      case 'DISCLOSURE_INFORMED_AND_CONFIRMED':
        return AllowanceActionStatusType.DisclosureInformedAndConfirmed;
      case 'DISCLOSURE_ANSWERED':
        return AllowanceActionStatusType.DisclosureAnswered;
      case 'DNV_INFORMED':
        return AllowanceActionStatusType.DnvInformed;
      case 'DNV_INFORMED_AND_CONFIRMED':
        return AllowanceActionStatusType.DnvInformedAndConfirmed;
      case 'DNV_ACTION_REJECTED':
        return AllowanceActionStatusType.DnvActionRejected;
      case 'DNV_ACTION_EXECUTED':
        return AllowanceActionStatusType.DnvActionExecuted;
      case 'NO_ACTION_SINCE_NO_CUSTOMER':
        return AllowanceActionStatusType.NoActionSinceNoCustomer;
      case 'RESEARCHING':
        return AllowanceActionStatusType.Researching;
      case 'DNV_ACTION_ANSWERED':
        return AllowanceActionStatusType.DnvActionAnswered;
      case 'DO_NOT_DISTURB':
        return AllowanceActionStatusType.DoNotDisturb;
      case 'NEEDS_ACTION_SINCE_CUSTOMER':
        return AllowanceActionStatusType.NeedsActionSinceCustomer;
      case 'DISCARDED':
        return AllowanceActionStatusType.Discarded;
      case 'RESEARCH_ERROR':
        return AllowanceActionStatusType.ResearchError;
      case 'CE_PROCESSING':
        return AllowanceActionStatusType.CeProcessing;
      case 'UNEXPECTED_ERROR':
        return AllowanceActionStatusType.UnexepectedError;
      case 'SEND_CUSTOMER_CANCEL_FAX':
        return AllowanceActionStatusType.SendCustomerCancelFax;
      case 'FAX_FAILED':
        return AllowanceActionStatusType.FaxFailed;
      case 'CREATED':
        return AllowanceActionStatusType.Created;
      case 'UNDEFINED':
      default:
        return AllowanceActionStatusType.Undefined;
    }
  }

  public static toQuickFormOrderStatus(value: string): QuickFormOrderStatus {
    switch (value) {
      case 'DRAFT':
        return QuickFormOrderStatus.Draft;
      case 'CONFIRMED':
        return QuickFormOrderStatus.Confirmed;
      case 'IN_PROGRESS':
        return QuickFormOrderStatus.InProgress;
      case 'PAUSED':
        return QuickFormOrderStatus.Paused;
      case 'FINISHED':
        return QuickFormOrderStatus.Finished;
      case 'ANONYMIZED':
        return QuickFormOrderStatus.Anonymized;
      case 'ARCHIVED':
        return QuickFormOrderStatus.Archived;
      case 'UNDEFINED':
      default:
        return QuickFormOrderStatus.Undefined;
    }
  }

  private static toQuickFormPrefilledDocument(dto: QuickFormPrefilledDocumentDto): QuickFormPrefilledDocument {
    return {
      name: dto.name,
      type: DtoConverter.toPrefilledDocumentType(dto.type)
    } as QuickFormPrefilledDocument;
  }

  private static toAccountRecipient(dto: OrderMasterdataDto): OrderMasterdata {
    return dto as OrderMasterdata;
  }

  private static toMoneyRepartition(dto: QuickFormMoneyRepartitionDto): QuickFormMoneyRepartition {
    return {
      sum: dto.sum,
      receiverType: DtoConverter.toMoneyRepartitionReceiverType(dto.receiverType),
      accountInformation: DtoConverter.toAccountInformation(dto.accountInformation)
    } as QuickFormMoneyRepartition;
  }

  private static toAccountInformation(accountInformation: QuickFormAccountInformationDto): QuickFormAccountInformation {
    if (accountInformation == null) {
      return null;
    }
    return {
      bic: accountInformation.bic,
      iban: accountInformation.iban,
      recipientName: accountInformation.recipientName
    } as QuickFormAccountInformation;
  }

  private static toPrefilledDocumentType(value: string): QuickFormPrefilledDocumentType {
    switch (value) {
      case 'CessionDeclaration':
        return QuickFormPrefilledDocumentType.CessionDeclaration;
      case 'LossDeclaration':
        return QuickFormPrefilledDocumentType.LossDeclaration;
      case 'OriginalsNeededCoverLetter':
        return QuickFormPrefilledDocumentType.OriginalsNeededCoverLetter;
      case 'WidowPensionDeclaration':
        return QuickFormPrefilledDocumentType.WidowPensionDeclaration;
      default:
        return QuickFormPrefilledDocumentType.Undefined;
    }
  }

  private static toCommentType(value: number): QuickFormOrderActionCommentType {
    switch (value) {
      case 1:
        return QuickFormOrderActionCommentType.DenialReason;
      case 2:
        return QuickFormOrderActionCommentType.CreatorsComment;
      case 3:
        return QuickFormOrderActionCommentType.Comment;
      case 0:
      default:
        return QuickFormOrderActionCommentType.Undefined;
    }
  }

  private static toQuickFormLoginType(value: number): QuickFormLoginType {
    switch (value) {
      case 0:
        return QuickFormLoginType.Undefined;
      case 1:
        return QuickFormLoginType.ColumbaEmployee;
      case 2:
        return QuickFormLoginType.MorticianEmployee;
      case 3:
        return QuickFormLoginType.PartnerEmployee;
      case 4:
        return QuickFormLoginType.Dependant;
      case 5:
        return QuickFormLoginType.PotentialPartner;
      case 6:
        return QuickFormLoginType.MorticianEnterpriseEmployee;
      case 7:
        return QuickFormLoginType.Precaution;
      case 99:
        return QuickFormLoginType.ThirdParty;
      case 100:
        return QuickFormLoginType.ThirdPartyCreateMortician;
      case 101:
        return QuickFormLoginType.ThirdPartyInsurance;
      case 999:
        return QuickFormLoginType.System;
      case 1001:
      default:
        return QuickFormLoginType.Undefined;
    }
  }

  private static toQuickFormFileType(value: string): QuickFormFileType {
    switch (value) {
      case 'PDF':
        return QuickFormFileType.Pdf;
      case 'PNG':
        return QuickFormFileType.Png;
      case 'HTML':
        return QuickFormFileType.Html;
      case 'JPG':
        return QuickFormFileType.Jpg;
      case 'EML':
        return QuickFormFileType.Eml;
      case 'JSON':
        return QuickFormFileType.Json;
      case 'UNDEFINED':
      default:
        return QuickFormFileType.Undefined;
    }
  }

  private static toQuickFormPartnerActionDocumentType(value: string): QuickFormOrderActionDocumentType {
    switch (value) {
      case 'CANCELLATION':
        return QuickFormOrderActionDocumentType.Cancellation;
      case 'CANCELLATION_CONFIRMATION':
        return QuickFormOrderActionDocumentType.CancellationConfirmation;
      default:
        return QuickFormOrderActionDocumentType.Unknown;
    }
  }

  private static toOrderActionType(value: string): QuickFormOrderActionType {
    switch (value) {
      case 'UNDEFINED':
        return QuickFormOrderActionType.Undefined;
      case 'DIRECT_CANCELATION':
        return QuickFormOrderActionType.DirectCancellation;
      case 'DISCLOSURE':
        return QuickFormOrderActionType.Disclosure;
      case 'DIRECT_TRANSFER':
        return QuickFormOrderActionType.DirectTransfert;
      case 'DIRECT_CANCELATION_WITH_EXPECTED_BALANCE':
        return QuickFormOrderActionType.DirectCancellationWithExpectedBalance;
    }
  }

  private static toQuickFormOrderDocumentResume(dto: QuickFormOrderResumeDocumentDto): QuickFormOrderResumeDocument {
    return {
      id: dto.id,
      type: DtoConverter.toDocumentType(dto.type),
      createdAt: new Date(dto.createdAt),
      verified: dto.verified,
      fileName: dto.fileName,
      displayName: dto.displayName
    } as QuickFormOrderResumeDocument;
  }

  private static toQuickFormPartnerActionProduct(dto: QuickFormOrderActionProductDto): QuickFormOrderActionProduct {
    return {
      name: dto.name,
      brandName: dto.brandName,
      companyName: dto.companyName
    } as QuickFormOrderActionProduct;
  }
}
