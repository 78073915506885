import { createSelector } from '@ngrx/store';
import { OrderPortalState, orderPortalStateFeatureSelector } from 'src/app/features/order-portal/store/common';
import { initialPrecautionInfoState } from './precaution-info.reducer';

export const selectPrecautionInfoForOrder = createSelector(
  orderPortalStateFeatureSelector,
  (state: OrderPortalState) => state?.precautionInfoState?.configuration || initialPrecautionInfoState.configuration
);

export const selectPrecautionInfoAdditionalInfoRequested = createSelector(
  orderPortalStateFeatureSelector,
  (state: OrderPortalState) => !!state?.precautionInfoState?.infoRequested
);

export const precautionInfoLoaded = createSelector(
  orderPortalStateFeatureSelector,
  (state: OrderPortalState) => !state?.precautionInfoState?.isLoading
);
