export class QuickFormErrorResponse {
  error: DefaultQuickFormResponse;
}

export interface IQuickFormResponse {
  errorCode: number;
  messages: Array<any>;
  traceId: string;
  statusCode: number;
}

export class DefaultQuickFormResponse implements IQuickFormResponse {
  errorCode: number;

  messages: any[];

  traceId: string;

  statusCode: number;
}

export interface QuickFormResponse<T> extends IQuickFormResponse {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  Data: T;
  data: T;
}
