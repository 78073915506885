import { createSelector } from '@ngrx/store';
import { Breakpoints } from '../../helper/breakpoints';
import { Device, Display } from '../../models/display';
import { CoreState, coreStateFeatureSelector } from '../common';
import {
  collapseNavigationAction,
  DisplayAction,
  reportBreakpointStateAction,
  reportNewBreakpointsStateAction,
  toggleHelpVisibilityAction,
  toggleNavigationVisibilityAction
} from './display.actions';

export const initialDisplayState: Display = {
  breakpointsState: {},
  currentDevice: Device.Desktop,
  isNavigationVisible: false,
  isHelpVisible: false
};

export const displayReducer = (state: Display = initialDisplayState, action: DisplayAction): Display => {
  switch (action.type) {
    case reportNewBreakpointsStateAction.type: {
      return {
        ...state,
        breakpointsState: action.breakpoints,
        currentDevice: Breakpoints.isDesktop(action.breakpoints) ? Device.Desktop : Device.Mobile
      };
    }

    case reportBreakpointStateAction.type: {
      // do not act on unmatch notification
      if (action.matches === false) {
        return state;
      }

      // copy existing state into new object
      const newBreakpointsState = { ...state.breakpointsState };

      // reset breakpoints
      Breakpoints.asList.forEach((breakpoint) => {
        newBreakpointsState[breakpoint] = false;
      });

      // update the concerning breakpoint status
      newBreakpointsState[action.breakpoint] = action.matches;

      return {
        ...state,
        breakpointsState: newBreakpointsState,
        currentDevice: Breakpoints.isDesktop(newBreakpointsState) ? Device.Desktop : Device.Mobile
      };
    }

    case collapseNavigationAction.type: {
      return {
        ...state,
        isNavigationVisible: false
      };
    }

    case toggleNavigationVisibilityAction.type: {
      return {
        ...state,
        isNavigationVisible: !state.isNavigationVisible
      };
    }

    case toggleHelpVisibilityAction.type: {
      return {
        ...state,
        isHelpVisible: !state.isHelpVisible
      };
    }
  }

  return state;
};

export const selectBreakpointsState = createSelector(coreStateFeatureSelector, (state: CoreState) =>
  state ? state.display.breakpointsState : initialDisplayState.breakpointsState
);

export const selectIsBreakpointAbove = createSelector(
  coreStateFeatureSelector,
  selectBreakpointsState,
  (state: CoreState, breakpoints: { [k: string]: boolean }, breakpoint: string) =>
    state ? Breakpoints.above(breakpoint, breakpoints) : true
);

export const selectCurrentDevice = createSelector(coreStateFeatureSelector, (state: CoreState) =>
  state ? state.display.currentDevice : Device.Desktop
);

export const selectIsNavigationVisible = createSelector(coreStateFeatureSelector, (state: CoreState) =>
  state ? state.display.isNavigationVisible : false
);

export const selectIsHelpVisible = createSelector(coreStateFeatureSelector, (state: CoreState) =>
  state ? state.display.isHelpVisible : false
);
