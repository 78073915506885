export class StatefulResult<T> {
  state = 0;

  message = '';

  data: T;

  constructor(data: T, state = 0, message = '') {
    this.data = data;
    this.state = state;
    this.message = message;
  }
}
