import { ComponentType } from '@angular/cdk/overlay';
import { Injectable, TemplateRef } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { deepmerge } from 'deepmerge-ts';
import { FilterDialogComponent } from '../components/filter/filter-dialog/filter-dialog.component';
import { FilterViewModel } from '../models/filter/filter.view-model';
import { IFilterDialogModel } from './../models/filter/filter-dialog.model';

@Injectable({
  providedIn: 'root'
})
export class DialogService {
  constructor(private dialog: MatDialog) {}

  /**
   * Merges the dialog config base with te provided options and returns the resulting configuration.
   */
  private static mergeConfig<D>(config?: MatDialogConfig<D>): MatDialogConfig {
    const baseConfig = {
      panelClass: ['mpac-mat-dlg']
    };

    if (config.panelClass && config.panelClass.length && !Array.isArray(config.panelClass)) {
      config.panelClass = [config.panelClass];
    }

    return deepmerge(baseConfig, config);
  }

  /**
   * Opens the dialog for the given template and options returning a reference.
   */
  public open<T, D = any, R = any>(
    componentOrTemplateRef: ComponentType<T> | TemplateRef<T>,
    config?: MatDialogConfig<D>
  ): MatDialogRef<T, R> {
    return this.dialog.open(componentOrTemplateRef, DialogService.mergeConfig(config));
  }

  /**
   * Opens the dialog for the given template and returns an after close observable.
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public openAndReturnObservable<T, D = any, R = any>(
    componentOrTemplateRef: ComponentType<T> | TemplateRef<T>,
    config?: MatDialogConfig<D>
  ): Observable<any> {
    return this.dialog.open(componentOrTemplateRef, DialogService.mergeConfig(config)).afterClosed();
  }

  /**
   * Opens the filter dialog with the desired options and returns an obserable.
   */
  public openFilterDialog(config: IFilterDialogModel): Observable<Array<FilterViewModel> | null | undefined | boolean> {
    return this.openAndReturnObservable(FilterDialogComponent, {
      panelClass: [FilterDialogComponent.baseClass],
      data: config
    });
  }
}
