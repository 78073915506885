import { AfterViewInit, Directive, ElementRef, OnDestroy, Renderer2 } from '@angular/core';
import { UserAgentHelper } from '@shared/helpers/user-agent.helper';
import { TooltipDirective } from 'ngx-bootstrap/tooltip';
import { Subscription } from 'rxjs';

/**
 * Directive for tooltip hiding on touch devices when performing a scroll
 */
@Directive({
  selector: '[mpacHideTooltipsOnScroll]'
})
export class HideTooltipsOnScrollDirective implements AfterViewInit, OnDestroy {
  private subscriptions: Subscription[] = [];

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private tooltipDirective: TooltipDirective
  ) {}

  public ngAfterViewInit(): void {
    if (this.elementRef && this.elementRef.nativeElement && UserAgentHelper.isTouchDevice()) {
      const scrollableParentElement: HTMLElement = this.getNextScrollableParentElement(this.elementRef);
      if (scrollableParentElement) {
        this.subscriptions.push(
          this.tooltipDirective.onShown.subscribe(() => {
            const unlistenFunction = this.renderer.listen(scrollableParentElement, 'scroll', () => {
              const oldTooltipFadeDuration = this.tooltipDirective.tooltipFadeDuration;
              this.tooltipDirective.tooltipFadeDuration = 0;
              this.tooltipDirective.hide();
              this.tooltipDirective.tooltipFadeDuration = oldTooltipFadeDuration;
              this.elementRef.nativeElement.blur();
              unlistenFunction();
            });
          })
        );
      }
    }
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  private getNextScrollableParentElement(elementRef: ElementRef): HTMLElement {
    if (elementRef && elementRef.nativeElement) {
      let parentElement = elementRef.nativeElement.parentElement;
      while (parentElement) {
        const parentHTMLElement = parentElement as HTMLElement;
        const computedStyle: CSSStyleDeclaration = window.getComputedStyle(parentHTMLElement, null);
        const overflowValue = computedStyle.getPropertyValue('overflow');
        const overflowYValue = computedStyle.getPropertyValue('overflow-y');
        const overflowXValue = computedStyle.getPropertyValue('overflow-x');
        const isScrollable =
          overflowValue === 'auto' ||
          overflowValue === 'scroll' ||
          overflowYValue === 'auto' ||
          overflowYValue === 'scroll' ||
          overflowXValue === 'auto' ||
          overflowXValue === 'scroll';
        if (isScrollable) {
          return parentHTMLElement;
        }
        parentElement = parentElement.parentElement;
      }
    }
    return null;
  }
}
