import { ValidationType } from './validation-type.enum';

export class PasswordValidation {
  type: ValidationType;

  typeValue: any;

  constructor(
    type: ValidationType,
    typeValue: any = null) {
    this.type = type;
    this.typeValue = typeValue;
  }

  public get translationKey(): string {
    // NOTE: subject to change if other usecases occur
    return `forms.labels.userRegistration.passwordValidation.${this.type}`;
  }

  public passesFor(value: string = ''): boolean {
    if (!value) {
      return false;
    }

    const regex = new RegExp(this.getRegExpStr());
    return regex.test(value);
  }

  private getRegExpStr(): string {
    switch (this.type) {
      case ValidationType.Uppercase:
        return '[A-Z]';
      case ValidationType.Lowercase:
        return '[a-z]';
      case ValidationType.Digit:
        return '[0-9]';
      case ValidationType.SpecialChar:
        return '[$@$!%*?&.,:;""§\\/()=<>#-*_|]';
      case ValidationType.MinLength:
        return `.{${this.typeValue as string},}`;
      default:
        return '';
    }
  }
}

export const defaultPasswordValidations = [
  new PasswordValidation(ValidationType.Uppercase),
  new PasswordValidation(ValidationType.Lowercase),
  new PasswordValidation(ValidationType.Digit),
  new PasswordValidation(ValidationType.SpecialChar),
  new PasswordValidation(ValidationType.MinLength, 8)
];

export const identityServerRegex = /^(?=.*[a-z])(?=.*[A-ZäöüÄÖÜß])(?=.*\d)(?=.*[$@$!%*?&.,:;""§\\/()=<>#\-*_|])[A-Za-zäöüÄÖÜß\d$@$!%*?&.,:;""§\\/()=<>#\-*_|]{8,}$/;
