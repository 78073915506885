export interface PdfViewerConfig {
  showSpinner?: boolean;
  allowDownload?: boolean;
  allowPrint?: boolean;
  allowSearchTools?: boolean;
  allowFullScreen?: boolean;
  allowFileOpen?: boolean;
  allowViewBookmark?: boolean;
  onDocumentLoad?(): any;
}

export interface PdfViewerDialogConfig {
  src: string;
  config?: PdfViewerConfig;
}

export const defaultPdfViewerConfig: PdfViewerConfig = {
  showSpinner: true,
  allowDownload: false,
  allowPrint: false,
  allowSearchTools: false,
  allowFullScreen: true,
  allowFileOpen: false,
  allowViewBookmark: false,
  onDocumentLoad: () => {}
};
