import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormControl, UntypedFormArray } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { FilterViewModel } from 'src/app/shared/models/filter/filter.view-model';
import { FormBaseComponent } from '../../form-base-component/form-base-component.component';
import { FilterDisplayType } from 'src/app/shared/enums/filter-display-type.enum';
import { FormsService } from '@rapid/forms';

@Component({
  selector: 'mpac-filter-form',
  templateUrl: './filter-form.component.html',
  styleUrls: ['./filter-form.component.variables.scss', './filter-form.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FilterFormComponent extends FormBaseComponent<Array<FilterViewModel>> implements OnInit {
  @Input()
  public filters: Array<FilterViewModel>;

  public formIsInit = false;

  constructor(
    protected formBuilder: UntypedFormBuilder,
    protected translateService: TranslateService,
    protected formsService: FormsService
  ) {
    super(formBuilder, translateService, formsService);
  }

  public ngOnInit(): void {
    this.initForm();
  }

  /**
   * Base call for initializing the form.
   */
  public submit(): void {
    if (this.form && this.form.valid) {
      this.submittedEmitter.emit(this.convertFormDataToType());
    }
  }

  public convertFormDataToType(): FilterViewModel[] | null {
    if (!this.form) {
      return null;
    }
    const filterViewModel = this.filters.slice(0);
    for (let i = 0, iLen = filterViewModel.length; i < iLen; ++i) {
      const filterItem = filterViewModel[i];
      const childKey = this.getItemKey(filterItem, i);

      const control = this.getFormControlNested(childKey);

      if (control instanceof UntypedFormArray) {
        // checkbox style
        for (let j = 0, jLen = filterItem.displayValues.length; j < jLen; ++j) {
          const item = filterItem.displayValues[j];
          item.selected = control.controls[j].value;
        }
      } else {
        for (let j = 0, jLen = filterItem.displayValues.length; j < jLen; ++j) {
          const item = filterItem.displayValues[j];
          item.selected =
            Array.isArray(control.value) && control.value.find((cv) => cv === item.value)
              ? true
              : control.value === item.value;
        }
      }
    }
    return filterViewModel;
  }

  public getItemKey(filterItem: FilterViewModel, index: number): string {
    return `${index}_${filterItem.key}`;
  }

  public isList(filterItem: FilterViewModel): boolean {
    return filterItem.displayType === FilterDisplayType.Select;
  }

  public isCheckbox(filterItem: FilterViewModel): boolean {
    return filterItem.displayType === FilterDisplayType.Checkbox;
  }

  public setHostClassBinding(): void {}

  protected initForm(): void {
    const group = new UntypedFormGroup({});
    if (!this.filters || !this.filters.length) {
      return;
    }
    for (let i = 0, iLen = this.filters.length; i < iLen; ++i) {
      const filterItem = this.filters[i];
      const childKey = this.getItemKey(filterItem, i);

      if (filterItem.displayType === FilterDisplayType.Checkbox) {
        const formArray = new UntypedFormArray([]);
        for (const value of filterItem.displayValues) {
          formArray.controls.push(new UntypedFormControl(value.selected));
        }
        group.addControl(childKey, formArray);
      } else {
        const values = [];
        for (const value of filterItem.displayValues) {
          if (value.selected) {
            values.push(value.value);
          }
        }

        // add form array here
        group.addControl(childKey, new UntypedFormControl(values));
      }
    }
    this.form = group;
  }
}
