import { DesctructableComponent } from 'src/app/shared/components/destructable/destructable.component';
import { ISetHostClasses } from './../../interfaces/iset-host-classes.interface';
import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  ViewEncapsulation,
  OnChanges,
  SimpleChanges,
  OnInit
} from '@angular/core';
import { slideInOutBottom } from '@core/animations/slide-in-out-bottom.animation';
import { selectIsHelpVisible } from '@core/store/display/display.reducer';
import { select, Store } from '@ngrx/store';
import { AlertType } from '../../enums/form-save-alert-types.enum';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'mpac-form-save',
  templateUrl: './form-save.component.html',
  styleUrls: ['./form-save.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [slideInOutBottom]
})
export class FormSaveComponent extends DesctructableComponent implements ISetHostClasses, OnInit, OnChanges {
  public static baseClass = 'mpac-form-save';

  public static baseClassVisible = 'mpac-form-save--visible';

  public static helpClassVisible = 'mpac-form-save--help';

  @HostBinding('class') hostClassBinding;

  @Input() messageContextKey: string;

  @Input() messageType: AlertType;

  @Input() displayMessage = false;

  @Input() submitDisabled: boolean;

  @Input()
  public animate = true;

  @Output() submitClicked: EventEmitter<void> = new EventEmitter<void>();

  public isHelpVisible = false;

  constructor(protected store: Store<any>) {
    super();
  }

  public get messageTranslationKey(): string {
    return ['formSave', this.contextOrDefaultMessageKey, this.messageType].filter((el) => !!el).join('.');
  }

  private get contextOrDefaultMessageKey(): string {
    return this.messageContextKey ? this.messageContextKey : 'shared';
  }

  public ngOnInit(): void {
    this.setHostClassBinding();
    this.subscribeToHelpVisibility();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes && !changes.displayMessage?.isFirstChange()) {
      this.setHostClassBinding();
    }
  }

  public buttonClicked(): void {
    if (!this.submitDisabled) {
      this.submitClicked.emit();
    }
  }

  public setHostClassBinding(): void {
    const classes = [FormSaveComponent.baseClass];
    if (this.displayMessage) {
      classes.push(FormSaveComponent.baseClassVisible);
    }
    if (this.isHelpVisible) {
      classes.push(FormSaveComponent.helpClassVisible);
    }
    this.hostClassBinding = classes.join(' ');
  }

  protected subscribeToHelpVisibility(): void {
    this.store.pipe(select(selectIsHelpVisible), takeUntil(this.shutdown$)).subscribe((visible) => {
      this.isHelpVisible = visible;
      this.setHostClassBinding();
    });
  }
}
