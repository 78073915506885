import { Component, ViewEncapsulation, OnInit, HostBinding } from '@angular/core';
import { CoreState } from '@core/store/common';
import { selectUserLevel } from '@core/store/user/user.reducer';
import { select, Store } from '@ngrx/store';
import { DesctructableComponent } from '@shared/components/destructable/destructable.component';
import { UserLevel } from '@shared/models/user-level.enum';
import { delay, takeUntil, tap } from 'rxjs/operators';
import { toggleHelpVisibilityAction, toggleNavigationVisibilityAction } from '../../store/display/display.actions';
import { TrainingCampLinkPlacement } from '../training-camp-link/training-camp-link.component';

@Component({
  selector: 'mpac-mobile-menu',
  templateUrl: './mobile-menu.component.html',
  styleUrls: ['./mobile-menu.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MobileMenuComponent extends DesctructableComponent implements OnInit {
  public static baseClass = 'mpac-mobile-menu';

  @HostBinding('class') hostClassBinding;

  public trainingCampLinkPlacement: TrainingCampLinkPlacement = 'mobile-menu';

  public isTrainingCampLinkVisible: boolean;

  constructor(private store$: Store<CoreState>) {
    super();
    this.store$
      .pipe(
        takeUntil(this.shutdown$),
        select(selectUserLevel),
        delay(0),
        tap((level: UserLevel) => {
          this.isTrainingCampLinkVisible = [UserLevel.Mortician, UserLevel.Rapid].includes(level);
        })
      )
      .subscribe();
  }

  public ngOnInit(): void {
    this.setHostClassBinding();
  }

  public toggleNav(): void {
    this.store$.dispatch(toggleNavigationVisibilityAction());
  }

  public toggleHelp(): void {
    this.store$.dispatch(toggleHelpVisibilityAction());
  }

  protected setHostClassBinding(): void {
    this.hostClassBinding = MobileMenuComponent.baseClass;
  }
}
