import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'mpac-logged-out-layout',
  templateUrl: './logged-out-layout.component.html',
  styleUrls: ['./logged-out-layout.component.scss']
})
export class LoggedOutLayoutComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
