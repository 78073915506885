import { FilterFormComponent } from './../filter-form/filter-form.component';
import { FilterViewModel } from './../../../models/filter/filter.view-model';
import { Component, Inject, ViewEncapsulation, ViewChild, HostBinding, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IFilterDialogModel } from '../../../models/filter/filter-dialog.model';

@Component({
  selector: 'mpac-filter-dialog',
  templateUrl: './filter-dialog.component.html',
  styleUrls: ['./filter-dialog.component.variables.scss', './filter-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FilterDialogComponent implements OnInit {
  public static baseClass = 'mpac-fltr-dlg';

  @HostBinding('class') hostClassBinding;

  @ViewChild('filterForm') filterForm: FilterFormComponent;

  public title: string;

  public resetButtonText: string;

  public applyButtonText: string;

  public filterList: Array<FilterViewModel>;

  constructor(
    public dialogRef: MatDialogRef<FilterDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IFilterDialogModel
  ) {
    // Update view with given values
    this.title = data.title;
    this.resetButtonText = data.resetButtonText;
    this.applyButtonText = data.applyButtonText;
    this.filterList = data.filterList;
  }

  public ngOnInit(): void {
    this.setHostClassBinding();
  }

  public dismiss(data: Array<FilterViewModel>): void {
    this.dialogRef.close(data);
  }

  public reset(): void {
    this.dialogRef.close(true);
  }

  public submitClicked(): void {
    if (this.filterForm) {
      this.filterForm.submit();
    }
  }

  /**
   * Overrides the base class method to add the widget-specific class too.
   */
  protected setHostClassBinding(): void {
    this.hostClassBinding += FilterDialogComponent.baseClass;
  }
}
