import { ViewChild, ElementRef, Component, OnDestroy, OnInit, ViewEncapsulation, HostBinding } from '@angular/core';
import { User } from '@core/models/user';
import { NavigationService } from '@core/services/navigation.service';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';
import { delay, distinctUntilChanged, map, takeUntil, tap } from 'rxjs/operators';
import { TrackerIntegrationService } from 'src/app/shared/services/tracker-integration.service';
import { ActivatableFeature } from '../../../shared/models/activatable-feature';
import { UserLevel } from '../../../shared/models/user-level.enum';
import { CoreState } from '../../store/common';
import { selectUser, selectUserLevel, selectUserName } from '../../store/user/user.reducer';
import { SettingsService } from '@core/config/settings.service';
import { AuthService } from '@core/services/auth.service';
import { DependantDemoService } from 'src/app/shared/services/dependant-demo.service';
import { selectCompany, selectCompanyFeatures } from '@core/store/company/company.selectors';
import { selectOrder } from 'src/app/features/order-portal/store/order/order.reducer';
import {
  quickformOrderFeaturesSelector,
  QuickformOrderFeaturesState
} from 'src/app/features/order-portal/store/quickform-order-features/quickform-order-features.reducer';
import { Router } from '@angular/router';
import { TrainingCampLinkPlacement } from '../training-camp-link/training-camp-link.component';
import { UserUtil } from '@shared/helpers/user-util';
import { DialogService } from '@shared/services/dialog-service';
import { EmailSettingsDialogComponent } from '@shared/components/email-settings-dialog/email-settings-dialog.component';

@Component({
  selector: 'mpac-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HeaderComponent implements OnInit, OnDestroy {
  public static baseClass = 'mpac-header';

  // TODO: SETTINGS!
  private static logoCustomers = 'logo-customer-center.png';

  // TODO: SETTINGS!
  private static logoMorticians = 'logo-service-center.png';

  private static imgDir = '/assets/images/logos';

  private static maxImageErrorRetries = 10;

  @HostBinding('class') hostClassBinding;

  @ViewChild('filterIcon') filterIcon: ElementRef;

  /**
   * indicates if the user is logged in
   */
  public isUserLoggedIn$: Observable<boolean>;

  /**
   * stream of current usernames from store
   */
  public userName$: Observable<string>;

  /**
   * The route for navigating to the user's root page (depending on the user's level).
   */
  public userRootRoute$: Observable<string>;

  /**
   * indicates if columba is active for this order
   */
  public isColumbaActive = false;

  /**
   * the title that is displayed in the left side of the header
   */
  public headerTitle = '';

  /**
   * path to the icon on the left side of the header
   */
  public headerIconPath = '';

  /**
   * the rapid id of the company associated to the current order
   */
  public companyRapidId: string;

  /**
   * the name of the company associated to the current order
   */
  public companyName: string;

  /**
   * the access level of the current user
   */
  public userLevel: UserLevel;

  /**
   * indicates if the logo image is currently loading
   */
  public logoIsLoading = true;

  /**
   * Indicates whether the menu option for dependant demo is available to the user.
   */
  public isDependantDemoVisible$: Observable<boolean>;

  public currentRoute: string;

  public trainingCampLinkPlacement: TrainingCampLinkPlacement = 'header';

  public isTrainingCampLinkVisible: boolean;

  /**
   * columba name segment for the request url
   */

  private nameSegmentId = '';

  private shutdown$: Subject<void> = new Subject<void>();

  private imageErrorRetries = 0;

  constructor(
    private store$: Store<CoreState>,
    private settingsService: SettingsService,
    private trackingService: TrackerIntegrationService,
    private translateService: TranslateService,
    private navigationService: NavigationService,
    private authService: AuthService,
    private dependantDemoService: DependantDemoService,
    private dialogService: DialogService,
    private router: Router
  ) {}

  public get isPageAdmin(): boolean {
    return UserUtil.isPageAdmin(this.userLevel);
  }

  /**
   * builds and returns the url to quick form
   */
  public get quickformUserData(): string {
    return `/gedenkseiten/${this.nameSegmentId}/columba/kontoeinstellungen`;
  }

  /**
   * builds the url to the company icon
   */
  public get companyIconUrl(): string {
    const config = this.settingsService.settings;
    return `${config.imageAssetRootStatic}${config.imageAssetStaticIndividual.replace(
      '{companyId}',
      this.companyRapidId
    )}${config.imageAssetStaticFavIco}`;
  }

  public ngOnInit(): void {
    this.setHostClassBinding();

    this.isUserLoggedIn$ = this.store$.pipe(
      takeUntil(this.shutdown$),
      select(selectUserLevel),
      delay(0),
      tap((level) => (this.userLevel = level)),
      map((level: UserLevel) => level > UserLevel.None)
    );

    this.isUserLoggedIn$.subscribe((isUserLoggedIn) => {
      if (isUserLoggedIn) {
        this.updateHeader(false);
      }
    });

    this.userName$ = this.store$.pipe(takeUntil(this.shutdown$), select(selectUserName));

    this.userRootRoute$ = this.store$.pipe(
      takeUntil(this.shutdown$),
      select(selectUser),
      map((user: User) => this.navigationService.getRootRouteForUser(user))
    );

    const activeFeatureNames$ = this.store$.pipe(
      takeUntil(this.shutdown$),
      select(selectCompanyFeatures),
      distinctUntilChanged(),
      map((features: ActivatableFeature[]) => features.filter((f) => f.isActive).map((f) => f.internalName))
    );

    this.isDependantDemoVisible$ = activeFeatureNames$.pipe(
      map((activeFeatureNames: string[]) => {
        if (!this.settingsService.demoMorticianSettings.dependantDemoFeatureEnabled) {
          return false;
        }

        if (this.authService.isRapidUser()) {
          return true;
        }

        return this.authService.isMortician() && !!activeFeatureNames.includes('DependantDemo');
      })
    );

    this.store$.pipe(takeUntil(this.shutdown$), select(selectCompany)).subscribe((c) => {
      if (!c || this.companyRapidId === c?.rapidId) {
        return;
      }
      this.companyRapidId = c.rapidId;
      this.companyName = c.name;
      this.updateHeader(false);
    });

    this.subscribeToQuickformOrderId();
    this.subscribeToOrder();
  }

  public ngOnDestroy(): void {
    this.shutdown$.next();
    this.shutdown$.unsubscribe();
  }

  /**
   * updates the header values
   */
  public updateHeader(hasImageLoadError: boolean): void {
    this.logoIsLoading = true;
    switch (this.userLevel) {
      case UserLevel.Rapid:
      case UserLevel.Mortician:
        this.headerTitle = this.translateService.instant('general.serviceMinusCenter');
        this.headerIconPath =
          `${HeaderComponent.imgDir}/${HeaderComponent.logoMorticians}` + '?' + new Date().getTime().toString();
        this.isTrainingCampLinkVisible = true;
        break;
      case UserLevel.PageAdmin:
        this.headerTitle = this.dependantDemoActive()
          ? this.translateService.instant('general.kundenMinusCenter')
          : this.companyName;
        if (this.companyRapidId) {
          if (hasImageLoadError) {
            if (this.imageErrorRetries < HeaderComponent.maxImageErrorRetries) {
              this.imageErrorRetries++;
              this.headerIconPath = `${HeaderComponent.imgDir}/${HeaderComponent.logoCustomers}?${new Date()
                .getTime()
                .toString()}`;
            }
          } else {
            this.headerIconPath = `${this.companyIconUrl}?${new Date().getTime().toString()}`;
          }
        } else {
          this.headerIconPath =
            `${HeaderComponent.imgDir}/${HeaderComponent.logoCustomers}` + '?' + new Date().getTime().toString();
        }
        this.isTrainingCampLinkVisible = false;
        break;
      default:
    }
  }

  /**
   * sets the logo visible
   */
  public showLogo(): void {
    this.logoIsLoading = false;
  }

  /**
   * Displays a cookie/technology banner for the user to change their privacy settings.
   */
  public displayPrivacySettings(): void {
    this.trackingService.displayCookieBanner();
  }

  public displayEmailSettings(): void {
    const isMortician = this.userLevel === UserLevel.Mortician;
    this.dialogService.open(EmailSettingsDialogComponent, {
      panelClass: [EmailSettingsDialogComponent.baseClass],
      data: {
        isMortician
      }
    });
  }

  public startDependantDemo(): void {
    this.authService.impersonate(this.settingsService.demoMorticianSettings.dependantIds4UserId);
    this.router.navigate(['/login']);
  }

  public finishDependantDemo(): void {
    this.authService.depersonate();
  }

  public dependantDemoActive(): boolean {
    return this.dependantDemoService.dependantDemoUserActive();
  }

  protected setHostClassBinding(): void {
    this.hostClassBinding = HeaderComponent.baseClass;
  }

  /**
   * subscribes to the store to check if columba is active
   */
  private subscribeToQuickformOrderId(): void {
    // TODO: Fix!
    this.store$
      .pipe(
        takeUntil(this.shutdown$),
        select(quickformOrderFeaturesSelector),
        map((state: QuickformOrderFeaturesState) => !!state?.quickformOrderId)
      )
      .subscribe((hasQuickformOrderId: boolean) => {
        this.isColumbaActive = hasQuickformOrderId;
      });
  }

  /**
   * subscribes to the store to get the columba name segment
   */
  private subscribeToOrder(): void {
    // TODO: Fix!
    this.store$.pipe(takeUntil(this.shutdown$), select(selectOrder)).subscribe((order) => {
      this.nameSegmentId = `${order?.friendlyUrl}-${order?.pk}`;
    });
  }
}
