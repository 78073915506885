export class ByteFormatter {
  public static niceByteFormat(value: number): string {
    const b = this.formatBytes(value);
    return `${b.size} ${b.type}`;
  }

  private static formatBytes(bytes) {
    const kb = 1024;
    const ndx = Math.floor(Math.log(bytes as number) / Math.log(kb));
    const fileSizeTypes = ['bytes', 'Kb', 'Mb', 'Gb', 'Tb', 'Pb', 'Eb', 'Zb', 'Yb'];
    return {
      size: +(bytes / kb / kb).toFixed(2),
      type: fileSizeTypes[ndx]
    };
  }
}
