import cloneDeep from 'lodash-es/cloneDeep';
import { BreadcrumbState, initialBreadcrumbsState } from './breadcrumb-state.model';
import { BreadcrumbActions, BreadcrumActionsEnum } from './breadcrumbs.actions';

export const breadcrumbsReducer = (
  state: BreadcrumbState = initialBreadcrumbsState,
  action: BreadcrumbActions
): BreadcrumbState => {
  let currentEntries;

  switch (action.type) {
    case BreadcrumActionsEnum.PopBreadcrumbAction:
      if (state.entries.length) {
        currentEntries = cloneDeep(state.entries);
        currentEntries.pop();
        return {
          ...state,
          entries: currentEntries
        };
      }
      return initialBreadcrumbsState;

    case BreadcrumActionsEnum.SetBreadcrumbSuccessAction:
      return {
        ...state,
        entries: action.breadcrumbs
      };

    case BreadcrumActionsEnum.ClearBreadcrumbsAction:
      return initialBreadcrumbsState;
  }
  return state;
};
