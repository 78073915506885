<mpac-mat-dialog-header [headline]="title"></mpac-mat-dialog-header>

<mpac-mat-dialog-content [customClass]="'mpac-filter-dialog__cnt'">
  <ng-template #contentElementRef>
    <mpac-filter-form [filters]="filterList" #filterForm (submittedEmitter)="dismiss($event)"></mpac-filter-form>
  </ng-template>
</mpac-mat-dialog-content>

<mpac-mat-dialog-footer>
  <ng-template let-functions>
    <mpac-mat-dialog-footer-fct>
      <ng-template let-fct>
        <button class="btn btn-outline-primary" (click)="reset()">
          <span class="btn__txt mpac-mat-dlg__ftr-fct-txt">
            {{ resetButtonText }}
          </span>
        </button>
      </ng-template>
    </mpac-mat-dialog-footer-fct>

    <mpac-mat-dialog-footer-fct>
      <ng-template let-fct>
        <button (click)="submitClicked()" class="btn btn-primary">
          <span class="btn__ico mpac-mat-dlg__ftr-fct-ico">
            <i class="fad svg-inline--fa fa-save"></i>
          </span>
          <span class="btn__txt mpac-mat-dlg__ftr-fct-txt">
            {{ applyButtonText }}
          </span>
        </button>
      </ng-template>
    </mpac-mat-dialog-footer-fct>
  </ng-template>
</mpac-mat-dialog-footer>
