import { Injectable, isDevMode } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { SettingsService } from '@core/config/settings.service';
import {
  GdprDisplayBannerGuardService,
  ShouldDisplayBannerResult,
  StoredGdprCookiesWithConfig
} from 'gdpr-cookie-banner';
import { IHotjarSettings, NgxHotjarInjectorService, NgxHotjarService } from 'ngx-hotjar-injector';
import { filter } from 'rxjs/operators';

/**
 * Tracking admission response data from the banner.
 */
enum TrackingAdmission {
  Admitted = 1,
  Forbidden = -1,
  Indeterminate = 2,
  NullValue = 3
}

/**
 * Service that handles user tracking and GDPR consent.
 */
@Injectable({
  providedIn: 'root'
})
export class TrackerIntegrationService {
  private _trackingInitialized = false;

  private _trackingAdmitted = false;

  private _trackingChanged = false;

  private _currentCookies: StoredGdprCookiesWithConfig = null;

  private _hotjarActive = false;

  constructor(
    private router: Router,
    private settingsService: SettingsService,
    private cookieBannerGuard: GdprDisplayBannerGuardService,
    private hotjarInjector: NgxHotjarInjectorService,
    private hjService: NgxHotjarService
  ) {}

  public get trackingInitialized(): boolean {
    return this._trackingInitialized;
  }

  public get trackingAdmitted(): boolean {
    return this._trackingAdmitted;
  }

  public get trackingChanged(): boolean {
    return this._trackingChanged;
  }

  /**
   * Adds the cookie banner check subscription on the router events.
   */
  public withPrivacySetup(): void {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((navEnd: NavigationEnd) => this.navigationEndHandler(navEnd));
  }

  public displayCookieBanner(): void {
    this.cookieBannerGuard.displayCookieBannerWhenReady(() => {
      this._trackingChanged = true;
      if (!this.trackingInitialized || this.trackingChanged) {
        this.initTracking();
      }
      if (
        this.isStaticsticsAdmitted() === TrackingAdmission.Forbidden ||
        this.isStaticsticsAdmitted() === TrackingAdmission.Indeterminate
      ) {
        window.location.reload();
      }
    });
  }

  /**
   * Handles the navigation end event and checks cookie initialization.
   */
  private navigationEndHandler(navEnd: NavigationEnd): void {
    const bannerResult = this.cookieBannerGuard.shouldDisplayBanner();
    switch (bannerResult) {
      case ShouldDisplayBannerResult.yes:
        // display
        this.cookieBannerGuard.displayCookieBannerWhenReady(() => {
          if (!this._trackingInitialized) {
            this.initTracking();
          }
        });
        break;
      case ShouldDisplayBannerResult.no:
        if (!this._trackingInitialized) {
          this.initTracking();
        }
        break;
      case ShouldDisplayBannerResult.error:
      case ShouldDisplayBannerResult.serviceNotReady:
        console.log('Cookie Banner service not ready!');
        break;
    }

    this.sendTrackingNavEvent(navEnd);
  }

  private isStaticsticsAdmitted(): TrackingAdmission {
    if (!this._currentCookies) {
      return TrackingAdmission.NullValue;
    }
    const tracking = this._currentCookies.categories.find((cat) => cat.name === 'tracking');

    if (tracking) {
      if (tracking.indeterminate) {
        return TrackingAdmission.Indeterminate;
      }
      return tracking.selected ? TrackingAdmission.Admitted : TrackingAdmission.Forbidden;
    }
    return TrackingAdmission.Forbidden;
  }

  /**
   * Initializes the tracking based on user consent.
   */
  private initTracking() {
    const cookies = this.cookieBannerGuard.getSavedCookiesWithConfig();
    if (!cookies) {
      return;
    }
    // check user consent
    if (navigator.doNotTrack !== undefined || navigator.doNotTrack !== null || navigator.doNotTrack !== '1') {
      this._trackingAdmitted = true;
      this._currentCookies = cookies;

      const statisticsAdmission = this.isStaticsticsAdmitted();

      if (statisticsAdmission === TrackingAdmission.NullValue) {
        return;
      }
      if (statisticsAdmission === TrackingAdmission.Admitted) {
        this._trackingAdmitted = true;
        this.initHotjar();
      } else {
        if (statisticsAdmission === TrackingAdmission.Indeterminate) {
          this._trackingAdmitted = true;
        }
        const category = this._currentCookies.categories.find((cat) => cat.name === 'tracking');
        // check if we need to revoke an element
        const hotjar = category.vendors.find((vendor) => vendor.name === 'hotjar');

        if (!this._hotjarActive && hotjar.selected) {
          this.initHotjar();
        } else if (this._hotjarActive && !hotjar.selected) {
          this._hotjarActive = false;
        }
      }
      // initialize tracking
      this._trackingInitialized = true;
    }
    this._trackingChanged = false;
  }

  /**
   * Initializes the HotJar tracking service with the relevant info.
   */
  private initHotjar() {
    if (!!this.settingsService.settings.hotjarKey === false) {
      return;
    }
    this.hotjarInjector.init({ hotjarId: this.settingsService.settings.hotjarKey } as IHotjarSettings);
    this._hotjarActive = true;
  }

  /**
   * Sends the tracking events for the page view.
   */
  private sendTrackingNavEvent(navEnd: NavigationEnd): void {
    if (this._trackingAdmitted) {
      try {
        if (this._hotjarActive) {
          this.hjService.virtualPageView(navEnd.urlAfterRedirects);
        }
      } catch (e) {
        if (isDevMode()) {
          console.warn('Tracking implementation caused an error!');
          console.warn(e);
        }
      }
    }
  }
}
