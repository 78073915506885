import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NgxGalleryAnimation, NgxGalleryComponent, NgxGalleryImage, NgxGalleryOptions } from '@kolkov/ngx-gallery';
import { TranslateService } from '@ngx-translate/core';
import { DesctructableComponent } from '@rapid/forms';
import isEqual from 'lodash-es/isEqual';
import { Observable } from 'rxjs';

export interface ImageGalleryConfig {
  options?: NgxGalleryOptions[];
  images: NgxGalleryImage[];
}

export const slideIntervalConfig = {
  min: 2000,
  max: 30000,
  default: 3000,
  step: 1000
};

export const imageGalleryConfigBaseOptions: NgxGalleryOptions = {
  width: '100%',
  height: '100%',
  imagePercent: 80,
  thumbnails: true,
  imageAnimation: NgxGalleryAnimation.Slide,
  previewInfinityMove: true,
  imageSwipe: true,
  previewFullscreen: true,
  previewCloseOnEsc: true,
  previewKeyboardNavigation: true,
  previewAutoPlay: true,
  previewArrows: true,
  previewAutoPlayInterval: slideIntervalConfig.default,
  previewDescription: false,
  previewArrowsAutoHide: true,
  preview: true
};

@Component({
  selector: 'mpac-image-gallery',
  templateUrl: './image-gallery.component.html',
  styleUrls: ['./image-gallery.component.scss']
})
export class ImageGalleryComponent extends DesctructableComponent implements OnInit, OnChanges {
  @ViewChild('ngxGalleryComponentRef') ngxGalleryComponentRef: NgxGalleryComponent;

  @Input() config: ImageGalleryConfig;

  @Input() isRuntimeConfigurationVisible = false;

  @Input() externalPreviewTrigger: Observable<void>;

  @Output() galleryImageChanged: EventEmitter<number> = new EventEmitter();

  @Output() galleryImagePreviewOpened: EventEmitter<number> = new EventEmitter();

  public galleryOptions: NgxGalleryOptions[] = [];

  public galleryImages: NgxGalleryImage[] = [];

  public slideIntervalConfig = slideIntervalConfig;

  public configOverridesForm: UntypedFormGroup;

  private isCustomBackgroundColorInvalid = false;

  private isPlaying = true;

  private currentImageIndex = 0;

  private isPreviewOpen = false;

  constructor(
    formBuilder: UntypedFormBuilder,
    private host: ElementRef<HTMLElement>,
    private translateService: TranslateService
  ) {
    super();
    this.configOverridesForm = formBuilder.group({
      slideInterval: [imageGalleryConfigBaseOptions.previewAutoPlayInterval],
      descriptionEnabled: [imageGalleryConfigBaseOptions.previewDescription],
      previewBackgroundColor: ['']
    });

    this.subscribeToFormControlChanges();
  }

  public get shouldShowColorPickerErrorMessage(): boolean {
    return this.isCustomBackgroundColorInvalid && !!this.configOverridesForm.value.previewBackgroundColor;
  }

  public ngOnInit(): void {
    this.setGalleryOptions();
    this.setGalleryImages();
    this.subscribeToExternalPreviewTrigger();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.config &&
      changes.config.firstChange === false &&
      !isEqual(changes.config.currentValue.images, this.galleryImages)
    ) {
      this.galleryImages = changes.config.currentValue.images;
      this.ngxGalleryComponentRef.images = this.galleryImages;
      this.ngxGalleryComponentRef.ngDoCheck();
      if (this.isPreviewOpen) {
        this.ngxGalleryComponentRef.preview.showAtIndex(this.currentImageIndex);
      }
      this.ngxGalleryComponentRef.image.reset(this.currentImageIndex);
    }
  }

  public onChange($event: { index: number; image: NgxGalleryImage }): void {
    this.currentImageIndex = $event.index;
    this.galleryImageChanged.emit(this.currentImageIndex);
  }

  public onPreviewChange($event: { index: number; image: NgxGalleryImage }): void {
    this.currentImageIndex = $event.index;
    this.galleryImagePreviewOpened.emit(this.currentImageIndex);
  }

  public toggleIsPreviewOpen(isOpen: boolean): void {
    this.isPreviewOpen = isOpen;

    if (!this.isPreviewOpen) {
      this.ngxGalleryComponentRef.image.show(this.currentImageIndex);
    }
  }

  public formatSliderLabel(value: number): string {
    return (value / 1000).toString();
  }

  public startPreviewMode(): void {
    if (this.configOverridesForm.valid) {
      this.ngxGalleryComponentRef.openPreview(0);
    }
  }

  public setIsCustomBackgroundColorInvalid(valid: boolean): void {
    this.isCustomBackgroundColorInvalid = !valid;
  }

  private subscribeToExternalPreviewTrigger(): void {
    this.externalPreviewTrigger?.subscribe(() => this.startPreviewMode());
  }

  private subscribeToFormControlChanges(): void {
    const controls = this.configOverridesForm.controls;

    controls.slideInterval.valueChanges.subscribe(
      (val: number) => (this.ngxGalleryComponentRef.currentOptions.previewAutoPlayInterval = val)
    );
    controls.descriptionEnabled.valueChanges.subscribe(
      (val: boolean) => (this.ngxGalleryComponentRef.currentOptions.previewDescription = val)
    );
    controls.previewBackgroundColor.statusChanges.subscribe((status: string) => {
      const isValidColor = status === 'VALID';
      if (isValidColor) {
        const colorValue: string = controls.previewBackgroundColor.value;
        this.host.nativeElement.style.setProperty('--image-gallery-background-color', colorValue);
      }
      this.setShowPreviewOnImageClick(isValidColor);
    });
  }

  private setShowPreviewOnImageClick(value: boolean): void {
    this.ngxGalleryComponentRef.currentOptions.preview = value;
  }

  private setGalleryOptions(): void {
    this.galleryOptions = this.config?.options || this.getDefaultGalleryOptions();
  }

  private setGalleryImages(): void {
    this.galleryImages = this.config?.images || [];
  }

  private togglePlayPause(): void {
    if (this.isPlaying) {
      this.ngxGalleryComponentRef.preview.stopAutoPlay();
    } else {
      this.ngxGalleryComponentRef.preview.startAutoPlay();
    }
    this.isPlaying = !this.isPlaying;
  }

  private getDefaultGalleryOptions(): NgxGalleryOptions[] {
    const defaultOptions: NgxGalleryOptions[] = [];
    const options = imageGalleryConfigBaseOptions;
    options.actions = [
      {
        icon: 'fa-solid fa-play-pause',
        disabled: false,
        titleText: this.translateService.instant('images.order.slideshow.dialog.pauseResumeSlideshow'),
        onClick: () => {
          this.togglePlayPause();
        }
      }
    ];
    defaultOptions.push(imageGalleryConfigBaseOptions);

    return defaultOptions;
  }
}
