import { AddressDto } from './../dtos/address-dto.model';
import { Observable } from 'rxjs';
import { SettingsService } from 'src/app/core/config/settings.service';
import { HttpClient } from '@angular/common/http';
import { EndpointBaseService } from 'src/app/shared/services/enpoint-base.service';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Share } from 'src/app/shared/decorators/share.decorator';
import { FuneralAddressDtoConverter } from '../converter/funeral-address-dto.converter';
import { AccessLevelDto } from '../dtos/access-level-dt.model';
import { AddressListDto } from '../dtos/address-list-dto.model';
import { FuneralAddressResponseDto } from '../dtos/funeral-address-response.dto';
import { AddressAccessLevel } from '../enums/address-access-level.enum';
import { AddressList } from '../models/address-list.model';
import { FuneralAddressIdDto } from '../dtos/funeral-address-id-dto.model';
import { Address } from '../models/address.model';

@Injectable({
  providedIn: 'root'
})
export class FuneralAddressService extends EndpointBaseService {
  private static listsEp = '/address-lists/';

  private static ordersListEp = '/webloop-orders/';

  constructor(httpClient: HttpClient, settingsService: SettingsService) {
    super('/v1.0', httpClient, settingsService);
  }

  protected get apiRoot(): string {
    return `${this.settingsService.settings.funeralAddressServiceApiRoot}`;
  }

  // #region Webloop
  @Share()
  public getAccessLevelForOrder(orderPk: number): Observable<AddressAccessLevel> {
    const url = this.accessLevelByOrderPkUrl(orderPk);
    return this.http.get<FuneralAddressResponseDto<AccessLevelDto>>(url, { responseType: 'json' }).pipe(
      map((response: FuneralAddressResponseDto<AccessLevelDto>) => {
        if (response) {
          return FuneralAddressDtoConverter.convertAccessLevelDto(response.data);
        }
        return AddressAccessLevel.NoAccess;
      })
    );
  }

  /**
   * Loads the address list for the given order.
   */
  @Share()
  public getAddressListForOrder(orderPk: number): Observable<AddressList> {
    const url = this.addressListByOrderPk(orderPk);
    return this.http.get<FuneralAddressResponseDto<AddressListDto>>(url, { responseType: 'json' }).pipe(
      map((response: FuneralAddressResponseDto<AddressListDto>) => {
        if (response) {
          return FuneralAddressDtoConverter.convertAddressListDto(response.data);
        }
        return null as AddressList;
      })
    );
  }

  /**
   * Creates a new order id for the given webloop order.
   */
  @Share()
  public postNewListForOrder(orderPk: number): Observable<string> {
    const url = `${this.baseUrl}${FuneralAddressService.ordersListEp}${orderPk}/address-list`;
    return this.http
      .post<FuneralAddressResponseDto<FuneralAddressIdDto>>(url, { webloopOrderPk: orderPk }, { responseType: 'json' })
      .pipe(
        map((response: FuneralAddressResponseDto<FuneralAddressIdDto>) => {
          if (!!response && !!response.data.id) {
            return response.data.id;
          }
          return null;
        })
      );
  }
  // #endregion

  // #region List stuff
  @Share()
  public postNewAddress(addressListUuId: string, address: Address): Observable<string> {
    const url = `${this.baseUrl}${FuneralAddressService.listsEp}${addressListUuId}/addresses`;
    const addresDto = FuneralAddressDtoConverter.convertAddress(address);

    return this.http
      .post<FuneralAddressResponseDto<FuneralAddressIdDto>>(url, addresDto, { responseType: 'json' })
      .pipe(
        map((response: FuneralAddressResponseDto<FuneralAddressIdDto>) => {
          if (!!response && !!response.data.id) {
            return response.data.id;
          }
          return null;
        })
      );
  }

  @Share()
  public getAddressesExcelExport(addressListUuId: string): Observable<any> {
    const url = this.excelExportByAddressesByUUid(addressListUuId);
    return this.http.get(url, { responseType: 'blob' });
  }

  @Share()
  public putAddress(addressListUuId: string, address: Address): Observable<any> {
    const url = `${this.baseUrl}${FuneralAddressService.listsEp}${addressListUuId}/addresses/${address.id}`;
    const addressDto = FuneralAddressDtoConverter.convertAddress(address);

    return this.http.put(url, addressDto, { responseType: 'json' });
  }

  @Share()
  public getAddress(addressListUuId: string, listEntryUuId: string): Observable<Address> {
    const url = `${this.baseUrl}${FuneralAddressService.listsEp}${addressListUuId}/addresses/${listEntryUuId}`;

    return this.http.get<FuneralAddressResponseDto<AddressDto>>(url, { responseType: 'json' }).pipe(
      map((response: FuneralAddressResponseDto<AddressDto>) => {
        if (response) {
          return FuneralAddressDtoConverter.convertAddressDto(response.data);
        }
        return null;
      })
    );
  }

  @Share()
  public deleteAddress(addressListUuId: string, addressId: string): Observable<any> {
    const url = `${this.baseUrl}${FuneralAddressService.listsEp}${addressListUuId}/addresses/${addressId}`;
    return this.http.delete(url, { responseType: 'json' });
  }

  @Share()
  public postListSectionRequest(addressListUuId: string, sectionUuiId: string): Observable<any> {
    const url = `${this.baseUrl}${FuneralAddressService.listsEp}${addressListUuId}/sections/${sectionUuiId}/request`;
    return this.http.post(url, { responseType: 'json' });
  }

  @Share()
  public postListSectionRequestReminder(addressListUuId: string, sectionUuiId: string): Observable<any> {
    const url = `${this.baseUrl}${FuneralAddressService.listsEp}${addressListUuId}/sections/${sectionUuiId}/reminder`;
    return this.http.post(url, { responseType: 'json' });
  }

  @Share()
  public postListSectioCancellationRequest(addressListUuId: string, sectionUuiId: string): Observable<any> {
    const url = `${this.baseUrl}${FuneralAddressService.listsEp}${addressListUuId}/sections/${sectionUuiId}/cancellation`;
    return this.http.post(url, { responseType: 'json' });
  }

  @Share()
  public postListSectionResponse(addressListUuId: string, sectionUuiId: string, comment: string): Observable<any> {
    const url = `${this.baseUrl}${FuneralAddressService.listsEp}${addressListUuId}/sections/${sectionUuiId}/response`;
    return this.http.post(url, { comment: comment ? comment : null }, { responseType: 'json' });
  }

  @Share()
  public getSectionCsvExport(addressListUuId: string, sectionUuiId: string): Observable<any> {
    const url = this.csvExportByAddressListUuiIdAndSectionUuiId(addressListUuId, sectionUuiId);
    return this.http.get(url, { responseType: 'text' });
  }

  @Share()
  public getSectionExcelExport(addressListUuId: string, sectionUuiId: string): Observable<any> {
    const url = this.excelExportByAddressListUuiIdAndSectionUuiId(addressListUuId, sectionUuiId);
    return this.http.get(url, { responseType: 'blob' });
  }
  // #endregion

  public accessLevelByOrderPkUrl = (orderPk: number): string =>
    `${this.baseUrl}${FuneralAddressService.ordersListEp}${orderPk}/access-level`;

  public addressListByOrderPk = (orderPk: number): string =>
    `${this.baseUrl}${FuneralAddressService.ordersListEp}${orderPk}/address-list`;

  public csvExportByAddressListUuiIdAndSectionUuiId = (addressListUuId: string, sectionUuiId: string): string =>
    `${this.baseUrl}${FuneralAddressService.listsEp}${addressListUuId}/sections/${sectionUuiId}/export/csv`;

  public excelExportByAddressListUuiIdAndSectionUuiId = (addressListUuId: string, sectionUuiId: string): string =>
    `${this.baseUrl}${FuneralAddressService.listsEp}${addressListUuId}/sections/${sectionUuiId}/export/excel`;

  public excelExportByAddressesByUUid = (addressListUuId: string): string =>
    `${this.baseUrl}${FuneralAddressService.listsEp}${addressListUuId}/addresses/export/excel`;
}
