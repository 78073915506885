export enum Device {
  Desktop = 1,
  Mobile
}

export interface Display {
  breakpointsState: { [key: string]: boolean };
  currentDevice: Device;
  isNavigationVisible: boolean;
  isHelpVisible: boolean;
}
