import { Component, HostBinding, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import { collapseNavigationAction } from '../../../core/store/display/display.actions';

@Component({
  selector: 'mpac-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PageComponent implements OnInit {
  public static baseClass = 'mpac-page';

  public static baseClassPadded = 'mpac-page--padded';

  @HostBinding('class') hostClassBinding;

  /**
   * indicates if the page-content has padding
   */
  @Input() public padding = true;

  constructor(private store$: Store<any>) {}

  public ngOnInit(): void {
    this.setHostClassBinding();
    this.store$.dispatch(collapseNavigationAction());
  }

  protected setHostClassBinding(): void {
    const classes = [PageComponent.baseClass];
    if (this.padding) {
      classes.push(PageComponent.baseClassPadded);
    }
    this.hostClassBinding = classes.join(' ');
  }
}
