import { createSelector } from '@ngrx/store';
import { coreStateFeatureSelector, CoreState } from '../common';
import { initialBreadcrumbsState } from './breadcrumb-state.model';

export const selectBreadcrumbsState = createSelector(coreStateFeatureSelector, (state: CoreState) =>
  state ? state.breadcrumbs : initialBreadcrumbsState
);

export const selectBreadcrumbs = createSelector(coreStateFeatureSelector, (state: CoreState) =>
  state && state.breadcrumbs?.entries ? state.breadcrumbs.entries : initialBreadcrumbsState.entries
);
