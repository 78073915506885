<div class="mpac-page-sec-cnt-hdr__wrap">
  <h2 [attr.id]="headlineId" class="mpac-page-sec-cnt-hdr__hdl">{{headlineText}}</h2>

  <div *ngIf="hasScrollButton" class="mpac-page-sec-cnt-hdr__scroll">
    <button
      (click)="scrollToElement(scrollToSelector, !scrollEvenIfInView)"
      title="{{ 'actions.jumpTo' |translate:{ jump: btnText } }}"
      class="mpac-page-sec-cnt-hdr__scroll-btn btn btn-link"
      type="button">
      <span
        class="btn__ico mpac-page-sec-cnt-hdr__scroll-btn-arr"
        [class.mpac-page-sec-cnt-hdr__scroll-btn-arr--down]="scrollArrowDirectionDown"></span>
      <span class="btn__txt">{{ btnText }}</span>
    </button>
  </div>
</div>
