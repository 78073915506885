import { ImageSizesEnum } from '@shared/enums/image-sizes.enum';
import * as Mime from 'mime';
export class FileHelper {
  public static fullBase64ToBlob(data: string, mime: string): Blob {
    const byteString = window.atob(data.split('base64,')[1] || '');
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    return new Blob([int8Array], { type: mime });
  }

  public static getFileNameFromUri(uri: string): string | null {
    if (!uri || !uri.length || typeof uri !== 'string') return null;

    const lastPart = uri.substring(uri.lastIndexOf('/') + 1, uri.length);
    const indexOfParam = lastPart.indexOf('?');

    if (indexOfParam === -1) return lastPart.trim();
    return lastPart.substring(0, indexOfParam);
  }

  public static getFileTypeByMimeType(mimeType: string): string | null {
    return Mime.getExtension(mimeType);
  }

  public static getImageSizeStringFromEnum(enumValue: ImageSizesEnum): string {
    if (typeof enumValue !== 'string') {
      enumValue = ImageSizesEnum[enumValue];
    }

    switch (enumValue) {
      case ImageSizesEnum.Original:
        return ImageSizesEnum.Original;
      case ImageSizesEnum.Slide:
        return ImageSizesEnum.Slide;
      case ImageSizesEnum.Thumbnail:
      default:
        return ImageSizesEnum.Thumbnail;
    }
  }
}
