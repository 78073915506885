import { createAction, props, union } from '@ngrx/store';

export const setTopicAction = createAction('[Help Topic] Set Topic', props<{ topic: string }>());

export const setTopicSuccessAction = createAction('[Help Topic] Set Topic Success', props<{ helpText: string }>());

const actions = union({
  /* eslint-disable @typescript-eslint/naming-convention */
  SetTopicAction: setTopicAction,
  SetTopicSuccessAction: setTopicSuccessAction
  /* eslint-enable @typescript-eslint/naming-convention */
});

export type HelpAction = typeof actions;
