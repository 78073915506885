import { Component, OnInit } from '@angular/core';
import { AuthService } from '@core/services/auth.service';
import { UserService } from '@core/services/user.service';
import { Observable } from 'rxjs';

import { ProcessUserResult } from '../../models/process-user-result';

@Component({
  selector: 'mpac-user-elevation-page',
  templateUrl: './user-elevation-page.component.html',
  styleUrls: ['./user-elevation-page.component.scss']
})
export class UserElevationPageComponent implements OnInit {
  public processUserResult: ProcessUserResult;

  public isLoggedIn$: Observable<boolean>;

  constructor(private authService: AuthService, private userService: UserService) {}

  ngOnInit(): void {
    this.processUserResult = history.state;
    this.userService.storeUsernameInStorage(this.processUserResult.userName);
    this.isLoggedIn$ = this.authService.isAuthenticated$;
    this.authService.refreshUserData();
    this.userService.removeReturnUrlFromStorage();
  }
}
