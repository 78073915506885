import { Expose, Transform } from 'class-transformer';

export class AppSettings {
  /**
   * Whether or not this runtime is prod.
   */
  @Expose({name: 'PRODUCTION'})
  @Transform(({value}) => {
    switch (value) {
      case 'true':
        return true;
      case 'false':
        return false;
      default:
        return value;
    }
  })
  public production: boolean;

  /**
   * Whether or not the environment is production.
   */
  @Expose({name: 'IS_PRODUCTION_ENVIRONMENT'})
  @Transform(({value}) => {
    switch (value) {
      case 'true':
        return true;
      case 'false':
        return false;
      default:
        return value;
    }
  })
  public isProductionEnvironment: boolean;

  /**
   * Contentful space id used for the help feature.
   */
  @Expose({name: 'CONTENTFUL_SPACE_ID'})
  public contentfulSpaceId: string;

  /**
   * Contentful space id used for help feature.
   */
  @Expose({name: 'CONTENTFUL_ACCESS_TOKEN'})
  public contentfulAccessToken: string;

  /**
   * Id of the contentful environment to be utilized.
   */
  @Expose({name: 'CONTENTFUL_ENVIRONMENT_ID'})
  public contentFulEnvironmentId: string;

  /**
   * The API URL to connect with BE.
   */
  @Expose({name: 'API_ROOT'})
  public apiRoot: string;

  /**
   * Quuickform API root.
   */
  @Expose({name: 'QUICKFORM_API_ROOT'})
  public quickformApiRoot: string;

  /**
   * API Root path for the funeral address service.
   */
  @Expose({name: 'FUNERAL_ADDRESS_SERVICE_API_ROOT'})
  public funeralAddressServiceApiRoot: string;

  /**
   * API Root path for the funeral music service.
   */
  @Expose({name: 'FUNERAL_MUSIC_SERVICE_API_ROOT'})
  public funeralMusicServiceApiRoot: string;

  /**
   * Host Url url for the images.
   */
  @Expose({name: 'IMAGE_ASSETS_HOST'})
  public imageAssetsHost: string;

  /**
   * Url pointing to the related instance for the "Erinnerungsbuch"
   */
  @Expose({name: 'MEMORY_BOOK_URL'})
  public memoryBookUrl: string;

  /**
   * Funeral Documents service url.
   */
  @Expose({name: 'FUNERALS_DOCUMENTS_API_URL'})
  public funeralsDocumentApiUrl: string;

  /**
   * Banned words service url.
   */
  @Expose({ name: 'BANNED_WORDS_API_URL' })
  public bannedWordsApiUrl: string;

  /**
   * Precaution Info service url.
   */
  @Expose({name: 'PRECAUTION_INFO_API_URL'})
  public precautionInfoApiUrl: string;

  /**
   *
   * Max upload size in MB for PrecautionInfo documents (both pdf and image).
   */
  @Expose({name: 'PRECAUTION_INFO_MAX_FILE_SIZE_MB'})
  @Transform(({value}) => {
    const parsedResult = parseInt(value as string, 10);
    if (Number.isNaN(value) && !Number.isNaN(parsedResult)) {
      return parsedResult;
    }
    return value;
  })
  public precautionInfoDocumentUploadMaxFileSizeMb: number;

  /**
   * HotJar integration api key.
   */
  @Expose({name: 'HOTJAR_KEY'})
  @Transform(({value}) => {
    if (value === '${HOTJAR_KEY:- }') {
      return '';
    }
    return value;
  })
  public hotjarKey: string;

  /**
   *
   * Max upload size in MB for the funeralDocs.
   */
  @Expose({name: 'FUNERALS_DOCUMENT_UPLOAD_LIMIT_MB'})
  @Transform(({value}) => {
    const parsedResult = parseInt(value as string, 10);
    if (Number.isNaN(value) && !Number.isNaN(parsedResult)) {
      return parsedResult;
    }
    return value;
  })
  public funeralsDocumentUploadLimitMb: number;

  /**
   *
   * Max upload size in MB for the funeralDocs.
   */
  @Expose({name: 'FUNERAL_DOCUMENT_MAX_FILE_COUNT'})
  @Transform(({value}) => {
    const parsedResult = parseInt(value as string, 10);
    if (Number.isNaN(value) && !Number.isNaN(parsedResult)) {
      return parsedResult;
    }
    return value;
  })
  public funeralDocumentMaxFileCount: number;

  /**
   * Max size for the general images in MB.
   */
  @Expose({name: 'IMAGE_UPLOAD_MAX_SIZE_MB'})
  @Transform(({value}) => {
    const parsedResult = parseInt(value as string, 10);
    if (Number.isNaN(value) && !Number.isNaN(parsedResult)) {
      return parsedResult;
    }
    return value;
  })
  public imageUploadMaxSizeMb: number;

  /**
   * Max amount of concurrent files to upload.
   */
  @Expose({name: 'IMAGE_UPLOAD_MAX_SIMULTANEOUSLY'})
  @Transform(({value}) => {
    const parsedResult = parseInt(value as string, 10);
    if (Number.isNaN(value) && !Number.isNaN(parsedResult)) {
      return parsedResult;
    }
    return value;
  })
  public imageUploadMaxSimultaneously: number;

  /**
   * Max amount of images per forder.
   */
  @Expose({name: 'MAX_IMAGES_PER_ORDER'})
  @Transform(({value}) => {
    const parsedResult = parseInt(value as string, 10);
    if (Number.isNaN(value) && !Number.isNaN(parsedResult)) {
      return parsedResult;
    }
    return value;
  })
  public maxImagesPerOrder: number;

  /**
   * Comma separated list of file extension.
   *
   * @example ".jpeg, .png"
   */
  @Expose({name: 'SUPPORTED_IMAGE_FILE_EXT'})
  public supportedImageFileExt: string;

  /**
   * Pipe separated string of image mime types to support.
   *
   * @example ".gif, .jpg, .png, .doc|audio/*|video/*|image/*|media_type"
   */
  @Expose({name: 'SUPPORTED_IMAGE_FILE_MIME'})
  public supportedImageFileMime: string;

  /**
   * Url to the impressum with protocol.
   */
  @Expose({name: 'IMPRESSUM_URL'})
  public impressumUrl: string;

  /**
   * Url to the privacy policy with protocol.
   */
  @Expose({name: 'PRIVACY_POLICY_URL'})
  public privacyPolicyUrl: string;

  @Expose({name: 'TRAININGCAMP_URL'})
  public trainingCampUrl: string;

  /**
   * Wehter or not the environment (docker) should be used.
   */
  @Expose({name: 'USE_ENVIRONMENT_VARIABLE_CONFIG'})
  @Transform(({value}) => {
    switch (value) {
      case 'true':
        return true;
      case 'false':
        return false;
      default:
        return value;
    }
  })
  public useEnvironmentVariableConfig: boolean;

  /**
   * Static root url for images that are being held on server (favicon, candles, logo).
   */
  @Expose({name: 'IMAGE_ASSET_ROOT_STATIC'})
  public imageAssetRootStatic: string;

  /**
   * Static url selgement for candles.
   */
  @Expose({name: 'IMAGE_ASSET_ROOT_STATIC_CANDLES'})
  public imageAssetStaticCandles: string;

  /**
   * Static url selgement for individual customer images.
   */
  @Expose({name: 'IMAGE_ASSET_ROOT_STATIC_INDIVIDUAL'})
  public imageAssetStaticIndividual: string;

  /**
   * Static url segment for individual company logos.
   */
  @Expose({name: 'IMAGE_ASSET_ROOT_STATIC_LOGO'})
  public imageAssetStaticLogo: string;

  /**
   * Static url segment for individual company logos.
   */
  @Expose({name: 'IMAGE_ASSET_ROOT_STATIC_FAVICO'})
  public imageAssetStaticFavIco: string;

  @Expose({name: 'QRCODE_SERVICE_URL'})
  public qrCodeServiceUrl: string;

  /**
   * Cookie Banner cookie config version.
   */
  @Expose({name: 'COOKIE_BANNER_CONFIG_REVISION'})
  public cookieBannerConfigRevision: string;

  /**
   * Font awesome kit file name to load for the menu items.
   */
  @Expose({name: 'FONT_AWESOME_KIT'})
  public fontAwesomeKit: string;

  /**
   * Base Url for the fontawesome kit to load.
   */
  @Expose({name: 'FONT_AWESOME_KIT_URL'})
  public fontAwesomeKitUrl: string;

  @Expose({name: 'APP_VERSION'})
  public appVersion: string;

  @Expose({name: 'COMPONENT_CATALOG_ENABLED'})
  @Transform(({value}) => {
    switch (value) {
      case 'true':
        return true;
      case 'false':
        return false;
      default:
        return value;
    }
  })
  public componentCatalogEnabled: boolean;

  @Expose({name: 'PENSION_NUMBER_PRODUCT_IDS'})
  public pensionNumberProductIds: string;

  @Expose({name: 'HVZ_WHITELISTED_IDS'})
  public hvzWhitelistedIds: string;
}
