import { Component, EventEmitter, HostBinding, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Breakpoints } from '@core/helper/breakpoints';
import { CoreState } from '@core/store/common';
import { selectIsBreakpointAbove } from '@core/store/display/display.reducer';
import { select, Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { DesctructableComponent } from '../destructable/destructable.component';

@Component({
  selector: 'mpac-page-action-button',
  templateUrl: './page-action-button.component.html',
  styleUrls: ['./page-action-button.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PageActionButtonComponent extends DesctructableComponent implements OnInit {
  public static baseClass = 'mpac-page-action-button';

  @HostBinding('class') hostClassBinding: string;

  @Input() visible = true;

  @Input() title = '';

  @Input() iconNames: string;

  @Input() isPrimary = true;

  @Output()
  public clickedEmitter: EventEmitter<void> = new EventEmitter<void>();

  public isMobile = true;

  constructor(private store$: Store<CoreState>) {
    super();
    this.subscribeToBreakpointChanges();
  }

  public ngOnInit(): void {
    this.setHostClassBinding();
  }

  public clicked(): void {
    this.clickedEmitter.emit();
  }

  protected setHostClassBinding(): void {
    this.hostClassBinding = PageActionButtonComponent.baseClass;
  }

  protected subscribeToBreakpointChanges(): void {
    this.store$
      .pipe(takeUntil(this.shutdown$), select(selectIsBreakpointAbove, Breakpoints.MD))
      .subscribe((notMobile) => {
        this.isMobile = !notMobile;
      });
  }
}
