export class UserAgentHelper {
  public static mobilePlatforms = [
    'iPhone',
    'iPad',
    'iPod',
    'iPhone Simulator',
    'iPad Simulator',
    'iPod Simulator',
    'Android',
    'Linux arm91',
    'Linux armv8l',
    'Linux armv7l',
    'Linux armv6l',
    'Linux aarch64',
    'Linux aarch32'
  ];

  public static possibleMobiles = ['MacIntel', 'Macintosh'];

  public static getUserAgentString(): string {
    // eslint-disable-next-line @typescript-eslint/dot-notation
    return navigator.platform || navigator?.['userAgentData']?.platform || '';
  }

  public static isMobile(): boolean {
    const agentString = UserAgentHelper.getUserAgentString();

    return UserAgentHelper.mobilePlatforms.indexOf(agentString) > -1 || UserAgentHelper.isIpadOs(agentString)
      ? true
      : false;
  }

  public static isIpadOs(agentString: string = null): boolean {
    if (!agentString) {
      agentString = UserAgentHelper.getUserAgentString();
    }

    return (
      navigator.maxTouchPoints &&
      navigator.maxTouchPoints > 2 &&
      UserAgentHelper.possibleMobiles.indexOf(agentString) > -1
    );
  }

  public static isTouchDevice(): boolean {
    return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
  }
}
