import { IQuickFormResponse, QuickFormErrorResponse } from '../dtos/quick-form-response';
import { Observable, throwError } from 'rxjs';
import { ServiceError } from '../errors/service.error';
import { ApplicationErrorType } from '../../store/quick-form-order-actions/quick-form-order.actions';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { FuneralResponseDto } from '../../features/funeral-print/dtos/funeral-response.dto';

export class HttpHelper {
  public static extractBlob(res: HttpResponse<any>): { blob: Blob; fileName: string } {
    const contentDisposition = res.headers.get('content-disposition');
    const responseBody: BlobPart = res.body;
    const rawFileName =
      contentDisposition !== null
        ? contentDisposition.replace('attachment; filename=', '').replace(new RegExp('"', 'g'), '')
        : null;
    return {
      blob: new Blob([responseBody], { type: res.headers.get('Content-Type') }),
      fileName: decodeURIComponent(rawFileName)
    };
  }

  // TODO: WTF is x?
  public static ensureValidResponse<T>(x: any): FuneralResponseDto<T> {
    if (!!x.errorCode && x.errorCode !== 1) {
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      throw new Error(`Something went wrong: ${x.messages}`);
    }
    return x;
  }

  public static ensureValidQuickFormResponse(response: IQuickFormResponse): IQuickFormResponse
  {
    if (!!response.errorCode && response.errorCode !== 1) {
      const errorResponse = new QuickFormErrorResponse();
      errorResponse.error = response;
      throw errorResponse;
    }
    return response;
  }

  public static throwApiError(response: any): Observable<never> {
    if (response instanceof HttpErrorResponse) {
      return throwError(() => new ServiceError(response.message, '-1', ApplicationErrorType.Unknown, response.status));
    } else if (response instanceof QuickFormErrorResponse) {
      const error = response?.error;
      const messages = error?.messages;
      if (messages && messages.length > 0) {
        const firstErrorMessage: string = messages[0];
        return throwError(() =>
          new ServiceError(firstErrorMessage, error?.traceId, ApplicationErrorType.Unknown, error?.errorCode)
        );
      }
      return throwError(() =>
        new ServiceError('Unknown error.', error?.traceId, ApplicationErrorType.Unknown, error?.errorCode)
      );
    } else if (response instanceof Observable) {
      return response as Observable<never>;
    } else if (response.message) {
      return throwError(new ServiceError(response.message as string, '-1', ApplicationErrorType.Unknown, -1));
    }
    return throwError(() =>
      new ServiceError(
        'Unable to handle the error. Please contact the support.',
        '-1',
        ApplicationErrorType.Unknown,
        -1
      )
    );
  }
}
