import { AbstractControl, ValidatorFn } from '@angular/forms';

export const futureDateValue =
  (): ValidatorFn =>
  (control: AbstractControl): { [key: string]: any } | null => {
    if (control.value) {
      const dateToday = new Date();
      dateToday.setHours(0, 0, 0, 0);
      const maxDate = new Date();
      const maxYear = 1;
      maxDate.setFullYear(dateToday.getFullYear() + maxYear);
      const currentValue: string = control.value;
      const selectedDate = new Date(currentValue);
      if (isNaN(Date.parse(currentValue)) || selectedDate < dateToday) {
        return { futureDate: true };
      }
      if (selectedDate > maxDate) {
        return { futureDateMax: maxYear };
      }
    }
  };
