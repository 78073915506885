import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class SettingBasedOnHostnameService {
  // TODO: Settings all of the below
  private static logoDir = '/assets/images/logos';

  private static logoCustomerCenter = 'logo-customer-center--txt.png';

  private static logoCustomerCenterWhite = 'logo-customer-center--txt--white.png';

  private static logoServiceCenter = 'logo-service-center--txt.png';

  private static logoServiceCenterWhite = 'logo-service-center--txt--white.png';

  private static folderCompanyPortal = 'favicons_company-portal';

  private static folderOrderPortal = 'favicons_order-portal';

  constructor(private titleService: Title, private translate: TranslateService) {}

  public setFaviconByHostname(): void {
    const linkElements = document.querySelectorAll(`link[ href*='${SettingBasedOnHostnameService.folderOrderPortal}']`);
    if (!linkElements) {
      return;
    }

    if (this.hostnameServiceCenter()) {
      for (const linkElement of Array.from(linkElements)) {
        const path = linkElement.getAttribute('href');
        const newPath = path.replace(
          SettingBasedOnHostnameService.folderOrderPortal,
          SettingBasedOnHostnameService.folderCompanyPortal
        );
        linkElement.setAttribute('href', newPath);
      }
    }
  }

  public setTitleByHostname(): void {
    const title = this.hostnameServiceCenter()
      ? this.translate.instant('general.serviceMinusCenter')
      : this.translate.instant('general.kundenMinusCenter');

    this.titleService.setTitle(title as string);
  }

  public setTitleToDependantDemo(): void {
    const demo = this.translate.instant('general.dependantDemo');
    const title = `${this.translate.instant('general.kundenMinusCenter') as string} ${demo as string}`;
    this.titleService.setTitle(title);
  }

  public getLogoPathByHostname(fontWhite: boolean = false): string {
    let logoName: string;
    if (fontWhite) {
      logoName = this.hostnameServiceCenter()
        ? SettingBasedOnHostnameService.logoServiceCenterWhite
        : SettingBasedOnHostnameService.logoCustomerCenterWhite;
    } else {
      logoName = this.hostnameServiceCenter()
        ? SettingBasedOnHostnameService.logoServiceCenter
        : SettingBasedOnHostnameService.logoCustomerCenter;
    }
    return `${SettingBasedOnHostnameService.logoDir}/${logoName}`;
  }

  public hostnameServiceCenter(): boolean {
    return window.location.hostname.includes('service');
  }
}
