import { AbstractControl, ValidatorFn } from '@angular/forms';

export const birthDateValue =
  (): ValidatorFn =>
  (control: AbstractControl): { [key: string]: any } | null => {
    if (control.value) {
      const dateToday = new Date();
      const selectedDate = new Date(control.value as string);
      const minDate = new Date();
      minDate.setFullYear(dateToday.getFullYear() - 100);
      if (isNaN(Date.parse(control.value as string)) || selectedDate < minDate || selectedDate > dateToday) {
        return { birthDate: 'Falscher Wert' };
      }
    }
  };
