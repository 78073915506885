import { createSelector } from '@ngrx/store';
import { OrderState } from '../../../company-portal/models/order-entry';
import { Order } from '../../models/order';
import { OrderPortalState, orderPortalStateFeatureSelector } from '../common';
import { loadOrderSuccessAction, OrderAction, saveOrderSettingsSuccessAction } from './order.actions';

const initialState: Order = undefined;

export const orderReducer = (state: Order = initialState, action: OrderAction): Order => {
  switch (action.type) {
    case loadOrderSuccessAction.type:
    case saveOrderSettingsSuccessAction.type:
      return { ...action.order };
  }

  return state;
};

export const selectOrder = createSelector(orderPortalStateFeatureSelector, (state: OrderPortalState) =>
  state ? state.order : initialState
);

export const selectOrderHasMemorialPage = createSelector(orderPortalStateFeatureSelector, (state: OrderPortalState) =>
  state ? state.order?.state !== OrderState.NoMemorialPage ?? false : false
);

export const selectOrderIsMemorialPageVisible = createSelector(
  orderPortalStateFeatureSelector,
  (state: OrderPortalState) =>
    state
      ? (state.order?.state !== OrderState.NoMemorialPage &&
          new Date(state.order?.dateOfLock).getTime() > new Date().getTime()) ??
        false
      : false
);

export const selectOrderAppointments = createSelector(selectOrder, (state: Order) =>
  state ? state?.appointments : []
);
