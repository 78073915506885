import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

export interface TooltipContentConfig {
  cookieId: string;
  headerTranslationKey: string;
  bodyTranslationKey: string;
  dismissButtonTranslationKey: string;
}

@Injectable({
  providedIn: 'root'
})
export class HelpToggleDismissableTooltipService {
  private static cookiePrefix = 'dismissHintOn';

  public canShowTooltip$: Observable<boolean>;

  public dismissableTooltipContentConfig$: Observable<TooltipContentConfig>;

  private canShowTooltipSubject$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  private dismissableTooltipContentConfigSubject$: Subject<TooltipContentConfig> = new BehaviorSubject(null);

  constructor() {
    this.dismissableTooltipContentConfig$ = this.dismissableTooltipContentConfigSubject$.asObservable();
    this.canShowTooltip$ = this.canShowTooltipSubject$.asObservable();
  }

  public setContentConfigFor(callerClassName: string): void {
    const config: TooltipContentConfig = this.getConfigFor(callerClassName);
    if (!this.isTooltipDismissed(config.cookieId)) {
      this.dismissableTooltipContentConfigSubject$.next(config);
      this.canShowTooltipSubject$.next(true);
    } else {
      this.canShowTooltipSubject$.next(false);
    }
  }

  public clearContentConfig(): void {
    this.canShowTooltipSubject$.next(false);
    this.dismissableTooltipContentConfigSubject$.next(null);
  }

  public setCookieAndDismissTooltip(cookieId: string): void {
    document.cookie = `${cookieId}=`;
    this.canShowTooltipSubject$.next(false);
  }

  private getConfigFor(callerClassName: string): TooltipContentConfig {
    return {
      cookieId: HelpToggleDismissableTooltipService.cookiePrefix + this.normalizedId(callerClassName),
      headerTranslationKey: `dismissableTooltips.${callerClassName}.header`,
      bodyTranslationKey: `dismissableTooltips.${callerClassName}.body`,
      dismissButtonTranslationKey: `dismissableTooltips.${callerClassName}.dismissButton`
    };
  }

  private isTooltipDismissed(cookieId): boolean {
    return !!document.cookie?.split(';').some((item) => item.trim().startsWith(cookieId as string));
  }

  private normalizedId(id: string): string {
    // remove word "Component" from string-id to avoid potential exposure of project structure
    return id.replace('Component', '');
  }
}
