import { DesctructableComponent } from 'src/app/shared/components/destructable/destructable.component';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { filter, takeUntil } from 'rxjs/operators';
import { SettingsService } from 'src/app/core/config/settings.service';
import { User } from 'src/app/core/models/user';
import { CoreState } from 'src/app/core/store/common';
import { selectUser } from 'src/app/core/store/user/user.reducer';
import { UserMessage } from '../../models/user-message';
import { GlobalWriteProtectionService } from '../../services/global-write-protection.service';
import { ValidatorsService } from '@rapid/forms';
import { UserLevel } from '@shared/models/user-level.enum';

export interface ContactFormPrefillData {
  subject?: string;
  message?: string;
}

@Component({
  selector: 'mpac-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactFormComponent extends DesctructableComponent implements OnInit {
  @Input()
  public privacyPolicyUrl: string;

  @Input()
  public prefillData: ContactFormPrefillData;

  /**
   * Fires an event containing the user message object
   * when the contact form has been submitted
   */
  @Output()
  public formSubmitted = new EventEmitter<UserMessage>();

  /**
   * The reactive form group that handles the input fields.
   */
  public formGroup: UntypedFormGroup;

  /**
   * The currently logged in user. Used to prepopulate
   * the sender info.
   */
  public user: User;

  /**
   * Indicates whether the client is running in production mode.
   */
  public isProduction: boolean;

  /**
   * Backing field for the isBusy getter/setter
   */
  private isBusyField = false;

  constructor(
    private store$: Store<CoreState>,
    private formBuilder: UntypedFormBuilder,
    private globalWriteProtectionService: GlobalWriteProtectionService,
    settingsService: SettingsService
  ) {
    super();
    this.isProduction = settingsService.settings.production;
  }

  public get globalWriteProtectionIsActive(): boolean {
    return this.globalWriteProtectionService.isActive();
  }

  /**
   * Indicates whether the busy state of this form control is active.
   */
  public get isBusy(): boolean {
    return this.isBusyField;
  }

  /**
   * Controls the busy state of this form control
   */
  @Input()
  public set isBusy(value: boolean) {
    this.isBusyField = value;

    if (!this.formGroup) {
      return;
    }

    if (value === true) {
      this.formGroup.disable();
      return;
    }

    this.formGroup.enable();
    this.disableSenderAddressFormFieldWhenAdmin();
  }

  public ngOnInit(): void {
    this.store$
      .pipe(
        takeUntil(this.shutdown$),
        select(selectUser),
        filter((user: User) => !!user)
      )
      .subscribe((user: User) => {
        this.user = user;
        this.formGroup = this.createFormGroup();
        this.disableSenderAddressFormFieldWhenAdmin();
        this.prefillFormGroup();
      });
  }

  /**
   * Checks if the form is valid and invokes the
   *
   * @{formSubmitted} event emitter if so.
   */
  public formSubmit(): void {
    if (this.formGroup.invalid) {
      return;
    }

    const user: UserMessage = this.formGroup.getRawValue() as UserMessage;
    if (this.user.level === UserLevel.PageAdmin) {
      user.cc = this.user?.emailAddress;
    }
    this.formSubmitted.emit(user);
  }

  private prefillFormGroup(): void {
    this.formGroup.controls.subject.setValue(this.prefillData?.subject);
    this.formGroup.controls.message.setValue(this.prefillData?.message);
  }

  private disableSenderAddressFormFieldWhenAdmin(): void {
    if (this.user.level === UserLevel.PageAdmin) {
      this.formGroup.controls.senderAddress.disable();
    }
  }

  private createFormGroup(): UntypedFormGroup {
    return this.formBuilder.group({
      senderAddress: [this.user?.emailAddress ?? null, [Validators.required, ValidatorsService.email]],
      senderName: [null, [Validators.maxLength(150)]],
      subject: ['', [Validators.required, Validators.maxLength(350)]],
      message: ['', [Validators.required]],
      dataProtectionCheckbox: ['', [Validators.requiredTrue]]
    });
  }
}
