import { Component, Input, ViewEncapsulation } from '@angular/core';
import { NotificationTypes } from '../../enums/notification-types.enum';

@Component({
  selector: 'mpac-notification-box',
  templateUrl: './notification-box.component.html',
  styleUrls: ['./notification-box.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NotificationBoxComponent {
  @Input() type: NotificationTypes = NotificationTypes.Info;

  @Input() text: string;

  public show = true;

  public get isInfo(): boolean {
    return this.type === NotificationTypes.Info;
  }

  public get isMissingInfo(): boolean {
    return this.type === NotificationTypes.MissingInfo;
  }

  public get isWarning(): boolean {
    return this.type === NotificationTypes.Warning;
  }

  public get isError(): boolean {
    return this.type === NotificationTypes.Error;
  }

  public get isSuccess(): boolean {
    return this.type === NotificationTypes.Success;
  }

  public get isIndeterminate(): boolean {
    return this.type === NotificationTypes.Indeterminate;
  }

  public get isChange(): boolean {
    return this.type === NotificationTypes.Change;
  }

  public get isDate(): boolean {
    return this.type === NotificationTypes.Date;
  }
}
