import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class JwtHttpInterceptorService implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  /**
   * Sets the 'Authorization' header in an @{HttpRequest} to a bearer token authorization.
   *
   * @param request: The request.
   * @param next: ???
   * @returns ???
   */
  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    request = this.setBearerTokenHeader(request);

    return next.handle(request).pipe(catchError((error: HttpErrorResponse) => throwError(error)));
  }

  /**
   * Adds or - if it already exists - replaces the 'Authorization' header with a bearer token taken from @{authService}.
   *
   * @param request: The request in which to add/replace the header.
   * @returns a copy of the original request (for further processing).
   */
  private setBearerTokenHeader(request: HttpRequest<any>): HttpRequest<any> {
    request = request.clone({
      setHeaders: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Authorization: `Bearer ${this.authService.accessToken}`
      }
    });

    return request;
  }
}
