import { Injectable } from '@angular/core';
import { SettingsService } from '@core/config/settings.service';
import { AuthService } from '@core/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class DependantDemoService {
  constructor(private settingsService: SettingsService, private authService: AuthService) {}

  public dependantDemoUserActive(): boolean {
    // TODO: Fix this check => userImpersonate() seems to be wrong on somne occasions
    // return this.authService.hasValidAccessToken && this.authService.userImpersonated()
    //   && this.settingsService.demoMorticianSettings.dependantIds4UserId === this.authService.getUserId();
    return this.settingsService.demoMorticianSettings.dependantIds4UserId === this.authService.getUserId();
  }
}
