<div class="modal-header">
  <h2 class="modal-title pull-left">
    <h2 class="modal-title pull-left">{{ 'order.administration.removeInvitation' | translate }}</h2>
  </h2>
  <button (click)="hide()" attr.aria-label="{{ 'actions.close' | translate }}" class="close pull-right" type="button">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <p>{{ 'order.administration.removeInvite.body' | translate }}</p>

  <div *ngIf="invitation">
    <div class="form-group">
      <label
        ><strong>{{ 'order.administration.mailAddress' | translate }}</strong></label
      >
      <p>{{ invitation.emailAddress }}</p>
    </div>
    <div class="form-group">
      <label
        ><strong>{{ 'order.administration.invitedOn' | translate }}</strong></label
      >
      <p>{{ invitation.dateOfInvitation | date }}</p>
    </div>
  </div>
</div>
<div class="modal-footer">
  <div class="container modal-footer__cnt">
    <div class="row modal-footer__row">
      <div class="col modal-footer__col col-12 col-md-auto ml-auto">
        <button (click)="hide()" class="btn btn-primary">{{ 'actions.cancel' | translate }}</button>
      </div>
      <div class="col modal-footer__col col-12 col-md-auto">
        <button (click)="hide(true)" class="btn btn-secondary" mpacGlobalWriteProtection>
          <i class="fal fa-trash-alt mr-2"></i>{{ 'actions.withdraw' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
