import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SettingsService } from '@core/config/settings.service';
import includes from 'lodash-es/includes';
import { Observable, of } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';

/**
 * Represents "server-local-version"-check result.
 */
interface AppVersionCheckResult {
  serverVersion: string;
  localVersion: string;
  /**
   * Is "true" if serverVersion differs from localVersion, otherwise "false"
   */
  isNewVersionAvailable: boolean;
}

/**
 * Represents data in "version.json"-file.
 */
interface AppVersionFileContent {
  version: string;
}

@Injectable({
  providedIn: 'root'
})
export class AppVersionService {
  constructor(private httpClient: HttpClient, private settingsService: SettingsService) {}

  /**
   * Loads "version.json" from server and returns "server-local-version"-check result.
   */
  public checkForNewServerVersion(): Observable<AppVersionCheckResult> {
    const url = 'version.json';
    /* eslint-disable @typescript-eslint/naming-convention */
    const httpOptions = {
      headers: new HttpHeaders({
        'Cache-Control': 'no-cache, no-store, must-revalidate',
        Pragma: 'no-cache',
        Expires: '0'
      })
    };
    /* eslint-enable @typescript-eslint/naming-convention */

    return this.httpClient.get<AppVersionFileContent>(url, httpOptions).pipe(
      catchError(() =>
        // silent failure in case of an HTTP- or parsing-error -> is handled like no version change
        of(null)
      ),
      take(1),
      map((response) => {
        const localVersion = this.settingsService?.settings?.appVersion || '';
        const serverVersion = response?.version || '';
        const isNewVersionAvailable =
          !!localVersion &&
          !!serverVersion &&
          localVersion !== serverVersion &&
          !includes(serverVersion, '#{AppBuildVersion}'); // if build pipeline is not well configured

        const result: AppVersionCheckResult = {
          localVersion,
          serverVersion,
          isNewVersionAvailable
        };

        return result;
      })
    );
  }
}
