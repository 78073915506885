import { FuneralPrintItem } from './../models/funeral-print.model';
import { Injectable } from '@angular/core';
import { Observable, Observer, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError, map, switchMap } from 'rxjs/operators';
import { SettingsService } from '@core/config/settings.service';
import { FuneralDtoConverter } from '../converter/funeral-dto.converter';
import { FuneralResponseDto } from '../dtos/funeral-response.dto';
import {
  FuneralPrintMetadataResponseDto,
  ValidateMetadataResponse
} from '../dtos/responses/funeral-print-metadata-response.dto';
import { GetFuneralDocumentResponseDto } from '../dtos/responses/get-funeral-document-query-response.dto';
import { GetFuneralDocumentStatusesByOrderQueryResponseDto } from '../dtos/responses/get-funeral-document-statuses-by-order-query-response.dto';
import { GetFuneralDocumentsByOrderQueryResponseDto } from '../dtos/responses/get-funeral-documents-by-order-query-response.dto';
import { HttpHelper } from '../../../services/helper/http.helper';
import { FuneralPrintDto } from '../dtos/funeral-print.dto';
import { FuneralPrintFileThumbnailSize } from '../enums/funeral-print-file-thumbnail-size.enum';
import { FuneralPrintStatus } from '../enums/funeral-print-status.enum';
import { FuneralPrintFile } from '../models/funeral-print-file.model';
import { FuneralDocumentFileDto } from '../dtos/shared/funeral-document-file.dto';
import { FuneralDocumentStatusesOrderDto } from '../dtos/shared/funeral-document-statuses-order.dto';
import { Base64Helper } from '@order/helpers';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class FuneralPrintService {
  private static serviceEndpoint = '/v1';

  constructor(
    private httpClient: HttpClient,
    private settingsService: SettingsService,
    private translateService: TranslateService
  ) {}

  public templatesUrl = (orderId: number): string => `${this.baseUrl('/v2.0')}/orders/${orderId}/templates`;

  public funeralDocumentsUrl = (orderId: number): string =>
    `${this.baseUrl('/v2.0')}/orders/${orderId}/funeral-documents`;

  public filesUrl = (fileId: string, size: string): string =>
    `${this.baseUrl('/v3.0')}/files/${fileId}?imageSize=${size}`;

  public getFuneralPrintMetadata(orderId: number): Observable<FuneralPrintMetadataResponseDto> {
    const url = this.templatesUrl(orderId);
    return this.httpClient.get<FuneralPrintMetadataResponseDto>(url);
  }

  public getFuneralPrintItems(orderId: number): Observable<FuneralPrintItem[]> {
    const url = this.funeralDocumentsUrl(orderId);

    return this.httpClient
      .get<FuneralResponseDto<GetFuneralDocumentsByOrderQueryResponseDto>>(url)
      .pipe(map(HttpHelper.ensureValidResponse), catchError(HttpHelper.throwApiError))
      .pipe(
        map((response: FuneralResponseDto<GetFuneralDocumentsByOrderQueryResponseDto>) =>
          response.data.documents.map((x) => FuneralDtoConverter.toFuneralPrint(x))
        )
      );
  }

  public getFuneralPrintItem(id: string): Observable<FuneralPrintItem> {
    const url = `${this.baseUrl('/v2.0')}/funeral-documents/${id}`;

    return this.httpClient
      .get<FuneralResponseDto<GetFuneralDocumentResponseDto>>(url)
      .pipe(map(HttpHelper.ensureValidResponse), catchError(HttpHelper.throwApiError))
      .pipe(
        map((response: FuneralResponseDto<GetFuneralDocumentResponseDto>) =>
          FuneralDtoConverter.toFuneralPrint(response.data.document)
        )
      );
  }

  public validateDocument(templateId: string, formData: FuneralPrintDto): Observable<ValidateMetadataResponse> {
    const url = `${this.baseUrl('/v2.0')}/templates/${templateId}/validate`;

    return this.httpClient
      .post<FuneralResponseDto<ValidateMetadataResponse>>(url, formData)
      .pipe(map(HttpHelper.ensureValidResponse), catchError(HttpHelper.throwApiError))
      .pipe(map((response: FuneralResponseDto<ValidateMetadataResponse>) => response.data));
  }

  public createDocument(formData: FormData, orderId: number): Observable<{ id: string; mailSent: boolean }> {
    const url = `${this.baseUrl('/v3.0')}/orders/${orderId}/funeral-documents`;

    return this.httpClient
      .post<FuneralResponseDto<{ id: string }>>(url, formData)
      .pipe(map(HttpHelper.ensureValidResponse), catchError(HttpHelper.throwApiError))
      .pipe(
        map((response: FuneralResponseDto<{ id: string; success: boolean; message: string }>) => ({
          id: response.data.id,
          mailSent: response.data.success
        }))
      );
  }

  public changeStatus(documentId: string, status: FuneralPrintStatus, comment: string): Observable<{ id: string }> {
    const url = `${this.baseUrl()}/funeral-documents/${documentId}/document-status`;
    let bodyComment = null;
    if (comment !== null) {
      bodyComment = { text: comment };
    }
    const body = {
      comment: bodyComment,
      documentStatus: FuneralDtoConverter.toDtoFuneralPrintStatus(status)
    };
    return this.httpClient
      .put<FuneralResponseDto<{ id: string }>>(url, body)
      .pipe(map(HttpHelper.ensureValidResponse), catchError(HttpHelper.throwApiError))
      .pipe(map((response: FuneralResponseDto<{ id: string }>) => ({ id: response.data.id })));
  }

  public loadFile(
    fileId: string,
    fileName: string,
    funeralPrintStatus: string,
    size: FuneralPrintFileThumbnailSize = FuneralPrintFileThumbnailSize.Medium,
    isWatermarkRequired: boolean = false
  ): Observable<FuneralPrintFile> {
    const url = this.filesUrl(fileId, size);
    if (fileId) {
      const httpOptions = {
        observe: 'response' as 'body',
        responseType: 'blob' as 'json'
      };
      return this.httpClient
        .get(url, httpOptions)
        .pipe(map(HttpHelper.extractBlob), catchError(HttpHelper.throwApiError))
        .pipe(
          switchMap(({ blob }) =>
            this.getFuneralDocumentFileDto(blob, fileId, fileName, funeralPrintStatus, isWatermarkRequired)
          ),
          map((result: FuneralDocumentFileDto) => FuneralDtoConverter.toFuneralPrintFile(result, size))
        );
    }
    return of({} as FuneralPrintFile);
  }

  public resendEmailNotification(documentId: string): Observable<boolean> {
    const url = `${this.baseUrl()}/funeral-documents/${documentId}/notify`;

    return this.httpClient
      .post<FuneralResponseDto<any>>(url, {})
      .pipe(map(HttpHelper.ensureValidResponse), catchError(HttpHelper.throwApiError))
      .pipe(map((response: FuneralResponseDto<{ success: boolean }>) => response.data.success));
  }

  public updateDocument(funeralPrintId: string, formData: FormData): Observable<{ id: string }> {
    const url = `${this.baseUrl('/v3.0')}/funeral-documents/${funeralPrintId}`;

    return this.httpClient
      .put<FuneralResponseDto<{ id: string }>>(url, formData)
      .pipe(map(HttpHelper.ensureValidResponse), catchError(HttpHelper.throwApiError))
      .pipe(map((response: FuneralResponseDto<{ id: string }>) => ({ id: response.data.id })));
  }

  public loadDocumentStatuses(orderPks: Array<number>): Observable<Array<FuneralDocumentStatusesOrderDto>> {
    const url = `${this.baseUrl('/v2.0')}/orders/funeral-document-statuses`;
    const orderIdList = new Array<string>();
    orderPks.forEach((x) => {
      orderIdList.push(x.toString());
    });
    const formData = { orderIds: orderIdList };

    return this.httpClient
      .post<FuneralResponseDto<GetFuneralDocumentStatusesByOrderQueryResponseDto>>(url, formData)
      .pipe(
        map((response: FuneralResponseDto<GetFuneralDocumentStatusesByOrderQueryResponseDto>) =>
          FuneralDtoConverter.toFuneralDocumentStatusesOrder(response.data)
        )
      );
  }

  private baseUrl(version: string = FuneralPrintService.serviceEndpoint): string {
    return this.settingsService?.settings?.funeralsDocumentApiUrl
      ? `${this.settingsService.settings.funeralsDocumentApiUrl}${version}`
      : '';
  }

  private getFuneralDocumentFileDto(
    blob: Blob,
    funeralPrintFileId: string,
    funeralPrintFileName: string,
    funeralPrintStatus: string,
    isWatermarkRequired = false
  ): Observable<FuneralDocumentFileDto> {
    return Base64Helper.blobToBase64(blob).pipe(
      switchMap((base64Data) => {
        if (isWatermarkRequired) {
          return this.addWatermark(base64Data);
        }

        return of(base64Data);
      }),
      map((watermarkedImageData: string) => {
        const funeralDocumentFileDto: FuneralDocumentFileDto = {
          id: funeralPrintFileId,
          name: funeralPrintFileName,
          data: watermarkedImageData,
          status: funeralPrintStatus
        };

        return funeralDocumentFileDto;
      })
    );
  }

  private addWatermark(base64Image: string): Observable<string> {
    return new Observable((observer: Observer<string>) => {
      const img = new Image();
      img.src = base64Image.toString();
      img.onload = () => {
        const text: string = this.translateService.instant('general.preview');
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;

        const context = canvas.getContext('2d');
        context.drawImage(img, 0, 0);
        context.font = '18px Arial';
        context.globalAlpha = 0.15;
        context.fillStyle = '#999999';
        context.rotate((-45 * Math.PI) / 180);

        const textHeight = 75;
        const offset = 75;
        const textWidth = Math.ceil(context.measureText(text).width);
        const textWithSpace = new Array(textWidth * 2).join(`${text}     `);
        const maxLength = img.height > img.width ? img.height : img.width;
        const maxNumberOfRows = Math.ceil(maxLength / (textHeight - 25));
        for (let i = 0; i < maxNumberOfRows; i++) {
          context.fillText(textWithSpace, -(i * offset), i * textHeight);
        }

        observer.next(canvas.toDataURL());
        observer.complete();
      };
    });
  }
}
