import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  HostBinding,
  Input,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { ControlValueAccessor, UntypedFormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'mpac-visibility-btn',
  templateUrl: './visibility-btn.component.html',
  styleUrls: ['./visibility-btn.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => VisibilityBtnComponent),
      multi: true
    }
  ],
  encapsulation: ViewEncapsulation.None
})
export class VisibilityBtnComponent implements ControlValueAccessor, OnInit {
  public static cssBaseClass = 'mpac-visibility-btn';

  public static cssActiveClass = 'mpac-visibility-btn--active';

  public static cssIntegratedClass = 'mpac-visibility-btn--integrated';

  @HostBinding('class') hostElementClass = VisibilityBtnComponent.cssBaseClass;

  @Input()
  public disabled = false;

  @Input()
  public integrated = true;

  @Input()
  public formGroup: UntypedFormGroup;

  @Input()
  public controlName: string;

  @Output()
  public changeEmitter = new EventEmitter<any>();

  @Output()
  public touchedEmitter = new EventEmitter<any>();

  private _value: boolean;

  constructor(private ref: ChangeDetectorRef, public translateService: TranslateService) {}

  public get value(): boolean {
    return this._value;
  }

  public set value(value: boolean) {
    this._value = value;
    this.setHostBindingChanges();
    this.propagateChange(this._value);
  }

  // #region Implements ControlValueAccessor
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-unused-vars
  public propagateChange = (_: any) => {};

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public writeValue(obj: any): void {
    this._value = !!obj;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public registerOnChange(fn: any): void {
    this.changeEmitter.emit(fn);
    this.propagateChange(fn);
  }

  public registerOnTouched(): void {}

  public setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  // #endregion

  public ngOnInit(): void {
    this.value = !!this.formGroup.get(this.controlName).value;
  }

  /**
   * Click handler for the button click.
   */
  public changeValue(value: boolean): void {
    if (this.disabled) {
      return;
    }
    this.value = value;
    this.formGroup.get(this.controlName).setValue(this.value);
    this.formGroup.get(this.controlName).markAsDirty();
  }

  /**
   * Reflects the valeu changes on the hostElement class.
   */
  private setHostBindingChanges() {
    this.hostElementClass = !this.value
      ? `${VisibilityBtnComponent.cssBaseClass}${
          this.integrated ? ' ' + VisibilityBtnComponent.cssIntegratedClass : ''
        }`
      : `${VisibilityBtnComponent.cssBaseClass} ${VisibilityBtnComponent.cssActiveClass}${
          this.integrated ? ' ' + VisibilityBtnComponent.cssIntegratedClass : ''
        }`;
    this.ref.detectChanges();
  }
}
