import { CompanyState } from './company/company.state.model';
import { createFeatureSelector } from '@ngrx/store';
import { BreadcrumbState } from './breadcrumbs/breadcrumb-state.model';
import { Display } from '../models/display';
import { User } from '../models/user';

export interface CoreState {
  companyState: CompanyState;
  breadcrumbs: BreadcrumbState;
  helpText: string;
  user: User;
  display: Display;
}

export const coreStateFeatureKey = 'coreState';
export const coreStateFeatureSelector = createFeatureSelector<CoreState>(coreStateFeatureKey);
