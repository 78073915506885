import { IEndpointBaseService } from './../interfaces/endpoint-base-service.interface';
import { HttpClient } from '@angular/common/http';
import { SettingsService } from '@core/config/settings.service';

export abstract class EndpointBaseService implements IEndpointBaseService {
  public readonly serviceEndpoint: string;

  constructor(endpoint: string, protected http: HttpClient, protected settingsService: SettingsService) {
    this.serviceEndpoint = endpoint;
  }

  /**
   * Returns the base url to call endpoints on.
   */
  public get baseUrl(): string {
    return this.apiRoot ? `${this.apiRoot}${this.serviceEndpoint}` : '';
  }

  /**
   * The api root from the settings.
   */
  protected abstract get apiRoot(): string;
}
