import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmDialogModel } from '@core/models/confirm-dialog.model';

export interface ConfirmDialogConfig {
  title?: string;
  message?: string;
  confirmButtonText?: string;
  confirmButtonIcon?: string;
  dismissButtonText?: string;
  dismissButtonIcon?: string;
}

export const confirmButtonDefaultTranslationKey = 'confirmModal.confirm.default';

export const dismissButtonDefaultTranslationKey = 'confirmModal.dismiss.default';

export const titleDefaultTranslationKey = 'confirmModal.title.default';

export const messageDefaultTranslationKey = 'confirmModal.message.default';

@Component({
  selector: 'mpac-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.variables.scss', './confirm-dialog.component.scss']
})
export class ConfirmDialogComponent {
  public static baseClass = 'mpac-confirm-dlg';

  public title: string;

  public message: string;

  public dismissButtonText: string;

  public confirmButtonText: string;

  public confirmButtonIcn: string;

  public confirmButtonId: string;

  public dismissButtonIcn: string;

  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogModel
  ) {
    // Update view with given values
    this.title = data.title;
    this.message = data.message;
    this.dismissButtonText = data.dismissButtonText;
    this.dismissButtonIcn = data?.dismissButtonIcn;
    this.confirmButtonText = data.confirmButtonText;
    this.confirmButtonIcn = data?.confirmButtonIcn;
    this.confirmButtonId = data.confirmButtonId;
  }

  public onConfirm(): void {
    // Close the dialog, return true
    this.dialogRef.close(true);
  }

  public onDismiss(): void {
    // Close the dialog, return false
    this.dialogRef.close(false);
  }
}
