import { Component, Input, OnInit } from '@angular/core';
import { FileHelper } from '@shared/helpers/file.helper';
import { defaultPdfViewerConfig, PdfViewerConfig } from '@shared/models/pdf-viewer.interface';
import clone from 'lodash-es/clone';
import merge from 'lodash-es/merge';

@Component({
  selector: 'mpac-pdf-viewer',
  templateUrl: './pdf-viewer.component.html'
})
export class PdfViewerComponent implements OnInit {
  @Input() src: string;

  @Input() pdfViewerConfig: PdfViewerConfig;

  public viewerId: number = Math.floor(Math.random() * 100);

  public locale = 'de-DE';

  public config: PdfViewerConfig = {};

  public pdfData: string | Blob | Uint8Array;

  public ngOnInit(): void {
    this.setConfig();
    this.setPdfData();
  }

  private setConfig(): void {
    const config = this.pdfViewerConfig ? this.pdfViewerConfig : {};
    this.config = merge(clone(defaultPdfViewerConfig), config);
  }

  private setPdfData(): void {
    if (this.src?.includes('data:')) {
      const defaultMime = 'application/pdf';
      this.pdfData = FileHelper.fullBase64ToBlob(this.src, defaultMime);
    } else {
      this.pdfData = this.src;
    }
  }
}
