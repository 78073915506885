<nav class="navbar navbar-light shadow-lg">
  <a
    [ngStyle]="{ visibility: logoIsLoading ? 'hidden' : '' }"
    class="col-auto col-lg-3 col-xl-2 p-0 navbar-brand"
    [routerLink]="userRootRoute$ | async">
    <img alt="Logo" (load)="showLogo()" id="logo" src="{{ headerIconPath }}" (error)="updateHeader(true)" />
    <span class="text-dark d-none d-md-inline">{{ headerTitle }}</span>
    <span *ngIf="dependantDemoActive()" class="dependant-demo-label d-md-inline">{{
      'general.dependantDemo' | translate
    }}</span>
  </a>
  <mpac-breadcrumb [userLevel]="userLevel" class="breadcrumb-back"></mpac-breadcrumb>
  <div id="right-menu-container">
    <mpac-training-camp-link
      *ngIf="isTrainingCampLinkVisible"
      [linkPlacement]="trainingCampLinkPlacement"></mpac-training-camp-link>
    <mpac-help-toggle class="d-lg-block d-none"></mpac-help-toggle>
    <div *ngIf="isUserLoggedIn$ | async; else loggedOut" class="user-dropdown" dropdown placement="bottom right">
      <a
        (click)="(false)"
        aria-controls="dropdown-alignment"
        class="cursor-pointer text-dark user-dropdown__dropdown-toggle"
        dropdownToggle
        id="user-menu-toggler">
        <i class="fas fa-user d-inline d-lg-none mr-3"></i>
        <span class="d-none d-lg-inline user-dropdown__username">{{ userName$ | async }}</span>
        <i aria-hidden="true" class="fas fa-caret-down"></i>
      </a>

      <ul *dropdownMenu class="dropdown-menu dropdown-menu-right shadow-lg">
        <li class="dropdown-item cursor-pointer" *ngIf="dependantDemoActive()">
          <a (click)="finishDependantDemo()">
            {{ 'general.finishDependantDemo' | translate }}
          </a>
        </li>
        <li class="dropdown-item cursor-pointer" *ngIf="isDependantDemoVisible$ | async">
          <a (click)="startDependantDemo()">
            {{ 'general.startDependantDemo' | translate }}
          </a>
        </li>
        <li class="dropdown-item cursor-pointer">
          <a (click)="displayEmailSettings()">{{ 'general.emailSettings' | translate }}</a>
        </li>
        <li class="dropdown-item cursor-pointer">
          <a (click)="displayPrivacySettings()">{{ 'cookieBanner.privacySettings' | translate }}</a>
        </li>
        <li class="dropdown-item cursor-pointer">
          <a [routerLink]="['/logout']">{{ 'actions.signout' | translate }}</a>
        </li>
      </ul>
    </div>
  </div>
</nav>

<ng-template #loggedOut>-{{ 'general.notSignedIn' | translate }}-</ng-template>

<button
  *ngIf="dependantDemoActive()"
  (click)="finishDependantDemo()"
  class="dependant-demo-quick-logout-btn btn btn-secondary">
  {{ 'general.finishDependantDemo' | translate }} <i class="fas fa-arrow-up fa-rotate-90"></i>
</button>
