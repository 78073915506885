import { Component, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

@Component({ template: '' })
export abstract class DesctructableComponent implements OnDestroy {
  protected shutdown$: Subject<void> = new Subject<void>();

  public ngOnDestroy(): void {
    this.shutdown$.next(null);
    this.shutdown$.unsubscribe();
  }
}
