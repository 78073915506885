import { AuthConfig } from 'angular-oauth2-oidc';
import { SettingsService } from './settings.service';

export class AppAuthConfig {
  private config = {
    clientId: '',
    issuer: '',
    redirectUri: '',
    requireHttps: false,
    responseType: 'code',
    scope:
      'openid webToolsProfile webToolsAdminApi precautionInfosApi quickFormDependantApi funeralAddressApi offline_access funeralMusicInternalApi bannedWordsApi funeralDocumentsApi',
    sessionCheckIntervall: 5 * 1000 * 60,
    sessionChecksEnabled: true,
    skipIssuerCheck: false
  } as AuthConfig;

  constructor(private settingsService: SettingsService) {
    this.config.issuer = this.settingsService.authSettings.identityAuthority;
    this.config.skipIssuerCheck = this.settingsService.authSettings.skipIssuerCheck;
    this.config.clientId = this.settingsService.authSettings.identityApiClientId;
    this.config.requireHttps = this.settingsService.authSettings.requireHttps;
    this.config.redirectUri = `${window.location.origin}/`;
  }

  get authConfig(): AuthConfig {
    return this.config;
  }
}
