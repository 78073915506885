import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EventService {
  /**
   * A stream that emits <void> if a paged is scrolled to the end
   */
  private pageScrollsReachesEndStream$: Subject<void> = new Subject<void>();

  constructor() {}

  /**
   * returns the pageScrollsReachesEndStream$
   */
  public get pageScrollsReachesEnd$(): Observable<void> {
    return this.pageScrollsReachesEndStream$ as Observable<void>;
  }

  /**
   * Pushes ScrollEvent to the pageScrollsReachesEndStream$
   */
  public emitPageScrollReachesEndEvent(): void {
    this.pageScrollsReachesEndStream$.next();
  }
}
