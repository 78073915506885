<svg width="0" height="0">
  <defs>
    <clipPath id="edge-clip-path">
      <polygon points="-5 -5,60 -5,120 60,120 -5,1000 -5, 1000 1000, -5 1000"></polygon>
    </clipPath>
  </defs>
</svg>

<div class="shadow-container">
  <div class="content">
    <h1>{{ 'userElevation.header' | translate }}</h1>

    <p>
      {{ 'userElevation.emailExists' | translate }}
      <span *ngIf="!processUserResult?.isLocalUser"> {{ 'userElevation.localUserText' | translate }}</span>
    </p>

    <p>{{ processUserResult?.message }}</p>

    <div class="d-flex justify-content-end align-items-center mt-4">
      <a *ngIf="isLoggedIn$ | async; else loggedOutLink" class="btn btn-primary" routerLink="/">{{
        'userElevation.toHomepage' | translate
      }}</a>
    </div>
  </div>
</div>
<ng-template #loggedOutLink>
  <a class="btn btn-primary" routerLink="/login">{{ 'userElevation.toLogin' | translate }}</a>
</ng-template>
