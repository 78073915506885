import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SettingsService } from '@core/config/settings.service';
import { FakeBackend } from '@core/models/fake-backend.model';
import { Observable } from 'rxjs';
import { FuneralAddressService } from 'src/app/features/order-portal/features/funeral-address/services/funeral-address.service';
import { FuneralPrintService } from 'src/app/features/order-portal/features/funeral-print/services/funeral-print.service';
import { QuickFormDependantService } from 'src/app/features/order-portal/services/quick-form-dependant.service';
import { QuickformOrderInfoService } from 'src/app/features/order-portal/services/quickform-order-info.service';
import { QuickformOrderMasterdataService } from 'src/app/features/order-portal/services/quickform-order-masterdata.service';
import { GlobalWriteProtectionService } from 'src/app/shared/services/global-write-protection.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class FakeBackendInterceptor implements HttpInterceptor {
  constructor(
    private globalWriteProtectionService: GlobalWriteProtectionService,
    private userService: UserService,
    private settingsService: SettingsService,
    private funeralPrintService: FuneralPrintService,
    private quickformOrderInfoService: QuickformOrderInfoService,
    private quickFormDependantService: QuickFormDependantService,
    private quickformOrderMasterdataService: QuickformOrderMasterdataService,
    private funeralAddressService: FuneralAddressService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const user = this.userService.readUserFromLocalStorage();
    if (!this.globalWriteProtectionService.isActive() || !user || !user.managedOrders) {
      return next.handle(request);
    }

    const dependantOrderId = this.settingsService.demoMorticianSettings.dependantOrderId;
    const orderPk = user.managedOrders
      .filter((o) => o.orderId === dependantOrderId)
      .map((o) => o.id.match(/\d+/g).map(Number)[0])[0];

    const fakeBackends = this.initfakeBackend(orderPk);
    const fakeBackend = fakeBackends.filter((r) => request.url === r.url && request.method === 'GET')[0];
    if (fakeBackend) {
      request = request.clone({
        url: `/assets/api-data/demo-mortician/${dependantOrderId}/${fakeBackend.response}`
      });
    }

    return next.handle(request);
  }

  private initfakeBackend(orderId: number): FakeBackend[] {
    const demoMorticianSettings = this.settingsService.demoMorticianSettings;
    // funeral-documents
    const fakeBackendArray: FakeBackend[] = [
      { url: this.funeralPrintService.templatesUrl(orderId), response: 'funeral-documents/template.json' },
      {
        url: this.funeralPrintService.funeralDocumentsUrl(orderId),
        response: 'funeral-documents/funeral-documents.json'
      }
    ];

    demoMorticianSettings.dependantFuneralDocumentsImages.split(',').forEach((imageId) => {
      fakeBackendArray.push({
        url: this.funeralPrintService.filesUrl(imageId, 'Thumbnail'),
        response: `funeral-documents/images/${imageId}-thumbnail.png`
      });
      fakeBackendArray.push({
        url: this.funeralPrintService.filesUrl(imageId, 'Med'),
        response: `funeral-documents/images/${imageId}-med.png`
      });
    });
    // quickform
    fakeBackendArray.push({
      url: this.quickformOrderInfoService.ordersUrl(demoMorticianSettings.dependantOrderId),
      response: 'quickform/orders.json'
    });
    fakeBackendArray.push({
      url: this.quickFormDependantService.orderByIdUrl(demoMorticianSettings.dependantColumbaOrderId),
      response: 'quickform/order-by-id.json'
    });
    fakeBackendArray.push({
      url: this.quickFormDependantService.partnerActionsUrl(demoMorticianSettings.dependantColumbaOrderId),
      response: 'quickform/partner-actions.json'
    });

    demoMorticianSettings.dependantQuickformPartnerActions.split(',').forEach((partnerActionId) => {
      fakeBackendArray.push({
        url: this.quickFormDependantService.partnerActionByIdUrl(partnerActionId),
        response: `quickform/partner-actions/${partnerActionId}.json`
      });
    });

    demoMorticianSettings.dependantQuickformPartnerActionsDocuments.split(',').forEach((partnerActionDocumentId) => {
      fakeBackendArray.push({
        url: this.quickFormDependantService.partnerActionDocumentByIdUrl(partnerActionDocumentId),
        response: `quickform/partner-action-documents/${partnerActionDocumentId}`
      });
    });

    demoMorticianSettings.dependantQuickformOrderDocuments.split(',').forEach((orderDocumentId) => {
      fakeBackendArray.push({
        url: this.quickFormDependantService.orderDocumentsUrl(orderDocumentId),
        response: `quickform/order-documents/${orderDocumentId}`
      });
    });

    fakeBackendArray.push({
      url: this.quickformOrderMasterdataService.masterdataUrl(demoMorticianSettings.dependantColumbaOrderId),
      response: 'quickform/master-data.json'
    });

    // funeral-address
    fakeBackendArray.push({
      url: this.funeralAddressService.accessLevelByOrderPkUrl(orderId),
      response: 'funeral-address/access-level.json'
    });
    fakeBackendArray.push({
      url: this.funeralAddressService.addressListByOrderPk(orderId),
      response: 'funeral-address/address-list.json'
    });

    const addressListId = demoMorticianSettings.dependantFuneralAddressAddressListIdForCsvExport;
    const sectionId = demoMorticianSettings.dependantFuneralAddressSectionIdForCsvExport;
    fakeBackendArray.push({
      url: this.funeralAddressService.excelExportByAddressesByUUid(addressListId),
      response: 'funeral-address/export.xlsx'
    });
    fakeBackendArray.push({
      url: this.funeralAddressService.excelExportByAddressListUuiIdAndSectionUuiId(addressListId, sectionId),
      response: 'funeral-address/export-section.xlsx'
    });

    return fakeBackendArray;
  }
}
