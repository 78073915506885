<div class="mpac-dyn-table" [class.mpac-dyn-table--ksc]="!useNativeStyles">
  <table
    mat-table
    [dataSource]="dataSource"
    matSort
    (matSortChange)="sortData($event)"
    class="mpac-dyn-table__tbl"
    #dTable
    *ngIf="tableConfig">
    <ng-container *ngFor="let columnDef of tableConfig.columDefinitions" [matColumnDef]="columnDef.accessor">
      <ng-container *ngIf="isRowSortable(columnDef)">
        <th
          class="mpac-dyn-table__tbl-cell mpac-dyn-table__tbl-cell--hdr mpac-dyn-table__tbl-cell--hdr-srt"
          mat-header-cell
          [mat-sort-header]="columnDef.accessor"
          *matHeaderCellDef
          (mouseover)="toggleSortHoverClass($event)"
          (mouseleave)="toggleSortHoverClass($event)">
          {{ columnDef.displayNameKey | translate }}
        </th>
      </ng-container>
      <ng-container *ngIf="!isRowSortable(columnDef)">
        <th class="mpac-dyn-table__tbl-cell mpac-dyn-table__tbl-cell--hdr" mat-header-cell *matHeaderCellDef>
          {{ columnDef.displayNameKey | translate }}
        </th>
      </ng-container>

      <!-- Cell with actions -->
      <ng-container *ngIf="hasColCellAction(columnDef)">
        <td
          class="mpac-dyn-table__tbl-cell mpac-dyn-table__tbl-cell--action"
          [class.mpac-dyn-table__tbl-cell--img-with-text]="hasColImage(columnDef) && shouldShowTextValue(columnDef)"
          mat-cell
          *matCellDef="let element"
          [routerLink]="getCellAction(columnDef, element)">
          <img
            class="mpac-dyn-table__tbl-cell--img"
            *ngIf="hasColImage(columnDef)"
            #icon
            (error)="columnDef.image.errorFunction(icon)"
            [src]="apply(element, columnDef.image.srcFunction)"
            [alt]="apply(element, columnDef.image.altFunction)"
            [title]="apply(element, columnDef.image.titleFunction)" />

          <ng-container *ngIf="shouldShowTextValue(columnDef)">
            {{ element[columnDef.accessor] }}
          </ng-container>

          <!-- Cell functions -->
          <mpac-dynamic-table-cell-functions
            *ngIf="hasColCellFunctions(columnDef)"
            [cellFunctions]="columnDef.cellFunctions"
            [data]="element">
          </mpac-dynamic-table-cell-functions>
        </td>
      </ng-container>

      <!-- Cell without actions -->
      <ng-container *ngIf="!hasColCellAction(columnDef)">
        <td
          class="mpac-dyn-table__tbl-cell"
          [class.mpac-dyn-table__tbl-cell--img-with-text]="hasColImage(columnDef) && shouldShowTextValue(columnDef)"
          mat-cell
          *matCellDef="let element">
          <img
            class="mpac-dyn-table__tbl-cell--img"
            *ngIf="hasColImage(columnDef)"
            #icon
            (error)="columnDef.image.errorFunction(icon)"
            [src]="apply(element, columnDef.image.srcFunction)"
            [alt]="apply(element, columnDef.image.altFunction)"
            [title]="apply(element, columnDef.image.titleFunction)" />

          <ng-container *ngIf="shouldShowTextValue(columnDef)">
            {{ element[columnDef.accessor] }}
          </ng-container>

          <!-- Cell functions -->
          <mpac-dynamic-table-cell-functions
            *ngIf="hasColCellFunctions(columnDef)"
            [cellFunctions]="columnDef.cellFunctions"
            [data]="element">
          </mpac-dynamic-table-cell-functions>
        </td>
      </ng-container>
    </ng-container>

    <!-- No Data -->
    <ng-container matColumnDef="isDataAvailable">
      <td
        class="mpac-dyn-table__tbl-cell mpac-dyn-table__tbl-cell--footer"
        *matFooterCellDef
        [attr.colspan]="
          tableConfig && tableConfig.columDefinitions && tableConfig.columDefinitions.length
            ? tableConfig.columDefinitions.length
            : 0
        ">
        {{ tableConfig.noDataMessage }}
      </td>
    </ng-container>

    <!-- Row Definitions -->
    <tr
      class="mpac-dyn-table__tbl-row mpac-dyn-table__tbl-row--header"
      mat-header-row
      *matHeaderRowDef="tableConfig.displayedColumns"></tr>

    <ng-container *ngIf="hasRowAction()">
      <tr
        class="mpac-dyn-table__tbl-row mpac-dyn-table__tbl-row--action"
        mat-row
        *matRowDef="let row; columns: tableConfig.displayedColumns"
        [routerLink]="getRowAction(row)"
        [queryParams]="getRowQueryParams(row)"></tr>
    </ng-container>
    <ng-container *ngIf="!hasRowAction()">
      <tr class="mpac-dyn-table__tbl-row" mat-row *matRowDef="let row; columns: tableConfig.displayedColumns"></tr>
    </ng-container>

    <tr
      class="mpac-dyn-table__tbl-row mpac-dyn-table__tbl-row--footer"
      mat-footer-row
      *matFooterRowDef="['isDataAvailable']"
      [class.mpac-dyn-table__tbl-row--hidden]="hasData()"></tr>
  </table>

  <!-- Pagination -->
  <mat-paginator
    class="mpac-dyn-table__pag"
    [hidden]="(tableConfig?.paginatorOptions?.enabled | async) === false"
    [pageSize]="tableConfig?.paginatorOptions?.pageSize"
    [pageSizeOptions]="tableConfig?.paginatorOptions?.pageSizeOptions">
  </mat-paginator>
</div>
