/**
 * The company's full featured database model.
 */
import { ProductPackagesDto } from '../dtos/product-packages-dto.enum';
import { ActivatableFeature } from './activatable-feature';

export interface Company {
  adButtontext: string;
  adHeader: string;
  adHref: string;
  adSymbolIndex: number;
  adText: string;
  candleAutomationIsActive: boolean;
  candleKinds: string;
  dataProtectionLink: string;
  defaultAdminEmailText: string;
  defaultCountry: string;
  emailAddress: string;
  emailSignature: string;
  escortPageSize: number;
  eurofloristId: string;
  eurofloristMemberId: string;
  features: ActivatableFeature[];
  floristChoiceText: string;
  flowershopReturnUri: string;
  hasNoAddressInputAssistance: boolean;
  helpfunusColorCode: string;
  helpfunusLogoUrl: string;
  host: string;
  impressumLink: string;
  isEntryWithBlockedWordsLocked: boolean;
  canCreateManualOrders: boolean;
  canUpsertAppointments: boolean;
  useCommonLayout: boolean;
  isSocialMediaAvailable: boolean;
  lastAutomaticQuotationPk: number;
  maxTemplates: number;
  memoryBookUserPk?: number;
  name: string;
  phoneNumber: string;
  pk: number;
  portalState: PortalState;
  productPackage: ProductPackagesDto;
  profileImageAssignmentMode: number;
  prolongationMode: number;
  prolongationMonths: number;
  quotationAssignmentMode: number;
  rapidId: string;
  selectedCandleColor: CandleColor;
  selectedCandleShape: CandleShape;
  selectedCandleTextPk: number;
  selectedProfileImagePk: number;
  shortSignature: string;
  town: string;
  ucloudDays: number;
  zipCode: string;
  isDemo: boolean;
  shouldSendInvitations: boolean;
}

/**
 * An enumeration of the available portal states.
 */
export enum PortalState {
  /**
   * The portal can't contain memorial pages.
   */
  None = 0,

  /**
   * The portal may only contain basic memorial pages.
   */
  Basic = 1,

  /**
   * The portal may contain memorial pages with all features (premium).
   */
  Complete = 2
}

/**
 * Enumeration of possible candle colors.
 */
export enum CandleColor {
  Blue = 'blau',
  Yellow = 'gelb',
  Green = 'gruen',
  LightBlue = 'hellblau',
  Purple = 'lila',
  Red = 'rot'
}

/**
 * Enumeration of possible candle shapes.
 */
export enum CandleShape {
  Bulgy = 'Bulgy',
  Normal = 'Normal',
  Globular = 'Globular'
}
