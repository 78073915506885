import { createReducer, on } from '@ngrx/store';
import { PrecautionInfoState } from 'src/app/features/company-portal/store/common';
import * as PrecautionInfoActions from './precaution-info.actions';

export const initialPrecautionInfoState: PrecautionInfoState = {
  infoRequested: false,
  isLoading: true,
  configuration: { enabled: false }
} as PrecautionInfoState;

export const precautionInfoOrderStateReducer = createReducer(
  initialPrecautionInfoState,
  on(PrecautionInfoActions.loadPrecautionInfoSuccess, (state: PrecautionInfoState, { config }) => ({
    ...state,
    configuration: config
  })),
  on(PrecautionInfoActions.requestAdditionalInformationSuccess, (state: PrecautionInfoState) => ({
    ...state,
    infoRequested: true
  }))
);
