<mpac-mat-dialog-header
  *ngIf="title"
  [customClass]="'mpac-confirm-dlg__hdr'"
  [headline]="title"
  [translateHeadline]="false">
</mpac-mat-dialog-header>

<mpac-mat-dialog-content [customClass]="'mpac-confirm-dlg__cnt'">
  <ng-template #contentElementRef>
    <p [innerHtml]="message"></p>
  </ng-template>
</mpac-mat-dialog-content>

<mpac-mat-dialog-footer [customClass]="'mpac-confirm-dlg__ftr'">
  <ng-template let-functions>
    <mpac-mat-dialog-footer-fct>
      <ng-template let-fct>
        <button *ngIf="dismissButtonText" class="btn btn-outline-primary" (click)="onDismiss()">
          <span *ngIf="dismissButtonIcn" class="btn__txt mpac-mat-dlg__ftr-fct-icn">
            <i class="{{ dismissButtonIcn }}"></i>
          </span>
          <span class="btn__txt mpac-mat-dlg__ftr-fct-txt">
            {{ dismissButtonText }}
          </span>
        </button>
      </ng-template>
    </mpac-mat-dialog-footer-fct>

    <mpac-mat-dialog-footer-fct>
      <ng-template let-fct>
        <button class="btn btn-primary" (click)="onConfirm()" [attr.id]="confirmButtonId" mpacGlobalWriteProtection>
          <span *ngIf="confirmButtonIcn" class="btn__txt mpac-mat-dlg__ftr-fct-icn">
            <i class="{{ confirmButtonIcn }}"></i>
          </span>
          <span class="btn__txt mpac-mat-dlg__ftr-fct-txt">
            {{ confirmButtonText }}
          </span>
        </button>
      </ng-template>
    </mpac-mat-dialog-footer-fct>
  </ng-template>
</mpac-mat-dialog-footer>
