<mat-form-field class="chip-list w-100" appearance="outline">
  <mat-label>{{ 'general.tags' | translate }}{{ isRequired ? '*' : '' }}</mat-label>
  <mat-chip-list #chipList [formControl]="ngControl.control">
    <mat-chip *ngFor="let i of items" (removed)="remove(i)">
      {{ valueAttr ? i[valueAttr] : i }}
      <button matChipRemove class="close pull-right" type="button">
        <span aria-hidden="true">&times;</span>
      </button>
    </mat-chip>
    <input
      [(ngModel)]="currentInput"
      (focus)="onFocus()"
      placeholder="{{ 'forms.placeholders.shared.newTag' | translate }}"
      [matChipInputFor]="chipList"
      (matChipInputTokenEnd)="addTagFromInput($event)" />
  </mat-chip-list>
  <mat-hint *ngIf="currentInput?.length" [innerHtml]="'general.enterToConfirm' | translate"></mat-hint>
</mat-form-field>
<mpac-print-form-errors-for [control]="ngControl.control"> </mpac-print-form-errors-for>
