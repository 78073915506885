import { AbstractControl, ValidatorFn } from '@angular/forms';

export const lessThanValidator =
  (valueFieldKey: string, valueFieldName: string): ValidatorFn =>
  (control: AbstractControl): { [key: string]: any } | null => {
    if (control.value) {
      if (control.parent) {
        const relatedField = control.parent.get(valueFieldKey);
        if (!!relatedField.value && control.value > relatedField.value) {
          return { lessThan: valueFieldName };
        }
      }
    }
  };
