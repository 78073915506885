import { DesctructableComponent } from 'src/app/shared/components/destructable/destructable.component';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { CmsService, ContentfulEntriesService, EmailTemplate, ProductInfoPage } from '@rapid/cms-lib';
import { MarkdownService } from 'ngx-markdown';
import { filter, map, takeUntil } from 'rxjs/operators';
import { CoreState } from '../../store/common';
import { setTopicAction } from '../../store/help/help.actions';
import { CmsContentTypes } from '@shared/features/contentful/enums/cms-content-types.enum';
import { selectBreadcrumbs } from '@core/store/breadcrumbs/breadcrum.selectors';
import { BreadcrumbEntry } from '@core/models/breadcrumb-entry';
import { selectCompany } from '@core/store/company/company.selectors';
import { Company } from '@shared/models/company';

@Component({
  selector: 'mpac-product-info-page',
  templateUrl: './product-info-page.component.html',
  styleUrls: ['./product-info-page.component.scss']
})
export class ProductInfoPageComponent extends DesctructableComponent implements OnInit, OnDestroy {
  /**
   * Indicates whether the content is currently being loaded
   * from the CMS.
   */
  public isContentLoading = true;

  /**
   * Indicates whether the email template is currently being loaded
   * from the contentful CMS.
   */
  public isEmailTemplateLoading = true;

  /**
   * Indicates whether fetching the content from the
   * CMS failed.
   */
  public hasContentLoadErrorOccurred = false;

  /**
   * The @{ProductInfoPageContent} containing the text for
   * this product information page.
   */
  public pageContent: ProductInfoPage;

  /**
   * The contentful entry id of the E-Mail template.
   */
  public emailTemplateId: string;

  /**
   * The current email template for this pages 'activate' button.
   */
  public emailTemplate: EmailTemplate;

  /**
   * Contentful entry ID of the content to fetch for this page.
   */
  private pageContentId: string;

  /**
   * Contentful entry ID for the help text.
   */
  private helpTopicId: string;

  /**
   * The current name of the module activation target, either
   * the company name in case of the mortician user or the
   * order name in case of an order admin.
   */
  private moduleActivationTarget: string;

  private companyName: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private store$: Store<CoreState>,
    private markdownService: MarkdownService,
    private cmsService: CmsService,
    private contentfulEntriesService: ContentfulEntriesService
  ) {
    super();
    this.pageContentId = this.contentfulEntriesService.instant(this.route.snapshot.data.cmsEntryKey as string);
    this.emailTemplateId = this.contentfulEntriesService.instant(this.route.snapshot.data.emailTemplateKey as string);
    this.helpTopicId = this.contentfulEntriesService.instant('helpText.productInformation');
  }

  /**
   * Returns the headline for the page stripped of the outer <p>-tags
   * added by the markdown compiler.
   */
  public get markdownHeadline(): string {
    const markupWithParagraph = this.markdownService.parse(this.pageContent?.headline);
    return markupWithParagraph
      .replace('&#10;', '') // it puts &#10; at the end. no idea why.
      .replace(/<\s*p[^>]*>|<\s*\/\s*p>/g, ''); // remove p-tags for heading
  }

  public ngOnInit(): void {
    this.store$.dispatch(setTopicAction({ topic: this.helpTopicId }));

    this.store$
      .pipe(
        takeUntil(this.shutdown$),
        select(selectBreadcrumbs),
        filter((bc) => !!bc),
        map((breadcrumbs: BreadcrumbEntry[]) => breadcrumbs[0]?.displayText ?? '')
      )
      .subscribe((moduleActivationTarget: string) => (this.moduleActivationTarget = moduleActivationTarget));

    this.store$
      .pipe(
        takeUntil(this.shutdown$),
        select(selectCompany),
        filter((c) => !!c),
        map((company: Company) => company.name)
      )
      .subscribe((companyName: string) => (this.companyName = companyName));

    this.cmsService
      .getFieldById([this.pageContentId, this.emailTemplateId])
      .pipe(
        takeUntil(this.shutdown$),
        filter((res) => !!res)
      )
      .subscribe(
        (cmsResponse: Map<string, Map<string, any>> | ProductInfoPage | EmailTemplate) => {
          if (cmsResponse instanceof Map) {
            const pips = cmsResponse?.get(CmsContentTypes.ProductInfoPage);
            const emailTemplates = cmsResponse?.get(CmsContentTypes.EmailTemplate);

            this.pageContent = pips?.get(this.pageContentId) || null;
            this.emailTemplate = emailTemplates?.get(this.emailTemplateId) || null;
          } else {
            if (cmsResponse) {
              if (cmsResponse instanceof EmailTemplate) {
                this.emailTemplate = cmsResponse;
              } else {
                if (cmsResponse instanceof ProductInfoPage) {
                  this.pageContent = cmsResponse;
                } else {
                  this.hasContentLoadErrorOccurred = true;
                }
              }
            }
          }
          if (!this.pageContent && !this.hasContentLoadErrorOccurred) {
            this.hasContentLoadErrorOccurred = true;
          }
          if (this.emailTemplateId && !this.emailTemplate) {
            console.warn(`Die Email Template mit der ID: ${this.emailTemplateId} konnte nicht geladen werden!`);
          }
          this.isEmailTemplateLoading = false;
          this.isContentLoading = false;
        },
        () => {
          this.isContentLoading = false;
          this.hasContentLoadErrorOccurred = true;
        }
      );
  }

  public navigateToContactFormWithRouterState(): void {
    const subject = this.emailTemplate?.subject?.replace(/{activationTargetName}/, this.moduleActivationTarget);
    const body = this.emailTemplate?.body
      ?.replace(/{activationTargetName}/g, this.moduleActivationTarget)
      .replace(/{companyName}/, this.companyName);
    this.router.navigate(['../kontakt'], { relativeTo: this.route, state: { subject, body } });
  }
}
