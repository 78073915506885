import { createReducer, createSelector, on } from '@ngrx/store';
import { OrderPortalState, orderPortalStateFeatureSelector } from '../common';

import * as QuickformOrderFeaturesActions from '../quickform-order-features/quickform-order-features.actions';

export interface QuickformOrderFeaturesState {
  quickformOrderId: string;
  morticianLicenseName: string;
}

export const initialState: QuickformOrderFeaturesState = {
  quickformOrderId: null,
  morticianLicenseName: null
};

export const quickformOrderFeaturesReducer = createReducer(
  initialState,
  on(
    QuickformOrderFeaturesActions.loadQuickformOrderFeaturesSuccessAction,
    (state: QuickformOrderFeaturesState, { quickformOrderId, morticianLicenseName }) => ({ quickformOrderId, morticianLicenseName })
  )
);

export const quickformOrderFeaturesSelector = createSelector(
  orderPortalStateFeatureSelector,
  (state: OrderPortalState) => (state ? state.quickformOrderFeatures : undefined)
);
