<div class="modal-header">
  <h4 *ngIf="dialogTitleKey" class="modal-title pull-left">{{ dialogTitleKey | translate }}</h4>
  <button
    (click)="hideDialog()"
    attr.aria-label="{{ 'general.close' | translate }}"
    class="close pull-right"
    type="button">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <ng-content select="[mpacDialogContent]"></ng-content>
</div>
