import { Component, EventEmitter, Input, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControlDirective, NgControl, Validators } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';

@Component({
  selector: 'mpac-tags-input',
  templateUrl: './tags-input.component.html',
  styleUrls: ['./tags-input.component.scss']
})
export class TagsInputComponent implements ControlValueAccessor, OnInit {
  @ViewChild(FormControlDirective, { static: true }) formControlDirective: FormControlDirective;

  @Input() onFormSubmit: EventEmitter<void>;

  @Input() minTagLength: number;

  @Input() valueAttr?: string;

  @Input() idAttr?: string;

  public items: Set<any> = new Set();

  public currentInput: string;

  constructor(public ngControl: NgControl) {
    this.ngControl.valueAccessor = this;
  }

  public get isRequired(): boolean {
    return !!this.ngControl.control?.hasValidator(Validators.required);
  }

  public ngOnInit(): void {
    if (this.ngControl.control && !this.ngControl.control.value) {
      this.ngControl.control.setValue([], { emitEvent: false });
    }

    this.onFormSubmit?.subscribe(() => (this.currentInput = ''));
  }

  public remove(tag: any): void {
    this.items.delete(tag);
    this.propagateValue(true);
  }

  public addTagFromInput(event: MatChipInputEvent): void {
    if (event.value) {
      if (this.validateCurrentInput(event)) {
        if (this.valueAttr) {
          const newTag = {};
          newTag[this.valueAttr] = event.value;
          this.items.add(newTag);
        } else {
          this.items.add(event.value);
        }
        event.chipInput.clear();
        this.propagateValue();
        this.currentInput = '';
      }
    }
  }

  public onFocus(): void {
    this.ngControl.control.markAsUntouched();
    this.ngControl.control.updateValueAndValidity();
  }

  public writeValue(value: any): void {
    if (value) {
      this.items = this.getItemsFromValue(value);
      this.formControlDirective?.valueAccessor?.writeValue(value);
    }
  }

  public registerOnChange(fn: any): void {
    this.formControlDirective?.valueAccessor?.registerOnChange(fn);
  }

  public registerOnTouched(fn: any): void {
    this.formControlDirective?.valueAccessor?.registerOnTouched(fn);
  }

  public setDisabledState?(isDisabled: boolean): void {
    this.formControlDirective?.valueAccessor?.setDisabledState(isDisabled);
  }

  private validateCurrentInput(event: MatChipInputEvent): boolean {
    const inputLength = event.value.length;
    let isValid = true;

    if (inputLength < this.minTagLength) {
      isValid = false;
      this.ngControl.control.setErrors({ tagInputMinLength: this.minTagLength });
      event.chipInput.inputElement.blur();
    }

    return isValid;
  }

  private propagateValue(emitEvent: boolean = false): void {
    const currentValues = Array.from(this.items.values());
    this.ngControl.control.setValue(currentValues, { emitEvent });
  }

  private getItemsFromValue(value: any): Set<string> {
    const set = new Set<string>();

    if (Array.isArray(value)) {
      value.forEach((item: string) => set.add(item));
    } else if (typeof value === 'string') {
      set.add(value);
    } else {
      console.warn(
        'Trying to set control value to an unsupported-type value. Only string and string-list is allowed: ',
        value
      );
    }

    return set;
  }
}
