<label *ngIf="hasLabel" class="d-block small">{{ getLabelText() }}</label>
<div>
  <input type="hidden" [formControl]="ngControl.control" />
  <div class="btn-group" btnRadioGroup [(ngModel)]="currentValue" (ngModelChange)="onValueChange($event)">
    <label
      [class.disabled]="!!configuration?.left?.isDisabled || ngControl.control.disabled"
      [btnRadio]="configuration?.left?.value"
      [disabled]="configuration?.left?.isDisabled || ngControl.control.disabled"
      tooltip="{{ configuration?.left?.tooltipTranslationKey | translate }}"
      attr.aria-label="{{ configuration?.left?.tooltipTranslationKey | translate }}"
      class="btn toggle-btn"
      role="button"
      tabindex="0">
      <i aria-hidden="true" class="{{ configuration?.left?.iconNames }}"></i>
    </label>
    <label
      [class.disabled]="!!configuration?.middle?.isDisabled || ngControl.control.disabled"
      [btnRadio]="configuration?.middle?.value"
      [disabled]="configuration?.middle?.isDisabled || ngControl.control.disabled"
      tooltip="{{ configuration?.middle?.tooltipTranslationKey | translate }}"
      attr.aria-label="{{ configuration?.middle?.tooltipTranslationKey | translate }}"
      class="btn toggle-btn"
      role="button"
      tabindex="0">
      <i aria-hidden="true" class="{{ configuration?.middle?.iconNames }}"></i>
    </label>
    <label
      [class.disabled]="!!configuration?.right?.isDisabled || ngControl.control.disabled"
      [btnRadio]="configuration?.right?.value"
      [disabled]="configuration?.right?.isDisabled || ngControl.control.disabled"
      tooltip="{{ configuration?.right?.tooltipTranslationKey | translate }}"
      attr.aria-label="{{ configuration?.right?.tooltipTranslationKey | translate }}"
      class="btn toggle-btn"
      role="button"
      tabindex="0">
      <i aria-hidden="true" class="{{ configuration?.right?.iconNames }}"></i>
    </label>
  </div>
  <mpac-print-form-errors-for [control]="ngControl.control"></mpac-print-form-errors-for>
</div>
