import { TranslationModule } from './core/translations/translations.module';
import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { MarkdownModule } from 'ngx-markdown';
import { ToastrModule } from 'ngx-toastr';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SettingsHttpService } from './core/config/settings.http.service';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { appSettingsFactory } from './core/config/settings.factory';
import { BaseModule } from './base/base.module';
import { TranslateService } from '@ngx-translate/core';
import { I18nService } from './shared/services/i18n.service';
import { appTranslationsFactory } from '@core/config/app-translations.factory';
import { NgxHotjarInjectorModule } from 'ngx-hotjar-injector';
import {
  CmsLibModule,
  CmsModuleConfiguration,
  CMS_LIB_ADAPTER,
  CountryAdapter,
  CountryListAdapter,
  EmailTemplateAdapter,
  FeatureRichTextAdapter,
  FeatureTextAdapter,
  ProductInfoPageAdapter,
  StartpageContentAdapter,
  HelpTextAdapter,
  InfoBannerAdapter,
  CuratedListOrderAdapter,
  FeatureMediaAdapter
} from '@rapid/cms-lib';
import { ContentfulEntriesHttpService } from '@core/config/cms/contentful-entries-http.service';
import { cmsEntriesServiceFactory } from '@core/config/cms/cms-entries-service.factory';
import { coreStateReducers, coreStateMetaReducers } from '@core/store';
import { GlobalErrorHandler } from './global-error-handler';

const appSettings = SettingsHttpService.getAppSettingsForJson();
@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    BrowserModule,
    TranslationModule,
    CoreModule.forRoot(),
    BaseModule,
    HttpClientModule,
    MarkdownModule.forRoot(),
    NgxHotjarInjectorModule,
    InfiniteScrollModule,
    SharedModule,
    StoreModule.forRoot(coreStateReducers, { metaReducers: coreStateMetaReducers }),
    EffectsModule.forRoot([]),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: appSettings.production }),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: appSettings.production }),
    CmsLibModule.forRoot({
      cmsConfig: {
        space: appSettings.contentfulSpaceId,
        locale: 'de',
        accessToken: appSettings.contentfulAccessToken,
        environment: appSettings.contentFulEnvironmentId
      },
      idServiceSourceFile: './../assets/cms/contentful-entries.json'
    } as CmsModuleConfiguration)
  ],
  providers: [
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: APP_INITIALIZER, useFactory: appSettingsFactory, deps: [SettingsHttpService], multi: true },
    {
      provide: APP_INITIALIZER,
      useFactory: appTranslationsFactory,
      deps: [TranslateService, I18nService],
      multi: true
    },
    // CMS Service
    {
      provide: APP_INITIALIZER,
      useFactory: cmsEntriesServiceFactory,
      deps: [ContentfulEntriesHttpService],
      multi: true
    },
    { provide: CMS_LIB_ADAPTER, useClass: CountryAdapter, multi: true },
    { provide: CMS_LIB_ADAPTER, useClass: CountryListAdapter, multi: true },
    { provide: CMS_LIB_ADAPTER, useClass: EmailTemplateAdapter, multi: true },
    { provide: CMS_LIB_ADAPTER, useClass: FeatureRichTextAdapter, multi: true },
    { provide: CMS_LIB_ADAPTER, useClass: FeatureTextAdapter, multi: true },
    { provide: CMS_LIB_ADAPTER, useClass: ProductInfoPageAdapter, multi: true },
    { provide: CMS_LIB_ADAPTER, useClass: StartpageContentAdapter, multi: true },
    { provide: CMS_LIB_ADAPTER, useClass: HelpTextAdapter, multi: true },
    { provide: CMS_LIB_ADAPTER, useClass: InfoBannerAdapter, multi: true },
    { provide: CMS_LIB_ADAPTER, useClass: CuratedListOrderAdapter, multi: true },
    { provide: CMS_LIB_ADAPTER, useClass: FeatureMediaAdapter, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
