<ngx-gallery
  #ngxGalleryComponentRef
  [options]="galleryOptions"
  [images]="galleryImages"
  (change)="onChange($event)"
  (previewChange)="onPreviewChange($event)"
  (previewOpen)="toggleIsPreviewOpen(true)"
  (previewClose)="toggleIsPreviewOpen(false)"
  class="ngx-gallery mb-3"></ngx-gallery>

<hr *ngIf="isRuntimeConfigurationVisible" />

<div *ngIf="isRuntimeConfigurationVisible" [formGroup]="configOverridesForm" class="custom-configuration pt-3">
  <h4>{{ 'images.order.slideshow.dialog.customConfigurationLabel' | translate }}</h4>
  <div class="form-row">
    <div class="form-group col-12">
      <div class="custom-control custom-checkbox">
        <input
          formControlName="descriptionEnabled"
          id="isPreviewDescriptionEnabled"
          type="checkbox"
          class="custom-control-input" />
        <label
          title="{{ 'images.order.slideshow.dialog.showImageDescription' | translate }}"
          for="isPreviewDescriptionEnabled"
          class="custom-control-label">
          {{ 'images.order.slideshow.dialog.showImageDescription' | translate }}
        </label>
      </div>
    </div>
  </div>

  <div class="form-row">
    <div class="form-group col-12 col-md-6">
      <div class="slider-label-container slider-label-horizontal">
        <label class="slider-name-label">{{ 'images.order.slideshow.dialog.customInterval' | translate }}</label>
        <label class="slider-value-label">{{
          'general.nSeconds' | translate: { n: configOverridesForm.controls.slideInterval.value / 1000 }
        }}</label>
      </div>
      <mat-slider
        class="preview-interval-slider"
        [thumbLabel]="true"
        [displayWith]="formatSliderLabel"
        [max]="slideIntervalConfig.max"
        [min]="slideIntervalConfig.min"
        [step]="slideIntervalConfig.step"
        tickInterval="auto"
        formControlName="slideInterval"
        aria-labelledby="slider-name-label">
      </mat-slider>
    </div>

    <div class="form-group col-12 col-md-6">
      <label for="previewBackgroundColor">{{
        'images.order.slideshow.dialog.customBackgroundColor' | translate
      }}</label>
      <mpac-color-input
        formControlName="previewBackgroundColor"
        (selectedColorValid)="setIsCustomBackgroundColorInvalid($event)"
        id="previewBackgroundColor"></mpac-color-input>
      <small *ngIf="shouldShowColorPickerErrorMessage" class="form-text text-danger">{{
        'forms.errorMessages.shared.invalidColor'
          | translate: { color: configOverridesForm.value.previewBackgroundColor }
      }}</small>
    </div>
  </div>

  <div class="text-center">
    <button class="btn" (click)="startPreviewMode()">
      <span mpacIcon="far fa-play-circle"></span>
      {{ 'images.order.slideshow.dialog.startSlideshow' | translate }}
    </button>
  </div>
</div>
