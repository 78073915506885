<svg width="0" height="0">
  <defs>
    <clipPath id="edge-clip-path">
      <polygon points="-5 -5,60 -5,120 60,120 -5,1000 -5, 1000 1000, -5 1000"></polygon>
    </clipPath>
  </defs>
</svg>

<div class="shadow-container">
  <mpac-registration-form
    *ngIf="registrationData.userName"
    [isRegistrationInProgress]="isRegistrationInProgress"
    [privacyPolicyUrl]="privacyPolicyUrl$ | async"
    [registrationData]="registrationData"
    (dataSubmitted)="submitData($event)"
    [isUserNameDisabled]="true">
  </mpac-registration-form>
</div>
