<label *ngIf="labelTextKey" [attr.for]="htmlIdAttributeValue">{{ labelTextKey | translate }}</label>
<div class="input-group color-input">
  <div class="input-group-prepend">
    <input
      [formControl]="ngControl.control"
      class="input-group-text color-input__picker"
      [name]="namePrefix + '__color-picker'"
      type="color"
      [attr.id]="htmlIdAttributeValue"
      libTrim />
  </div>

  <input
    [disabled]="ngControl.control.disabled"
    [value]="ngControl.control.value"
    (keyup)="colorStringValueChanged($event)"
    (blur)="markControlAsDirty()"
    libTrim
    class="form-control color-input__text-input"
    [ngClass]="{ 'is-invalid': !ngControl.control.valid && ngControl.control.touched && !ngControl.control.disabled }"
    [name]="namePrefix + '__text-input'"
    type="text" />
</div>
<mpac-print-form-errors-for [control]="ngControl.control"></mpac-print-form-errors-for>
