/* eslint-disable @typescript-eslint/adjacent-overload-signatures */
import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { FormsService } from '@rapid/forms';
import { EmailSettings } from '@shared/models/email-settings';
import { UpdateEmailSetting } from '@shared/models/update-email-setting';

@Component({
  selector: 'mpac-email-settings-form',
  templateUrl: './email-settings-form.component.html',
  styleUrls: ['./email-settings-form.component.scss']
})
export class EmailSettingsFormComponent implements OnInit {
  public static baseClass = 'mpac-form mpac-form--estg';

  @HostBinding('class') hostClassBinding;

  @Output()
  public toggleClicked = new EventEmitter<UpdateEmailSetting>();

  public inputData: EmailSettings;

  public form: UntypedFormGroup;

  private busy = false;

  private isMorticianUser: boolean;

  constructor(protected formBuilder: UntypedFormBuilder,
    protected translateService: TranslateService,
    protected formsService: FormsService
  ) {
  }

  public get isMortician(): boolean {
    return this.isMorticianUser;
  }

  public get isBusy(): boolean {
    return this.busy;
  }


  @Input()
  public set isMortician(value: boolean) {
    this.isMorticianUser = value;
  }

  @Input()
  public set isBusy(value: boolean) {
    if (!this.form) {
      return;
    }

    if (value === true) {
      this.form.disable();
      this.busy = true;
      return;
    }

    this.form.enable();
    this.busy = false;
  }

  ngOnInit(): void {
    this.hostClassBinding = EmailSettingsFormComponent.baseClass;
  }

  public setDataAndInitForm(formData: EmailSettings) {
    this.inputData = formData;
    this.initForm();
  }

  public initForm(): void {
    this.form = this.formBuilder.group({
      orderAdminInvitationSent: [ this.inputData?.isOrderAdminInvitationSentEmailEnabled ?? true ],
      orderAdminRegistered: [ this.inputData?.isOrderAdminDeRegisteredEmailEnabled ?? true ],
      orderAdminDeRegistered: [ this.inputData?.isOrderAdminDeRegisteredEmailEnabled ?? true ],
      companyUserRegistered: [ this.inputData?.isCompanyUserRegisteredEmailEnabled ?? true ],
      memorialPageVisibilityEndsReminder: [ this.inputData?.isMemorialPageVisibilityEndsReminderEmailEnabled ?? true ]
    });
  }

  public onToggleClicked(event) {
    const data = {
      emailKey: event.target.getAttribute('id'),
      isEnabled: event.currentTarget.checked
    } as UpdateEmailSetting;

    this.toggleClicked.emit(data);
  }
}
