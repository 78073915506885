import { Component, ContentChild, TemplateRef, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'mpac-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ToolbarComponent {
  @ContentChild(TemplateRef) toolbarListRef;

  constructor() {}
}
