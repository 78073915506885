import { BrandStatus } from './brand-status.enum';
import { PartnerActionType } from './partner-action-type.enum';
import { ParameterHelpType } from './parameter-help-type.enum';

export interface ProductAutocompleteListItem {
  productId: string;
  productName: string;
  brandName: string;
  brandAndProductName: string;
  replacementBrandName: string;
  shortcut: string;
  allowedOrderExtensionTypes: PartnerActionType[];
  parameterHelps: ParameterHelpType[];
  brandStatus: BrandStatus;
  countryCode: string;
  domain: string;
  productNotes: ProductNote[];
}

export interface ProductNote {
  productNoteHinweisText: string;
  responsibleForType: PartnerActionType;
  noteEnum: NoteType;
  significance: Significance;
}

export enum Significance {
  UNDEFINED = 'UNDEFINED',
  HINT = 'HINT',
  HELPFULL = 'HELPFULL',
  REQUIRED = 'REQUIRED',
  ORIGINALREQUIRED = 'ORIGINAL_REQUIRED'
}

export enum NoteType {
  UNDEFINED = 'UNDEFINED',
  NEEDSORIGINAL = 'NEEDS_ORIGINAL',
  POLICYNEEDED = 'POLICY_NEEDED',
  INHERITANCE = 'INHERITANCE',
  DEPENDANTID = 'DEPENDANT_ID',
  OTHER = 'OTHER',
  DEATHCERTIFICATENEEDED = 'DEATH_CERTIFICATE_NEEDED',
  WIDOWPENSION = 'WIDOW_PENSION',
  CERTIFICATEOFINHERITANCEAUSTRIAN = 'CERTIFICATE_OF_INHERITANCE_AUSTRIAN'
}
