import { Component, Input } from '@angular/core';

@Component({
  selector: 'mpac-spinner-full',
  templateUrl: './spinner-full.component.html',
  styleUrls: ['./spinner-full.component.scss']
})
export class SpinnerFullComponent {
  /**
   * Indicates busy state
   */
  @Input()
  public isBusy: boolean;

  /**
   * classes the .spinner-wrapper has to have
   */
  @Input()
  public wrapperClasses = '';

  /**
   * placeholder minimum height in vh (25, 50, 75, 100)
   */
  @Input()
  public placeholderMinHeight = '';

  /**
   * additional text to display
   */
  @Input()
  public spinnerText = '';

  /**
   * indicates if the content is visible while busy state
   */
  @Input()
  public displayContentWhileBusy = false;

  /**
   * if true, the original content is responsible for the taken space and the placeholder is only an overlay
   * else the placeholder take the space while busy
   */
  @Input()
  public overlayMode = true;

  /**
   * background of the overlay
   */
  @Input()
  public overlayBg = 'transparent';

  /**
   * classes the spinner element has to have
   * default is a large spinner of type border
   */
  @Input()
  public spinnerClasses = 'spinner-border spinner-border-lg';

  constructor() {}
}
