import { Component, Input } from '@angular/core';

export type TooltipPlacementType = 'top' | 'right' | 'bottom' | 'left' | 'auto';

@Component({
  selector: 'mpac-tooltip-container',
  templateUrl: './tooltip-container.component.html',
  styleUrls: ['./tooltip-container.component.scss']
})
export class TooltipContainerComponent {
  @Input() textKey: string;

  @Input() textKeyParams = {};

  @Input() text: string;

  @Input() iconCssClassNames: string;

  @Input() placement: TooltipPlacementType = 'auto';

  @Input() tooltipContainer = 'body';

  private readonly defaultIconClassNames = 'fal fa-info-circle';

  public get iconClassNames(): string {
    return this.iconCssClassNames || this.defaultIconClassNames;
  }
}
