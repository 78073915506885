import { DesctructableComponent } from 'src/app/shared/components/destructable/destructable.component';
import { IFileUploadResult } from './../../interfaces/file-upload-result.interface';
import { HttpEvent, HttpEventType, HttpResponse } from '@angular/common/http';
import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, UntypedFormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FileUploadControl, FileUploadValidators, ValidatorFn } from '@iplab/ngx-file-upload';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, takeUntil, tap } from 'rxjs/operators';
import { serializeError } from 'serialize-error';
import { IFileUploadStatus } from '../../models/file-upload-status.interface';
import { FileUploadService } from '../../services/file-upload.service';

@Component({
  selector: 'mpac-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MpacFileUploadComponent),
      multi: true
    }
  ]
})
export class MpacFileUploadComponent extends DesctructableComponent implements ControlValueAccessor, OnInit {
  /**
   * Whether or not multiple files are allowed.
   */
  @Input()
  public multiple: boolean;

  @Input()
  public value: Array<File>;

  @Input()
  public isAudioFile?: false;

  /**
   * Comma separated list of mime types the control should accept.
   */
  @Input()
  public accept: string;

  @Input()
  public maxFileSize: number;

  @Input()
  public controlName: string;

  @Input()
  public formGroup: UntypedFormGroup;

  /**
   * Whether or not images should be uploaded immediately after selection/drop.
   */
  @Input()
  public uploadOnSelect = false;

  /**
   * Whether or not the form validity state should be checked before uploading.
   */
  @Input()
  public checkFormValidationBeforeUpload = true;

  @Input()
  public disabled = false;

  @Input()
  public fileuploadService: FileUploadService;

  /**
   * General upload metadata to add when calling the service.
   *
   * @example [{"companyPk: "xyz"}]
   */
  @Input()
  public uploadMetaData: Array<{ key: string; value: string }>;

  /**
   * Metadata for individual images to be added when calling the service.
   *
   * @example [{"description: "xyz"}]
   */
  @Input()
  public uploadFileMetaData: Array<{ key: string }>;

  @Output()
  public uploadDone = new EventEmitter<IFileUploadResult>();

  @Output()
  public uploadStarted = new EventEmitter<void>();

  @Output()
  public uploadInitDone = new EventEmitter<MpacFileUploadComponent>();

  public onChange = new EventEmitter<any>();

  public onTouched = new EventEmitter<any>();

  /**
   * Map that contains the upload progress for each file
   */
  public uploadProgress = new Map<number, IFileUploadStatus>();

  /**
   * Map that contains the upload progress for each file
   */
  public fileValidationMessages = new Map<number, Array<string>>();

  public uploadInProgress: boolean;

  /**
   * The control, which handles the files to upload.
   */
  public fileUploadControl = new FileUploadControl();

  /**
   * Validator for file mime
   */
  private filesAcceptValidator: ValidatorFn;

  constructor(private translate: TranslateService) {
    super();
  }

  public get placeholderTextTranslationKey(): string {
    return `forms.placeholders.shared.${this.placeholderKeyPostfix}`;
  }

  public get placeholderKeyPostfix(): string {
    if (this.isAudioFile) return 'audio';

    if (this.accept.includes('pdf')) {
      return this.multiple ? 'files' : 'file';
    }
    return this.multiple ? 'images' : 'image';
  }

  public ngOnInit(): void {
    this.subscribeToChanges();
    this.uploadInitDone.emit(this);
    const acceptWithoutWhiteSpace = this.accept.replace(/[ ,]+/g, ',');
    this.fileUploadControl.setListVisibility(false).acceptFiles(acceptWithoutWhiteSpace);
    this.filesAcceptValidator = FileUploadValidators.accept(acceptWithoutWhiteSpace.split(','));
  }

  // #region Implements ControlValueAccessor
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public writeValue(obj: any): void {
    this.value = obj;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public registerOnChange(fn: any): void {
    this.onChange.emit(fn);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public registerOnTouched(fn: any): void {
    this.onChange.emit(fn);
  }

  public setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
    if (isDisabled) {
      this.fileUploadControl.enable();
    } else {
      this.fileUploadControl.disable();
    }
  }

  /**
   * Triggers the file upload for the selected elements.
   * Form validation will be checked before running the upload.
   */
  public triggerUpload(): void {
    this.upload();
  }
  // #endregion

  /**
   * Removes the given file from the list of selected files to upload.
   */
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public removeFile(file: File) {
    this.fileUploadControl.removeFile(file);
  }

  /**
   * @returns The validation messages for the given file.
   */
  public validateFile(file: File): Array<string> {
    const messages = [];
    if (file.size > this.maxFileSize) {
      messages.push(
        this.translate.instant('forms.errorMessages.shared.fileSizeSingle', {
          fileSize: this.maxFileSize / 1024 / 1024
        })
      );
    }

    const anyErrors = this.filesAcceptValidator(new UntypedFormControl([file]));
    if (anyErrors) {
      messages.push(
        this.translate.instant('forms.errorMessages.shared.fileTypes', {
          type: file.name.substring(file.name.lastIndexOf('.'), file.name.length)
        })
      );
    }
    return messages;
  }

  /**
   * Iterates the files and adds all file validation messages to the given files.
   */
  public validateFiles(): void {
    if (this.value && this.value.length) {
      for (let i = 0, iLen = this.value.length; i < iLen; ++i) {
        const file = this.value[i];
        const messages = this.validateFile(file);
        if (messages && messages.length) {
          this.fileValidationMessages.set(i, messages);
        } else {
          if (this.fileValidationMessages.has(i) && !!messages) {
            this.fileValidationMessages.delete(i);
          }
        }
      }
    } else {
      this.fileValidationMessages.clear();
    }
  }

  /**
   * Resets the file upload, progress and value assignment.
   */
  public reset(): void {
    this.fileUploadControl.setValue([]);
    this.fileValidationMessages.clear();
    this.value = [];
    this.clearProgress();
    this.uploadInProgress = false;
  }

  /**
   * @returns Whether or not the component is valid.
   */
  public isvalid(): boolean {
    const imageControl = this.formGroup.get(this.controlName);
    return !(imageControl.touched && imageControl.errors);
  }

  /**
   * Upload method that is called from within the code and may be overwriten by child components.
   */
  protected upload(): void {
    if (this.isvalid() && this.value && this.value.length) {
      this.uploadStarted.emit();

      forkJoin(this.createUploadTasks())
        .pipe(takeUntil(this.shutdown$))
        .subscribe((results: HttpResponse<any>[]) => {
          const isSuccess = this.fileuploadService.areResultSuccess(results);
          this.uploadDone.emit({
            canceled: false,
            failure: !isSuccess,
            success: isSuccess,
            results
          } as IFileUploadResult);
        });
    } else {
      this.uploadDone.emit({
        canceled: true,
        failure: false,
        success: false,
        results: []
      } as IFileUploadResult);
    }
  }

  /**
   * Subscribes to the file upload change "event".
   */
  private subscribeToChanges(): void {
    this.fileUploadControl.valueChanges
      .pipe(takeUntil(this.shutdown$))
      .subscribe((files: File[]) => this.fileChanges(files));
  }

  /**
   * Called when the files of the upload control change.
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private fileChanges(files: Array<File>): void {
    this.value = this.fileUploadControl.value;
    this.validateFiles();

    if (this.uploadOnSelect && this.isvalid() && this.value && this.value.length) {
      this.triggerUpload();
    }
  }

  private clearProgress(): void {
    if (this.uploadProgress) {
      this.uploadProgress.clear();
    }
  }

  /**
   * Collects the file metadaa from the form.
   */
  private collectFileMetaData(): { key: string; value: string }[] {
    const fileMeta: Array<{ key: string; value: string }> = [];
    if (this.uploadFileMetaData) {
      for (const meta of this.uploadFileMetaData) {
        fileMeta.push({ key: meta.key, value: this.formGroup.get(meta.key)?.value });
      }
    }
    return fileMeta;
  }

  /**
   * Creates the upload tasks for all individual image and handles the progress event.
   */
  private createUploadTasks(): Array<Observable<any>> {
    const uploadFileMetaData = this.collectFileMetaData();
    // metadatas for images and general upload
    const uploadResults = this.value.map(
      (file: File, index: number): Observable<any> =>
        this.fileuploadService.uploadFiles(file, this.uploadMetaData, uploadFileMetaData).pipe(
          takeUntil(this.shutdown$),
          tap((event: HttpEvent<any>) => {
            let progressEvent;
            switch (event.type) {
              case HttpEventType.UploadProgress:
                progressEvent = event;
                this.uploadProgress.set(index, {
                  progress: Math.floor((100 * progressEvent.loaded) / progressEvent.total),
                  failure: false,
                  success: false
                } as IFileUploadStatus);
                break;

              case HttpEventType.Response:
                this.uploadProgress.set(index, {
                  progress: 100,
                  failure: false,
                  success: true
                } as IFileUploadStatus);
                break;

              case HttpEventType.Sent:
                break;

              default:
                console.warn(`unknown event type #${index}`);
            }
          }),
          catchError((err) => {
            this.uploadProgress.set(index, {
              progress: 100,
              success: false,
              failure: true
            } as IFileUploadStatus);
            return of(serializeError(err));
          })
        )
    );

    return uploadResults;
  }
}
