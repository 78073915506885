import { DesctructableComponent } from 'src/app/shared/components/destructable/destructable.component';
import { Component, HostBinding, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Breakpoints } from '@core/helper/breakpoints';
import { selectIsBreakpointAbove } from '@core/store/display/display.reducer';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EventService } from '../../../core/services/event.service';
import { selectUserLevel } from '../../../core/store/user/user.reducer';
import { MenuSection } from '../../models/menu-item.interface';

@Component({
  selector: 'mpac-portal-layout',
  templateUrl: './portal-layout.component.html',
  styleUrls: ['./portal-layout.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PortalLayoutComponent extends DesctructableComponent implements OnInit {
  public static baseClass = 'mpac-portal-layout';

  public static baseClassMobile = 'mpac-portal-layout--mobile';

  @HostBinding('class') hostClassBinding;

  /**
   * menu items associated to the current portal to pass into side menu
   */
  @Input() public menuSections: MenuSection[] = [];

  public isMobile: boolean;

  /**
   * Access level of the current user
   */
  public userLevel$: Observable<number>;

  constructor(private eventService: EventService, private store: Store<any>) {
    super();
    this.subscribeToBreakpointChanges();
  }

  public ngOnInit(): void {
    this.setHostClassBinding();
    this.userLevel$ = this.store.pipe(select(selectUserLevel));
  }

  /**
   * Handles ScrollEvents of the scrollable main area
   */
  public handleScroll(): void {
    this.eventService.emitPageScrollReachesEndEvent();
  }

  protected setHostClassBinding(): void {
    const bindings = [PortalLayoutComponent.baseClass];
    if (this.isMobile) {
      bindings.push(PortalLayoutComponent.baseClassMobile);
    }
    this.hostClassBinding = bindings.join(' ');
  }

  protected subscribeToBreakpointChanges(): void {
    this.store
      .pipe(takeUntil(this.shutdown$), select(selectIsBreakpointAbove, Breakpoints.MD))
      .subscribe((notMobile) => {
        this.isMobile = !notMobile;
        this.setHostClassBinding();
      });
  }
}
