import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'mpac-not-implemented',
  templateUrl: './not-implemented.component.html',
  styleUrls: ['./not-implemented.component.scss']
})
export class NotImplementedComponent implements OnInit {
  /**
   * Headline to display
   */
  public headline: string;

  constructor(private route: ActivatedRoute) {}

  public ngOnInit(): void {
    this.headline = this.route.snapshot.data.headline;
  }
}
