import { DesctructableComponent } from 'src/app/shared/components/destructable/destructable.component';
import { SettingsService } from 'src/app/core/config/settings.service';
import { Component, OnInit } from '@angular/core';
import { CmsService, HelpText, ContentfulEntriesService } from '@rapid/cms-lib';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'mpac-terms-of-use',
  templateUrl: './terms-of-use.component.html',
  styleUrls: ['./terms-of-use.component.scss']
})
export class TermsOfUseComponent extends DesctructableComponent implements OnInit {
  /**
   * Text content describing the terms of use for uploading things,
   * fetched from the Contentful CMS.
   */
  public termsOfUseText: string;

  public loading = true;

  public privacyPolicyUrl: string;

  private readonly termsTextId: string;

  constructor(
    private settingsService: SettingsService,
    private cmsService: CmsService,
    private contentfulEntriesService: ContentfulEntriesService
  ) {
    super();
    this.termsTextId = this.contentfulEntriesService.instant('helpText.termsAndProtection');
  }

  public ngOnInit(): void {
    this.cmsService
      .getFieldById(this.termsTextId)
      .pipe(
        takeUntil(this.shutdown$),
        filter((res) => !!res)
      )
      .subscribe(
        (touText: HelpText) => {
          this.termsOfUseText = touText.content;
          this.loading = false;
        },
        () => {
          this.loading = false;
          this.termsOfUseText = null;
        }
      );
    this.privacyPolicyUrl = this.settingsService.settings.privacyPolicyUrl;
  }
}
