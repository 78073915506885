import { Expose, Transform } from 'class-transformer';

export class DemoMorticianSettings {
  @Expose({ name: 'DEMO_DEPENDANT_IS4_USER_ID' })
  public dependantIds4UserId: string;

  @Expose({ name: 'DEMO_DEPENDANT_FEATURE_ENABLED' })
  @Transform(({ value }) => {
    if (value && value === 'true') {
      return true;
    }
    return false;
  })
  public dependantDemoFeatureEnabled: boolean;

  public dependantOrderId = 'DemoOrder2021';

  public dependantFuneralDocumentsImages = '1,2,3,4';

  public dependantColumbaOrderId = '9999';

  public dependantQuickformPartnerActions = '1,2,3,4';

  public dependantQuickformPartnerActionsDocuments = '3.1.pdf';

  public dependantQuickformOrderDocuments = '1.pdf,2.pdf';

  public dependantFuneralAddressAddressListIdForCsvExport = '1a';

  public dependantFuneralAddressSectionIdForCsvExport = '1s';
}
