import { FontAwesomeTypes } from './../../enums/font-awesome-types.enum';
import { IDynamiTableColFunction } from './../../models/dyn-table/dynamic-table-column-function.model';
import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit } from '@angular/core';

@Component({
  selector: 'mpac-dynamic-table-cell-functions',
  templateUrl: './dynamic-table-cell-functions.component.html',
  styleUrls: [
    './dynamic-table-cell-functions.component.variables.scss',
    './dynamic-table-cell-functions.component.scss'
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DynamicTableCellFunctionsComponent implements OnInit {
  public static baseClass = 'mpac-cell-fcts';

  @HostBinding('class') hostClassBinding;

  /**
   * The definition for the cell function(s) to be displayed.
   */
  @Input()
  public cellFunctions: Array<IDynamiTableColFunction>;

  /**
   * The data to get the relevant information for params from.
   */
  @Input()
  public data: any;

  public iconTypeEnum = FontAwesomeTypes;

  constructor() {}

  public ngOnInit(): void {
    this.setHostClassBinding();
  }

  /**
   * Calls the functions provided by the config and inserts the params given by the data.
   */
  public apply(event: Event, fct: IDynamiTableColFunction, index?: number, currentValue: any = null): void {
    const call = fct.call;
    if (call && typeof call === 'function') {
      // Apply args
      const args = [];
      for (const paramKey of fct.callParams) {
        if (!paramKey) {
          continue;
        }
        args.push(this.data[paramKey]);
        if (index >= 0) {
          args.push(index);
        }
      }

      if (currentValue !== null && currentValue !== undefined) {
        args.push(currentValue);
      }
      // call
      call.apply(fct.thisRef, args);
      event.stopImmediatePropagation();
    }
  }

  /**
   * Gets the icon name from the fct definition or the data binding.
   */
  public getIconName(fct: IDynamiTableColFunction, index?: number): string {
    if (fct.iconName) {
      return fct.iconName;
    }

    if (fct.dataBindingKey) {
      return Array.isArray(this.data[fct.dataBindingKey])
        ? this.data[fct.dataBindingKey][index]
        : this.data[fct.dataBindingKey] || '';
    }
  }

  /**
   * Gets value from the data binding.
   */
  public getValue(fct: IDynamiTableColFunction, index?: number): string | number | boolean {
    if (fct.dataBindingKey) {
      return Array.isArray(this.data[fct.dataBindingKey])
        ? this.data[fct.dataBindingKey][index]
        : this.data[fct.dataBindingKey] || '';
    }
    return null;
  }

  /**
   * Checks whether or not a call is set.
   */
  public isCallSet(fct: IDynamiTableColFunction): boolean {
    return !!fct && fct.call && typeof fct.call === 'function';
  }

  /**
   * Get data of dataBindingKey if multi is true
   */
  public getDataArray(fct: IDynamiTableColFunction): Array<string> {
    if (!!fct.dataBindingKey && Array.isArray(this.data[fct.dataBindingKey])) {
      return this.data[fct.dataBindingKey] || [];
    }
    return [];
  }

  /**
   * Checks whether all cell functions are multi
   */
  public allFctsMulti(fct: IDynamiTableColFunction[]): boolean {
    return fct.every((f) => f.multi);
  }

  /**
   * Whether or not the function should be rendered.
   */
  public shouldRender(fct: IDynamiTableColFunction): boolean {
    if (fct && fct.optionalRendering && fct.optionalRendering.enabled) {
      if (this.data[fct.optionalRendering.bindingRef] !== fct.optionalRendering.value) {
        return false;
      }
    }
    return true;
  }

  public checkboxClicked(event: Event, fct: IDynamiTableColFunction, index?: number): void {
    let newValue = null;
    if (Array.isArray(this.data[fct.dataBindingKey])) {
      this.data[fct.dataBindingKey][index] = newValue = !this.data[fct.dataBindingKey][index];
    } else {
      this.data[fct.dataBindingKey] = newValue = !this.data[fct.dataBindingKey];
    }
    this.apply(event, fct, index, newValue);
  }

  public getTitleText(fct: IDynamiTableColFunction): string {
    if (fct.nameDataBindingKey) {
      return `${fct.name}: ${this.data[fct.nameDataBindingKey] as string}`;
    }
    return fct.name;
  }

  protected setHostClassBinding(): void {
    this.hostClassBinding = `${DynamicTableCellFunctionsComponent.baseClass}`;
  }
}
