import { createAction, props } from '@ngrx/store';
import { PrecautionInfoConfiguration } from 'src/app/shared/models/precaution-info-configuration.interface';

/* another precaution info store exists in the company feature, hence all actions here have "Order" added to their type
   if this feature store is removed, consider removing these add ons company store */

export const loadPrecautionInfo = createAction(
  '[PrecautionInfo | Order] Load Precaution Info configuration',
  props<{ id: string }>()
);

export const loadPrecautionInfoSuccess = createAction(
  '[PrecautionInfo | Order] Load Precaution Info configuration success',
  props<{ config: PrecautionInfoConfiguration }>()
);

export const requestAdditionalInformation = createAction(
  '[PrecautionInfo | Order] request Precaution Info',
  props<{ orderId: number; recipient: string }>()
);

export const requestAdditionalInformationSuccess = createAction(
  '[PrecautionInfo | Order] request Precaution Info success'
);

export const requestAdditionalInformationFailure = createAction(
  '[PrecautionInfo | Order] request Precaution Info failure',
  props<{ message: string }>()
);

export const noopAction = createAction('[PrecautionInfo | Order] Precaution Info NoopAction');
